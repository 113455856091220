import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { toast } from "react-toastify";
import bitcoinImg from "../../assets/img/bitcoin.svg?v3";
import bitcoinImgOrange from "../../assets/img/Orangebitcoin.png";
import DecImgLogo from "../../assets/img/sharpDecLogo.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dollarImgLogo from "../../assets/img/dollar-icon.png";
import ethImgLogo from "../../assets/img/ethereum-eth-logo.svg";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  hashrateConverter,
  getBtcUsd,
} from "../../utils/Util";
import Api from "../../services/api";
import validators from "../../validators";
import { tokenSymbol } from "../../services/api-config";

class AdminUserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      machineRenderFlag: false,
      fullname: "",
      email: "",
      manualContributed: 0,
      ethContributed: 0,
      btcContributed: 0,
      ltcContributed: 0,
      role: "",
      usdcContributed: 0,
      btcWalletdateCreated: "",
      isHavingBudget: "",
      decWalletDateCreated: "",
      walletData: "",
      isKycVerified: "",
      isEmailVerified: "",
      phoneNumber: "N/A",
      withdrawBTCWallet: "",
      withdrawETHWallet: "",
      receivingETHWallet: "",
      receivingBTCWallet: "",
      page: 1,
      sizePerPage: 10,
      filters: "",
      emailStatus: "",
      kycStatus: "",
      loading: false,
      buttonLoading: false,
      transactionModalOpen: false,
      transactionAmount: 0,
      tokens: "",
      receivingEthOriginalAddress: "",
      receivingEthAddress: "",
      machinePage: 1,
      macineSizePerPage: 10,
      machineLoading: false,
      timeHorizon: "24h",
      earningDetails: {},
    };
    this.validators = validators;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showTransactionModal = this.showTransactionModal.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.AddTransactionRequest = this.AddTransactionRequest.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.selectTimeHorizon = this.selectTimeHorizon.bind(this);
    this.copyWhiteListAddress = this.copyWhiteListAddress.bind(this);
    this.handleEarningTableChange = this.handleEarningTableChange.bind(this);
    this.selectBaseCurrency = this.selectBaseCurrency.bind(this);
    this.editMachine = this.editMachine.bind(this);
  }

  copyWhiteListAddress() {
    let msg = "Address successfully copied!";
    toast.success(msg);
  }
  async componentDidMount() {
    // this.getEarningRecords();
    const api = new Api();
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    try {
      const response1 = await api
        .setToken(authenticationToken)
        .create("admin/getWalletInfo", { minerId: userId });
      if (response1.code === 200) {
        this.setState({
          btcWalletdateCreated: response1.data.btcWalletAddress[0].timestamp,
        });
      }
      const response = await api
        .setToken(authenticationToken)
        .get("admin/get/user/details", { userId: userId });
      if (response.code === 200) {
        this.setState(
          {
            fullName: response.data.user.fullName,
            email: response.data.user.email,
            manualContributed: response.data.user.manualContributed,
            ethContributed: response.data.user.ethContributed,
            btcContributed: response.data.user.btcContributed,
            ltcContributed: response.data.user.ltcContributed,
            isHavingBudget: response.data.user.isHavingBudget,
            role: response.data.user.role,
            withdrawETHWallet: response.data.user.withdrawEthAddress
              ? response.data.user.withdrawEthAddress
              : "",
            withdrawBTCWallet: response.data.user.withdrawBtcAddress
              ? response.data.user.withdrawBtcAddress
              : "",
            // receivingEthAddress:response.data.user.
            receivingBTCWallet: response.data.user.btcWalletAddress
              ? response.data.user.btcWalletAddress
              : "",
            usdcContributed: response.data.user.usdcContributed,
            isKycVerified: response.data.user.isKycVerified,
            decWalletDateCreated:
              response.data.user.receivingEthAddressCreatedDate,
            isEmailVerified: response.data.user.isEmailVerified,
            phoneNumber: response.data.user.phoneNumber,
            depositeAddress: response.data.user._id,
            identificationDataFilled:
              response.data.user.identificationDataFilled,
            fatcaCrsDataFilled: response.data.user.fatcaCrsDataFilled,
            tokens: response.data.user.tokens,
            receivingEthOriginalAddress:
              response.data.user &&
              response.data.user.receivingEthOriginalAddress
                ? response.data.user.receivingEthOriginalAddress
                : "",
            receivingEthAddress:
              response.data.user && response.data.user.receivingEthAddress
                ? response.data.user.receivingEthAddress
                : "",
          }
          // async () => {

          //   if (typeof this.props.pageProgress === "function") {
          //     this.props.pageProgress("remove");
          //   }
          // }
        );
      }
      let walletData = [
        {
          asset: "BTC",
          walletAddress: this.state.withdrawBTCWallet,
          type: "Withdraw",
          network: "BTC",
        },
        {
          asset: "BTC",
          walletAddress: this.state.receivingBTCWallet,
          type: "Deposit",
          date: this.state.btcWalletdateCreated
            ? moment(this.state.btcWalletdateCreated).format("DD MMM YYYY")
            : "",
          network: "BTC",
        },
        {
          asset: "DEC",
          walletAddress: this.state.withdrawETHWallet,
          type: "Withdraw",
          network: "ERC20",
        },
        {
          asset: "DEC",
          walletAddress: this.state.receivingEthAddress,
          type: "Deposit",
          date: this.state.decWalletDateCreated
            ? moment(this.state.decWalletDateCreated).format("DD MMM YYYY")
            : "",

          network: "ERC20",
        },
      ];
      this.setState({ walletData: walletData });

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
    this.getRecords();
    this.getMachineRecords();
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  selectTimeHorizon(value) {
    this.setState(
      {
        timeHorizon: value,
      },
      () => {
        this.displayEarnings();
      }
    );
  }
  selectBaseCurrency(value) {
    this.setState(
      {
        baseCurrency: value,
      },
      () => {
        this.displayEarnings();
      }
    );
  }

  handleEarningTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, earningsPage: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  async displayEarnings() {
    const {
      timeHorizon,
      baseCurrency,
      yesterdayEarnings,
      yesterdayNetEarnings,
      yesterdayPoolFees,
      yesterdayPowerCost,
      last7DaysEarnings,
      last7DaysPoolFees,
      last7DaysPowerCost,
      last7DaysNetEarnings,
      last30DaysEarnings,
      last30DaysPoolFees,
      last30DaysPowerCost,
      last30DaysNetEarnings,
      accumulatedEarnings,
      pooFeesInBtc,
      accumulatedPowerCost,
    } = this.state;

    const response = {
      grossEarnings: 0,
      poolFees: 0,
      powerCost: 0,
      netEarnings: 0,
      decimal: baseCurrency === "btc" ? 8 : 2,
    };

    const priceUsd = await getBtcUsd();
    let currentBtcPrice = parseFloat(priceUsd);
    const multiplicationRate = baseCurrency === "usd" ? currentBtcPrice : 1;
    if (timeHorizon === "24h") {
      response.grossEarnings =
        parseFloat(yesterdayEarnings) * multiplicationRate;
      response.poolFees = parseFloat(yesterdayPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(yesterdayPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(yesterdayNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "7d") {
      response.grossEarnings =
        parseFloat(last7DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last7DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last7DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last7DaysNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "30d") {
      response.grossEarnings =
        parseFloat(last30DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last30DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last30DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last30DaysNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "all") {
      response.grossEarnings =
        parseFloat(accumulatedEarnings) * multiplicationRate;
      response.poolFees = parseFloat(pooFeesInBtc) * multiplicationRate;
      response.powerCost = parseFloat(accumulatedPowerCost);
      const netEarnings =
        parseFloat(accumulatedEarnings) -
        parseFloat(pooFeesInBtc) -
        parseFloat(accumulatedPowerCost);
      response.netEarnings = parseFloat(netEarnings) * multiplicationRate;
    }
    this.setState({
      earningDetails: response,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, txHash, depositeAddress } = this.state;
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;
    this.setState({ loading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("admin/get/user/transactions/", {
        sizePerPage: sizePerPage,
        page: page,
        txHash: txHash,
        depositeAddress: depositeAddress,
        userId: userId,
      });
    if (response.code === 200) {
      this.setState({
        renderFlag: true,
        loading: false,
        data: response.data.transactions,
        totalSize: response.data.totalTransactions,
      });
    }
  }

  async getMachineRecords() {
    const api = new Api();
    const { macineSizePerPage, machinePage } = this.state;
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;
    this.setState({ machineLoading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("admin/user/machines/", {
        sizePerPage: macineSizePerPage,
        page: machinePage,
        userId,
      });
    if (response.code === 200) {
      this.setState({
        machineLoading: false,
        machineData: response.data.machines,
        machineTotalSize: response.data.totalMachines,
        machineRenderFlag: true,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
      }
    }
  };

  showTransactionModal() {
    this.setState({ transactionModalOpen: true });
  }

  onCloseModal() {
    this.setState({ transactionModalOpen: false });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["transactionAmount"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  async AddTransactionRequest() {
    let authenticationToken = this.props.authToken;
    const { transactionAmount, transactionNote } = this.state;
    const isFormValid = this.isFormValid();
    if (transactionAmount && transactionAmount > 0 && isFormValid) {
      let userId = this.props.match.params.id;
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/manual/transaction/add", {
          userId: userId,
          transactionAmount: transactionAmount,
          transactionNote: transactionNote,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          transactionAmount: 0,
          userNote: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = !isFormValid
        ? "Please enter valid details for required fields."
        : transactionAmount <= 0
        ? "Please enter valid transaction amount."
        : "Something went wrong. Please try again later.";
      toast.error(msg);
    }
  }

  deleteMachine(machineId = "") {
    if (machineId !== "") {
      let _self = this;
      let buttons = "";
      buttons = [
        {
          label: "Delete",
          onClick: () => _self.deleteMachineRequest(machineId),
        },
        {
          label: "Cancel",
        },
      ];

      confirmAlert({
        title: _self.state.isHavingBudget
          ? "Miner is having budget you really want to delete the machine?"
          : "Sure to delete?",
        buttons: buttons,
      });
    }
  }
  editMachine(userId, machineId, history) {
    if (machineId !== "") {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1 className="">
                  Miner is having budget you really want to edit the details?
                </h1>
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    No
                  </button>

                  <button
                    onClick={() => {
                      onClose();
                      history.push({pathname:`/machine/edit/${userId}/${machineId}`,
                      state:{ishavingbudget:true},
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          );
        },
      });
    }
  }

  async deleteMachineRequest(machineId) {
    if (machineId !== "") {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/machine/remove", {
          machineId,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.getMachineRecords();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  }

  async activeUser() {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
    .setToken(authenticationToken)
    .create("admin/activeUser", {
      email: this.state.email,
    });
  if (!_.isUndefined(response)) {
    if (response.code === 200) {
      this.setState({
        isEmailVerified: true
      })
      toast.success(response.message);
      
    } else {
      toast.error(response.message);
    }
  }
}
  

  render() {
    let _self = this;
    const {
      fullName,
      email,
      manualContributed,
      ethContributed,
      btcContributed,
      ltcContributed,
      usdcContributed,
      isKycVerified,
      phoneNumber,
      data,
      sizePerPage,
      page,
      renderFlag,
      loading,
      role,
      isEmailVerified,
      walletData,
      receivingBTCWallet,
      receivingETHWallet,
      withdrawBTCWallet,
      withdrawETHWallet,
      identificationDataFilled,
      fatcaCrsDataFilled,
      tokens,
      receivingEthOriginalAddress,
      receivingEthAddress,
      machineLoading,
      machineData,
      machinePage,
      macineSizePerPage,
      machineTotalSize,
      machineRenderFlag,
      timeHorizon,
      baseCurrency,
      earningDetails,
    } = this.state;

    let emailStatuslabelClassName =
      isEmailVerified === true ? "badge-success" : "badge-danger";
    let emailLabel = isEmailVerified === true ? "Verified" : "Not Verified";

    let kycStatuslabelClassName =
      isKycVerified === true ? "badge-success" : "badge-danger";
    let kycLabel = isKycVerified === true ? "Verified" : "Not Verified";
    let phoneNumberDisplay =
      phoneNumber !== "" && phoneNumber !== null ? phoneNumber : "N/A";
    let userId = this.props.match.params.id;

    let _this = this;
    let history = this.props.history;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "txHash",
        text: "Tx Hash",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div
              className="text-left"
              style={{ width: "150px", overflow: "hidden" }}
            >
              {!_.isUndefined(cell) && (
                <div>
                  <a
                    rel="noopener noreferrer"
                    href={row.txDetailURL}
                    target="_blank"
                    title={cell}
                  >
                    {cell}
                  </a>
                </div>
              )}
              {(_.isUndefined(cell) || cell === "") && (
                <div className="btn btn-primary btn-xs" type="button">
                  N/A
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "txTime",
        text: "Date",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Received",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  cell,
                  row.transactionTypeDisplay
                )}
              >
                {niceNumberDecimalDisplay(cell, 2)} {row.transactionTypeDisplay}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "usdAmount",
        text: "USD Amount",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let usd_amount =
            parseFloat(row.amountRecieve) * parseFloat(row.cryptoLiveRate);
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(usd_amount, "$")}
              >
                {niceNumberDecimalDisplay(usd_amount, 2)}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "totalCoins",
        text: `${tokenSymbol} Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(cell, tokenSymbol)}
              >
                {niceNumberDecimalDisplay(cell, 2)}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "cryptoLiveRate",
        text: "USD Crypto Rate",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(cell, "$", "prepend")}
              >
                {niceNumberDecimalDisplay(cell, 2)}
              </span>
            </div>
          );
        },
      },
    ];
    const walletColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "asset",
        text: "ASSET",
        formatter: function (cell) {
          return (
            <div className="logowithText">
              <span className="p-inherit">
                {cell === "BTC" ? (
                  <div>
                    <img
                      src={bitcoinImgOrange}
                      //  src="https://pngimg.com/uploads/bitcoin/bitcoin_PNG48.png"
                      alt="earnings"
                      height="70"
                      width="70"
                      style={{ marginRight: "5px", marginLeft: "5px" }}
                    />
                  </div>
                ) : (
                  <div style={{ backgroundColor: "" }}>
                    <div>
                      <img
                        src={DecImgLogo}
                        className="setDeclogo"
                        alt="DEC Logo"
                        height="70"
                        width="70"
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                      />
                      {/* <i
                        className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1"
                        style={{
                          fontSize: "18px",
                          color: "#999999",
                          cursor: "pointer",
                          visibility: "hidden",
                          // marginRight: "5px"
                        }}
                      ></i> */}
                    </div>
                  </div>
                )}
              </span>
              {cell === "BTC" ? (
                <div className="decText ml-1">
                  <span class="text-bold">Bitcoin</span>
                  <br />
                  <span>(BTC)</span>
                </div>
              ) : cell === "DEC" ? (
                <div className=" ml-1">
                  <span class="text-bold">Digital Energy Currency</span>
                  <br />
                  <span>(DEC)</span>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "type",
        text: "TYPE",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "network",
        text: "NETWORK",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },

      {
        headerClasses: "custom-table-th",
        dataField: "walletAddress",
        text: "WALLET ADDRESS",
        isDummyField: true,
        formatter: function (cell, row) {
          let smallAddress;
          if (row.walletAddress) {
            smallAddress = String(row.walletAddress);
            smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
              smallAddress.length - 3,
              smallAddress.length
            )}`;
          }
          return row.walletAddress ? (
            <div>
              <span style={{ color: "gray" }}>{smallAddress}</span>
              <CopyToClipboard
                text={row.walletAddress}
                onCopy={() => _this.copyWhiteListAddress()}
              >
                <i
                  className="fa fa-clipboard cursor-pointer ml-2 standardColor"
                  aria-hidden="true"
                ></i>
              </CopyToClipboard>
            </div>
          ) : row.walletAddress === "" && row.asset === "DEC" ? (
            <span>N/A</span>
          ) : (
            <span>N/A</span>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "date",
        text: "DATE CREATED",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
    ];

    const machineColumns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "invoiceNumber",
        text: "Invoice #",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "manufacturer",
        text: "Manufacturer",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "model",
        text: "Model",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "algorithm",
        text: "Algorithm",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "hashrate",
        text: "Hashrate (TH)",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "powerConsumption",
        text: "power Consumption (W)",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "priceInUsd",
        text: "price in USD",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "quantity",
        text: "Quantity",
        sort: true,
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
      },
      {
        headerClasses: "text-bold",
        dataField: "trackLink",
        text: "Operation",
        formatter: function (cell, row) {
          return (
            <div className="d-flex" style={{ overflow: "hidden" }}>
              {!_.isUndefined(cell) && (
                <a
                  className="btn btn-primary btn-xs"
                  rel="noopener noreferrer"
                  href={row.trackLink}
                  target="_blank"
                >
                  Track
                </a>
              )}
              {/* {(_.isUndefined(cell) || cell === "") && <div>N/A</div>} */}
              {(_.isUndefined(cell) || cell === "") && (
                <div className="btn btn-primary btn-xs" type="button">
                  N/A
                </div>
              )}
              {!_this.state.isHavingBudget && (
                <Link to={`/machine/edit/${userId}/${row._id}`}>
                  <button className="btn btn-info btn-xs ml-1" type="button">
                    Edit
                  </button>
                </Link>
              )}
              {_this.state.isHavingBudget && (
                <button
                  className="btn btn-info btn-xs ml-1"
                  type="button"
                  onClick={() => {
                    _this.editMachine(userId, row._id, history);
                  }}
                >
                  Edit
                </button>
              )}

              <button
                className="btn btn-danger btn-xs ml-1"
                type="button"
                onClick={() => _self.deleteMachine(row._id)}
              >
                {" "}
                Delete
              </button>
            </div>
          );
        },
      },
    ];

    const WalletRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={loading}
          data={data}
          columns={walletColumns}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WalletRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={loading}
          data={data}
          columns={columns}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    const MachineRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={machineLoading}
          data={data}
          columns={machineColumns}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    MachineRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <div className="element-header">
                    <div className="d-flex user-detail-heading">
                      <div className="h6">Details</div>
                      <div className="content-end w-100">
                        <div className="">
                          {identificationDataFilled && (
                            <span>
                              <Link
                                to={"/user_identificaiton_detail/" + userId}
                                className="color-white"
                              >
                                <button
                                  className="mr-2 mb-2 btn btn-warning"
                                  type="button"
                                >
                                  {" "}
                                  Identification Details
                                </button>
                              </Link>
                              <Link
                                to={"/identificaiton_details_pdf/" + userId}
                                className="color-white"
                              >
                                <button
                                  className="mr-2 mb-2 btn btn-success"
                                  type="button"
                                >
                                  PDF
                                </button>
                              </Link>
                            </span>
                          )}
                          {fatcaCrsDataFilled && (
                            <Link
                              to={"/user_fatca_crs_detail/" + userId}
                              className="color-white"
                            >
                              <button
                                className="mr-2 mb-2 btn btn-info"
                                type="button"
                              >
                                {" "}
                                FATCA Details
                              </button>
                            </Link>
                          )}
                        </div>
                        <div>
                          <Link to="/manage_users" className="">
                            Back to users list
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Full Name : </span>{" "}
                        {fullName}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">Email : </span>{" "}
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Email Verified :
                        </span>{" "}
                        <span className={"badge " + emailStatuslabelClassName}>
                          {emailLabel}
                        </span>
                        {
                          !isEmailVerified && 
                          <span style={{marginLeft: 10}}>
                             <button className="btn btn-primary" onClick={() => this.activeUser()}>Active User</button>
                             </span>
                        }
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">Kyc Verified :</span>{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Contact No : </span>{" "}
                        {phoneNumberDisplay}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Identification details submitted :
                        </span>{" "}
                        {identificationDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Fatca/CRS details submitted :
                        </span>{" "}
                        {fatcaCrsDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      {/* <div>
                        <span className="font-weight-bold">
                          Whitelisted wallet address :
                        </span>{" "}
                        <span style={{ wordBreak: "break-word" }}>
                          {receivingEthOriginalAddress &&
                          receivingEthOriginalAddress !== ""
                            ? receivingEthOriginalAddress
                            : receivingEthAddress}
                        </span>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          USDC Contribution :{" "}
                        </span>
                        {usdcContributed}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Manual Contribution :{" "}
                        </span>
                        ${manualContributed}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Identification details submitted :
                        </span>{" "}
                        {identificationDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Fatca/CRS details submitted :
                        </span>{" "}
                        {fatcaCrsDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Whitelisted wallet address :
                        </span>{" "}
                        <span style={{ wordBreak: "break-word" }}>
                          {receivingEthOriginalAddress &&
                          receivingEthOriginalAddress !== ""
                            ? receivingEthOriginalAddress
                            : receivingEthAddress}
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {(role === "i" || role === "ci") && (
                <div className="row mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
                  <div className="col-md-12">
                    <div className="dark-blue-theme-color card-inner-padding">
                      <h4 className="card-title text-xl text-default-color">
                        <span className="pull-left">
                          Subscriptions
                          {/* <span>
                        <select
                          className="earnings-select"
                          id="selectedCurrency"
                          name="selectedCurrency"
                          defaultValue="btc"
                          style={{
                            padding: "5px",
                          }}
                          onChange={(e) =>
                            this.selectBaseCurrency(e.target.value)
                          }
                        >
                          <option value="usd">USD</option>
                          <option value="btc">BTC</option>
                        </select>
                      </span> */}
                        </span>
                        {/* <span className="pull-right" style={{ fontSize: "14px" }}>
                      <span
                        style={{
                          padding: "5px 15px",
                          borderRadius: "5px",
                        }}
                        className={
                          timeHorizon === "24h" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("24h")}
                      >
                        24H
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        className={
                          timeHorizon === "7d" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("7d")}
                      >
                        7D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("30d")}
                        className={
                          timeHorizon === "30d" ? "active-currency" : ""
                        }
                      >
                        30D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("all")}
                        className={
                          timeHorizon === "all" ? "active-currency" : ""
                        }
                      >
                        All
                      </span>
                    </span> */}
                      </h4>
                      <div className="clearfix"></div>

                      <div className="row mt-3">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block mb-2 d-flex">
                          <img
                            src={bitcoinImgOrange}
                            height="40"
                            alt="bitcoin"
                          />
                          <div className="dashboard_text_space Earnings-text ml-4">
                            <span className="color-black font-weight-bold">
                              BTC Subscriptions
                            </span>
                            <br />
                            <span className="color-black dashboard-text-bold">
                              {btcContributed}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 mb-2 card-d-block d-flex xxs-earning-card-margin">
                          <img
                            src={ethImgLogo}
                            height="38"
                            width="40"
                            alt="bitcoin"
                          />
                          <div className="dashboard_text_space Earnings-text ml-4">
                            <span className="color-black font-weight-bold ">
                              ETH Subscriptions
                            </span>
                            <br />
                            <span className="color-black dashboard-text-bold">
                              {ethContributed}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 mb-2 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                          <img src={dollarImgLogo} height="35" alt="bitcoin" />
                          <div className="dashboard_text_space Earnings-text ml-4">
                            <span className="color-black font-weight-bold">
                              Manual Subscriptions
                            </span>
                            <br />
                            <span className="color-black dashboard-text-bold">
                              {manualContributed}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
                <div className="col-md-12">
                  <div className="dark-blue-theme-color card-inner-padding">
                    <h4 className="card-title text-xl text-default-color">
                      <span className="pull-left">
                        Wallets
                        {/* <span>
                        <select
                          className="earnings-select"
                          id="selectedCurrency"
                          name="selectedCurrency"
                          defaultValue="btc"
                          style={{
                            padding: "5px",
                          }}
                          onChange={(e) =>
                            this.selectBaseCurrency(e.target.value)
                          }
                        >
                          <option value="usd">USD</option>
                          <option value="btc">BTC</option>
                        </select>
                      </span> */}
                      </span>
                      {/* <span className="pull-right" style={{ fontSize: "14px" }}>
                      <span
                        style={{
                          padding: "5px 15px",
                          borderRadius: "5px",
                        }}
                        className={
                          timeHorizon === "24h" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("24h")}
                      >
                        24H
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        className={
                          timeHorizon === "7d" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("7d")}
                      >
                        7D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("30d")}
                        className={
                          timeHorizon === "30d" ? "active-currency" : ""
                        }
                      >
                        30D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("all")}
                        className={
                          timeHorizon === "all" ? "active-currency" : ""
                        }
                      >
                        All
                      </span>
                    </span> */}
                    </h4>
                    <div className="clearfix"></div>

                    <div className="row mt-3">
                      {renderFlag === true && (
                        <WalletRemoteAll
                          data={walletData}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={this.state.totalSize}
                          onTableChange={this.handleTableChange}
                        />
                      )}
                      {/* <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                        <img src={bitcoinImgOrange} height="40" alt="bitcoin" />
                        <div className="dashboard_text_space Earnings-text ml-4">
                          <span className="color-black font-weight-bold">Withdraw BTC Wallet</span>
                          <br />
                          <span className="color-black dashboard-text-bold wordBreak">
                            {withdrawBTCWallet}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                        <img src={bitcoinImgOrange} height="40" alt="bitcoin" />
                        <div className="dashboard_text_space Earnings-text ml-4">
                          <span className="color-black font-weight-bold">Deposit BTC Wallet</span>
                          <br />
                          <span className="color-black dashboard-text-bold wordBreak">
                          {receivingBTCWallet}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                        <img src={ethImgLogo} height="40" alt="bitcoin" />
                        <div className="dashboard_text_space Earnings-text ml-4">
                          <span className="color-black font-weight-bold">Withdraw ETH Wallet</span>
                          <br />
                          <span className="color-black dashboard-text-bold wordBreak">
                          {withdrawETHWallet}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                        <img src={ethImgLogo} height="40" alt="bitcoin" />
                        <div className="dashboard_text_space Earnings-text ml-4">
                          <span className="color-black font-weight-bold">Deposit ETH Wallet</span>
                          <br />

                          <span className="color-black dashboard-text-bold wordBreak">
                          {receivingEthAddress}
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="element-box mt-3">
                <div>
                  <h6 className="element-header">
                    <span>Transactions</span>
                    <span
                      className="fa fa-refresh ml-2 cursor-pointer"
                      onClick={() => this.refreshCurrenttable()}
                    ></span>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        {renderFlag === true && (
                          <RemoteAll
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={this.state.totalSize}
                            onTableChange={this.handleTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className=" filter-element-wrapper kyc-filter-element-wrapper"> */}
              <div className="element-box mt-3">
                <div>
                  <h6 className="element-header">
                    <span>Machines</span>
                    <span
                      className="fa fa-refresh ml-2 cursor-pointer"
                      onClick={() => this.refreshCurrenttable()}
                    ></span>
                    <span className="pull-right">
                      <Link to={`/user/${userId}/machine/add`}>
                        <button className="btn btn-primary">Add Machine</button>
                      </Link>
                    </span>
                  </h6>
                  <div className="clearfix"></div>
                  <div className="element-wrapper">
                    <div className="element-box horizontal-scroll">
                      <div>
                        {machineRenderFlag === true && (
                          <MachineRemoteAll
                            data={machineData}
                            page={machinePage}
                            sizePerPage={macineSizePerPage}
                            totalSize={this.state.machineTotalSize}
                            onTableChange={this.handleTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminUserDetail;
