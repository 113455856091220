import React from 'react';
import Api from "../../services/api";
import { toast } from 'react-toastify';

class EventVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  async componentDidMount() {
    let verificationCode = this.props.match.params.id;
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    const query = new URLSearchParams(this.props.location.search);
    const txnId = query.get('txnId')
    try {
      const api = new Api();
      const response = await api.setToken(verificationCode).get("admin/blockchain/event/verify", { 'txnId': txnId });
      if (response.code === 200) {
        toast.success(response.message);
        this.props.history.push("/event_success");
      }
      else{
        console.log("It comes here....");
        toast.error(response.message);
        this.props.history.push("/event_error");
      }
    }
    catch (e) {
      this.props.history.push("/event_error");
    }
  }
  
  render() {
    return (
      <div className="register-container"></div>
    );
  }
}
export default EventVerify