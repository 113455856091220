import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import _ from "lodash";
import Web3 from "web3";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import { PERMIAN_SMART_CONTRACT_ADDRESS } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
var tokenAbi = require("../../services/permianTokenABI");

class WhitelistAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      loading: false,
      selectedEthAddresses: [],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.whitelistAllAddress = this.whitelistAllAddress.bind(this);
    this.removeFromWhitelist = this.removeFromWhitelist.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/whitelist/addresses/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.addresses,
            totalSize: response.data.totalAddresses,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async componentDidMount() {
    document.title =
      messageConstants.ADMIN_WHITELIST_ADDRESS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  async whitelistAllAddress() {
    const { selectedEthAddresses } = this.state;
    const api = new Api();
    let authenticationToken = this.props.authToken;
    let _this = this;

    if (!_.isEmpty(selectedEthAddresses) && _.isArray(selectedEthAddresses)) {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();

      // tokenContract.methods.addToWhiteList().call(); // tokenContract.methods.setName("addToWhiteList").send(); // tokenContract.methods.addToWhiteList(whitelistAddresses).send();

      web3.eth.getAccounts().then(function (result) {
        let currentMetamaskAccount = result[0];
        if (!_.isUndefined(currentMetamaskAccount)) {
          const tokenContract = new web3.eth.Contract(
            tokenAbi.TokenABI,
            PERMIAN_SMART_CONTRACT_ADDRESS
          );
          tokenContract.methods
            .addToWhiteList(selectedEthAddresses)
            .send(
              { from: currentMetamaskAccount },
              async function (error, result) {
                if (error !== null) {
                  toast.error(error.message);
                  return false;
                }
                if (result) {
                  const response = await api
                    .setToken(authenticationToken)
                    .create("admin/ethaddress/whitelisted", {
                      selectedEthAddresses,
                      txHash: result,
                    });
                  if (!_.isUndefined(response)) {
                    if (response.code === 200) {
                      _this.setState(
                        {
                          selectedEthAddresses: [],
                        },
                        () => {
                          _this.getRecords();
                          toast.success(response.message);
                        }
                      );
                    } else {
                      toast.error(response.message);
                    }
                  }
                }
              }
            );
        }
      });
    } else {
      toast.error("Please select ETH addresses to whitelist.");
      return false;
    }
  }

  async removeFromWhitelistRequest(requestId) {
    if (requestId !== "") {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/whitelist/request/update", {
          requestId: requestId,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.setState(() => ({
            selectedEthAddresses: this.state.selectedEthAddresses.filter(
              (x) => x !== response.data.whitelistAddress
            ),
          }));
          this.getRecords();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  }

  removeFromWhitelist(requestId) {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Remove",
        onClick: () => _self.removeFromWhitelistRequest(requestId),
      },
      {
        label: "Cancel",
      },
    ];
    confirmAlert({
      title: "Sure to remove address?",
      buttons: buttons,
    });
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedEthAddresses: [...this.state.selectedEthAddresses, row.address],
      }));
    } else {
      this.setState(() => ({
        selectedEthAddresses: this.state.selectedEthAddresses.filter(
          (x) => x !== row.address
        ),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const walletAddresses = rows.map((r) => r.address);
    if (isSelect) {
      this.setState(() => ({
        selectedEthAddresses: walletAddresses,
      }));
    } else {
      this.setState(() => ({
        selectedEthAddresses: [],
      }));
    }
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Name",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.userId.fullName}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.userId.originalEmail}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "phone",
        text: "Phone No",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId.phoneNumber && row.userId.phoneNumber !== ""
                  ? row.userId.phoneNumber
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "address",
        text: "ETH Address",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <button
                    className="mr-2 mb-2 btn btn-danger"
                    type="button"
                    onClick={() => _self.removeFromWhitelist(row._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      selected: this.state.selectedEthAddresses,
    };

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="address"
          bordered={false}
          data={data}
          columns={columns}
          selectRow={selectRow}
          noDataIndication="No whitelisted addresses not found."
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Addresses</h5>
                <form className="form-inline">
                  <label className="sr-only">Name</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByName"
                    id="filterByName"
                    placeholder="Full Name"
                    type="text"
                    onChange={this.onchange}
                    value={filterByName}
                  />
                  <label className="sr-only"> Email</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Email Address"
                    type="text"
                    name="filterByEmail"
                    id="filterByEmail"
                    onChange={this.onchange}
                    value={filterByEmail}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </form>
              </div>
            </div>
            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span className="pull-left">
                    <span>Whitelist Address Requests</span>
                    <span
                      className="fa fa-refresh ml-2 cursor-pointer"
                      onClick={() => this.refreshCurrenttable()}
                    ></span>
                  </span>
                  <span className="pull-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.whitelistAllAddress()}
                    >
                      Whitelist
                    </button>
                  </span>
                </h5>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhitelistAddresses;
