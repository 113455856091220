import _ from "lodash";
import axios from "axios";
import {
    API_ROOT,
    API_NETWORKENVIROMENT,
    API_NETWORK,
} from "../services/api-config";
import { ethers } from "ethers";
import { toast } from "react-toastify";

export const niceNumberDisplay = (val) => {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1,$2");
    }
    return val;
};

export const sendTokens = async(
    tokenContractAddress,
    whitelistedAddress = "",
    tokenValue,
    decimalPoints = 18
) => {
    try {
        console.log("API_NETWORKENVIROMENT", API_NETWORK);
        console.log("tokenContractAddress", tokenContractAddress);
        console.log("receivingAddress", whitelistedAddress);
        console.log("tokenValue", tokenValue);

        if (tokenContractAddress && whitelistedAddress && tokenValue > 0) {
            let finalTokenValue = Math.ceil(Number(tokenValue));
            finalTokenValue = finalTokenValue * Math.pow(10, decimalPoints);
            finalTokenValue = await toPlainString(finalTokenValue);
            const signer = new ethers.providers.Web3Provider(
                window.ethereum
            ).getSigner();
            console.log("signer", signer);
            console.log("window.ethereum", window.ethereum);

            let contractABI = await getABIOfContact(tokenContractAddress);
            const network = API_NETWORKENVIROMENT;

            const provider = ethers.providers.getDefaultProvider(network);
            console.log("provider", provider);
            const contract1 = new ethers.Contract(
                tokenContractAddress,
                contractABI,
                provider
            );

            if (contractABI) {
                const contract = new ethers.Contract(
                    tokenContractAddress,
                    contractABI,
                    signer
                );
                const stakeResponse = await contract.transfer(
                    whitelistedAddress,
                    finalTokenValue
                );
                if (stakeResponse.txHash) {
                    toast.success(`Token issued successfully.`);
                }
                return stakeResponse;
            } else {
                toast.error(`Unable to process for whitelisting transaction.`);
            }
        }
    } catch (error) {
        const errorMessage = error.message;
        console.log(error.message);
        toast.error(errorMessage);
    }
};

const getABIOfContact = async(contractAddress) => {
    try {
        const url = `${API_NETWORK}?module=contract&action=getabi&address=${contractAddress}&apikey=${"APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V"}`;
        const abiData = await axios.get(url);
        return abiData.data.result;
    } catch (error) {
        console.error("error: ", error);
    }
};

export const niceNumberDecimalDisplay = (value, decimalPoint) => {
    let niceNumber = 0;
    if (!_.isUndefined(value)) {
        niceNumber = parseFloat(value);
        let decimal = !_.isUndefined(decimalPoint) ? decimalPoint : 3;
        niceNumber = niceNumber
            .toFixed(decimal)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    return niceNumber;
};

export const tooltipNumberDisplay = (value, symbol, action = "append") => {
    let finalNumber = "";
    if (!_.isUndefined(value) && !_.isNull(value)) {
        let niceNumber = parseFloat(value);
        let numberAsString = value.toString();
        if (
            numberAsString.indexOf(".") === -1 ||
            numberAsString.split(".")[1].length < 3
        ) {
            niceNumber =
                niceNumber > 0 ?
                niceNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") :
                0;
        } else {
            niceNumber =
                niceNumber > 0 ? numberAsString.replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
        }
        if (!_.isUndefined(symbol)) {
            finalNumber =
                action === "append" ?
                niceNumber + " " + symbol :
                symbol + " " + niceNumber;
        } else {
            finalNumber = niceNumber;
        }
    } else {
        finalNumber = 0;
    }
    return finalNumber;
};

export const ethAddressDisplay = (ethAddress) => {
    let ETHAddressDisplay = "";
    if (!_.isUndefined(ethAddress)) {
        ETHAddressDisplay = ethAddress.substr(0, 6) + "..." + ethAddress.substr(-4);
    }
    return ETHAddressDisplay;
};

export const hashrateConverter = async(
    number,
    onlyNumber = false,
    returnInPetaHash = false
) => {
    if (number < 1000) {
        return `${number} H/s`;
    } else {
        let kiloHash = number / 1000;
        let megaHash = number / 1000000;
        let gigahash = number / 1000000000;
        let teraHash = number / 1000000000000;
        let petaHash = number / 1000000000000000;
        let exahash = number / 1000000000000000000;

        if (returnInPetaHash === true) {
            petaHash = await niceNumberDecimalDisplay(petaHash, 2);
            return onlyNumber ? petaHash : `${petaHash} PH/s`;
        }

        if (kiloHash < 1000) {
            kiloHash = await niceNumberDecimalDisplay(kiloHash, 2);
            return onlyNumber ? kiloHash : `${kiloHash} KH/s`;
        }
        if (megaHash < 1000) {
            megaHash = await niceNumberDecimalDisplay(megaHash, 2);
            return onlyNumber ? megaHash : `${megaHash} MH/s`;
        }
        if (gigahash < 1000) {
            gigahash = await niceNumberDecimalDisplay(gigahash, 2);
            return onlyNumber ? gigahash : `${gigahash} GH/s`;
        }
        if (teraHash < 1000) {
            teraHash = await niceNumberDecimalDisplay(teraHash, 2);
            return onlyNumber ? teraHash : `${teraHash} TH/s`;
        }
        if (petaHash < 1000) {
            petaHash = await niceNumberDecimalDisplay(petaHash, 2);
            return onlyNumber ? petaHash : `${petaHash} PH/s`;
        }
        if (exahash < 1000) {
            exahash = await niceNumberDecimalDisplay(exahash, 2);
            return onlyNumber ? exahash : `${exahash} EH/s`;
        }
        return number;
    }
};
export function toFixedCustom(x) {
    if (Math.abs(x) < 1.0) {
        const e = parseInt(x.toString().split("e-")[1], 10);
        if (e) {
            x *= 10 ** (e - 1);
            x = `0.${new Array(e).join("0")}${x.toString().substring(2)}`;
        }
    } else {
        let e = parseInt(x.toString().split("+")[1], 10);
        if (e > 20) {
            e -= 20;
            x /= 10 ** e;
            x += new Array(e + 1).join("0");
        }
    }
    return x.toString();
}

export function getWeb3NetworkName(networkId) {
    let networkName;
    switch (networkId) {
        case 1:
            networkName = "Main";
            break;
        case 2:
            networkName = "Morden";
            break;
        case 3:
            networkName = "Ropsten";
            break;
        case 4:
            networkName = "Rinkeby";
            break;
        case 42:
            networkName = "Kovan";
            break;
        default:
            networkName = "Unknown";
    }
    return networkName;
}

export const getBtcUsd = async(number) => {
    const geminiPricingResponse = await axios.get(
        `${API_ROOT}/geminiPricing.json`
    );
    let priceUsd = "";
    if (geminiPricingResponse) {
        const parseJson = geminiPricingResponse.data;
        if (parseJson && parseJson["btc"]) {
            priceUsd = parseJson["btc"];
        }
    }
    if (!geminiPricingResponse || !priceUsd) {
        const response = await axios.get(
            `https://api.gemini.com/v1/pubticker/btcusd`
        );
        if (response) {
            const priceDetails = response.data;
            priceUsd = priceDetails.last;
        }
    }
    return priceUsd;
};

const toPlainString = async(num) => {
    return ("" + +num).replace(
        /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
        function(a, b, c, d, e) {
            return e < 0 ?
                b + "0." + Array(1 - e - c.length).join(0) + c + d :
                b + c + d + Array(e - d.length + 1).join(0);
        }
    );
};

export const getLiveStats = async(authenticationToken, Api) => {
    try {
        const api = new Api();
        const hashRateResponse = await api
            .setToken(authenticationToken)
            .get("user/fetchGlobalMinerWorkersStatisticsCron");

        if (
            hashRateResponse.code === 200 &&
            hashRateResponse.data &&
            !_.isEmpty(hashRateResponse.data.liveStatistics)
        ) {
            const statistcs = hashRateResponse.data;
            for (let i = statistcs.liveStatistics.length - 1; i >= 0; i--) {
                if (statistcs.liveStatistics[i].active_workers > 0) {
                    return statistcs.liveStatistics[i];
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const pageProgress = (action) => {
    console.log(`pageProgress:${action}`);
    const body = document.body;
    if (action === "remove") {
        body.classList.add("page-loaded");
    } else if (action === "force_remove") {
        setTimeout(() => {
            body.classList.add("page-loaded");
        }, 5000);
    } else {
        body.classList.remove("page-loaded");
    }
};