import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";

import Api from "../../services/api";
import auth from "../../utils/auth";
import validators from "../../validators";

class InviteUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.inviteUserRequest = this.inviteUserRequest.bind(this);

    this.validators = validators;
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async inviteUserRequest() {
    const { email, fullName, role, companyName, loading } = this.state;
    const { onSubmit } = this.props;
    if (!loading && this.isFormValid()) {
      this.setState({
        loading: true
      });
      const params = {
        email,
        fullName,
        role,
      }
      if (role === "d") {
        params.companyName = companyName;
      }
      const api = new Api();
      try {
        const { code, message } = await api
          .setToken(auth.getToken())
          .create("admin/inviteUser", params);
        if (code === 200) {
          this.setState({
            loading: false
          });
          toast.success(message);
          return onSubmit && onSubmit();
        }
        throw new Error(message);
      } catch (err) {
        console.log(err);
        this.setState({
          loading: false
        })
        toast.error(err.message);
      }
    }
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = [
      "fullName",
      "email",
    ];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    const { role, companyName } = this.state;
    if (!role) {
      status = false;
    }
    if (role === "d" && !companyName) {
      status = false;
    }
    return status;
  }

  render() {
    const {
      openModal,
      onCloseModal,
    } = this.props;
    const { loading, role, companyName } = this.state;
    const disabled = loading || !this.isFormValid();
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4 className="onboarding-title">Invite User</h4>
                    <form>

                      <div className="form-group">
                        <label htmlFor="title">
                          Full Name<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          placeholder="Enter Full Name"
                          type="text"
                          name="fullName"
                          onChange={this.onInputChange}
                        />
                      </div>

                      {
                        (role === "d") && (
                          <div className="form-group">
                            <label htmlFor="title">
                              Company Name<span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              placeholder="Enter Company Name"
                              type="text"
                              name="companyName"
                              value={companyName}
                              onChange={this.onInputChange}
                            />
                          </div>
                        )
                      }

                      <div className="form-group">
                        <label htmlFor="title">
                          Email<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          placeholder="Enter Email"
                          type="text"
                          name="email"
                          onChange={this.onInputChange}
                        />
                      </div>

                      <div className="form-group select-icon mb-5">
                        <label htmlFor="title">
                          Assign Role
                          <span className="required-field">*</span>
                        </label>
                        <select
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          placeholder="Select Assign Role"
                          name="role"
                          onChange={this.onInputChange}
                        >
                          <option value="m">Miner</option>
                          <option value="i">Individual Investor</option>
                          <option value="ci">Corporate Investor</option>
                          <option value="d">Dealer</option>
                          <option value="s">Supplier</option>
                        </select>
                      </div>

                      <div className="pull-left mb-3">
                        <button
                          className="btn btn-primary btn-md"
                          type="button"
                          onClick={this.inviteUserRequest}
                          disabled={disabled}
                        >
                          {" "}
                          Submit{" "}
                          {loading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default InviteUserModal;
