import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import AddCategoryModal from "./AddCategoryModal";

class AdminManageProgressCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByTitle: "",
      loading: false,
      modalOpen: false,
      totalSize:0,
      categoryId: "",
      title: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.deleteProgressCategory = this.deleteProgressCategory.bind(this);
    this.progressCategoryOperation = this.progressCategoryOperation.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.AddCategoryRequest = this.AddCategoryRequest.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async deleteProgressCategory(deleteId) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .delete("admin/progress/category/delete", {
        deleteId: deleteId,
      });
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }

  progressCategoryOperation(resourceId, type) {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Yes",
        onClick: () => _self.deleteProgressCategory(resourceId),
      },
      {
        label: "Cancel",
      },
    ];
    confirmAlert({
      title: messageConstants.DELETE_CONFIRM_MESSAGE,
      buttons: buttons,
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByTitle } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("admin/progress/categories", {
        sizePerPage: sizePerPage,
        page: page,
        filterByTitle: filterByTitle,
      });
    if (response.code === 200) {
      this.setState(
        {
          renderFlag: true,
          loading: false,
          data: response.data.progressCategories,
          totalSize: response.data.totalProgressCategories?response.data.totalProgressCategories:0,
        },
        async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        }
      );
    } else {
      this.setState({
        loading: false,
      });
      toast.message(response.message);
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByTitle: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_PROGRESS_CATEGORIES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  openEditModal(rowData = "") {
    if (rowData) {
      this.setState({
        title: rowData.title,
        modalOpen: true,
        categoryId: rowData._id,
      });
    }
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async AddCategoryRequest() {
    let authenticationToken = this.props.authToken;
    const { title, categoryId } = this.state;
    if (title && title !== "") {
      let userId = this.props.match.params.id;
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/progress/category/add", {
          userId: userId,
          title: title,
          categoryId,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          title: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }

  render() {
    const { data, sizePerPage, page, renderFlag, filterByTitle, loading } =
      this.state;
    let _self = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "title",
        text: "Title",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold text-left pull-right",
        dataField: "delete",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="pull-right">
              <button
                className="mr-2 mb-2 btn btn-info"
                type="button"
                onClick={() => _self.openEditModal(row)}
              >
                {" "}
                Edit
              </button>
              <button
                className="mr-2 mb-2 btn btn-danger"
                type="button"
                onClick={() => _self.progressCategoryOperation(row._id)}
              >
                {" "}
                Delete
              </button>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Progress Categories</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only"> Title</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-0"
                          name="filterByTitle"
                          id="filterByTitle"
                          placeholder="Title"
                          type="text"
                          onChange={this.onchange}
                          value={filterByTitle}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 manage-progress-btn pl-0">
                      {" "}
                      <button
                        className="btn btn-primary  "
                        type="button"
                        onClick={this.filterRecords}
                      >
                        {" "}
                        Filter
                      </button>
                      <button
                        className="btn btn-danger ml-2"
                        type="button"
                        onClick={this.clearFilterRecords}
                      >
                        {" "}
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Progress Report Categories</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                  <span className="pull-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => this.showModal()}
                    >
                      Add Category
                    </button>
                  </span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddCategoryModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          AddCategoryRequest={this.AddCategoryRequest}
        />
      </div>
    );
  }
}
export default AdminManageProgressCategories;
