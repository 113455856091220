import React from "react";
import "./caret.css";

export default function SortCategory({ sort }) {
  let bgUp = "bg-darkgrey";
  let bgDown = "bg-darkgrey";
  if (sort === "asc") {
    bgUp = "bg-black"
  } else if (sort === "desc") {
    bgDown = "bg-black"
  }
  return (
      <span>
        <span className={"fa fa-chevron-up " + bgUp} />
        <span className={"fa fa-chevron-down " + bgDown} />
      </span>
  )
}
