import React, { Component } from 'react';
import Modal from "react-responsive-modal";
class AddTransactionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { addTransactionButtonLoading, modalOpen, onCloseModal, onInputValueChange, generateTransaction, transactionAmount } = this.props;
    let buttonDisabled = (addTransactionButtonLoading === true) ? true : false;
    return (
      <Modal open={modalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">

              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-center">
                      Add transaction
                              </h4>
                    <div className="onboarding-text">
                      Kindly verify all details and add transaction.
                              </div>
                    <form>

                      <div className="form-group">
                        <label>Transaction Hash<span className="required-field">*</span></label>
                        <input className="form-control" id="transactionHash" placeholder="Enter bank transaction id" type="text" name="transactionHash" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label>Transaction Amount<span className="required-field">*</span></label>
                        <input className="form-control" id="transactionAmount" placeholder="Enter transaction amount" type="text" name="transactionAmount" onChange={onInputValueChange} value={transactionAmount} />
                      </div>

                      <div className="form-group">
                        <label>Note</label>
                        <textarea className="form-control" id="transactionNote" placeholder="Enter transaction note" name="transactionNote" onChange={onInputValueChange}></textarea>
                      </div>

                      <div>
                        <button className="btn btn-primary permian-button" type="button" disabled={buttonDisabled} onClick={generateTransaction}> Submit
                                  </button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddTransactionModal;