import React from "react";
import Api from "../../services/api";
// import IndividualIdentificationPdf from "./IndividualIdentificationPdf";
// import CorporateIdentificationPdf from "./CorporateIdentificationPdf";
class AdminIdentificationDetailPdfGenerate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identificationType: '',
      identificationData: ''
    };
  }

  async componentWillMount() {
    const api = new Api();
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    let authenticationToken = this.props.authToken;
    let identificationUserId = this.props.match.params.id;
    try {
      let response = await api.setToken(authenticationToken).download("user/generate/identification/pdf", {
        identificationUserId: identificationUserId
      }, identificationUserId);
      response = JSON.parse(response);
      if (response.code === 200) {
        this.props.history.push("/manage_users");
      }
    }
    catch (error) {
      if (typeof (this.props.pageProgress) === 'function') {
        this.props.pageProgress('remove');
      }
    }
  }

  render() {
    // const { identificationType, identificationData } = this.state;
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box"></div>
        </div>
      </div>
    );
  }
}
export default AdminIdentificationDetailPdfGenerate;