import React, { Component } from "react";
import { Link } from "react-router-dom";
import kycIcon from "../../assets/img/kyc.svg?v1";
class AdminMenu extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {};
  }

  render() {
    return (
      <div className="left-menu-sidebar">
        <div className="menu-mobile menu-activated-on-click color-scheme-dark">
          <div className="mm-logo-buttons-w">
            <a className="mm-logo" href={void 0}>
              <span>PermianChain Admin</span>
            </a>
            <div className="mm-buttons">
              <div className="mobile-menu-trigger">
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>

          <div className="menu-and-user">
            <ul className="main-menu">
              <li className="selected">
                <Link to="/dashboard" className="mobile-menu-link">
                  <div className="icon-w" title="Dashboard">
                    <div className="os-icon os-icon-layout"></div>
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="/manage_users">
                  <div className="icon-w" title="Manage Users">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Manage Users</span>
                </Link>
              </li>
              <li>
                <Link to="/global_mining">
                  <div className="icon-w" title="Global Minings">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Mining</span>
                </Link>
              </li>
              <li>
                <Link to="/global_earning">
                  <div className="icon-w" title="Global Earnings">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Earning</span>
                </Link>
              </li>
              <li>
                <Link to="/global_daily_earning">
                  <div className="icon-w" title="Global Daily Earnings">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Daily Earning</span>
                </Link>
              </li>
              <li>
                <Link to="/global_historic_workers">
                  <div className="icon-w" title="Global Workers">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Historic Workers</span>
                </Link>
              </li>
              <li>
                <Link to="/global_workers">
                  <div className="icon-w" title="Global Workers">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Workers</span>
                </Link>
              </li>
              <li>
                <Link to="/global_daily_workers">
                  <div className="icon-w" title="Global Workers">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Daily Workers</span>
                </Link>
              </li>

              <li>
                <Link to="/manage_team_members">
                  <div className="icon-w" title="Manage Team Members">
                    <div className="fa fa-user"></div>
                  </div>
                  <span>Manage Team Members</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_miner_kyc">
                  <div className="icon-w" title="Manage KYC">
                    {/* <div className="fa fa-user"></div> */}
                    <img className="kycIcon" src={kycIcon} alt="kyc image"></img>
                    {/* <i class="fa fa-id-card-alt"></i> */}
                  </div>
                  <span>Manage Miner KYC</span>
                </Link>
              </li>

              <li>
                <Link to="/manage_dealers">
                  <div className="icon-w" title="Manage Dealers">
                    <div className="fa fa-user-secret"></div>
                  </div>
                  <span>Manage Dealers</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_dec_transfer">
                  <div className="icon-w" title="Manage DEC Transfer">
                    <div className="fa fa-user-secret"></div>
                  </div>
                  <span>Manage DEC Transfer</span>
                </Link>
              </li>


              <li>
                <Link to="/manage_wire_transfer">
                  <div className="icon-w">
                    <div className="fa fa-list"></div>
                  </div>
                  <span>Manage Wire Transfer</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_budget_wire_transfer">
                  <div className="icon-w">
                    <div className="fa fa-list"></div>
                  </div>
                  <span>Manage Budget</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_invoices">
                  <div className="icon-w">
                    <div className="fa fa-list"></div>
                  </div>
                  <span>Manage Invoices</span>
                </Link>
              </li>
              <li>
                <Link to="/getpayment">
                  <div className="icon-w">
                    <div className="fa fa-list"></div>
                  </div>
                  <span>Update Payments</span>
                </Link>
              </li>
              <li>
                <Link to="/sendEmail">
                  <div className="icon-w">
                    <div className="fa fa-envelope"></div>
                  </div>
                  <span>Send Email</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_campaigns">
                  <div className="icon-w">
                    <div className="fa fa-list"></div>
                  </div>
                  <span>Campaigns</span>
                </Link>
              </li>

              <li>
                <Link to="/manage_progress_categories">
                  <div className="icon-w">
                    <div className="fa fa-tasks"></div>
                  </div>
                  <span>Progress Categories</span>
                </Link>
              </li>
              <li>
                <Link to="/manage_categories">
                  <div className="icon-w">
                    <div className="fa fa-tasks"></div>
                  </div>
                  <span>Manage Categories</span>
                </Link>
              </li>


              <li>
                <Link to="/manage_resources" className="mobile-menu-link">
                  <div className="icon-w" title="Manage Resources">
                    <div className="fa fa-file"></div>
                  </div>
                  <span>Manage Resources</span>
                </Link>
              </li>

              <li>
                <Link to="/logout">
                  <div className="icon-w theme-color" title="Logout">
                    <div className="fa fa-sign-out"></div>
                  </div>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="menu-w selected-menu-color-light menu-has-selected-link menu-activated-on-click color-scheme-dark color-style-bright color-style-transparent sub-menu-color-light menu-position-side menu-side-left menu-layout-compact sub-menu-style-inside">
          <div className="logo-w">
            <Link to="/dashboard" className="logo">
              <div className="logo-label">Permian Admin</div>
            </Link>
          </div>

          <ul className="main-menu">
            <li className="selected">
              <Link to="/dashboard">
                <div className="icon-w">
                  <div className="os-icon os-icon-layout"></div>
                </div>
                <span>Dashboard</span>
              </Link>
            </li>


            <li>
              <Link to="/manage_users">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Manage Users</span>
              </Link>
            </li>
            <li>
              <Link to="/global_mining">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Global Mining</span>
              </Link>
            </li>
            <li>
              <Link to="/global_earning">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Global Earning</span>
              </Link>
            </li>
            <li>
              <Link to="/global_daily_earning">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Global daily Earning</span>
              </Link>
            </li>
            <li>
              <Link to="/global_historic_workers">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Global Historic Workers</span>
              </Link>
            </li>
            <li>
              <Link to="/global_workers">
                <div className="icon-w">
                  <div className="fa fa-users"></div>
                </div>
                <span>Global Workers</span>
              </Link>
            </li>
            <li>
                <Link to="/global_daily_workers">
                  <div className="icon-w">
                    <div className="fa fa-users"></div>
                  </div>
                  <span>Global Daily Workers</span>
                </Link>
              </li>
            <li>
              <Link to="/manage_team_members">
                <div className="icon-w">
                  <div className="fa fa-user"></div>
                </div>
                <span>Manage Team Members</span>
              </Link>
            </li>
            <li>
                <Link to="/manage_miner_kyc">
                  <div className="icon-w" title="Manage KYC">
                    <img className="kycIcon" src={kycIcon} alt="kyc image"></img>
                    
                  </div>
                  <span>Manage Miner KYC</span>
                </Link>
              </li>
            <li>
              <Link to="/manage_dealers">
                <div className="icon-w">
                  <div className="fa fa-user-secret"></div>
                </div>
                <span>Manage Dealers</span>
              </Link>
            </li>
            <li>
                <Link to="/manage_dec_transfer">
                  <div className="icon-w">
                    <div className="fa fa-user-secret"></div>
                  </div>
                  <span>Manage DEC Transfer</span>
                </Link>
              </li>

            <li>
              <Link to="/manage_wire_transfer">
                <div className="icon-w">
                  <div className="fa fa-list"></div>
                </div>
                <span>Manage Wire Transfer</span>
              </Link>
            </li>
            <li>
              <Link to="/manage_budget_wire_transfer">
                <div className="icon-w">
                  <div className="fa fa-list"></div>
                </div>
                <span>Manage Budget</span>
              </Link>
            </li>
            <li>
              <Link to="/manage_invoices">
                <div className="icon-w">
                  <div className="fa fa-list"></div>
                </div>
                <span>Manage Invoices</span>
              </Link>
            </li>
            <li>
              <Link to="/getpayment">
                <div className="icon-w">
                  <div className="fa fa-list"></div>
                </div>
                <span>Update Payments</span>
              </Link>
            </li>
            <li>
              <Link to="/sendEmail">
                <div className="icon-w">
                  <div className="fa fa-envelope"></div>
                </div>
                <span>Send Email</span>
              </Link>
            </li>
            <li>
              <Link to="/manage_campaigns">
                <div className="icon-w">
                  <div className="fa fa-list"></div>
                </div>
                <span>Campaigns</span>
              </Link>
            </li>

            <li>
              <Link to="/manage_progress_categories">
                <div className="icon-w">
                  <div className="fa fa-tasks"></div>
                </div>
                <span>Progress Categories</span>
              </Link>
            </li>
            <li>
              <Link to="/manage_categories">
                <div className="icon-w">
                  <div className="fa fa-tasks"></div>
                </div>
                <span>Manage Categories</span>
              </Link>
            </li>
            <li>
              <Link to="/manage_resources">
                <div className="icon-w">
                  <div className="fa fa-file"></div>
                </div>
                <span>Manage Resources</span>
              </Link>
            </li>
            <li>
              <Link to="/worker_inventory">
                <div className="icon-w">
                  <i className="fa fa-file" />
                </div>
                <span>Worker Inventory</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default AdminMenu;
