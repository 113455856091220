import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import UploadWorkerInventoryModal from "./UploadWorkerInventoryModal";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "./Inventory.css";
import Select from "react-select";
import SortCategory from "./SortCategory";
import StopLossTable from "./StopLossTable";

const api = new Api();

export default class WorkerInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoading: false,
      workerLoading: false,
      inventoryPage: 1,
      inventorySizePerPage: 10,
      inventoryTotalSize: 0,
      workerData: [],
      modalOpen: false,
      filter: {}
    };
    this.props.pageProgress("force_remove");
  }

  onchange = (event) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [event.target.name]: event.target.value
      },
    });
  }

  onStatusChange = (obj) => {
    this.setState({
      filter: {
        ...this.state.filter,
        status:  obj ? obj.value: ''
      },
    });
  }

  doFilterRecords = () => {
    this.setState(
        {
          inventoryPage: 1
        },
        async () => {
          this.fetchWorkerInventory();
        }
    );
  }

  clearFilterRecords = () => {
    this.setState(
        {
          filter: {
            workerIdViaBTC: "",
            serial: "",
            status: ""
          }
        },
        async () => {
          this.fetchWorkerInventory();
        }
    );
  }

  showModal = () => {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      messageConstants.WORKER_INVENTORY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.fetchWorkerInventory();
    this.setState({ fileName: "MinerData" });
  }

  fetchWorkerInventory = async() => {
    try {
      const { inventoryPage, inventorySizePerPage, filter } = this.state;
      this.setState({
        workerLoading: true,
        tableLoading: true,
      });
      const response = await api
        .setToken(this.props.authToken)
        .get("admin/workerInvetory", {
          ...filter,
          page: inventoryPage,
          sizePerPage: inventorySizePerPage,
        });
      if (response.code !== 200 || !response.data || !response.data.workerInventory) {
        throw new Error("Fail to fetch worker inventory")
      }
      this.setState({
        workerData: response.data.workerInventory,
        inventoryTotalSize:response.data.total,
        workerLoading: false,
        tableLoading: false,
      });
    } catch (err) {
      alert(`${err}`)
      this.setState({
        workerLoading: false,
        tableLoading: false,
      });
    }
  }

  sortWorkerInventory = (sortField, sortOrder) => {
    const data = this.state.workerData;
    if (data) {
      data.sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];
        if (valueA === valueB) {
          return 0;
              }
        const reverse = sortOrder === "asc"? 1: -1;
        return reverse * (valueA > valueB? 1: -1) ;
      })
    }
  }

  handleTableChange = (
    type,
    params
  ) => {
    if (type === "pagination") {
	  const { page, sizePerPage} = params;
      if (
          this.state.inventorySizePerPage !== sizePerPage ||
          this.state.inventoryPage !== page
      ) {
        this.setState(
            { inventorySizePerPage: sizePerPage, inventoryPage: page },
            () => {
              this.fetchWorkerInventory();
            }
        );
      }
    } else if (type === "sort") {
    	const {sortField, sortOrder} = params;
        this.sortWorkerInventory(sortField, sortOrder);
    }
  };

  render() {
    const {
      tableLoading,
      inventorySizePerPage,
      inventoryTotalSize,
      inventoryPage,
      workerLoading,
      workerData,
      filter
    } = this.state;

    const statusColors = {
      delivered: "purple",
      installed: "success",
      repairing: "orange",
      "in transit": "warning",
      configuring: "primary",
      "stop loss": "red",
    }

    const sortCaret = (order) => {
      return (<SortCategory sort={order} />)
    }

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "model",
        text: "Miner",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "serial",
        text: "Serial Number",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate",
        text: "HR",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerConsumption",
        text: "W",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerWth",
        text: "W/THs",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "location",
        text: "Location",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "workerIdViaBTC",
        text: "Worker ID (viaBTC)",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "subAccountId",
        text: "Sub Account ID",
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "STATUS",
        formatter: (text) => {
          const badgeColor = statusColors[text.toLowerCase()] || 'secondary';
          return (
              <span className={"badge badge-" + badgeColor}>
                {text}
              </span>
          )
        },
        sort: true,
        sortCaret
      },
      {
        headerClasses: "custom-table-th",
        dataField: "notes",
        text: "Notes"
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box pr-0">
              <h5 className="form-header">
                <div className="row w-100">
                  <div className="col-sm-12 col-md-12 col-lg-12 spaceBetween flex">
                    <span className="pull-left">Worker Inventory</span>
                    <span className="exportData" style={{ fontSize: "14px" }}>
                    <button
                        className="btn btn-primary mt-1"
                        onClick={() => this.showModal()}
                    >
                      Import Data
                    </button>
                    <UploadWorkerInventoryModal
                        modalOpen={this.state.modalOpen}
                        authToken={this.props.authToken}
                        onCloseModal={this.onCloseModal}
                        onUploadSuccess={this.fetchWorkerInventory}
                        onUploadFail={(err) => alert(`Fail to upload: ${err}`)}
                    />
                  </span>
                  </div>
                </div>
              </h5>
              <form className="form-inline">
                <div className="row w-100">
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label className="sr-only"> Work ID</label>
                      <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="workerIdViaBTC"
                          placeholder="Worker ID (VIABTC)"
                          type="text"
                          onChange={this.onchange}
                          value={filter.workerIdViaBTC}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label className="sr-only"> Serial</label>
                      <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          placeholder="Serial Number"
                          type="text"
                          name="serial"
                          onChange={this.onchange}
                          value={filter.serial}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label className="sr-only"> Contact No</label>
                      <Select
                          className="w-100"
                          value={filter.status && {
                            value: filter.status,
                            label: filter.status,
                          }}
                          name="status"
                          options={[
                            { value: "Delivered", label: "Delivered" },
                            { value: "Installed", label: "Installed" },
                            { value: "Repairing", label: "Repairing" },
                            { value: "In Transit", label: "In Transit" },
                            { value: "Configuring", label: "Configuring" },
                            { value: "Stop Loss", label: "Stop Loss" },
                          ]}
                          placeholder="Select Status"
                          onChange={this.onStatusChange}
                          isClearable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="manageuser-btn-group button-center">
                  <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.doFilterRecords}
                  >
                    Filter
                  </button>
                  <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
            </div>
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              {tableLoading && (
                <div className="text-center">
                  <span id="loading" color="#047bf8" />
                </div>
              )}
              <div className="earnings-table table-responsive">
                {workerData && (
                  <WorkerRemoteAll
                    data={workerData}
                    page={inventoryPage}
                    sizePerPage={inventorySizePerPage}
                    totalSize={inventoryTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
            <StopLossTable authToken={this.props.authToken}/>
          </div>
        </div>
      </div>
    );
  }
}
