import React, { Component } from 'react';
import { Radio, RadioGroup } from "react-icheck";
import Modal from "react-responsive-modal";
class TransactionOperationModal extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { addTransactionButtonLoading, modalOpen, onCloseModal, onInputValueChange, generateTransaction, transactionAmount, handleRadioChange } = this.props;
    let buttonDisabled = (addTransactionButtonLoading === true) ? true : false;
    return (
      <Modal open={modalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onCloseModal}>
                <span className="os-icon os-icon-close"></span>
              </button>

              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-center">Update Transaction Status</h4>
                    <div className="onboarding-text">Kindly verify all details and update transaction.</div>
                    <form>

                      <div className="form-group">
                        <label>Transaction Hash<span className="required-field">*</span></label>
                        <input className="form-control" id="transactionHash" placeholder="Enter bank transaction id" type="text" name="transactionHash" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label>Transaction Amount<span className="required-field">*</span></label>
                        <input className="form-control" id="transactionAmount" placeholder="Enter transaction amount" type="text" name="transactionAmount" onChange={onInputValueChange} value={transactionAmount} />
                      </div>

                      <div className="form-group">
                        <label>Note</label>
                        <textarea className="form-control" id="adminNote" placeholder="Enter transaction note" name="adminNote" onChange={onInputValueChange}></textarea>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Transaction Status</label>
                          <RadioGroup name="type" className="wealth-split-radio-container" onChange={(e, value) => handleRadioChange(e, value, 'type')}>
                            <Radio
                              value='APPROVED'
                              radioClass="iradio_square-blue"
                              increaseArea="20%"
                              label={" Accept"}
                            />
                            <Radio
                              value='REJECTED'
                              radioClass="iradio_square-blue"
                              increaseArea="20%"
                              label={" Reject"}
                            />
                          </RadioGroup>
                        </div>
                      </div>

                      <div><button className="btn btn-primary permian-button submit-button-padding-set" type="button" disabled={buttonDisabled} onClick={generateTransaction}> Submit</button></div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default TransactionOperationModal;