import React from 'react';

class WiredTransferVerifiedSuccess extends React.Component {
  
  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1>Wired Transfer Details Verified </h1>
          <p>You have successfully verified investor's wire transfer request.</p>
        </div>
      </div>
    );
  }
}
export default WiredTransferVerifiedSuccess