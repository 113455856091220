import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import validators from "../../validators";
import AddVerifierModal from "./AddVerifierModal";

class ManageExchangeVerifiers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      loading: false,
      verifierModalOpen: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.AddVerifierRequest = this.AddVerifierRequest.bind(this);
    this.validators = validators;
    this.updateValidators = this.updateValidators.bind(this);
    this.verifierOperation = this.verifierOperation.bind(this);
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/sellmandate/verifier/requests/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.verifierRequests,
            totalSize: response.data.totalVerifierRequestCount,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_EXCHANGE_VERIFIERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  showVerifierModal() {
    this.setState({ verifierModalOpen: true });
  }

  onCloseModal() {
    this.setState({ verifierModalOpen: false });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  async AddVerifierRequest() {
    let authenticationToken = this.props.authToken;
    const { fullName, email, phoneNumber } = this.state;
    const isFormValid = this.isFormValid();
    if (fullName && email && isFormValid) {
      this.setState({ buttonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/sellmandate/verifier/add", {
          fullName,
          email,
          phoneNumber,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          fullName: "",
          email: "",
          phoneNumber: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = !isFormValid
        ? "Please enter valid details for required fields."
        : fullName === ""
        ? "Please enter valid name."
        : "Something went wrong. Please try again later.";
      toast.error(msg);
    }
  }

  async deleteOperation(verifierId) {
    if (verifierId) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/sellmandate/verifier/delete", {
          verifierId: verifierId,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.getRecords();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  }

  verifierOperation(verifierId) {
    if (verifierId) {
      let _self = this;
      let buttons = "";
      buttons = [
        {
          label: "Delete",
          onClick: () => _self.deleteOperation(verifierId),
        },
        {
          label: "Cancel",
        },
      ];
      confirmAlert({
        title: "Are you sure want to delete verifier?",
        buttons: buttons,
      });
    }
  }

  render() {
    let _self = this;
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "phoneNumber",
        text: "Phone Number",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell ? cell : "N/A"}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "isEmailVerified",
        text: "Email Verified",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "No";
          let className = "badge-warning";
          if (row.isEmailVerified === true) {
            label = "Yes";
            className = "badge-success";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={"badge " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <div>
                <div className="d-inline-block">
                  {row.deleteStatus === false && (
                    <button
                      className="mr-2 mb-2 btn btn-danger"
                      type="button"
                      onClick={() => _self.verifierOperation(row._id)}
                    >
                      Delete
                    </button>
                  )}
                  {!row.deleteStatus === false && <span>N/A</span>}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Exchange Verifiers</h5>

                <form className="form-inline">
                  <label className="sr-only"> Name</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByName"
                    id="filterByName"
                    placeholder="Full Name"
                    type="text"
                    onChange={this.onchange}
                    value={filterByName}
                  />
                  <label className="sr-only"> Email</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Email Address"
                    type="text"
                    name="filterByEmail"
                    id="filterByEmail"
                    onChange={this.onchange}
                    value={filterByEmail}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>

                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span className="pull-left">Manage Exchange Verifiers</span>
                  <span className="pull-right">
                    <button
                      className="mr-2 mb-2 btn btn-success"
                      type="button"
                      onClick={() => this.showVerifierModal()}
                    >
                      Add Verifier
                    </button>
                  </span>
                </h5>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddVerifierModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          AddVerifierRequest={this.AddVerifierRequest}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
        />
      </div>
    );
  }
}
export default ManageExchangeVerifiers;
