// let backendHost = 'http://localhost:3000';
// let recaptchaSiteKey = '6LdTt5IUAAAAAHr8LILfR5h6e6PDmsqfxYHHed4K';
let smartContractAddress = "0xc02f22ef822ebac7a74122aa45ee9f1899e46fe5";
let txnLinkValue = "https://rinkeby.etherscan.io/tx";
let contractAddress = "0xa78fE1e7829c20670d8F3f0F7784E28D74355Ffc";
let network = "https://api-kovan.etherscan.io/api";
let networkEnviroment = "kovan";
let priceKey = "price_1KDpF7B0KxQtg04w1Hb8edTw";

let blockchainTxnLinkValue = "https://www.blockchain.com/btc/tx";
let contractReadApiUrlValue = "https://api-rinkeby.etherscan.io/api";
let MINER_PANEL_URL = "http://localhost:8085"
let backendHost = "http://localhost:3000";
let recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";

if (process.env.REACT_APP_NODE_ENV === "production") {
    priceKey = "price_1KBjNQFNDiMaOrJqkF3QcyLs";
    MINER_PANEL_URL = "https://miner.permianchain.com";
    networkEnviroment = "mainnet";
    contractAddress = "0xa6D7BCc3c352700d891DA2B5DCaf2fa7A2A0c383";
    network = "https://api.etherscan.io/api";
    backendHost = "https://masterapi.permianchain.com";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    smartContractAddress = "";
    txnLinkValue = "https://etherscan.io/tx";
    blockchainTxnLinkValue = "https://www.blockchain.com/btc/tx";
    contractReadApiUrlValue = "https://api-rinkeby.etherscan.io/api";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
    MINER_PANEL_URL = "https://privateminer.permianchain.com";
    backendHost = "https://api.permianchain.com";
    network = "https://api-kovan.etherscan.io/api";
    priceKey = "price_1KDpF7B0KxQtg04w1Hb8edTw";
    contractAddress = "0xa78fE1e7829c20670d8F3f0F7784E28D74355Ffc";
    networkEnviroment = "kovan";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    smartContractAddress = "0xc02f22ef822ebac7a74122aa45ee9f1899e46fe5";
    txnLinkValue = "https://rinkeby.etherscan.io/tx";
    blockchainTxnLinkValue = "https://www.blockchain.com/btc/tx";
    contractReadApiUrlValue = "https://api-rinkeby.etherscan.io/api";
}
export const API_NETWORKENVIROMENT = `${networkEnviroment}`;
export const API_NETWORK = `${network}`;
export const PRICE_KEY = `${priceKey}`;
export const API_CONTRACTADDRESS = `${contractAddress}`;
export const API_ROOT = `${backendHost}`;
export const MINER_ROOT = `${MINER_PANEL_URL}`;
export const RECAPTCHASITEKEY = `${recaptchaSiteKey}`;
export const PERMIAN_SMART_CONTRACT_ADDRESS = smartContractAddress;
export const txnLink = txnLinkValue;
export const btcExplorerLink = blockchainTxnLinkValue;
export const tokenSymbol = "NRT";
export const contractReadApiUrl = contractReadApiUrlValue;
export const imageVersion = 1;