import React, { Component } from "react";
import {
  niceNumberDecimalDisplay,
  getBtcUsd,
  getLiveStats,
} from "../../utils/Util";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import * as messageConstants from "../../utils/Messages";
import graphImg from "../../assets/img/graph.svg?v3";
import effectiveImg from "../../assets/img/effective.svg?v3";
import inactiveImg from "../../assets/img/inactive.svg?v3";
import bitcoinImg from "../../assets/img/bitcoin.svg?v3";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Api from "../../services/api";
import moment from "moment";
import _ from "lodash";
import { UncontrolledPopover, PopoverBody } from "reactstrap";

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class GlobalMining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProvedTokens: "",
      totalProvedTokensValue: "",
      totalProbablePossibleTokens: "",
      totalProbablePossibleValue: "",
      totalUnlockedTokens: "",
      totalUnlockedValue: "",
      popoverOpen: false,
      minerBinanceAccountId: "",
      machineRenderFlag: false,
      machinePage: 1,
      macineSizePerPage: 10,
      machineLoading: false,
      validNum: 0,
      invalidNum: 0,
      fifteenMinHashRate: "0 TH/s",
      dayHashRate: "0 TH/s",
      accumulatedEarnings: 0,
      hashRatestatisticsLoading: true,
      offlineWorkers: 0,
      hashrateGraphValue: "hour",
      baseCurrency: "btc",
      rateOfElectricity: "0",
      statisticsLoading: true,
      powerCoststatisticsLoading: true,
      workerStatisticsLoading: true,
      monthlyElectricityCost: "0",
      yesterdayEarnings: "",
      yesterdayNetEarnings: "",
      yesterdayPoolFees: "",
      netEarnings: "",
      netPoolFee: "",
      yesterdayPowerCost: "",
      timeHorizon: "24h",
      earningDetails: {},
      graphData: "",
    };
    this.filterHashRateGraph = this.filterHashRateGraph.bind(this);
    this.selectBaseCurrency = this.selectBaseCurrency.bind(this);
    this.selectTimeHorizon = this.selectTimeHorizon.bind(this);
    this.displayEarnings = this.displayEarnings.bind(this);
    this.getTableData = this.getTableData.bind(this);
  }

  async getTableData(tableData) {
    let workerData = tableData;
    let data = [];

    for (let i = 0; i < workerData.length; i++) {
      if (workerData[i].workers) {
        for (let j = 0; j < workerData[i].workers.length; j++) {
          data.push(workerData[i].workers[j]);
        }
      }
    }
    return data;
  }

  async componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const minerAccountId = await this.getMinerId();
    let authenticationToken = this.props.authToken;

    const stats = await getLiveStats(authenticationToken, Api);
    this.setState({
      workerStatisticsLoading: false,
    });
    if (stats) {
      this.setState({
        validNum: stats.active_workers,
        invalidNum: stats.unactive_workers,
        fifteenMinHashRate: stats.hashrate_10min,
        dayHashRate: stats.hashrate_24hour,
      });
    }

    this.setStatistics();
    this.initChart();
  }

  async getMinerId() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/miner/id");
      if (response.code === 200) {
        this.setState({
          minerBinanceAccountId: response.data.binanceUserAccountId,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    }
  }

  async initChart() {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = await this.generateChartData();
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: "hours",
      count: 1,
    };
    chart.dateFormatter.inputDateFormat = "DD MM YYYY HH:mm:ss";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = "";

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "visits";
    series.tooltipText =
      "Hashrate: [bold]{valueY}[/] TH/s \n Date:[bold]{date} ";
    series.fillOpacity = 0.3;
    valueAxis.title.text = `Hash Rate`;
    dateAxis.title.text = "Time in hours";
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);
  }
  validateEmail(emailAdress) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  async generateChartData() {
    const { hashrateGraphValue } = this.state;
    let chartData = [];
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/fetchAdminChartData");

      if (response.code === 200) {
        let workerData =
          response.data && response.data.minerGrossprofits
            ? response.data.minerGrossprofits
            : [];

        const tempData = workerData;
        chartData = await Promise.all(
          (chartData = await tempData.map(async (chartEntityDetails) => {
            let newDate = moment(chartEntityDetails.timestamp * 1000).format();
            let hashRateDisp = chartEntityDetails.hashrate;
            return {
              date: newDate,
              visits: parseFloat(hashRateDisp),
            };
          }))
        );
        return chartData;
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  async setStatistics() {
    try {
      const priceUsd = await getBtcUsd();
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/fetchGlobalMinerStatistics");

      if (response.code === 200) {
        const statistcs = response.data;
        const accumulatedEarningsFormatted = niceNumberDecimalDisplay(
          statistcs.grossMinerAllDaysData[0].LastAllDaysEarnings,
          8
        );

        let profitYesterdayInUsd = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysNetEarnings
          ? parseFloat(
              statistcs.grossMinerAllDaysData[0].LastAllDaysNetEarnings
            ) * parseFloat(priceUsd)
          : 0;
        profitYesterdayInUsd = niceNumberDecimalDisplay(
          profitYesterdayInUsd,
          2
        );
        let accumulatedEarningsInUsd = accumulatedEarningsFormatted
          ? parseFloat(accumulatedEarningsFormatted) * parseFloat(priceUsd)
          : 0;
        accumulatedEarningsInUsd = niceNumberDecimalDisplay(
          accumulatedEarningsInUsd,
          2
        );

        let pooFeesInBtc = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysPoolFees
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysPoolFees
          : 0;
        let LastAllDaysNetEarnings = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysNetEarnings
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysNetEarnings
          : 0;
        let LastAllDaysPoolFees = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysPoolFees
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysPoolFees
          : 0;

        let accumulatedPowerCost = statistcs.grossMinerAllDaysData[0]
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysPowerCost
          : "";
        let monthlyElectricityCost = statistcs.grossMiner30DaysData[0]
          ? parseFloat(statistcs.grossMiner30DaysData[0].Last30DaysPowerCost) *
            parseFloat(priceUsd)
          : "0";

        pooFeesInBtc = niceNumberDecimalDisplay(pooFeesInBtc, 8);
        LastAllDaysNetEarnings = niceNumberDecimalDisplay(
          LastAllDaysNetEarnings,
          8
        );
        LastAllDaysPoolFees = niceNumberDecimalDisplay(LastAllDaysPoolFees, 8);

        this.setState(
          {
            monthlyElectricityCost:
              monthlyElectricityCost && monthlyElectricityCost,
            yesterdayEarnings:
              statistcs.grossMinerYesterdaysData[0] &&
              statistcs.grossMinerYesterdaysData[0].YesterdaysEarnings,
            yesterdayNetEarnings:
              statistcs.grossMinerYesterdaysData[0] &&
              statistcs.grossMinerYesterdaysData[0].YesterdaysNetEarnings,
            last7DaysEarnings:
              statistcs.grossMiner7DaysData[0] &&
              statistcs.grossMiner7DaysData[0].Last7DaysEarnings,
            last7DaysPoolFees:
              statistcs.grossMiner7DaysData[0] &&
              statistcs.grossMiner7DaysData[0].Last7DaysPoolFees,
            last7DaysPowerCost:
              statistcs.grossMiner7DaysData[0] &&
              statistcs.grossMiner7DaysData[0].Last7DaysPowerCost,
            last7DaysNetEarnings:
              statistcs.grossMiner7DaysData[0] &&
              statistcs.grossMiner7DaysData[0].Last7DaysNetEarnings,
            last30DaysEarnings:
              statistcs.grossMiner30DaysData[0] &&
              statistcs.grossMiner30DaysData[0].Last30DaysEarnings,
            last30DaysPoolFees:
              statistcs.grossMiner30DaysData[0] &&
              statistcs.grossMiner30DaysData[0].Last30DaysPoolFees,
            last30DaysPowerCost:
              statistcs.grossMiner30DaysData[0] &&
              statistcs.grossMiner30DaysData[0].Last30DaysPowerCost,
            last30DaysNetEarnings:
              statistcs.grossMiner30DaysData[0] &&
              statistcs.grossMiner30DaysData[0].Last30DaysNetEarnings,
            accumulatedPowerCost: accumulatedPowerCost && accumulatedPowerCost,
            yesterdayPoolFees:
              statistcs.grossMinerYesterdaysData[0] &&
              statistcs.grossMinerYesterdaysData[0].YesterdaysPoolFees,
            rateOfElectricity:
              statistcs.grossMinerYesterdaysData[0] &&
              statistcs.grossMinerYesterdaysData[0].YesterdaysPowerCost
                ? niceNumberDecimalDisplay(
                    statistcs.grossMinerYesterdaysData[0].YesterdaysPowerCost *
                      priceUsd,
                    2
                  )
                : "0",
            yesterdayPowerCost:
              statistcs.grossMinerYesterdaysData[0] &&
              statistcs.grossMinerYesterdaysData[0].YesterdaysPowerCost,
            accumulatedEarnings:
              accumulatedEarningsFormatted && accumulatedEarningsFormatted,
            accumulatedEarningsInUsd:
              accumulatedEarningsInUsd && accumulatedEarningsInUsd,
            netEarnings: LastAllDaysNetEarnings,
            netPoolFee: LastAllDaysPoolFees,
            pooFeesInBtc: pooFeesInBtc,
            statisticsLoading: false,
          },
          () => {
            this.displayEarnings();
          }
        );
      }
    } catch (err) {
      console.log("err", err);
      this.setState({
        workerStatisticsLoading: false,
      });
    }
  }

  async getMachineRecords() {
    const api = new Api();
    const { macineSizePerPage, machinePage } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ machineLoading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("user/machines", {
        sizePerPage: macineSizePerPage,
        page: machinePage,
      });
    if (response.code === 200) {
      this.setState({
        machineLoading: false,
        machineData: response.data.machines,
        machineTotalSize: response.data.totalMachines,
        machineRenderFlag: true,
      });
    }
  }

  async filterHashRateGraph(e, filterType) {
    this.setState(
      {
        hashrateGraphValue: filterType,
      },
      () => {
        this.initChart();
      }
    );
  }

  selectBaseCurrency(value) {
    this.setState(
      {
        baseCurrency: value,
      },
      () => {
        this.displayEarnings();
      }
    );
  }

  selectTimeHorizon(value) {
    this.setState(
      {
        timeHorizon: value,
      },
      () => {
        this.displayEarnings();
      }
    );
  }

  async displayEarnings() {
    const {
      timeHorizon,
      baseCurrency,
      yesterdayEarnings,
      yesterdayNetEarnings,
      yesterdayPoolFees,
      yesterdayPowerCost,
      last7DaysEarnings,
      last7DaysPoolFees,
      last7DaysPowerCost,
      last7DaysNetEarnings,
      last30DaysEarnings,
      last30DaysPoolFees,
      last30DaysPowerCost,
      last30DaysNetEarnings,
      accumulatedEarnings,
      pooFeesInBtc,
      netEarnings,
      netPoolFee,
      accumulatedPowerCost,
    } = this.state;

    const response = {
      grossEarnings: 0,
      poolFees: 0,
      powerCost: 0,
      netEarnings: 0,
      decimal: baseCurrency === "btc" ? 8 : 2,
    };

    const priceUsd = await getBtcUsd();
    let currentBtcPrice = parseFloat(priceUsd);
    const multiplicationRate = baseCurrency === "usd" ? currentBtcPrice : 1;
    if (timeHorizon === "24h") {
      response.grossEarnings =
        parseFloat(yesterdayEarnings) * multiplicationRate;
      response.poolFees = parseFloat(yesterdayPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(yesterdayPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(yesterdayNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "7d") {
      response.grossEarnings =
        parseFloat(last7DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last7DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last7DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last7DaysNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "30d") {
      response.grossEarnings =
        parseFloat(last30DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last30DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last30DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last30DaysNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "all") {
      response.grossEarnings =
        parseFloat(accumulatedEarnings) * multiplicationRate;
      response.poolFees = parseFloat(netPoolFee) * multiplicationRate;
      response.powerCost =
        parseFloat(accumulatedPowerCost) * multiplicationRate;
      response.netEarnings = parseFloat(netEarnings) * multiplicationRate;
    }
    this.setState({
      earningDetails: response,
    });
  }

  render() {
    const {
      machineLoading,
      machinePage,
      fifteenMinHashRate,
      dayHashRate,
      validNum,
      invalidNum,
      baseCurrency,
      rateOfElectricity,
      statisticsLoading,
      workerStatisticsLoading,
      monthlyElectricityCost,
      hashRatestatisticsLoading,
      timeHorizon,
      earningDetails,
    } = this.state;

    const machineColumns = [
      {
        headerClasses: "custom-table-th",
        style: { "white-space": "nowrap" },
        dataField: "invoiceNumber",
        text: "Invoice #",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        style: { "white-space": "nowrap" },
        dataField: "manufacturer",
        text: "Manufacturer",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "model",
        text: "Model",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "algorithm",
        text: "Algorithm",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate",
        text: "Hashrate (TH)",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerConsumption",
        text: "power Consumption (W)",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "priceInUsd",
        text: "price in USD",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "quantity",
        text: "Quantity",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "Status",
      },
      {
        headerClasses: "custom-table-th",
        dataField: "trackLink",
        text: "Operations",
        formatter: function (cell, row) {
          return (
            <div
              className="text-left"
              style={{ width: "150px", overflow: "hidden" }}
            >
              {!_.isUndefined(cell) && (
                <div>
                  <a
                    className="btn btn-primary btn-xs"
                    rel="noopener noreferrer"
                    href={row.trackLink}
                    target="_blank"
                  >
                    Track
                  </a>

                  <a
                    className="btn btn-info btn-xs ml-1"
                    rel="noopener noreferrer"
                    href="mailto:sales@permianchain.com"
                    target="_blank"
                  >
                    <i className="fa fa-mail"></i> Email
                  </a>
                </div>
              )}
              {(_.isUndefined(cell) || cell === "") && <div>N/A</div>}
            </div>
          );
        },
      },
    ];

    const MachineRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={machineLoading}
          data={data}
          columns={machineColumns}
          pagination={
            machinePage > 1
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    MachineRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container ">
        <div className="content-i operation_page_main dashboard-page-main">
          <div className="content-box">
            <div className="r1">
              <div className="c c1">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    Hashrate
                  </h4>
                  <div className="row mt-3 ">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                      <img
                        className="icon"
                        src={graphImg}
                        height="40"
                        alt="Hashrate"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black">10m</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {workerStatisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!workerStatisticsLoading ? fifteenMinHashRate : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                      <img
                        className="bg-white icon"
                        src={graphImg}
                        height="40"
                        style={{ borderRadius: "7px" }}
                        alt="Hashrate"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black">24h</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {workerStatisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!workerStatisticsLoading ? dayHashRate : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c c2">
                <div className="dark-blue-theme-color cpad">
                  <h4 className="card-title text-xl text-default-color">
                    Workers
                  </h4>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                      <img
                        className="icon"
                        src={effectiveImg}
                        height="40"
                        alt="activeworkers"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black">Active</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {workerStatisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!workerStatisticsLoading ? validNum : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                      <img
                        className="icon"
                        src={inactiveImg}
                        height="40"
                        alt="inactive"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black">Inactive</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {workerStatisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!workerStatisticsLoading ? invalidNum : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c c3">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    Power Cost
                  </h4>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                      <img
                        className="icon"
                        src={graphImg}
                        height="40"
                        alt="Hashrate"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black"> Power Cost (24H)</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && rateOfElectricity
                            ? `$ ${rateOfElectricity}`
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                      <img
                        className="bg-white icon"
                        src={graphImg}
                        height="40"
                        style={{ borderRadius: "7px" }}
                        alt="Hashrate"
                      />
                      <div className="dashboard_text_space Workers-text ml-2">
                        <span className="color-black">
                          Power Cost (30D) &nbsp;
                          <i
                            className="fa fa-info-circle"
                            id="socialSecurityNumberInformation"
                            style={{ marginRight: "20px" }}
                          ></i>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="bottom"
                            target="socialSecurityNumberInformation"
                          >
                            <PopoverBody>
                              This calculates your average monthly cost per
                              worker (ASIC).
                            </PopoverBody>
                          </UncontrolledPopover>
                        </span>
                        <br></br>
                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && monthlyElectricityCost
                            ? `$ ${niceNumberDecimalDisplay(
                                monthlyElectricityCost,
                                2
                              )}`
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              <div className="col-md-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    <span className="pull-left">
                      Earnings
                      <span>
                        <select
                          className="earnings-select"
                          id="selectedCurrency"
                          name="selectedCurrency"
                          defaultValue="btc"
                          style={{
                            padding: "5px",
                          }}
                          onChange={(e) =>
                            this.selectBaseCurrency(e.target.value)
                          }
                        >
                          <option value="usd">USD</option>
                          <option value="btc">BTC</option>
                        </select>
                      </span>
                    </span>
                    <span className="pull-right" style={{ fontSize: "14px" }}>
                      <span
                        style={{
                          padding: "5px 15px",
                          borderRadius: "5px",
                        }}
                        className={
                          timeHorizon === "24h" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("24h")}
                      >
                        24H
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        className={
                          timeHorizon === "7d" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("7d")}
                      >
                        7D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("30d")}
                        className={
                          timeHorizon === "30d" ? "active-currency" : ""
                        }
                      >
                        30D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("all")}
                        className={
                          timeHorizon === "all" ? "active-currency" : ""
                        }
                      >
                        All
                      </span>
                    </span>
                  </h4>
                  <div className="clearfix"></div>

                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Gross Earnings</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && (
                            <span>
                              {`${
                                earningDetails.grossEarnings
                                  ? niceNumberDecimalDisplay(
                                      earningDetails.grossEarnings,
                                      earningDetails.decimal
                                    )
                                  : "0"
                              } ${_.toUpper(baseCurrency)}`}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Fees</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && (
                            <span>
                              {`${
                                earningDetails.poolFees
                                  ? niceNumberDecimalDisplay(
                                      earningDetails.poolFees,
                                      earningDetails.decimal
                                    )
                                  : "0"
                              } ${_.toUpper(baseCurrency)}`}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Power Cost</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && (
                            <span>
                              {`${
                                earningDetails.powerCost
                                  ? niceNumberDecimalDisplay(
                                      earningDetails.powerCost,
                                      earningDetails.decimal
                                    )
                                  : "0"
                              } ${_.toUpper(baseCurrency)}`}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Net Earnings</span>
                        <br />

                        <span className="color-black dashboard-text-bold">
                          {statisticsLoading && (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                          {!statisticsLoading && (
                            <span>
                              {`${
                                earningDetails.netEarnings
                                  ? niceNumberDecimalDisplay(
                                      earningDetails.netEarnings,
                                      earningDetails.decimal
                                    )
                                  : "0"
                              } ${_.toUpper(baseCurrency)}`}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="element-box table-space mt-3 mb-4 extra-space">
              <div className="d-flex justify-content-between hashrate-card-d-block">
                <div className="d-flex  hashrate-card-text">
                  <div className="color-black card-title-font font-weight-light">
                    Hashrate
                  </div>
                </div>
              </div>
              <div
                id="chartdiv"
                style={{ width: "100%", height: "500px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalMining;
