import React from 'react';

class VerifySuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1> Email Verified </h1>
          <p>Your email is successfully registered. You will receive notifications on your registered email to verify events that are relevant to your role. No further action is required on your part. Thank you! </p>
        </div>
      </div>
    );
  }
}
export default VerifySuccess