import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import GlobalDailyWorkerModal from "./GlobalDailyWorkerModal";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  hashrateConverter,
  getBtcUsd,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import "./Earnings.css";
class GlobalDailyWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earningsLoading: true,
      workerTotalSize: "",
      tableLoading: true,
      offlineWorkers: 0,
      accumulatedEarnings: 0,
      earningsData: [],
      earningsDatas: [],
      tableData: [],
      earningsPage: 1,
      earningsSizePerPage: 20,
      earningsRenderFlag: true,
      earningsTotalSize: "",
      baseCurrency: "btc",
      electricityCost: 0,
      yesterdayEarnings: "",
      yesterdayNetEarnings: "",
      yesterdayPoolFees: "",
      yesterdayPowerCost: "",
      timeHorizon: "all",
      earningDetails: {},
      viewers: [],
      fileName: "",
      excelData: [],
      workerData: [],
    };
    this.props.pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.selectBaseCurrency = this.selectBaseCurrency.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.allData = this.allData.bind(this);
  }
  allData() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("user/getdailyWorkerData")
      .then((data) => {
        if (data.data) {
          const workerArr = [];
          const sortData = data.data.minerGrossprofits;
          sortData.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          sortData.map((data) => {
            let worker = {};
            worker.date = data.date;
            worker["10M"] = data.hashrate_10min;
            worker["1H"] = data.hashrate_1hour;
            worker["24H"] = data.hashrate_24hour;
            worker.inactive = data.unactive_workers
              ? niceNumberDecimalDisplay(data.unactive_workers, 0)
              : 0;
            worker.active = data.active_workers
              ? niceNumberDecimalDisplay(data.active_workers, 0)
              : 0;
            workerArr.push(worker);
          });
          this.setState({ excelData: workerArr });
        }
      });
  }

  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getRecords();
    this.setState({ fileName: "MinerData" });
    this.allData();
  }

  async getRecords() {
    try {
      const { earningsPage, earningsSizePerPage } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/getdailyWorkerData", {
          page: earningsPage,
          sizePerPage: earningsSizePerPage,
        });
      if (response.code === 200) {
        let workerData =
          response.data && response.data.minerGrossprofits
            ? response.data.minerGrossprofits
            : "";

        workerData.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        this.setState({
          workerData,
          workerLoading: false,
          tableLoading: false,
          workerRenderFlag: true,
          earningsTotalSize:response.data.totalWorkers
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.earningsSizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState(
        { earningsSizePerPage: sizePerPage, earningsPage: page },
        () => {
          this.getRecords();
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  selectBaseCurrency(value) {
    this.setState(
      {
        baseCurrency: value,
      },
      () => {
        this.getRecords();
      }
    );
  }

  selectTimeHorizon(value) {
    this.setState(
      {
        timeHorizon: value,
      },
      () => {
        this.setState({ earningsPage: 1 });
        this.getRecords();
      }
    );
  }

  render() {
    const {
      earningsData,
      tableData,
      tableLoading,
      earningsSizePerPage,
      earningsTotalSize,
      earningsRenderFlag,
      earningsPage,
      workerLoading,
      baseCurrency,
      earningDetails,
      timeHorizon,
      viewers,
      fileName,
      excelData,
      workerData,
    } = this.state;

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "date",
        text: "Date",
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "hashrate_10min",
        text: "10M",
        formatter: function (cell, row) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate_1hour",
        text: "1H",
        formatter: function (cell) {
          return <span>{cell ? cell : 0}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate_24hour",
        text: "24H",
        formatter: function (cell) {
          return <span>{cell ? cell : 0}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "unactive_workers",
        text: "Inactive",
        formatter: function (cell, row) {
          return <span>{parseInt(cell)}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "active_workers",
        text: "Active",
        formatter: function (cell, row) {
          return <span>{parseInt(cell)}</span>;
        },
      },
    ];
    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    <div className="row spaceBetween">
                      <span className="pull-left">Global Daily Workers</span>
                      <span className="exportData" style={{ fontSize: "14px" }}>
                        <button
                          className="btn btn-primary mt-1"
                          onClick={() => this.showModal()}
                        >
                          Export Data
                        </button>
                        <GlobalDailyWorkerModal
                          {...this.props}
                          {...this.state}
                          excelData={excelData}
                          onCloseModal={this.onCloseModal}
                          onInputValueChange={this.onchange}
                          AddMinerRequest={this.AddMinerRequest}
                        />
                      </span>
                    </div>
                  </h4>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              {tableLoading && (
                <div className="text-center">
                  <span id="loading" color="#047bf8" />
                </div>
              )}
              <div className="earnings-table table-responsive">
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll
                    data={workerData}
                    page={earningsPage}
                    sizePerPage={earningsSizePerPage}
                    totalSize={earningsTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalDailyWorker;
