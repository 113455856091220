import React from "react";
import Api from "../../services/api";
import { toast } from "react-toastify";
class GetPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicKey: "",
      secretKey: "",
      stripePriceId: "",
      stripeUnitPrice: "",
      walletAddress: "",
      stripeDecPriceId: "",
      stripeDecUnitPrice: "",
      decPrice: "",
      decDiscount: "",
      decAddress: "",
      budgetBtcwalletAddress: "",
      wireTransferDetails: "",
    };
  }
  getDecInfo = async () => {
    try {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("admin/decTokenDetails", {
          symbol: "DEC",
        });
      if (response.code === 200 && response.data && response.data.tokenDetail) {
        this.setState({
          decPrice: response.data.tokenDetail.price,
          decDiscount: response.data.tokenDetail.discount,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  onChange = (e) => {
    this.setState({ [`${e.target.name}`]: e.target.value });
  };
  componentDidMount() {
    this.getDecInfo();
  }
  updateBTCBudgetWallet = async () => {
    const { budgetBtcwalletAddress } = this.state;
    if (budgetBtcwalletAddress) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/BTCWalletPaymentCredentials", {
          wallet: budgetBtcwalletAddress,
          budget: true,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill Wallet Address!");
    }
  };
  updateDecInfo = async () => {
    try {
      const { decAddress, decPrice, decDiscount } = this.state;
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/decTokenDetails", {
          symbol: "DEC",
          receivingAddress: decAddress,
          discount: decDiscount,
          price: decPrice,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  updateBTCWallet = async () => {
    const { walletAddress } = this.state;
    if (walletAddress) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/BTCWalletPaymentCredentials", {
          wallet: walletAddress,
          paymentMode: "btc",
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill Wallet Address!");
    }
  };
  async componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }
  updateStripeBudgetInfo = async () => {
    const { stripePriceId, stripeUnitPrice } = this.state;
    if (stripePriceId && stripeUnitPrice) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/addStripePrice", {
          stripePriceId: stripePriceId,
          type: "budget",
          unitPrice: stripeUnitPrice,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill All Stripe Budget Info!");
    }
  };
  updateStripeDecInfo = async () => {
    const { stripeDecPriceId, stripeDecUnitPrice } =
      this.state;
    if (stripeDecPriceId && stripeDecUnitPrice) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/addStripePrice", {
          stripePriceId: stripeDecPriceId,
          type: "DEC",
          unitPrice: stripeDecUnitPrice,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill All Stripe DEC Info!");
    }
  };
  updateStripeInfo = async () => {
    const { publicKey, secretKey } = this.state;
    if (publicKey && secretKey) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/StripePaymentCredentials", {
          key: publicKey,
          secret: secretKey,
          paymentMode: "stripe",
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill All Keys!");
    }
  };
  updateWireTransferInfo = async () => {
    const { wireTransferDetails } = this.state;
    if (wireTransferDetails) {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/WireTransferPaymentCredentials", {
          wireTransferDetails: wireTransferDetails,
          paymentMode: "wireTransfer",
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please Fill The Wire Transfer Details!");
    }
  };
  render() {
    const {
      publicKey,
      secret,
      stripeUnitPrice,
      stripeDecPriceId,
      stripeDecUnitPrice,
      stripePriceId,
      budgetBtcwalletAddress,
      walletAddress,
      decPrice,
      decDiscount,
      decAddress,
    } = this.state;
    return (
      <div className="adminDashboardContainer">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                  <h6 className="element-header">Update Stripe Budget Info</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Price ID<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Price ID"
                          type="text"
                          onChange={this.onChange}
                          name="stripePriceId"
                          value={stripePriceId}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Unit Price<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Unit Price"
                          onChange={this.onChange}
                          name="stripeUnitPrice"
                          type="text"
                          value={stripeUnitPrice}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={this.updateStripeBudgetInfo}
                    >
                      Submit
                    </button>
                  </div>
                  <h6 className="element-header">Update Stripe DEC Info</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Price ID<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Price ID"
                          type="text"
                          onChange={this.onChange}
                          name="stripeDecPriceId"
                          value={stripeDecPriceId}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Unit Price<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Unit Price"
                          onChange={this.onChange}
                          name="stripeDecUnitPrice"
                          type="text"
                          value={stripeDecUnitPrice}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={this.updateStripeDecInfo}
                    >
                      Submit
                    </button>
                  </div>

                  <div>
                    <h6 className="element-header">
                      Update Invoice Stripe Info
                    </h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Public Key<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Public Key"
                            type="text"
                            onChange={this.onChange}
                            name="publicKey"
                            value={publicKey}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Private Key<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Secret Key"
                            type="text"
                            onChange={this.onChange}
                            name="secret"
                            value={secret}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-1">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.updateStripeInfo}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                  <h6 className="element-header">Update BTC Budget Wallet</h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Wallet Address<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Wallet Address"
                          type="text"
                          onChange={this.onChange}
                          name="budgetBtcwalletAddress"
                          value={budgetBtcwalletAddress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={this.updateBTCBudgetWallet}
                    >
                      Submit
                    </button>
                  </div>

                  <div>
                    <h6 className="element-header">
                      Update Invoice BTC Wallet
                    </h6>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Wallet Address
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Wallet Address"
                            type="text"
                            onChange={this.onChange}
                            name="walletAddress"
                            value={walletAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-1">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.updateBTCWallet}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                <div className="d-flex justify-content-between">
                  <h6 className="element-header">Update DEC Info</h6>
                </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>DEC Price</label>
                        <input
                          className="form-control"
                          placeholder="DEC Price"
                          type="text"
                          onChange={this.onChange}
                          name="decPrice"
                          value={decPrice}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>DEC Discount</label>
                        <input
                          className="form-control"
                          placeholder="DEC Discount"
                          type="number"
                          onChange={this.onChange}
                          name="decDiscount"
                          value={decDiscount}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>DEC Receiving Address</label>
                        <input
                          className="form-control"
                          placeholder="DEC Address"
                          type="text"
                          onChange={this.onChange}
                          name="decAddress"
                          value={decAddress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 mb-3">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={() => {
                        this.updateDecInfo();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                  <h6 className="element-header">Update Wire Transfer Info</h6>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Please follow the below pattern
                        <span className="required-sign">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Bank Name : name, Bank Account Number : No, ..."
                        name="wireTransferDetails"
                        value={this.wireTransferDetails}
                        onChange={this.onChange}
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={this.updateWireTransferInfo}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GetPayment;
