import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import Web3 from "web3";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import { MINER_ROOT, PRICE_KEY, PERMIAN_SMART_CONTRACT_ADDRESS } from "../../services/api-config";
import {
  getWeb3NetworkName,
} from "../../utils/Util";
import AddMinerModal from "./AddMinerModal";
import InviteUserModel from "./InviteUserModal";
import SubaccountModal from "./SubaccountModal";
import eth from "../../utils/Eth";
import * as messageConstants from "../../utils/Messages";
import "./ManageUser.css";
let permianTokenAbi = require("../../services/permianTokenABI");

const STATUS = {
  INITIAL: "initial",
  NOT_LOGGED: "not-logged",
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
  PROCESSING: "processing",
  MISSING_METAMASK: "missing-metamask",
  MISSING_CONTRACT_ADDRESS: "missing-contract-address",
  MISMATCH_FROM_ADDRESS: "mimatch-from-address",
};

class AdminManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      geminiButtonLoading: false,
      page: 1,
      sizePerPage: 10,
      starter: true,
      geminiCheck: false,
      viabtcCheck: false,
      filterByName: "",
      filterByEmail: "",
      filterByType: "",
      filterBySubAccountId: "",
      loading: false,
      adminSmartContractAddress: "",
      decimalSmartContract: "",
      tokenSendDesiredNetwork: "",
      status: STATUS.LOADING,
      modalOpen: false,
      inviteUserModelOpen: false,
      subaccountEditModal: false,
      minerId: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.sendTokens = this.sendTokens.bind(this);
    this.removeFromWhitelist = this.removeFromWhitelist.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.onchange = this.onchange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showSubAccountModal = this.showSubAccountModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onInviteUserCloseModal = this.onInviteUserCloseModal.bind(this);
    this.onSubAccountCloseModal = this.onSubAccountCloseModal.bind(this);
    this.AddMinerRequest = this.AddMinerRequest.bind(this);
    this.ModifyPoolSettings = this.ModifyPoolSettings.bind(this);
    this.loginRedirect = this.loginRedirect.bind(this);
    this.ModifyGeminiSettings = this.ModifyGeminiSettings.bind(this);
    this.viabtcFlow = this.viabtcFlow.bind(this);
    this.geminiFlow = this.geminiFlow.bind(this);
    this.upgrade = this.upgrade.bind(this);
    this.metamaskNetwork = {
      1: messageConstants.METAMASK_CONNECT_TO_MAINNET,
      4: messageConstants.METAMASK_CONNECT_TO_RINKEBY,
      42: messageConstants.METAMASK_CONNECT_TO_KOVAN,
    };
  }

  async upgrade(email, value, userId) {
    try {
      const api = new Api();
      let authenticationToken = this.props.authToken;

      const SubscriptionIdResponse = await api
        .setToken(authenticationToken)
        .create("miner/viewSubscriptionId", {
          userId: userId,
        });

      if (SubscriptionIdResponse.code === 200) {
        if (
          SubscriptionIdResponse.data &&
          SubscriptionIdResponse.data.Subscribe
        ) {
          const response = await api
            .setToken(authenticationToken)
            .create("miner/changeSubscriptionPrice", {
              subscribeUserId: userId,
              oldSubId:
                SubscriptionIdResponse.data.Subscribe.stripeSubscribedId,
              newPriceId: PRICE_KEY,
            });
          let insert = {
            email: value,
          };
          if (response.code === 200) {
            localStorage.setItem(email, JSON.stringify(insert));
            toast.success(response.message);
            this.getRecords();
          } else {
            localStorage.setItem(email, JSON.stringify(insert));
            toast.error(response.message);
            this.getRecords();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  refreshCurrenttable() {
    this.getRecords();
  }

  viabtcFlow() {
    this.setState({ viabtcCheck: true, starter: false });
  }
  geminiFlow() {
    this.setState({ geminiCheck: true, viabtcCheck: false, starter: false });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  onInviteUserCloseModal() {
    this.setState({ inviteUserModelOpen: false });
  }

  onSubAccountCloseModal() {
    this.setState({
      subaccountEditModal: false,
      starter: true,
      geminiCheck: false,
      viabtcCheck: false,
    });
  }

  showSubAccountModal(e, row) {
    this.setState({
      minerId: row._id,
      minerDetails: row,
      subaccountEditModal: true,
      viaBtcApiKey: row.viaBtcApiKey || "",
      geminiApiKey: row.geminiApiKey || "",
      binanceAccountNumber: row.binanceAccountNumber || "",
      viaBtcSecretKey: row.viaBtcSecretKey || "",
      geminiSecretKey: row.geminiSecretKey || "",
      viaBtcPoolFees: row.viaBtcPoolFees || "",
      binanceManualEarnings: row.binanceManualEarnings || "",
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail, filterByType, filterBySubAccountId } =
      this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/users/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
          filterByType: filterByType,
          filterBySubAccountId,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.users,
            totalSize: response.data.totalUsers,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  async getTokenAddress() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("admin/token/address");
    if (response.code === 200) {
      this.setState(
        {
          adminSmartContractAddress: response.data
            ? response.data.adminSmartContractAddress
            : "",
          decimalSmartContract: response.data
            ? response.data.decimalSmartContract
            : "",
          tokenSendDesiredNetwork: response.data
            ? response.data.tokenSendDesiredNetwork
            : "",
        },
        () => {
          this.interval = setInterval(() => {
            if (eth.reload) {
              eth.reloadDone();
              this.setUpContract();
            }
          }, 1000);
          this.setUpContract();
        }
      );
    }
  }

  async sendTokens(userRow) {
    if (!userRow) {
      toast.error("Invalid request");
      return;
    }

    const { tokenSendDesiredNetwork, status } = this.state;
    var tokens = userRow.tokens;
    var userEthAddress = userRow.receivingEthAddress;
    var totalTokens = parseFloat(tokens);
    if (
      totalTokens > 0 &&
      userRow.isKycVerified === true &&
      !_.isUndefined(userRow.receivingEthAddress) &&
      userRow.receivingEthAddress !== "" &&
      userRow.receivingEthAddress !== null
    ) {
      if (status !== STATUS.INITIAL) {
        return;
      }
      if (!userEthAddress) {
        this.onError({
          message: "Please enter Receiver address",
        });
        return;
      }
      if (!totalTokens) {
        this.onError({
          message: "Please enter valid amount",
        });
        return;
      }
      if (eth.networkId !== parseInt(tokenSendDesiredNetwork)) {
        this.onError({
          message: `Wrong Metamask network. Please connect to ${getWeb3NetworkName(
            tokenSendDesiredNetwork
          )} network.`,
        });
        return;
      }
      if (!eth.isLoggedin) {
        await eth.login();
      }

      eth.getUserAccountTokenBalance();
      const adminTokenBalance = eth.getUserAccountTokenBalance;
      if (adminTokenBalance < totalTokens) {
        toast.error(
          `Insufficient Token Balance. You have ${adminTokenBalance} tokens`
        );
        return false;
      }

      this.setState({
        actualSentTokens: totalTokens,
        processedUserId: userRow._id,
      });

      this.updateStatus(STATUS.PROCESSING);
      eth
        .transferTokens(userEthAddress, totalTokens)
        .then(this.onSuccess)
        .catch((e) => {
          this.updateStatus(STATUS.ERROR);
          setTimeout(() => this.updateStatus(STATUS.INITIAL), 3000);
          this.onError(e);
        });
    } else {
      let errorMsg =
        userRow.isKycVerified === false
          ? "User is not KYC Verified."
          : totalTokens <= 0 || totalTokens === "" || _.isUndefined(totalTokens)
          ? "Invalid token request."
          : _.isUndefined(userRow.receivingEthAddress)
          ? userRow.fullName + " has not updated receiving ETH address yet."
          : "Something went wrong. Please try again.";
      toast.error(errorMsg);
    }
  }

  async onSuccess(transactionHash) {
    const { actualSentTokens, processedUserId } = this.state;
    let authenticationToken = this.props.authToken;
    let _this = this;
    if (!authenticationToken) {
      toast.error("Invalid Request.");
      return false;
    }
    const api = new Api();
    this.updateStatus(STATUS.SUCCESS);
    const response = await api
      .setToken(authenticationToken)
      .create("admin/token/update_status/sent", {
        userId: processedUserId,
        txHash: transactionHash,
        actualSentTokens,
      });
    if (response.code === 200) {
      this.setState({
        actualSentTokens: 0,
        processedUserId: "",
      });
      toast.success("Tokens transfered successfully.");
      _this.getRecords();
    }
  }

  onError = (e) => {
    let msg =
      e.message !== "" && e.message.length < 300
        ? e.message
        : "Something went wrong. Please try again later.";
    toast.error(msg);
  };

  async setUpContract() {
    const { adminSmartContractAddress } = this.state;
    this.updateStatus(STATUS.LOADING);
    if (!eth.isAvailable()) {
      this.updateStatus(STATUS.MISSING_METAMASK);
      window.open("https://metamask.io");
    }
    await eth.setContractAddress(adminSmartContractAddress);
    if (!eth.isTokenContractAvailable()) {
      this.updateStatus(STATUS.MISSING_CONTRACT_ADDRESS);
    } else {
      this.updateStatus(STATUS.INITIAL);
    }
  }

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setUpContract();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_USERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
    this.getTokenAddress();
  }

  filterRecords() {
    this.getRecords();
  }

  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByType: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  async loginRedirect(userId) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/getToken", { userId: userId });
    if (response.code === 200) {
      if (response.data) {
        const token = response.data.token;
        window.open(
          `${MINER_ROOT}/secret?token=${token}`,
          "_blank",
        );
      }
    } else {
      toast.error("Failed to login!");
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  async removeFromWhitelistContract(ethAddress) {
    if (ethAddress) {
      const { data } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      let _this = this;
      if (!_.isEmpty(data)) {
        const finalWhitelistAddress = [];
        finalWhitelistAddress.push(ethAddress);
        if (!_.isEmpty(finalWhitelistAddress)) {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.enable();
          web3.eth.getAccounts().then(function (result) {
            let currentMetamaskAccount = result[0];
            if (!_.isUndefined(currentMetamaskAccount)) {
              const tokenContract = new web3.eth.Contract(
                permianTokenAbi.TokenABI,
                PERMIAN_SMART_CONTRACT_ADDRESS
              );
              tokenContract.methods
                .removeFromWhiteList(finalWhitelistAddress)
                .send(
                  { from: currentMetamaskAccount },
                  async function (error, result) {
                    if (error !== null) {
                      toast.error(error.message);
                      return false;
                    }
                    if (result) {
                      const response = await api
                        .setToken(authenticationToken)
                        .create("admin/whitelist/address/remove", {
                          ethAddress,
                          txHash: result,
                        });
                      if (!_.isUndefined(response)) {
                        if (response.code === 200) {
                          _this.getRecords();
                          toast.success(response.message);
                        } else {
                          toast.error(response.message);
                        }
                      }
                    }
                  }
                );
            }
          });
        } else {
          toast.error(
            "Please select valid ETH address to remove from whitelist."
          );
          return false;
        }
      }
    }
  }

  removeFromWhitelist(ethAddress) {
    let _self = this;
    let buttons = "";
    if (ethAddress) {
      buttons = [
        {
          label: "Remove",
          onClick: () => _self.removeFromWhitelistContract(ethAddress),
        },
        {
          label: "Cancel",
        },
      ];
      confirmAlert({
        title: "Sure to remove address?",
        buttons: buttons,
      });
    }
  }

  async AddMinerRequest() {
    let authenticationToken = this.props.authToken;
    const {
      email,
      minerBinanceId,
      fullName,
      viaBtcApiKey,
      viaBtcSecretKey,
      binanceManualEarnings,
    } = this.state;
    if (email && minerBinanceId !== "" && viaBtcApiKey != "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/miner/add", {
          fullName,
          email,
          minerBinanceId,
          viaBtcApiKey,
          viaBtcSecretKey,
          binanceManualEarnings,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          title: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }

  async ModifyPoolSettings() {
    let authenticationToken = this.props.authToken;
    const {
      binanceAccountNumber,
      viaBtcApiKey,
      viaBtcSecretKey,
      viaBtcPoolFees,
      minerId,
      binanceManualEarnings,
    } = this.state;
    if (
      minerId !== "" &&
      binanceAccountNumber !== "" &&
      viaBtcApiKey != "" &&
      viaBtcSecretKey
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/miner/modify/details", {
          minerId,
          viaBtcPoolFees,
          binanceAccountNumber,
          viaBtcApiKey,
          viaBtcSecretKey,
          binanceManualEarnings,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          title: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onSubAccountCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }
  async ModifyGeminiSettings() {
    let authenticationToken = this.props.authToken;
    const {
      // binanceAccountNumber,
      geminiApiKey,
      geminiSecretKey,
      // viaBtcPoolFees,
      minerId,
      // binanceManualEarnings,
    } = this.state;
    if (
      minerId !== "" &&
      // binanceAccountNumber !== "" &&
      geminiApiKey != "" &&
      geminiSecretKey
    ) {
      this.setState({
        geminiButtonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/miner/modifyGeminiKeys/details", {
          minerId,
          geminiApiKey,
          geminiSecretKey,
        });
      if (response) {
        this.setState({
          geminiButtonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          title: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onSubAccountCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      filterByType,
      filterBySubAccountId,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "originalEmail",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "role",
        text: "Type",
        formatter: function (cell, row) {
          let labelClassName =
            row.role === "i"
              ? "badge-primary"
              : row.role === "s"
              ? "badge-warning"
              : row.role === "m"
              ? "badge-info"
              : "badge-success";
          let label =
            row.role === "i"
              ? "Individual Investor"
              : row.role === "s"
              ? "Supplier"
              : row.role === "m"
              ? "Miner"
              : row.role === "ci"
              ? "Corporate Investor"
              : "";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "kycStatus",
        isDummyField: true,
        text: "KYC Status",
        sort: true,
        formatter: function (cell, row) {
          let statusLabelClass = !_.isUndefined(row.kycStatusLabelClass)
            ? row.kycStatusLabelClass
            : "warning";
          let statusLabel = !_.isUndefined(row.kycStatusLabel)
            ? row.kycStatusLabel
            : "N/A";
          return (
            <div>
              <div>
                <span className={"badge badge-" + statusLabelClass}>
                  {statusLabel}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Login",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              <div className="text-left">
                <div className="d-flex">
                  <div
                    className="color-white"
                    onClick={() => {
                      _self.loginRedirect(row._id);
                    }}
                  >
                    {row.role === "m" && (
                      <button className="mr-2 btn btn-primary" type="button">
                        {" "}
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          let tokens =
            parseFloat(row.totalTokenSent) > 0
              ? parseFloat(row.tokens) - parseFloat(row.totalTokenSent)
              : row.tokens;
          return (
            <div>
              <div className="text-left">
                <div className="d-flex">
                  <Link to={"/user_detail/" + row._id} className="color-white">
                    <button className="mr-2 btn btn-primary" type="button">
                      {" "}
                      Details
                    </button>
                  </Link>

                  {row.role === "m" && (
                    <button
                      className="btn btn-xs btn-info"
                      onClick={(e) => _self.showSubAccountModal(e, row)}
                    >
                      Edit Settings
                    </button>
                  )}
                  {row.role === "m" &&
                    row.email === "miner@permianchain.com" &&
                    !localStorage.getItem(row.email) && (
                      <button
                        className="mr-2 btn btn-warning"
                        onClick={(e) => _self.upgrade(row.email, true, row._id)}
                      >
                        Upgrade
                      </button>
                    )}
                  {row.role === "m" &&
                    row.email === "edelmann.dubai@hotmail.com" &&
                    !localStorage.getItem(row.email) && (
                      <button
                        className="mr-2 btn btn-warning"
                        onClick={(e) => _self.upgrade(row.email, true, row._id)}
                      >
                        Upgrade
                      </button>
                    )}
                  {row.role === "m" &&
                    row.email === "daniel.luntadi@gmail.com" &&
                    !localStorage.getItem(row.email) && (
                      <button
                        className="mr-2 btn btn-warning"
                        onClick={(e) => _self.upgrade(row.email, true, row._id)}
                      >
                        Upgrade
                      </button>
                    )}
                  {row.role === "m" &&
                    row.email === "nik31273@gmail.com" &&
                    !localStorage.getItem(row.email) && (
                      <button
                        className="mr-2 btn btn-warning"
                        onClick={(e) => _self.upgrade(row.email, true, row._id)}
                      >
                        Upgrade
                      </button>
                    )}
                  {row.role === "m" &&
                    row.email === "fghorayeb@hotmail.com" &&
                    !localStorage.getItem(row.email) && (
                      <button
                        className="mr-2 btn btn-warning"
                        onClick={(e) => _self.upgrade(row.email, true, row._id)}
                      >
                        Upgrade
                      </button>
                    )}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => {
      return (
        <div className="table-responsive">
          <BootstrapTable
            remote
            loading={loading}
            keyField="_id"
            bordered={false}
            data={data}
            columns={columns}
            noDataIndication="No results!"
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
            overlay={overlayFactory({
              spinner: true,
              background: "rgba(192,192,192,0.3)",
            })}
            classes="table table-striped table-lightfont dataTable"
          />
        </div>
      );
    };

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    const {
      inviteUserModelOpen,
    } = this.state;

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper users-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Users</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Name</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByName"
                          id="filterByName"
                          placeholder="Full Name"
                          type="text"
                          onChange={this.onchange}
                          value={filterByName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Email</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          placeholder="Email Address"
                          type="text"
                          name="filterByEmail"
                          id="filterByEmail"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="form-group select-icon">
                        <select
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          onChange={this.onchange}
                          value={filterByType}
                          name="filterByType"
                          id="filterByType"
                          placeholder="Filter by user role"
                        >
                          <option value="">All</option>
                          <option value="i">Individual Investor</option>
                          <option value="ci">Corporate Investor</option>
                          <option value="s">Supplier</option>
                          <option value="m">Miner</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="form-group select-icon">
                        <label className="sr-only"> Sub Account ID</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterBySubAccountId"
                          id="filterBySubAccountId"
                          placeholder="Sub Account ID"
                          type="text"
                          onChange={this.onchange}
                          value={filterBySubAccountId}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper">
              <div className="element-box horizontal-scroll">
                <h5 className="form-header">
                  <span className="pull-left">
                    <span>Manage Users</span>
                    <span
                      className="fa fa-refresh ml-2 cursor-pointer"
                      onClick={() => this.refreshCurrenttable()}
                    ></span>
                  </span>
                  <span className="pull-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.showModal()}
                    >
                      Add Miner
                    </button>
                    <button
                      className="btn btn-info"
                      onClick={() => this.setState({
                        inviteUserModelOpen: true
                      })}
                    >
                      Invite User
                    </button>
                  </span>
                </h5>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddMinerModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          AddMinerRequest={this.AddMinerRequest}
        />

        <InviteUserModel
          openModal={inviteUserModelOpen}
          onCloseModal={this.onInviteUserCloseModal}
          onSubmit={() => {
            this.onInviteUserCloseModal();
            this.filterRecords();
          }}
        />

        <SubaccountModal
          {...this.props}
          {...this.state}
          onSubAccountCloseModal={this.onSubAccountCloseModal}
          onInputValueChange={this.onchange}
          ModifyPoolSettings={this.ModifyPoolSettings}
          ModifyGeminiSettings={this.ModifyGeminiSettings}
          geminiFlow={this.geminiFlow}
          viabtcFlow={this.viabtcFlow}
        />
      </div>
    );
  }
}
export default AdminManageUsers;
