import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import WireTransferModal from "./WireTransferModal";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class ManageWireTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      adminNote: "",
      transactionAmount: "",
      documnetLink: "",
      invoiceId: "",
      filterByAccount: "",
      authToken: "",
      filterByContactno: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    // this.transactionOperation = this.transactionOperation.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.onWireTransferCloseModal = this.onWireTransferCloseModal.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.showWireTransferModal = this.showWireTransferModal.bind(this);
    this.setTransactionAmount = this.setTransactionAmount.bind(this);
    this.giveElement = this.giveElement.bind(this);
    this.setInvoiceId = this.setInvoiceId.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.setAdminNote = this.setAdminNote.bind(this);
    this.getDocument = this.getDocument.bind(this);
  }
  showWireTransferModal(amount, invoiceId) {
    this.setState({ wireTransferModalOpen: true });
    this.setTransactionAmount(amount);
    this.setInvoiceId(invoiceId);
    this.getDocument(invoiceId);
  }
  setInvoiceId(value) {
    this.setState({
      invoiceId: value,
    });
  }

  async getDocument(invoiceId) {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("miner/getMinerWirePaymentDoc", { invoiceId: invoiceId });
    if (response.code === 200) {
      let object = response.data.minerPaymentDetail.paymentDocument;
      this.setState({
        documnetLink: object[Object.keys(object)[0]],
      });
    }
  }
  setAdminNote(event) {
    this.setState({ adminNote: event.target.value });
  }
  setTransactionAmount(value) {
    this.setState({ transactionAmount: value });
  }
  onWireTransferCloseModal() {
    this.setState({ wireTransferModalOpen: false });
  }
  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  refreshCurrenttable() {
    this.getRecords();
  }
  async updatePayment(invoiceId, status, adminNote) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("admin/saveUnderreviewPaymentResponse", {
        action: status,
        invoiceId: invoiceId,
        adminNote: adminNote,
      });

    if (response.code === 200) {
      this.getRecords();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByAccount } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true, authToken: authenticationToken });

    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/showUnderreviewWireTransferPayment", {
          sizePerPage: sizePerPage,
          page: page,
          filterByAccount: filterByAccount,
        });

      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.minerpaymentrequestlists,
            totalSize: response.data.minerpaymentrequestCount,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByAccount: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_KYC_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };
  giveElement() {
    return <input type="text" />;
  }
  kycOperation(invoiceId, type, reference, childInvoiceId) {
    if (reference && childInvoiceId) {
      let label = type === "a" ? "a" : "r";
      let question =
        label === "a"
          ? "Are you sure want to accept the details?"
          : "Are you sure want to reject the details?";
      let _self = reference;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1 className="">
                  {/* {"Are you sure want to " + label=="r"?"reject":"accept" + " the details?"} */}
                  {question}
                </h1>
                {label === "r" && (
                  <p className="alert__body text-center">
                    Please mention the reason for rejection.
                  </p>
                )}
                {label === "r" && (
                  <input
                    className="form-control w-100"
                    type="text"
                    value={_self.adminNote}
                    onChange={_self.setAdminNote}
                  />
                )}
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      // this.handleClickDelete();
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  {label === "r" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(
                          childInvoiceId,
                          type,
                          _self.state.adminNote
                        );
                        onClose();
                      }}
                    >
                      Reject
                    </button>
                  )}
                  {label === "a" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(childInvoiceId, type);
                        onClose();
                      }}
                    >
                      Accept
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      });
    }
    if (!reference && !childInvoiceId) {
      let label = type === "a" ? "a" : "r";
      let question =
        label === "a"
          ? "Are you sure want to accept the details?"
          : "Are you sure want to reject the details?";

      let _self = this;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1 className="">
                  {/* {"Are you sure want to " + label=="r"?"reject":"accept" + " the details?"} */}
                  {question}
                </h1>
                {label === "r" && (
                  <p className="alert__body text-center">
                    Please mention the reason for rejection.
                  </p>
                )}
                {label === "r" && (
                  <input
                    className="form-control w-100"
                    type="text"
                    value={_self.adminNote}
                    onChange={_self.setAdminNote}
                  />
                )}
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      // this.handleClickDelete();
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  {label === "r" && (
                    <button
                      onClick={() => {
                        this.updatePayment(
                          invoiceId,
                          type,
                          _self.state.adminNote
                        );
                        // console.log("_self.adminNote", _self.state.adminNote);
                        onClose();
                      }}
                    >
                      Reject
                    </button>
                  )}
                  {label === "a" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(invoiceId, type);
                        onClose();
                      }}
                    >
                      Accept
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      });
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByAccount,
      filterByName,
      filterByEmail,
      authToken,
      invoiceId,
      adminNote,
      setAdminNote,
      transactionAmount,
      wireTransferModalOpen,
      filterByContactno,
      loading,
      documnetLink,
    } = this.state;
    let _self = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "",
        text: "ACCOUNT",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{row.invoiceId.account}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "",
        text: "INOVICE ID",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{row.invoiceId._id}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "",
        text: "INVOICE AMOUNT",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>$ {Number(row.invoiceId.raisedAmountinUSD).toFixed(2)}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "transactionAmount",
        text: "PAID AMOUNT",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>$ {Number(cell).toFixed(2)}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "PAYMENT INFO",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-center">
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => {
                  _self.showWireTransferModal(
                    row.transactionAmount,
                    row.invoiceId._id
                  );
                }}
              >
                Details
              </button>
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <div className="">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <button
                      className="mr-2 mb-2 btn btn-primary"
                      type="button"
                      onClick={() => _self.kycOperation(row.invoiceId._id, "a")}
                    >
                      Accept
                    </button>
                    <br />
                    <button
                      className="mr-2 mb-2 btn btn-danger"
                      type="button"
                      onClick={() => _self.kycOperation(row.invoiceId._id, "r")}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Wire Tranfer Requests</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only"> Name</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByAccount"
                          id="filterByAccount"
                          placeholder="ACCOUNT NUMBER"
                          type="text"
                          onChange={this.onchange}
                          value={filterByAccount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box horizontal-scroll">
                <h5 className="form-header">
                  <span>Manage Wire Transfer Requests</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
                <WireTransferModal
                  {...this.props}
                  {...this.state}
                  onCloseModal={this.onWireTransferCloseModal}
                  wireTransferModalOpen={wireTransferModalOpen}
                  onChangeCurrencyType={this.onChangeCurrencyType}
                  transactionAmount={transactionAmount}
                  onAmountChange={this.onAmountChange}
                  kycOperation={this.kycOperation}
                  authToken={authToken}
                  onInputValueChange={this.onchange}
                  documnetLink={documnetLink}
                  reference={this}
                  adminNote={adminNote}
                  setAdminNote={setAdminNote}
                  invoiceId={invoiceId}
                  onUpdateAmount={this.onUpdateTransactionAmount}
                  displayValidationErrors={this.displayValidationErrors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageWireTransfer;
