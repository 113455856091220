import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import { Link } from "react-router-dom";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Select from "react-select";
import { API_ROOT } from "../../services/api-config";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class AdminResourceAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceTitle: "",
      typeList: [
        { value: "file", label: "Document" },
        { value: "link", label: "Link" },
      ],
      categoryList: [],
      selectedCategory: "",
      selectedCategoryLabel: "",
      selectedType: "link",
      selectedTypeLabel: "Link",
      panelList: [
        { value: "m", label: "Miner" },
        { value: "i", label: "Investor" },
        { value: "s", label: "Supplier" },
        { value: "d", label: "Dealer" },
        { value: "all", label: "All" },
      ],
      selectedPanel: "",
      selectedPanelLabel: "",
      resourceDescription: "",
      linkValue: "",
      buttonLoading: false,
    };
    this.fileUpdated = this.fileUpdated.bind(this);
    this.onchange = this.onchange.bind(this);
    this.saveResource = this.saveResource.bind(this);
    this.changePanel = this.changePanel.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.uploadImageArray = {};
  }

  changeCategory(e) {
    this.setState({
      selectedCategory: e.value,
      selectedCategoryLabel: e.label,
    });
  }
  changeType(e) {
    this.setState({
      selectedType: e.value,
      selectedTypeLabel: e.label,
    });
  }
  async getCategories() {
    try {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      this.setState({ loading: true });
      const response = await api
        .setToken(authenticationToken)
        .get("user/viewCategories");
      if (response.code === 200) {
        if (response.data) {
          const categoryArr = [];
          const resourceCategories = response.data.resourceCategories;

          resourceCategories.map((data) => {
            const category = {};
            category.value = data.title;
            category.label = data.title;
            categoryArr.push(category);
          });
          categoryArr.push({
            value: "undefined",
            label: "undefined",
          });

          this.setState({ categoryList: categoryArr });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  changePanel(e) {
    this.setState({
      selectedPanel: e.value,
      selectedPanelLabel: e.label,
    });
  }
  async componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
  }

  componentDidMount() {
    this.getCategories();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveResource(event) {
    let _this = this;
    try {
      const {
        resourceTitle,
        resourceDescription,
        selectedType,
        linkValue,
        selectedPanel,
        selectedCategory,
      } = this.state;
      this.setState({
        buttonLoading: true,
      });

      if (selectedType === "link") {
        const api = new Api();
        let authenticationToken = this.props.authToken;
        const response = await api
          .setToken(authenticationToken)
          .create("admin/resource/add", {
            resourceTitle: resourceTitle,
            resourceDescription: resourceDescription,
            location: linkValue,
            panel: selectedPanel,
            category: selectedCategory,
            type: selectedType,
          });
        if (response.code === 200) {
          toast.success(response.message);
          _this.props.history.push("/manage_resources");
        } else {
          this.setState({
            buttonLoading: false,
          });
        }
      } else if (selectedType === "file") {
        const data = new FormData();
        data.append("image", _this.uploadImageArray["file"]);
        data.append("resourceTitle", resourceTitle);
        data.append("resourceDescription", resourceDescription);
        data.append("panel", selectedPanel);
        data.append("category", selectedCategory);
        data.append("type", selectedType);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.props.authToken,
          },
        };
        axios
          .post(API_ROOT + "/admin/resource/add", data, config)
          .then(function (response) {
            if (!_.isUndefined(response)) {
              _this.setState({
                buttonLoading: false,
              });
              if (response.data.code === 200) {
                toast.success(response.data.message);
                _this.props.history.push("/manage_resources");
              } else {
                toast.error(response.data.message);
                this.setState({
                  buttonLoading: false,
                });
              }
            }
          })
          .catch(function (error) {
            toast.error(messageConstants.SOMETHING_WENT_WRONG);
            _this.setState({
              buttonLoading: false,
            });
          });
      }
    } catch (e) {
      console.log(e);
      _this.setState({
        buttonLoading: false,
      });
    }
  }

  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }

  render() {
    let _this = this;
    const {
      buttonLoading,
      selectedPanel,
      panelList,
      selectedPanelLabel,
      selectedTypeLabel,
      selectedType,
      typeList,
      selectedCategory,
      selectedCategoryLabel,
      linkValue,
      categoryList,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="pull-right">
                  <Link
                    to="/manage_resources"
                    className="pull-right text-underline"
                  >
                    Back to resources
                  </Link>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <form id="formValidate">
                      <h5 className="form-header"> Add resource </h5>

                      <div className="form-group">
                        <label htmlFor=""> Title </label>
                        <input
                          className="form-control"
                          placeholder="Enter resource title"
                          type="text"
                          name="resourceTitle"
                          onChange={this.onchange}
                        />
                      </div>
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <div className="form-group">
                            <label htmlFor=""> Type </label>
                            <Select
                              value={{
                                value: selectedType,
                                label: selectedTypeLabel || "Select Type",
                              }}
                              options={typeList}
                              onChange={(e) => {
                                this.changeType(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <div className="form-group">
                            <label htmlFor=""> Panel </label>
                            <Select
                              value={{
                                value: selectedPanel,
                                label: selectedPanelLabel || "Select Panel",
                              }}
                              options={panelList}
                              onChange={(e) => {
                                this.changePanel(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <div className="form-group">
                            <label htmlFor=""> Category </label>
                            <Select
                              value={{
                                value: selectedCategory,
                                label:
                                  selectedCategoryLabel || "Select Category",
                              }}
                              options={categoryList}
                              onChange={(e) => {
                                this.changeCategory(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label> Short Description</label>
                        <textarea
                          placeholder="Enter resource short description"
                          className="form-control"
                          rows="3"
                          name="resourceDescription"
                          onChange={this.onchange}
                        ></textarea>
                      </div>
                      {selectedType === "link" && (
                        <div className="form-group">
                          <label htmlFor="">Location </label>
                          <input
                            className="form-control"
                            placeholder="Enter Link"
                            type="text"
                            name="linkValue"
                            value={linkValue}
                            onChange={this.onchange}
                          />
                        </div>
                      )}
                      {selectedType === "file" && (
                        <div>
                          <label> Resource File</label>
                          <div className="resource-continaer dropzoneIdContainer">
                            <div className="row">
                              <div className="col-md-12">
                                <FilePond
                                  allowMultiple={false}
                                  allowFileTypeValidation={true}
                                  acceptedFileTypes={[
                                    "image/*",
                                    "application/pdf",
                                    "application/msword",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                  ]}
                                  instantUpload={false}
                                  onupdatefiles={(fileItems) => {
                                    if (
                                      !_.isEmpty(fileItems) &&
                                      fileItems[0].fileSize < 5000000
                                    ) {
                                      if (
                                        fileItems[0].fileExtension === "pdf" ||
                                        fileItems[0].fileExtension === "docx"
                                      ) {
                                        _this.fileUpdated(fileItems);
                                      } else if (
                                        (fileItems[0].fileExtension == "jpeg" ||
                                          fileItems[0].fileExtension == "jpg" ||
                                          fileItems[0].fileExtension == "gif" ||
                                          fileItems[0].fileExtension ==
                                            "tiff" ||
                                          fileItems[0].fileExtension == "psd" ||
                                          fileItems[0].fileExtension == "eps" ||
                                          fileItems[0].fileExtension == "ai" ||
                                          fileItems[0].fileExtension ==
                                            "indd" ||
                                          fileItems[0].fileExtension == "raw" ||
                                          fileItems[0].fileExtension ==
                                            "png") &&
                                        fileItems[0].fileSize < 500000
                                      ) {
                                        _this.fileUpdated(fileItems);
                                      } else if (
                                        (fileItems[0].fileExtension == "jpeg" ||
                                          fileItems[0].fileExtension == "jpg" ||
                                          fileItems[0].fileExtension == "gif" ||
                                          fileItems[0].fileExtension ==
                                            "tiff" ||
                                          fileItems[0].fileExtension == "psd" ||
                                          fileItems[0].fileExtension == "eps" ||
                                          fileItems[0].fileExtension == "ai" ||
                                          fileItems[0].fileExtension ==
                                            "indd" ||
                                          fileItems[0].fileExtension == "raw" ||
                                          fileItems[0].fileExtension ==
                                            "png") &&
                                        fileItems[0].fileSize > 500000
                                      ) {
                                        toast.error(
                                          "Please make sure image size is under 500kb"
                                        );
                                      }
                                    } else if (
                                      !_.isEmpty(fileItems) &&
                                      fileItems[0].fileSize > 5000000
                                    ) {
                                      toast.error(
                                        "Please make sure document size is under 5mb"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-buttons-w d-flex justify-content-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={this.saveResource}
                          disabled={buttonDisabled}
                        >
                          Submit{" "}
                          {buttonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminResourceAdd;
