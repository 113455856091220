import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { ExportCSV } from "./ExportCSV";
import _ from "lodash";
import "./Earnings.css";
class ExportBudgetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      show: false,
      exportAll: "",
      earningData: [],
      fileName: "Budget Transaction",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.setShow = this.setShow.bind(this);
    this.exportAllData = this.exportAllData.bind(this);
    this.clearTime = this.clearTime.bind(this);
  }

  setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ exportAll: "" });
  }

  exportAllData() {
    this.setState(
      {
        exportAll: "All",
      },
      () => {
        this.setState({ startDate: "", endDate: "" });
      }
    );
  }
  async onChangeStartDate(e) {
    this.setState(
      {
        startDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
        this.setState({ exportAll: "" });
      }
    );
  }
  async onChangeEndDate(e) {
    this.setState(
      {
        endDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
        this.setState({ exportAll: "" });
      }
    );
  }
  async onSubmit() {}

  render() {
    const {
      modalOpen,
      onCloseModal,
      allData,
      exportAll,
    } = this.props;

    const { fileName, earningData, timeHorizon } = this.state;
    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">

                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4 className="onboarding-title">
                      Export Budget Transactions
                    </h4>
                    <div className="form-group">
                      <label htmlFor="title">
                        Start Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100"
                        id="startDate"
                        type="date"
                        value={this.state.startDate}
                        onChange={this.onChangeStartDate}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="title">
                        End Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="endDate"
                        type="date"
                        value={this.state.endDate}
                        onChange={this.onChangeEndDate}
                      />
                    </div>
                    <div className="separator">
                      <hr />
                      <span>OR</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Export All Data</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="btn-group mr-1 mb-1"
                          style={{ width: "151px" }}
                        >
                          <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            className="btn default-border-btn dropdown-toggle"
                            data-toggle="dropdown"
                            id="dropdownMenuButton6"
                            type="button"
                          >
                            {this.state.exportAll || <span>Select All</span>}
                          </button>
                          <div
                            aria-labelledby="dropdownMenuButton6"
                            className="dropdown-menu"
                          >
                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.exportAllData();
                              }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pull-left mb-3">
                      <ExportCSV
                        csvData={allData}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        exportAll={this.state.exportAll}
                        fileName={fileName}
                        onCloseModal={onCloseModal}
                        clearTime={this.clearTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ExportBudgetModal;
