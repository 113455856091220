import { tokenSymbol } from '../services/api-config';

/* Page title */
export const SIGNUP_PAGE_TITLE = "Signup";
export const SIGNIN_PAGE_TITLE = "Signin";
export const FORGOT_PAGE_TITLE = "Forgot Password";
export const RESET_PASSWORD_PAGE_TITLE = "Reset Password";

export const KYC_BASIC_DETAILS_PAGE_TITLE = "Identity & Contact Details";
export const KYC_UPLOAD_FILES_PAGE_TITLE = "Upload KYC files";
export const KYC_SUBMIT_PAGE_TITLE = "Submit KYC";
export const KYC_STATUS_PAGE_TITLE = "KYC Status";

export const DASHBOARD_PAGE_TITLE = "Dashboard";
// export const GLOBAL_EARNING_PAGE_TITLE = "Global Earning";
// export const GLOBAL_MINING_PAGE_TITLE = "Global Mining";
// export const GLOBAL_WROKERS_PAGE_TITLE = "Global WROKERS";
export const WORKERS_PAGE_TITLE = 'Global Workers';
export const EARNINGS_PAGE_TITLE = 'Global Earnings';
export const MINING_PAGE_TITLE = 'Global Minings';
export const MY_ACCOUNT_PAGE_TITLE = "My Account";
export const BUY_XPR_PAGE_TITLE = `Buy ${tokenSymbol}`;
export const MY_INVESTMENT_PAGE_TITLE = "My Investment";
export const LISTINGS_PAGE_TITLE = "Listings";
export const TERMS_CONDITIONS_PAGE_TITLE = "Terms & Conditions";
export const RESOURCES_PAGE_TITLE = "Resources";
export const WORKER_INVENTORY_PAGE_TITLE = 'Worker Inventory';

export const ADMIN_SINGIN_PAGE_TITLE = "Signin";
export const ADMIN_DASHBOARD_PAGE_TITLE = "Dashboard";
export const ADMIN_SITE_SETTINGS_PAGE_TITLE = "Site Settings";
export const ADMIN_TOKEN_SETTINGS_PAGE_TITLE = "Token Settings";
export const ADMIN_CHANGE_PASSWORD_PAGE_TITLE = "Change Password";
export const ADMIN_MANAGE_KYC_PAGE_TITLE = "Manage KYC";
export const ADMIN_MANAGE_USERS_PAGE_TITLE = "Manage Users";
export const ADMIN_MANAGE_MANDATE_PAGE_TITLE = "Manage Mandates";
export const ADMIN_MANAGE_PAYMENT_REQUESTS_PAGE_TITLE = "Manage Payment Requests";
export const ADMIN_MANAGE_TRANSACTION_PAGE_TITLE = "Manage Transactions";
export const ADMIN_EXCHANGE_TRANSACTION_PAGE_TITLE = "Exchange Transactions";
export const ADMIN_MANAGE_UNRESOLVED_TRANSACTION_PAGE_TITLE = "Manage Unresolved Transactions";
export const ADMIN_MANAGE_RESOURCES_PAGE_TITLE = "Manage Resources";
export const ADMIN_MANAGE_PROGRESS_CATEGORIES_PAGE_TITLE = "Manage Progress Categories";
export const ADMIN_MANAGE_LISTING_REQUESTS_PAGE_TITLE = "Manage Listing Requests";
export const LISTING_PROGRESS_REPORTS_PAGE_TITLE = "Progress Reports";
export const ADMIN_MANAGE_VERIFIERS_PAGE_TITLE = "Manage Verifiers";
export const ADMIN_MANAGE_EXCHANGE_VERIFIERS_PAGE_TITLE = "Manage Exchange Verifiers";
export const ADMIN_MANAGE_EVENTS_PAGE_TITLE = "Manage Events";
export const ADMIN_MANAGE_VERIFICATION_EVENTS_PAGE_TITLE = "Manage Verification Events";
export const ADMIN_WHITELIST_ADDRESS_PAGE_TITLE = "Whitelist Addresses";
export const ADMIN_MANAGE_WITHDRAWAL_REQUESTS_PAGE_TITLE = "Manage Withdrawal Requests";
export const ADMIN_MANAGE_DEALERS_PAGE_TITLE = "Manage Dealers";

export const PAGE_TITLE_SEPERATOR = " - ";

/* Page title ends */
export const PERMIAN_LABEL = "PermianChain";
export const LABEL_SELECT_COUNTRY = "Select country";
export const SOMETHING_WENT_WRONG = "Something went wrong. Please try again later.";
export const RESOURCE_RECORD_NOT_FOUND = "There are no resources to display.";
export const REQUIRED_FIELDS_VALID_MSG = "Please enter valid details for required fields.";
export const PLEASE_ACCEPT_DECLARATION = "Please accept all declaration.";
export const KYC_DOCUMENTS_VERIFIED_SUCCESS = "Your KYC documents verified successfully.";
export const KYC_DOCUMENTS_REJECTED = "Your KYC documents has been rejected. Please submit it again.";
export const KYC_DOCUMENTS_UNDER_REVIEW = "Your KYC details are under review. We will notify you once it is completed.";
/* Error Message Display */

export const ERROR_MSG_VALID_RESOURCE_TITLE = "Please enter valid resource title.";
export const ERROR_MSG_VALID_RESOURCE_FILE = "Please add resource file.";
export const ERROR_MSG_SIGNATURE = "Please add your signature.";
export const ERROR_MSG_VALID_FILES = "Please upload valid files.";
export const ERROR_MSG_REQUIRED_FILES = "Please upload all required files.";
export const ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS = "Please enter valid receiving ETH address.";
export const ERROR_MSG_VALID_TWO_FA_CODE = "Please enter valid two factor code.";
export const ERROR_US_TIN_REQUIRED = "Please enter valid U.S. TIN no.";
export const METAMASK_CONNECT_TO_MAINNET = "Please connect to mainnet in metamask.";
export const METAMASK_CONNECT_TO_RINKEBY = "Please connect to rinkeby test network in metamask.";
export const METAMASK_CONNECT_TO_KOVAN = "Please connect to Kovan test network in metamask.";
export const ENOUGHT_XPR_TOKEN_BALANCE = `You does not have enough ${tokenSymbol} token balance to send.`;
export const TOKEN_TRANSFERED_SUCCESSFULLY = "Tokens transfered successfully.";
export const NOT_ENOUGHT_ETHER_TO_TRANSFER_TOKENS = "You dont have enough ethers to transfer Tokens.";
export const PLEASE_CONNECT_METAMASK_ACCOUNT = "Please connect with metamask account.";
export const USER_KYC_PENDING = "User is not KYC Verified.";
export const INVALID_TOKEN = "Invalid token request.";
export const INVALID_REQUEST = "Invalid request";
export const ERROR_MSG_VALID_CATEGORY_TITLE = "Enter valid category title.";
export const DELETE_CONFIRM_MESSAGE = "Are you sure want to delete?";
export const PROGRESS_REPORTS_RECORD_NOT_FOUND = "Progress reports not found.";
export const BLOCK_CHAIN_EVENT_NOT_SELECTED = "Block chain event not selected for approval. Please select blockchain event.";

/* Error Message Display */
export const LABEL_INDIVIDUAL_PROFESSIONAL_STATUS = {
    "E": "Employed",
    "SE": "Self Employed",
    "R": "Retired",
    "NE": "Not Employed",
};

export const LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE = {
    "OE": "By one of your employees",
    "OWN": "On my own initiative (marketing, website…)",
    "R": "Referral by one of your other customers",
    "ROUB": "Referral by one of your business introducers/intermediaries",
};

export const LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW = {
    "L500000USD": "Less than 500,000 USD",
    "500000TO1000000USD": "500,000 to 1,000,000 USD",
    "M1000000USD": "More than 1,000,000 USD"
};

export const LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH = {
    "L500000USD": "Less than 500,000 USD",
    "500000TO1000000USD": "500,000 to 1,000,000 USD",
    "1000000TO5000000USD": "1,000,000 to 5,000,000 USD",
    "5000000TO10000000USD": "5,000,000 to 10,000,000 USD",
    "M10000000USD": "More than 10,000,000 USD",
    "OTHER": "Other"
};

export const LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH = {
    "PROFESSIONALBUSINESSACTIVITIES": "Professional/business activities",
    "INHERITANCEGIFTS": "Inheritance / Gifts",
    "PENSIONORSTOCKOPTIONPAYOUT": "Pension or Stock option pay out",
    "REALESTATETRANSACTIONS": "Real estate transactions",
    "MANAGINGPERSONALINVESTMENTS": "Managing personal investments",
    "SALEOFOWNBUSINESS": "Sale of own business",
    "OTHER": "Other",
};

export const LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME = {
    "UPTO100000USD": "Up to 100,000 USD",
    "100000TO250000USD": "100,000 to 250,000 USD",
    "250000TO500000USD": "250,000 to 500,000 USD",
    "500000TO1000000USD": "500,000 to 1,000,000 USD",
    "M1000000USD": "More than 1,000,000 USD"
};

export const LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME = {
    "SALARYORPENSION": "Salary or pension",
    "INCOMEFROMINVESTMENTS": "Income from investments",
    "BUSINESSINCOME": "Business income",
    "RENTALINCOME": "Rental income",
    "M1000000USD": "More than 1,000,000 USD"
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET = {
    "EXCELLENT": "Excellent",
    "GOOD": "Good",
    "MODERATE": "Moderate",
    "POOR": "Poor",
    "OTHER": "Other"
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET = {
    "L1YEAR": "Less than 1 year",
    "1TO2YEARS": "1 to 2 years",
    "3TO5YEARS": "3 to 5 years",
    "M5YEARS": "More than 5 years",
    "OTHER": "Other"
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE = {
    "WITHADVICEROFFINANCIALADVISOR": "With advice of a financial advisor",
    "NONDISCRETIONARYMANAGEMENT": "Non-discretionary management",
    "DISCRETIONARYMANAGEMENT": "Discretionary management",
    "BYMYSELF": "By Myself",
    "OTHER": "Other"
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED = {
    "SHARES": "Shares (equity)",
    "BONDS": "Bonds (debentures)",
    "COLLECTIVEINVESTMENTFUND": "Collective investment funds",
    "SUKUK": "Sukuk",
    "STRUCTUREDPRODUCTS": "Structured products",
    "DERIVATIVES": "Derivatives (options, futures)",
    "INSURANCEINVESTMENTS": "Insurance investments",
    "COMMODITIES": "Commodities",
    "CURRENCIES": "Currencies",
    "HEDGEFUND": "Hedge funds",
    "PRIVATEEQUITY": "Private equity",
    "OTHER": "Other",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS = {
    "SELDOM": "Seldom",
    "MONTHLY": "Monthly",
    "WEEKLY": "Weekly",
    "DAILY": "Daily",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_BUSINESS_ACTIVITIES = {
    "COMMERCIAL_ACTIVITIES": "Commercial activities",
    "INVESTMENT_ACTIVITIES": "Investment activities",
    "PERSONAL_INVESTMENT_VEHICLE": "Personal investment vehicle",
    "ANY_OTHER_ACTIVITIES": "Any other activities"
};


export const LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE = {
    "OE": "By one of your employees",
    "OWN": "On my own initiative (marketing, website…)",
    "R": "Referral by one of your other customers",
    "ROUB": "Referral by one of your business introducers/intermediaries",
};


export const LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW = {
    "L500000USD": "Less than 500,000 USD",
    "500000TO1000000USD": "500,000 to 1,000,000 USD",
    "M1000000USD": "More than 1,000,000 USD"
};


export const LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH = {
    "L500000USD": "Less than 500,000 USD",
    "500000TO1000000USD": "500,000 to 1,000,000 USD",
    "1000000TO5000000USD": "1,000,000 to 5,000,000 USD",
    "5000000TO10000000USD": "5,000,000 to 10,000,000 USD",
    "M10000000USD": "More than 10,000,000 USD",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP = {
    "BUSINESS_INCOME": "Business income",
    "INCOME_FROM_INVESTMENT": "Income from Investments",
    "SHAREHOLDER_EQUITY": "Shareholder’s equity",
    "PRIVATE_PLACEMENT": "Private Placement",
    "SALE_OF_INVESTMENT": "Sale of Investments",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE = {
    "PROFESSIONAL_INCOME": "Professional Income",
    "SALE_FROM_INVESTMENT": "Sale of Investments",
    "REAL_ESTATE_TRANSACTION": "Real estate transactions",
    "GIFT_INHERITANCE": "Gift /Inheritance",
    "INCOME_FROM_INVESTMENT": "Income from Investments",
    "OTHER": "Other"
};


export const LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET = {
    "EXCELLENT": "Excellent",
    "GOOD": "Good",
    "MODERATE": "Moderate",
    "POOR": "Poor",
    "OTHER": "Other"
};


export const LABEL_CORPORATE_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET = {
    "L1YEAR": "Less than 1 year",
    "1TO2YEARS": "1 to 2 years",
    "3TO5YEARS": "3 to 5 years",
    "M5YEARS": "More than 5 years",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE = {
    "WITHADVICEROFFINANCIALADVISOR": "With advice of a financial advisor",
    "NONDISCRETIONARYMANAGEMENT": "Non-discretionary management",
    "DISCRETIONARYMANAGEMENT": "Discretionary management",
    "BYMYSELF": "By Myself",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS = {
    "SELDOM": "Seldom",
    "MONTHLY": "Monthly",
    "WEEKLY": "Weekly",
    "DAILY": "Daily",
    "OTHER": "Other"
};

export const LABEL_CORPORATE_FATCA_CRS_DECLARATION = {
    "SPECIFIEDUSPERSON": "The Subscriber is a Specified U.S. Person and the Subscriber’s U.S. Federal Taxpayer Identifying number (U.S. TIN) is as follows:",
    "NOTSPECIFIEDUSPERSON": "The Subscriber is not a Specified U.S. Person (please also complete Sections 3, 4 and 5).",
    "ENTITYUSPERSON": "The Entity is a US person but not a Specified U.S. Person (please also complete Sections 3, 4 and 5)."
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION = {
    "PARTNER": "Partner Jurisdiction Financial Institution",
    "DEEMEDCOMPLIANT": "Registered Deemed Compliant Foreign Financial Institution",
    "PARTICIPATING": "Participating Foreign Financial Institution"
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN = {
    "SPONSOREDBY": "The Subscriber has not yet obtained a GIIN but is sponsored by another entity which does have a GIIN (its “Sponsor”).  Please provide the sponsor’s name and sponsor’s GIIN",
    "EXEMPTBENEFICIALOWNER": "Exempt Beneficial Owner",
    "CERTIFIEDDEEMEDCOMPLIANT": "Certified Deemed Compliant Foreign Financial Institution (including a deemed compliant Financial Institution under Annex II of the Agreement)",
    "NONPARTICIPATING": "Non-Participating Foreign Financial Institution",
    "EXCEPTED": "Excepted Foreign Financial Institution",
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION = {
    "ACTIVE": "Active Non-Financial Foreign Entity",
    "PASSIVE": "Passive Non-Financial Foreign Entity",
    "EXCEPTED": "Excepted Non-Financial Foreign Entity"
};

export const LABEL_CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_FINANCIAL_INSTITUTION = {
    "UNDERCRS": "Financial Institution under CRS",
    "OTHERFINANCIALINSTITUTION": "An Investment Entity located in a Non-Participating Jurisdiction and managed by another Financial Institution"
};

export const LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION = {
    "CORPORATION": "Active Non-Financial Entity – a corporation the stock of which is regularly traded on an established securities market or a corporation which is a related entity of such a corporation",
    "GOVERNMENT": "Active Non-Financial Entity – a Government Entity or Central Bank",
    "INTERNATIONALORGANIZATION": "Active Non-Financial Entity – an International Organization",
    "OTHER": "Active Non-Financial Entity – other than (I)-(III) (for example a start-up Non-Financial Entity or a non-profit Non-Financial Entity)",
    "PASSIVE": "Passive Non-Financial Entity"
};

export const LABEL_INDIVIDUAL_FATCA_CRS_DECLARATION_US_PERSON = {
    "Y": "I confirm that [I am]/[the Subscriber is] a U.S. citizen and/or resident in the U.S. for tax purposes and [my]/[its] U.S. federal taxpayer identifying number (U.S. TIN) is as follows:",
    "N": "I confirm that [I am not]/[the Subscriber is not] a U.S. citizen or resident in the U.S. for tax purposes."
};
/* Messages */