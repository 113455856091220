import React, { Component } from "react";
import axios from "axios";
import { API_ROOT } from "../../services/api-config";
import Modal from "react-responsive-modal";
import "./Inventory.css";

export default class UploadWorkerInventoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false
    };
  }

  render() {
    const {
      modalOpen,
      onCloseModal,
      onUploadSuccess,
      onUploadFail
    } = this.props;

    const handleUpload = async (event) => {
      try {
        if(!event.target.files[0]) {
          throw new Error('No file found!')
        }
        const formData = new FormData();
        formData.append('csv', event.target.files[0])
        this.setState({ isUploading: true });

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.props.authToken,
          },
        };
        const response = await axios.post(`${API_ROOT}/admin/workerInvetory/upload`, formData, config)
        if (response.status !== 200 || !response.data) {
          throw new Error("Fail to fetch worker inventory")
        }
        this.setState({ isUploading: false });
        onCloseModal()
        onUploadSuccess()
      } catch (err) {
        this.setState({ isUploading: false });
        onCloseModal()
        onUploadFail(err)
      }
    }

    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4 className="onboarding-title">Import Data</h4>
                    {this.state.isUploading ? 
                    <div> Uploading... </div> 
                    :
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Select CSV File:</div>
                      <input type="file" onChange={handleUpload}/>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
