import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";

import Api from "../../services/api";
const api = new Api();

export default function StopLossTable({ authToken }) {
  const [workerData, setWorkerData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const handleTableChange = async (type, params) => {
    if (type === "pagination") {
      const { page: pPage, sizePerPage: pSizePerPage } = params;
      if (sizePerPage !== pSizePerPage || page !== pPage) {
        setPage(pPage);
        setSizePerPage(pSizePerPage);
      }
    }
  };

  const fetchStopLoss = async () => {
    setLoading(true)
    const response = await api.setToken(authToken).get("miner/getStopLoss", {
      page,
      sizePerPage,
    });
    if (response.code === 200) {
      const data = response.data.stopLoss.map((d) => {
        return {
          ...d,
          binanceAccountNumber: d.userId.binanceAccountNumber || "",
          email: d.userId.email || "",
          date: moment(d.updatedAt).format("YYYY-MM-DD"),
          status: d.status === "s" ? "Proceeded" : "Pending",
        };
      });
      setWorkerData(data);
      setTotalSize(response.data.total);
    }
    setLoading(false)
  };

  const setAsProceeded = async (id) => {
    const response = await api.setToken(authToken).create("miner/setStopLoss", {
      id,
    });
    if (response.code === 200) {
      await fetchStopLoss();
    }
  };

  useEffect(() => {
    fetchStopLoss();
  }, [page, sizePerPage]);

  const workerColumns = [
    {
      headerClasses: "custom-table-th",
      style: { whiteSpace: "nowrap" },
      dataField: "binanceAccountNumber",
      text: "Sub Account ID",
    },
    {
      headerClasses: "custom-table-th",
      style: { whiteSpace: "nowrap" },
      dataField: "email",
      text: "Email",
    },
    {
      headerClasses: "custom-table-th",
      style: { whiteSpace: "nowrap" },
      dataField: "number",
      text: "Workers",
    },
    {
      headerClasses: "custom-table-th",
      dataField: "date",
      text: "Date",
    },
    {
      headerClasses: "custom-table-th",
      dataField: "status",
      text: "Status",
    },
    {
      headerClasses: "custom-table-th",
      dataField: "status",
      text: "STATUS",
      formatter: function (cell, row, rowIndex, formatExtraData) {
        return (
          <div className="row justify-content-center">
            <div className="d-inline-block">
              <div className="btn-group mr-1 mb-1">
                <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  className="btn default-border-btn dropdown-toggle"
                  data-toggle="dropdown"
                  id="dropdownMenuButton6"
                  type="button"
                  disabled={row.status === "Proceeded"}
                >
                  Action
                </button>
                <div
                  aria-labelledby="dropdownMenuButton6"
                  className="dropdown-menu"
                >
                  <button
                    className="dropdown-item"
                    onClick={async () => {
                      setAsProceeded(row._id);
                    }}
                  >
                    Proceeded
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const WorkerRemoteAll = ({ data, onTableChange }) => (
    <div className="table-responsive">
      <BootstrapTable
        remote
        keyField="_id"
        bordered={false}
        loading={isLoading}
        data={data}
        columns={workerColumns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        noDataIndication="No results!"
        overlay={overlayFactory({
          spinner: true,
          background: "rgba(192,192,192,0.3)",
        })}
        classes="table table-striped table-lightfont dataTable"
      />
    </div>
  );

  WorkerRemoteAll.propTypes = {
    data: PropTypes.array.isRequired,
  };
  return (
    <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
      <div className="earnings-table table-responsive">
        <WorkerRemoteAll
          data={workerData}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      </div>
    </div>
  );
}
