import React, { Component } from 'react';
import { Radio, RadioGroup } from "react-icheck";
import Modal from "react-responsive-modal";
class TransactionOperationModal extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { addTransactionButtonLoading, modalOpen, onCloseModal, onInputValueChange, updateWithdrawalRequestTransaction, handleRadioChange } = this.props;
    let buttonDisabled = (addTransactionButtonLoading === true) ? true : false;

    return (
      <Modal open={modalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" style={{'min-width':'500px'}}>
              
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-center">Update withdrawal request status</h4>
                    <form>
                      
                      <div className="form-group">
                        <label>Transaction Hash</label>
                        <input className="form-control" id="txHash" placeholder="Enter transaction hash" type="text" name="txHash" onChange={onInputValueChange} />
                      </div>
      
                      <div className="form-group">
                        <label>Note</label>
                        <textarea className="form-control" id="adminNote" placeholder="Enter note" name="adminNote" onChange={onInputValueChange}></textarea>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Status</label>
                          <RadioGroup name="type" className="wealth-split-radio-container" onChange={(e, value) => handleRadioChange(e, value, 'type')}>
                            <Radio
                              value='a'
                              radioClass="iradio_square-blue"
                              increaseArea="20%"
                              label={" Approved"}
                            />
                            <Radio
                              value='r'
                              radioClass="iradio_square-blue"
                              increaseArea="20%"
                              label={" Reject"}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      <div><button className="btn btn-primary permian-button submit-button-padding-set" type="button" disabled={buttonDisabled} onClick={updateWithdrawalRequestTransaction}> Submit</button></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default TransactionOperationModal;