import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import _ from "lodash";
import { niceNumberDecimalDisplay, getBtcUsd } from "../../utils/Util";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import GlobalDailyEarningModal from "./GlobalDailyEarningModal";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "./Earnings.css";
class GlobalDailyEarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earningsLoading: true,
      tableLoading: true,
      workerTotalSize: "",
      offlineWorkers: 0,
      accumulatedEarnings: 0,
      earningsData: [],
      earningsDatas: [],
      tableData: [],
      earningsPage: 1,
      earningsSizePerPage: 20,
      earningsRenderFlag: false,
      earningsTotalSize: 0,
      baseCurrency: "btc",
      electricityCost: 0,
      Last30DaysPowerCost: "",
      yesterdayEarnings: "",
      yesterdayNetEarnings: "",
      yesterdayPoolFees: "",
      yesterdayPowerCost: "",
      timeHorizon: "all",
      netEarnings: "",
      netPoolFee: "",
      excelData: [],
      earningDetails: {},
      viewers: [],
      fileName: "",
    };
    this.props.pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.selectBaseCurrency = this.selectBaseCurrency.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.allData = this.allData.bind(this);
  }

  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }
  allData() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("user/getdailyMinerData")
      .then(async (data) => {
        if (data.data) {
          const priceUSD = await getBtcUsd();
          const sortData = data.data.minerGrossprofits;
          sortData.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          let earningsArr = [];

          sortData.map((data) => {
            let earnings = {};
            earnings.date = data.date;
            earnings.grossEarnings = data.GrossEarnings
              ? niceNumberDecimalDisplay(data.GrossEarnings, 8)
              : "";
            earnings.grossEarningsinUSD = data.GrossEarnings
              ? niceNumberDecimalDisplay(data.GrossEarnings * priceUSD, 2)
              : "";
            earnings.fees = data.Fees
              ? niceNumberDecimalDisplay(data.Fees, 8)
              : "";
            earnings.feesinUSD = data.Fees
              ? niceNumberDecimalDisplay(data.Fees*priceUSD, 2)
              : "";
            earnings.powerCost = data.PowerCost
              ? niceNumberDecimalDisplay(data.PowerCost, 8)
              : "";
            earnings.powerCostinUSD = data.PowerCost
              ? niceNumberDecimalDisplay(data.PowerCost*priceUSD, 2)
              : "";
            earnings.netEarnings = data.NetEarnings
              ? niceNumberDecimalDisplay(data.NetEarnings, 8)
              : "";
            earnings.netEarningsinUSD = data.NetEarnings
              ? niceNumberDecimalDisplay(data.NetEarnings*priceUSD, 2)
              : "";
              earningsArr.push(earnings);
          });
          this.setState({ excelData: earningsArr });
        }
      });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getRecords();

    this.setState({ fileName: "MinerData" });
    this.allData();
  }

  async getRecords() {
    try {
      const { earningsPage, earningsSizePerPage } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const tableResponse = await api
        .setToken(authenticationToken)
        .get("user/getdailyMinerData", {
          sizePerPage: earningsSizePerPage,
          page: earningsPage,
        });

      if (tableResponse.code === 200) {
        if (tableResponse.data) {
          const data = tableResponse.data.minerGrossprofits;
          data.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });

          this.setState({
            tableData: data,
            earningsTotalSize: tableResponse.data.totalMiners,
          });
        }

        this.setState({
          earningsLoading: false,
          tableLoading: false,
          earningsRenderFlag: true,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.earningsSizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState(
        { earningsSizePerPage: sizePerPage, earningsPage: page },
        () => {
          this.getRecords();
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  selectBaseCurrency(value) {
    this.setState(
      {
        baseCurrency: value,
      },
      () => {
        this.getRecords();
      }
    );
  }

  selectTimeHorizon(value) {
    this.setState(
      {
        timeHorizon: value,
      },
      () => {
        this.setState({ earningsPage: 1 });
        this.displayEarnings();
      }
    );
  }

  render() {
    const {
      earningsData,
      tableData,
      earningsLoading,
      // earningsDatas,
      earningsSizePerPage,
      earningsTotalSize,
      earningsRenderFlag,
      tableLoading,
      earningsPage,
      workerLoading,
      baseCurrency,
      earningDetails,
      timeHorizon,
      viewers,
      fileName,
      excelData,
    } = this.state;

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "date",
        text: "Date",
        formatter: function (cell, row) {
          return <span>{row.date}</span>;
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "GrossEarnings",
        text: "Gross Earnings",
        formatter: function (cell, row) {
          return <span>{row.GrossEarnings?niceNumberDecimalDisplay(row.GrossEarnings,8):""}</span>;
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "pool_fees",
        text: "Fees",
        formatter: function (cell, row) {
          return <span>{row.Fees?niceNumberDecimalDisplay(row.Fees,8):""}</span>;
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "PowerCost",
        text: "Power Cost",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>{row.PowerCost?niceNumberDecimalDisplay(row.PowerCost,8):""}</span>;
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "",
        text: "Net Earnings",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <span>{row.NetEarnings?niceNumberDecimalDisplay(row.NetEarnings,8):""}</span>
          );
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={earningsLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    <div className="row spaceBetween">
                      <span className="pull-left">Daily Earnings</span>
                      <span className="exportData" style={{ fontSize: "14px" }}>
                        <button
                          className="btn btn-primary mt-1"
                          onClick={() => this.showModal()}
                        >
                          Export Data
                        </button>
                        <GlobalDailyEarningModal
                          {...this.props}
                          {...this.state}
                          earningsDatas={excelData}
                          onCloseModal={this.onCloseModal}
                          onInputValueChange={this.onchange}
                          AddMinerRequest={this.AddMinerRequest}
                        />
                      </span>
                    </div>
                  </h4>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              {tableLoading && (
                <div className="text-center">
                  <span id="loading" color="#047bf8" />
                </div>
              )}
              <div className="earnings-table table-responsive">
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll
                    data={tableData}
                    page={earningsPage}
                    sizePerPage={earningsSizePerPage}
                    totalSize={earningsTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalDailyEarning;
