import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import Api from "../../services/api";
import { toast } from "react-toastify";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  getWeb3NetworkName,
} from "../../utils/Util";
import eth from "../../utils/Eth";
import * as messageConstants from "../../utils/Messages";
import { tokenSymbol } from "../../services/api-config";

const STATUS = {
  INITIAL: "initial",
  NOT_LOGGED: "not-logged",
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
  PROCESSING: "processing",
  MISSING_METAMASK: "missing-metamask",
  MISSING_CONTRACT_ADDRESS: "missing-contract-address",
  MISMATCH_FROM_ADDRESS: "mimatch-from-address",
};

class AdminManageTeamMembers extends React.Component {
  constructor(props) {
    super(props);
    this.tokenContract = "";
    this.web3 = "";
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 100,
      filterByName: "",
      filterByEmail: "",
      loading: false,
      adminSmartContractAddress: "",
      decimalSmartContract: "",
      tokenSendDesiredNetwork: "",
      status: STATUS.LOADING,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.sendTokens = this.sendTokens.bind(this);
    this.metamaskNetwork = {
      1: messageConstants.METAMASK_CONNECT_TO_MAINNET,
      4: messageConstants.METAMASK_CONNECT_TO_RINKEBY,
      42: messageConstants.METAMASK_CONNECT_TO_KOVAN,
    };
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/team_members/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.users,
            totalSize: response.data.totalUsers,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  async getTokenAddress() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("admin/token/address");
    if (response.code === 200) {
      this.setState(
        {
          adminSmartContractAddress: response.data.adminSmartContractAddress,
          decimalSmartContract: response.data.decimalSmartContract,
          tokenSendDesiredNetwork: response.data.tokenSendDesiredNetwork,
        },
        () => {
          this.interval = setInterval(() => {
            if (eth.reload) {
              eth.reloadDone();
              this.setUpContract();
            }
          }, 1000);
          this.setUpContract();
        }
      );
    }
  }

  async sendTokens(userRow) {
    if (!userRow) {
      toast.error("Invalid request");
      return;
    }

    const { tokenSendDesiredNetwork, status } = this.state;
    var tokens = userRow.tokens;
    var userEthAddress = userRow.receivingEthAddress;
    var totalTokens = parseFloat(tokens);

    if (
      totalTokens > 0 &&
      userRow.isKycVerified === true &&
      !_.isUndefined(userRow.receivingEthAddress) &&
      userRow.receivingEthAddress !== "" &&
      userRow.receivingEthAddress !== null
    ) {
      if (status !== STATUS.INITIAL) {
        return;
      }
      if (!userEthAddress) {
        this.onError({
          message: "Please enter Receiver address",
        });
        return;
      }
      if (!totalTokens) {
        this.onError({
          message: "Please enter valid amount",
        });
        return;
      }
      if (eth.networkId !== parseInt(tokenSendDesiredNetwork)) {
        this.onError({
          message: `Wrong Metamask network. Please connect to ${getWeb3NetworkName(
            tokenSendDesiredNetwork
          )} network.`,
        });
        return;
      }
      if (!eth.isLoggedin) {
        await eth.login();
      }

      eth.getUserAccountTokenBalance();
      const adminTokenBalance = eth.getUserAccountTokenBalance;
      if (adminTokenBalance < totalTokens) {
        toast.error(
          `Insufficient Token Balance. You have ${adminTokenBalance} tokens`
        );
        return false;
      }

      this.setState({
        actualSentTokens: totalTokens,
        processedUserId: userRow._id,
      });

      this.updateStatus(STATUS.PROCESSING);
      eth
        .transferTokens(userEthAddress, totalTokens)
        .then(this.onSuccess)
        .catch((e) => {
          this.updateStatus(STATUS.ERROR);
          setTimeout(() => this.updateStatus(STATUS.INITIAL), 3000);
          this.onError(e);
        });
    } else {
      let errorMsg =
        userRow.isKycVerified === false
          ? "User is not KYC Verified."
          : totalTokens <= 0 || totalTokens === "" || _.isUndefined(totalTokens)
          ? "Invalid token request."
          : _.isUndefined(userRow.receivingEthAddress)
          ? userRow.fullName + " has not updated receiving ETH address yet."
          : "Something went wrong. Please try again.";
      toast.error(errorMsg);
    }
  }

  async onSuccess(transactionHash) {
    const { actualSentTokens, processedUserId } = this.state;
    let authenticationToken = this.props.authToken;
    let _this = this;
    if (!authenticationToken) {
      toast.error("Invalid Request.");
      return false;
    }
    const api = new Api();
    this.updateStatus(STATUS.SUCCESS);
    const response = await api
      .setToken(authenticationToken)
      .create("admin/token/update_status/sent", {
        userId: processedUserId,
        txHash: transactionHash,
        actualSentTokens,
      });
    if (response.code === 200) {
      this.setState({
        actualSentTokens: 0,
        processedUserId: "",
      });
      toast.success("Tokens transfered successfully.");
      _this.getRecords();
    }
  }

  onError = (e) => {
    let msg =
      e.message !== "" && e.message.length < 300
        ? e.message
        : "Something went wrong. Please try again later.";
    toast.error(msg);
  };

  async setUpContract() {
    const { adminSmartContractAddress } = this.state;
    this.updateStatus(STATUS.LOADING);
    if (!eth.isAvailable()) {
      this.updateStatus(STATUS.MISSING_METAMASK);
      window.open("https://metamask.io");
    }
    await eth.setContractAddress(adminSmartContractAddress);
    if (!eth.isTokenContractAvailable()) {
      this.updateStatus(STATUS.MISSING_CONTRACT_ADDRESS);
    } else {
      this.updateStatus(STATUS.INITIAL);
    }
  }

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setUpContract();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_USERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
    this.getTokenAddress();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "originalEmail",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "role",
        text: "Type",
        formatter: function () {
          let labelClassName = "badge-primary";
          let label = "Team Member";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "tokens",
        text: "Tokens",
        sort: true,
        formatter: function (cell, row) {
          let tokens = row.tokens;
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(tokens, tokenSymbol)}
              >
                {niceNumberDecimalDisplay(tokens, 2)}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "kycStatus",
        isDummyField: true,
        text: "KYC Status",
        sort: true,
        formatter: function (cell, row) {
          let statusLabelClass = !_.isUndefined(row.kycStatusLabelClass)
            ? row.kycStatusLabelClass
            : "warning";
          let statusLabel = !_.isUndefined(row.kycStatusLabel)
            ? row.kycStatusLabel
            : "N/A";
          return (
            <div>
              <div>
                <span className={"badge badge-" + statusLabelClass}>
                  {statusLabel}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          let tokens =
            parseFloat(row.totalTokenSent) > 0
              ? parseFloat(row.tokens) - parseFloat(row.totalTokenSent)
              : row.tokens;
          return (
            <div>
              <div className="text-left">
                <div className="d-flex">
                  <Link to={"/user_detail/" + row._id} className="color-white">
                    <button className="mr-2 mb-2 btn btn-primary" type="button">
                      {" "}
                      View Details
                    </button>
                  </Link>
                  {tokens > 0 && (
                    <button
                      className="mr-2 mb-2 btn btn-success"
                      type="button"
                      onClick={() => _self.sendTokens(row)}
                    >
                      {" "}
                      Send Tokens
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Team Members</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only "> Name</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2 "
                          name="filterByName"
                          id="filterByName"
                          placeholder="Full Name"
                          type="text"
                          onChange={this.onchange}
                          value={filterByName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only"> Email</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          placeholder="Email Address"
                          type="text"
                          name="filterByEmail"
                          id="filterByEmail"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper">
              <div className="element-box o-auto">
                <h5 className="form-header">
                  <span>Manage Team Members</span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminManageTeamMembers;
