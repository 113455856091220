import React from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import validators from "../../validators";
import auth from "../../utils/auth";
import Api from "../../services/api";
import { RECAPTCHASITEKEY } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
import logoImg from "../../assets/img/logo.png?v3";

class AdminSignin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      buttonLoading: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.signin = this.signin.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(
      this
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_SINGIN_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["email", "password"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async signin(event) {
    event.preventDefault();
    const { email, password, recaptchaResponse } = this.state;
    if (email !== "" && password !== "" && password.length >= 6) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api.create("admin/signin", {
        email: email,
        password: password,
        recaptchaResponse: recaptchaResponse,
      });
      this.setState({
        buttonLoading: false,
      });
      if (response.code === 200 && response.data.token !== "") {
        let adminInfo = {};
        adminInfo.firstName = response.data.user.fullName;
        adminInfo.email = response.data.user.email;
        adminInfo.userDisplayName = response.data.user.fullName;
        auth.setToken(response.data.token, true, "");
        auth.setUserInfo(adminInfo, true, "");
        this.props.checkAdminLogin();
        this.props.history.push("/dashboard");
      } else {
        toast.error(response.message);
        this.recaptcha.reset();
      }
    } else {
      toast.error("Please enter valid email and password.");
      this.recaptcha.reset();
    }
  }

  render() {
    const { email, password, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="login-container min-width-50">
        <div className="auth-box-w wider login-box">
          <div className="logo-w">
            <img alt="Logo" src={logoImg} width="250" />
          </div>
          <form action="" className="custom-form">
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                <input
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Email ID*"
                  type="text"
                  onChange={this.onchange}
                  value={this.state.email}
                />
              </div>
              {email && this.displayValidationErrors("email")}
            </div>

            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-key" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Password*"
                  type="password"
                  name="password"
                  onChange={this.onchange}
                  value={this.state.password}
                  id="password"
                />
              </div>
              {password && this.displayValidationErrors("password")}
            </div>

            <div className="form-check terms-block pl-0">
              <ReCAPTCHA
                ref={(el) => {
                  this.recaptcha = el;
                }}
                sitekey={RECAPTCHASITEKEY}
                onChange={this.handleCaptchaResponseChange}
              />
            </div>
            <div className="buttons-w mt-3">
              <button
                className="btn btn-primary custom-submit-button"
                onClick={this.signin}
                disabled={buttonDisabled}
              >
                SIGN IN
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white m-1" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default AdminSignin;
