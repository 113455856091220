import React from 'react';
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
class Basepages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="wrapper">
                <ToastContainer hideProgressBar={true} />
                <div className="bg-full-image">
                    <div className="bg-overlay"></div>
                    {/* <div className="all-wrapper menu-side with-pattern"> */}
                    <div className="min-height-90vh centerofthescreen">
                        {this.props.children}
                        {/* </div>     */}
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
export default Basepages;