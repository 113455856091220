import React from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import Select from "react-select";
import ExportBudgetModal from "./ExportBudgetModal";
import WireTransferBudgetModal from "./WireTransferBudgetModal";
import DepositTooltip from "./DepositTooltip"
import infoIcon from '../../assets/img/infoIcon.svg'
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import moment from "moment";

class ManageBudgetWireTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      quantity: "",
      status: "",
      filterByName: "",
      filterByStatusLabel: "Select Status",
      filterByEmail: "",
      modeList: [
        {
          value: "stripe",
          label: "stripe",
        },
        {
          value: "wireTransfer",
          label: "wireTransfer",
        },
        {
          value: "BTC",
          label: "BTC",
        },
      ],
      statusList: [
        {
          value: "p",
          label: "Pending",
        },
        {
          value: "a",
          label: "Paid",
        },
        {
          value: "r",
          label: "Rejected",
        },
        {
          value: "c",
          label: "Cancelled",
        },
      ],
      adminNote: "",
      filterByMode: "",
      filterByStartDate: "",
      filterByEndDate: "",
      filterByStatus: "",
      filterByAccount: "",
      transactionAmount: "",
      userId: "",
      documnetLink: "",
      filterByDate: "",
      invoiceId: "",
      // filterByAccount: "",
      modalOpen: false,
      authToken: "",
      filterByContactno: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.onWireTransferCloseModal = this.onWireTransferCloseModal.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.showWireTransferModal = this.showWireTransferModal.bind(this);
    this.setTransactionAmount = this.setTransactionAmount.bind(this);
    this.giveElement = this.giveElement.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.setAdminNote = this.setAdminNote.bind(this);
    this.getDocument = this.getDocument.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.allData = this.allData.bind(this);
  }

  allData() {
    const { totalSize } = this.state;
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("admin/viewBudget", {
        sizePerPage: totalSize,
        page: 1,
      })
      .then((data) => {
        let usdDataArr = [];
        data.data.miners.map((data) => {
          let dataObj = {};
          dataObj.date = data.createdOnUTC
            ? moment(data.createdOnUTC).format("YYYY-MM-DD")
            : "";
          dataObj.email = data.email;
          dataObj.account = data.account;
          dataObj.quantity = data.qty;
          dataObj.deposit =
            data.paymentStatus === "r" || data.paymentStatus === "c"
              ? -niceNumberDecimalDisplay(data.amountSubmitted, 2)
              : niceNumberDecimalDisplay(data.amountSubmitted, 2);
          dataObj.widthrawal =
            data.paymentStatus === "r" || data.paymentStatus === "c"
              ? -niceNumberDecimalDisplay(data.amountWithdrawal, 2)
              : niceNumberDecimalDisplay(data.amountWithdrawal, 2);
          dataObj.balance = niceNumberDecimalDisplay(data.balance, 2);
          dataObj.mode = data.mode;
          dataObj.status = data.paymentStatus
            ? data.paymentStatus === "p"
              ? "PENDING"
              : data.paymentStatus === "r"
              ? "REJECTED"
              : data.paymentStatus === "a"
              ? "PAID"
              : data.paymentStatus === "c"
              ? "CANCELLED"
              : ""
            : "";
          usdDataArr.push(dataObj);
          return 0;
        });
        this.setState({
          allData: usdDataArr,
        });
      });
  }
  onCloseModal() {
    this.setState({ modalOpen: false });
  }
  showModal() {
    this.setState({ modalOpen: true });
  }

  handleChangeMode(e) {
    this.setState({ filterByMode: e.value });
  }
  handleChangeStatus(e) {
    this.setState({
      filterByStatus: e.value,
      filterByStatusLabel: e.label,
    });
  }
  showWireTransferModal(amount, qty, userId, invoiceId, fileKey, status) {
    this.setState({ wireTransferModalOpen: true });
    this.setTransactionAmount(amount);
    this.setState({
      userId: userId,
      quantity: qty,
      invoiceId: invoiceId,
      status: status,
    });
    this.getDocument(fileKey, userId);
  }

  async getDocument(fileKey, userId) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("miner/getMinerBudgetWirePaymentDoc", {
        paymentFileKey: fileKey,
        minerId: userId,
      });
    if (response.code === 200) {
      let object = response.data.minerPaymentDetail.paymentDocument;
      this.setState({
        documnetLink: object[Object.keys(object)[0]],
      });
    }
  }
  setAdminNote(event) {
    this.setState({ adminNote: event.target.value }, () => {});
  }
  setTransactionAmount(value) {
    this.setState({ transactionAmount: value });
  }
  onWireTransferCloseModal() {
    this.setState({ wireTransferModalOpen: false });
  }
  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  refreshCurrenttable() {
    this.getRecords();
  }
  async updatePayment(minerId, status, invoiceId, adminNote) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("admin/saveWireTransferBudgetPayment", {
        action: status,
        minerId: minerId,
        adminNote: adminNote,
        invoiceId: invoiceId,
      });

    if (response.code === 200) {
      this.getRecords();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  async getRecords() {
    try {
      const api = new Api();
      let stringEndDate = "";
      let stringStartDate = "";
      const {
        sizePerPage,
        page,
        filterByEmail,
        filterByAccount,
        filterByStatus,
        filterByMode,
        filterByStartDate,
        filterByEndDate,
      } = this.state;

      if (filterByStartDate) {
        stringStartDate = filterByStartDate.concat("T00:00:00.000Z");
      }
      if (filterByEndDate) {
        stringEndDate = filterByEndDate.concat("T23:59:59.000Z");
      }

      if (!filterByStartDate && filterByEndDate) {
        toast.error("Please mention the start date also!");
      } else if (filterByStartDate && filterByEndDate) {
        if (moment(filterByStartDate) >= moment(filterByEndDate)) {
          toast.error(
            "Please make sure the end date must be greater then start date!"
          );
        } else {
          let authenticationToken = this.props.authToken;
          this.setState({ loading: true, authToken: authenticationToken });

          const response = await api
            .setToken(authenticationToken)
            .get("admin/viewBudget", {
              sizePerPage: sizePerPage,
              page: page,
              filterByEmail,
              filterByAccount,
              filterByStatus,
              filterByMode,
              filterByStartDate: stringStartDate,
              filterByEndDate: stringEndDate,
            });

          if (response.code === 200) {
            this.setState(
              {
                renderFlag: true,
                loading: false,
                // data: [],
                data: response.data.miners,
                totalSize: response.data.total,
              },
              async () => {
                if (typeof this.props.pageProgress === "function") {
                  this.props.pageProgress("remove");
                }
                this.allData();
              }
            );
          } else {
            this.setState({
              loading: false,
            });
            toast.message(response.message);
          }
        }
      } else {
        let authenticationToken = this.props.authToken;
        this.setState({ loading: true, authToken: authenticationToken });

        const response = await api
          .setToken(authenticationToken)
          .get("admin/viewBudget", {
            sizePerPage: sizePerPage,
            page: page,
            filterByEmail,
            filterByAccount,
            filterByStatus,
            filterByMode,
            filterByStartDate: stringStartDate,
            filterByEndDate: stringEndDate,
          });

        if (response.code === 200) {
          this.setState(
            {
              renderFlag: true,
              loading: false,
              // data: [],
              data: response.data.miners,
              totalSize: response.data.total,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
              this.allData();
            }
          );
        } else {
          this.setState({
            loading: false,
          });
          toast.message(response.message);
        }
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByAccount: "",
        filterByEmail: "",
        filterByStatus: "",
        filterByMode: "",
        filterByStatusLabel: "Select Status",
        filterByStartDate: "",
        filterByEndDate: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_KYC_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };
  giveElement() {
    return <input type="text" />;
  }
  kycOperation(userId, type, reference, invoiceId) {
    if (reference && invoiceId) {
      let label = type === "a" ? "a" : "r";
      let question =
        label === "a"
          ? "Are you sure want to accept the details?"
          : "Are you sure want to reject the details?";
      let _self = reference;
      // let adminNote= "sadbam";
      // let buttons = "";
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1 className="">
                  {/* {"Are you sure want to " + label=="r"?"reject":"accept" + " the details?"} */}
                  {question}
                </h1>
                {label === "r" && (
                  <p className="alert__body text-center">
                    Please mention the reason for rejection.
                  </p>
                )}
                {label === "r" && (
                  <input
                    className="form-control w-100"
                    type="text"
                    value={_self.adminNote}
                    onChange={_self.setAdminNote}
                  />
                )}
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      // this.handleClickDelete();
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  {label === "r" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(
                          userId,
                          type,
                          invoiceId,
                          _self.state.adminNote
                        );
                        onClose();
                      }}
                    >
                      Reject
                    </button>
                  )}
                  {label === "a" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(userId, type, invoiceId);
                        onClose();
                      }}
                    >
                      Accept
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      });
    }
    if (!reference && invoiceId) {
      let label = type === "a" ? "a" : "r";
      let question =
        label === "a"
          ? "Are you sure want to accept the details?"
          : "Are you sure want to reject the details?";

      let _self = this;
      // let buttons = "";
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1 className="">
                  {/* {"Are you sure want to " + label=="r"?"reject":"accept" + " the details?"} */}
                  {question}
                </h1>
                {label === "r" && (
                  <p className="alert__body text-center">
                    Please mention the reason for rejection.
                  </p>
                )}
                {label === "r" && (
                  <input
                    className="form-control w-100"
                    type="text"
                    value={_self.adminNote}
                    onChange={_self.setAdminNote}
                  />
                )}
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      // this.handleClickDelete();
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  {label === "r" && (
                    <button
                      onClick={() => {
                        this.updatePayment(
                          userId,
                          type,
                          invoiceId,
                          _self.state.adminNote
                        );
                        onClose();
                      }}
                    >
                      Reject
                    </button>
                  )}
                  {label === "a" && (
                    <button
                      onClick={() => {
                        _self.updatePayment(userId, type, invoiceId);
                        onClose();
                      }}
                    >
                      Accept
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      });
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      quantity,
      filterByEmail,
      authToken,
      filterByStartDate,
      invoiceId,
      filterByEndDate,
      allData,
      filterByStatus,
      filterByStatusLabel,
      statusList,
      filterByAccount,
      filterByMode,
      adminNote,
      modeList,
      userId,
      setAdminNote,
      transactionAmount,
      wireTransferModalOpen,
      // filterByContactno,
      loading,
      documnetLink,
    } = this.state;

    let _self = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        // text: "Date",
        text: (
          <span className="ml-1">
            DATE <span style={{ fontWeight: "normal" }}>(YYYY-MM-DD)</span>
          </span>
        ),

        // text: <span>DATE <span style={{fontWight}}>(DD-MM-YYYY)</span></span>,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              {row.createdOnUTC ? (
                <div>{moment(cell).format("YYYY-MM-DD")}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "EMAIL",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "account",
        text: "ACCOUNT",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "qty",
        text: "QUANTITY",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountSubmitted",
        text: "DEPOSIT",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              {row.paymentStatus === "r" ? (
                <div style={{ color: "red" }}>
                  $ {niceNumberDecimalDisplay(cell, 2)}
                </div>
              ) : (
                <div>$ {niceNumberDecimalDisplay(cell, 2)}
                {row.deletedMachineNote && <span className="custom-tooltip-coin2  justify-content-center ml-1 mr-1">
                    {/* <i
                      className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                      style={{
                        color: "#999999",
                        cursor: "pointer",
                      }}
                    ></i> */}
                    <img 
                    className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                    style={{
                      width:"17px",
                      height:"17px",
                      filter:"invert(50%)",
                        color: "#999999",
                        cursor: "pointer",
                      }} src={infoIcon} alt="" />
                    <DepositTooltip note={row.deletedMachineNote} />
                  </span>}
                </div>
                
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountWithdrawal",
        text: "WITHDRAWAL",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              {row.paymentStatus === "c" ? (
                <div style={{ color: "red" }}>
                  $ {niceNumberDecimalDisplay(cell, 2)}
                </div>
              ) : (
                <div>$ {niceNumberDecimalDisplay(cell, 2)}</div>
              )}
            </div>
          );
        },
      },

      {
        headerClasses: "text-bold",
        dataField: "balance",
        text: "BALANCE",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>$ {niceNumberDecimalDisplay(cell, 2)}</div>
            </div>
          );
        },
      },

      {
        headerClasses: "text-center text-bold",
        dataField: "mode",
        text: "MODE",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-center">
              {row.mode === "wireTransfer" && <span>Wire</span>}
              {row.mode === "stripe" && <span>Stripe</span>}
              {row.mode === "BTC" && <span>BTC</span>}
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "PAYMENT INFO",
        isDummyField: true,
        formatter: function (cell, row) {
          return row.mode === "wireTransfer" ? (
            <div className="text-center">
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => {
                  _self.showWireTransferModal(
                    row.amountSubmitted,
                    row.qty,
                    row.userId,
                    row._id,
                    row.paymentFileKey,
                    row.paymentStatus
                  );
                }}
              >
                Details
              </button>
            </div>
          ) : (
            ""
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return row.mode === "wireTransfer" && row.paymentStatus === "p" ? (
            <div>
              <div className="">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <button
                      className="mr-2 mb-2 btn btn-primary"
                      type="button"
                      onClick={() =>
                        _self.kycOperation(row.userId, "a", "", row._id)
                      }
                    >
                      {" "}
                      Accept
                    </button>
                    <br />
                    <button
                      className="mr-2 mb-2 btn btn-danger"
                      type="button"
                      onClick={() =>
                        _self.kycOperation(row.userId, "r", "", row._id)
                      }
                    >
                      {" "}
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "paymentStatus",
        text: "STATUS",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "";
          let className = "";
          if (row.paymentStatus === "a") {
            label = "PAID";
            className = "badge-success";
          } else if (row.paymentStatus === "r") {
            label = "REJECTED";
            className = "badge-danger";
          } else if (row.paymentStatus === "c") {
            label = "CANCELLED";
            className = "badge-danger";
          } else if (row.paymentStatus === "p") {
            label = "PENDING";
            className = "badge-warning";
          }
          return (
            <div>
              <div className="text-center">
                <div className="d-inline-block">
                  <span className={"badge " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Budget Requests</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByEmail"
                          id="filterByEmail"
                          placeholder="Enter Email"
                          type="text"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className={
                            !filterByStartDate
                              ? "filterStartDate form-control mb-2 mr-sm-2 mb-sm-2"
                              : "form-control mb-2 mr-sm-2 mb-sm-2"
                          }
                          name="filterByStartDate"
                          id="filterByStartDate"
                          placeholder="Start Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByStartDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className={
                            !filterByEndDate
                              ? "filterEndDate form-control mb-2 mr-sm-2 mb-sm-2"
                              : "form-control mb-2 mr-sm-2 mb-sm-2"
                          }
                          name="filterByEndDate"
                          id="filterByEndDate"
                          placeholder="End Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByEndDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByMode,
                            label: filterByMode ? filterByMode : "Select Mode",
                          }}
                          options={modeList}
                          placeholder="Select Mode"
                          onChange={this.handleChangeMode}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByStatus,
                            label: filterByStatusLabel,
                          }}
                          options={statusList}
                          placeholder="Select Status"
                          onChange={this.handleChangeStatus}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByAccount"
                          id="filterByAccount"
                          placeholder="Enter Account"
                          type="text"
                          onChange={this.onchange}
                          value={filterByAccount}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box horizontal-scroll">
                <div className="invoiceHeader row align-items-center justify-content-between">
                  <h5 className="form-header">
                    <span>Manage Budget Requests</span>
                    <span
                      className="fa fa-refresh ml-2 cursor-pointer"
                      onClick={() => this.refreshCurrenttable()}
                    ></span>
                  </h5>
                  {/* <ExportCSV
                    csvData={allData}
                    fileName={"Budget Transaction"}
                  /> */}

                  <button
                    className="btn btn-primary"
                    onClick={() => this.showModal()}
                  >
                    Export Data
                  </button>
                  <ExportBudgetModal
                    allData={allData}
                    onCloseModal={this.onCloseModal}
                    onInputValueChange={this.onchange}
                    modalOpen={this.state.modalOpen}
                  />
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
                <WireTransferBudgetModal
                  {...this.props}
                  {...this.state}
                  onCloseModal={this.onWireTransferCloseModal}
                  wireTransferModalOpen={wireTransferModalOpen}
                  onChangeCurrencyType={this.onChangeCurrencyType}
                  invoiceId={invoiceId}
                  transactionAmount={transactionAmount}
                  onAmountChange={this.onAmountChange}
                  quantity={quantity}
                  kycOperation={this.kycOperation}
                  userId={userId}
                  authToken={authToken}
                  status={this.state.status}
                  onInputValueChange={this.onchange}
                  documnetLink={documnetLink}
                  reference={this}
                  adminNote={adminNote}
                  setAdminNote={setAdminNote}
                  onUpdateAmount={this.onUpdateTransactionAmount}
                  displayValidationErrors={this.displayValidationErrors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageBudgetWireTransfer;
