import React from "react";
import Api from "../../services/api";
import IndividualFatca from "./IndividualFatcaDetail";
import CorporateFatca from "./CorporateFatcaDetail";

class AdminUserFatcaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fatcaType: '',
      fatcaData: ''
    };
  }

  async componentWillMount() {
    const api = new Api();
    
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }

    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;

    try {
      const fatcaData = await api.setToken(authenticationToken).get("admin/fatca/details", { 'userId': userId });
      if (fatcaData.code === 200) {
        this.setState({
          fatcaType: fatcaData.data.crsType,
          fatcaData: fatcaData.data
        });
      }
      if (typeof (this.props.pageProgress) === 'function') {
        this.props.pageProgress('remove');
      }
    }
    catch (error) {
      if (typeof (this.props.pageProgress) === 'function') {
        this.props.pageProgress('remove');
      }
    }
  }

  render() {
    const { fatcaType, fatcaData } = this.state;

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">

              {fatcaType === 'i' &&
                <IndividualFatca fatcaData={fatcaData} />
              }

              {fatcaType === 'ci' &&
                <CorporateFatca fatcaData={fatcaData} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminUserFatcaDetail;