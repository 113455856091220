import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { ExportCSV } from "./ExportCSV";
import "./Earnings.css";
class ExportInvoicesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      show: false,
      timeHorizon: "",
      earningData: [],
      fileName: "Invoices",
      exportTypeList: [
        { value: "csv", label: "CSV" },
        { value: "xlsx", label: "XLSX" },
      ],
      exportTypeLabel: "CSV",
      exportType: "csv",
      invoiceTypeList: [
        { value: "cad", label: "CAD Invoice" },
        { value: "usd", label: "USD Invoice" },
        { value: "orig", label: "Original Invoice"},
      ],
      invoiceTypeLabel: "CAD Invoice",
      invoiceType: "cad",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.setShow = this.setShow.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.clearTime = this.clearTime.bind(this);
    this.changeExportType = this.changeExportType.bind(this);
    this.changeInvoiceType = this.changeInvoiceType.bind(this);
  }

  async setShow(startDate, endDate) {
    
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async clearTime() {
    
      this.setState({ startDate: "" });
      this.setState({ endDate: "" });
      this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeStartDate(e) {
    this.setState(
      {
        startDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onChangeEndDate(e) {
    this.setState(
      {
        endDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async changeExportType(e) {
    this.setState({
      exportType: e.value,
      exportTypeLabel: e.label,
    });
  }
  async changeInvoiceType(e) {
    this.setState({
      invoiceType: e.value,
      invoiceTypeLabel: e.label,
    });
  }
  async onSubmit() {}

  render() {
    const {
      modalOpen,
      onCloseModal,
      // onInputValueChange,
      // AddMinerRequest,
      // buttonLoading,
      // binanceManualEarnings,
      usdData,
      cadData,
      originalData,
    } = this.props;
    // console.log(earningsDatas)
    // let buttonDisabled = buttonLoading === true ? true : false;

    const { 
      fileName,
      exportTypeLabel,
      exportType,
      exportTypeList,
      invoiceTypeLabel,
      invoiceType,
      invoiceTypeList,
      } = this.state;
      
      const date = new Date().toISOString().substring(0, 10);
      let originalFilename = `${fileName}-${date}`;
      let cadFilename = `${originalFilename}-cad`;
      let usdFilename = `${originalFilename}-usd`;
    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4 className="onboarding-title">Export Invoices</h4>
                      <div className="form-group row justify-content-between">
                        <span className="text-bold">
                          Export File Type:
                        </span>
                        <Select
                          className="w-50"
                          value={{
                            value: exportType,
                            label: exportTypeLabel,
                          }}
                          options={exportTypeList}
                          placeholder="Select Export Type"
                          onChange={this.changeExportType}
                        />
                      </div>
                      <div className="form-group row justify-content-between">
                        <span className="text-bold">
                          Invoice Type:
                        </span>
                        <Select
                          className="w-50"
                          value={{
                            value: invoiceType,
                            label: invoiceTypeLabel,
                          }}
                          options={invoiceTypeList}
                          placeholder="Select Invoice Type"
                          onChange={this.changeInvoiceType}
                        />
                      </div>
                    <div className="form-group row justify-content-between">
                    <span className="text-bold">
                        Export Invoice
                      </span>
                      {invoiceType == 'cad' && (
                      <ExportCSV
                        csvData={cadData}
                        fileName={cadFilename}
                        exportType={exportType}
                      />
                      )}
                      {invoiceType == 'usd' && (
                      <ExportCSV
                        csvData={usdData}
                        fileName={usdFilename}
                        exportType={exportType}
                      />
                      )}
                      {invoiceType == 'orig' && (
                      <ExportCSV
                        csvData={originalData}
                        fileName={originalFilename}
                        exportType={exportType}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ExportInvoicesModal;
