import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import GlobalEarningModal from "./GlobalEarningModal";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  getBtcUsd,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import bitcoinImg from "../../assets/img/bitcoin.svg?v3";
import "./Earnings.css";
class GlobalEarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earningsLoading: true,
      tableLoading: true,
      workerTotalSize: "",
      offlineWorkers: 0,
      accumulatedEarnings: 0,
      earningsData: [],
      earningsDatas: [],
      tableData: [],
      earningsPage: 1,
      setDate: "",
      earningsSizePerPage: 20,
      earningsRenderFlag: false,
      earningsTotalSize: "",
      modalOpen:false,
      baseCurrency: "btc",
      electricityCost: 0,
      yesterdayEarnings: "",
      yesterdayNetEarnings: "",
      yesterdayPoolFees: "",
      yesterdayPowerCost: "",
      timeHorizon: "all",
      earningDetails: {},
      viewers: [],
      excelData: [],
      netEarnings: "",
      netPoolFee: "",
      setAccount: "",
      fileName: "",
    };
    this.props.pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.selectBaseCurrency = this.selectBaseCurrency.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.allData = this.allData.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.getFilterRecords = this.getFilterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
  }

  clearFilterRecords(){
    this.setState({
      earningsSizePerPage:20,
      earningsPage:1,
      setAccount:"",
      setDate:""
    },()=>{
      this.getRecords();
    })
  }
  async getFilterRecords() {
    try {

      const { earningsPage, earningsSizePerPage,setAccount,setDate } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const tableResponse = await api
        .setToken(authenticationToken)
        .get("user/getGlobalMinerData",{ 
          filterByDate:setDate,
          filterByAccount:setAccount,
          sizePerPage:earningsSizePerPage,
          page:earningsPage
        });

      this.setState({
        tableData: tableResponse.data.minerGrossprofits,
      });
      
      this.setState({
        earningsTotalSize: tableResponse.data.totalMiners,
        earningsLoading: false,
        tableLoading: false,
        earningsRenderFlag: true,
      });
      this.displayEarnings();
    } catch (err) {
      console.log("err", err);
    }
  }


  async changeDate(e) {
    this.setState({
      setDate: e.target.value,
    });
  }
  async changeAccount(e) {
    this.setState({
      setAccount: e.target.value,
    });
  }
  allData() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("user/getGlobalMinerData")
      .then(async (data) => {
        if(data.data){
          if(data.data.minerGrossprofits){
            let excelArr = [];
            const priceUSD = await getBtcUsd();
            data.data.minerGrossprofits.map((data)=>{
              let earnings = {};
              earnings.date = data.date;
              earnings.accountNumber = data.binanceAccountNumber;
              earnings.grossEarnings = data.grossProfit?niceNumberDecimalDisplay(data.grossProfit,8):"";
              earnings.grossEarningsinUSD = data.grossProfit?niceNumberDecimalDisplay(data.grossProfit*priceUSD,2):"";
              earnings.fees = data.poolFee?niceNumberDecimalDisplay(data.poolFee,8):"";
              earnings.feesinUSD = data.poolFee?niceNumberDecimalDisplay(data.poolFee*priceUSD,2):"";
              earnings.powerCost = data.electricityCost?niceNumberDecimalDisplay(data.electricityCost,8):"";
              earnings.powerCostinUSD = data.electricityCost?niceNumberDecimalDisplay(data.electricityCost*priceUSD,2):"";
              earnings.netEarnings = data.netProfit?niceNumberDecimalDisplay(data.netProfit,8):"";
              earnings.netEarningsinUSD = data.netProfit?niceNumberDecimalDisplay(data.netProfit*priceUSD,2):"";
              excelArr.push(earnings);
            })
            this.setState({excelData:excelArr})
          }
        }
      });

  }
  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }



  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    this.getRecords();
    this.setStatistics();
    this.setState({ fileName: "MinerData" });
    this.allData();
  }

  async setStatistics() {
    try {
      const _this = this;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/fetchGlobalMinerStatistics");

      if (response.code === 200) {
        const statistcs = response.data;
        const priceUsd = await getBtcUsd();
        const accumulatedEarningsFormatted = await niceNumberDecimalDisplay(
          statistcs.grossMinerAllDaysData[0].LastAllDaysEarnings,
          8
        );

        let profitYesterdayInUsd = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysNetEarnings
          ? parseFloat(
              statistcs.grossMinerAllDaysData[0].LastAllDaysNetEarnings
            ) * parseFloat(priceUsd)
          : 0;
        profitYesterdayInUsd = await niceNumberDecimalDisplay(
          profitYesterdayInUsd,
          2
        );
        let accumulatedEarningsInUsd = accumulatedEarningsFormatted
          ? parseFloat(accumulatedEarningsFormatted) * parseFloat(priceUsd)
          : 0;
        accumulatedEarningsInUsd = await niceNumberDecimalDisplay(
          accumulatedEarningsInUsd,
          2
        );

        let LastAllDaysNetEarnings = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysNetEarnings
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysNetEarnings
          : 0;
        let LastAllDaysPoolFees = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysPoolFees
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysPoolFees
          : 0;

        let pooFeesInBtc = statistcs.grossMinerAllDaysData[0]
          .LastAllDaysPoolFees
          ? statistcs.grossMinerAllDaysData[0].LastAllDaysPoolFees
          : 0;

        let monthlyElectricityCost = statistcs.grossMiner30DaysData[0]
          ? parseFloat(statistcs.grossMiner30DaysData[0].Last30DaysPowerCost) *
            parseFloat(priceUsd)
          : 0;

        LastAllDaysNetEarnings = niceNumberDecimalDisplay(
          LastAllDaysNetEarnings,
          8
        );
        LastAllDaysPoolFees = niceNumberDecimalDisplay(LastAllDaysPoolFees, 8);
        pooFeesInBtc = niceNumberDecimalDisplay(pooFeesInBtc, 8);
        monthlyElectricityCost = niceNumberDecimalDisplay(
          monthlyElectricityCost,
          2
        );

        this.setState(
          {
            rateOfElectricity: response.data.globalRateOfElectricity,
            monthlyElectricityCost: monthlyElectricityCost,
            yesterdayEarnings: statistcs.grossMinerYesterdaysData[0]
              ? statistcs.grossMinerYesterdaysData[0].YesterdaysEarnings
              : "",
            yesterdayNetEarnings: statistcs.grossMinerYesterdaysData[0]
              ? statistcs.grossMinerYesterdaysData[0].YesterdaysNetEarnings
              : "",
            last7DaysEarnings: statistcs.grossMiner7DaysData[0]
              ? statistcs.grossMiner7DaysData[0].Last7DaysEarnings
              : "",
            last7DaysPoolFees: statistcs.grossMiner7DaysData[0]
              ? statistcs.grossMiner7DaysData[0].Last7DaysPoolFees
              : "",
            last7DaysPowerCost: statistcs.grossMiner7DaysData[0]
              ? statistcs.grossMiner7DaysData[0].Last7DaysPowerCost
              : "",
            last7DaysNetEarnings: statistcs.grossMiner7DaysData[0]
              ? statistcs.grossMiner7DaysData[0].Last7DaysNetEarnings
              : "",

            last30DaysEarnings: statistcs.grossMiner30DaysData[0]
              ? statistcs.grossMiner30DaysData[0].Last30DaysEarnings
              : "",
            last30DaysPoolFees: statistcs.grossMiner30DaysData[0]
              ? statistcs.grossMiner30DaysData[0].Last30DaysPoolFees
              : "",
            last30DaysPowerCost: statistcs.grossMiner30DaysData[0]
              ? statistcs.grossMiner30DaysData[0].Last30DaysPowerCost
              : "",
            last30DaysNetEarnings: statistcs.grossMiner30DaysData[0]
              ? statistcs.grossMiner30DaysData[0].Last30DaysNetEarnings
              : "",
            accumulatedPowerCost: statistcs.grossMinerAllDaysData[0]
              ? statistcs.grossMinerAllDaysData[0].LastAllDaysPowerCost
              : "",

            yesterdayPoolFees: statistcs.grossMinerYesterdaysData[0]
              ? statistcs.grossMinerYesterdaysData[0].YesterdaysPoolFees
              : "",
            yesterdayPowerCost: statistcs.grossMinerYesterdaysData[0]
              ? statistcs.grossMinerYesterdaysData[0].YesterdaysPowerCost
              : "",
            accumulatedEarnings: accumulatedEarningsFormatted
              ? accumulatedEarningsFormatted
              : "",
            accumulatedEarningsInUsd: accumulatedEarningsInUsd
              ? accumulatedEarningsInUsd
              : "",
            pooFeesInBtc: pooFeesInBtc ? pooFeesInBtc : "",
            netEarnings: LastAllDaysNetEarnings,
            netPoolFee: LastAllDaysPoolFees,
            statisticsLoading: false,
          },
          () => {
            this.displayEarnings();
            this.setState({
              viewers: [
                {
                  id: 1,
                  GrossEarnings: this.state.last7DaysEarnings,
                  Fees: this.state.last7DaysPoolFees,
                  PowerCost: this.state.last7DaysPowerCost,
                  NetEarnings: this.state.last7DaysNetEarnings,
                },
              ],
            });
          }
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  async getRecords() {
    try {
      const { earningsPage, earningsSizePerPage } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const tableResponse = await api
        .setToken(authenticationToken)
        .get("user/getGlobalMinerData", {
          sizePerPage: earningsSizePerPage,
          page: earningsPage,
        });

      this.setState({
        tableData: tableResponse.data.minerGrossprofits,
      });
      this.setState({
        earningsTotalSize: tableResponse.data.totalMiners,
        earningsLoading: false,
        tableLoading: false,
        earningsRenderFlag: true,
      });
      this.displayEarnings();
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.earningsSizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState(
        { earningsSizePerPage: sizePerPage, earningsPage: page },
        () => {
          if(this.state.setAccount==="" && this.state.setDate ){

            this.getRecords();
          }
          else{
            this.getFilterRecords()
          }
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          if(this.state.setAccount==="" && this.state.setDate ){

            this.getRecords();
          }
          else{
            this.getFilterRecords()
          }
        });
        return true;
      }
    }
  };

  selectBaseCurrency(value) {
    this.setState(
      {
        baseCurrency: value,
      },
      () => {
        this.getRecords();
      }
    );
  }

  selectTimeHorizon(value) {
    this.setState(
      {
        timeHorizon: value,
      },
      () => {
        this.setState({ earningsPage: 1 });
        this.displayEarnings();
      }
    );
  }

  async displayEarnings() {
    const {
      timeHorizon,
      baseCurrency,
      yesterdayEarnings,
      yesterdayNetEarnings,
      yesterdayPoolFees,
      yesterdayPowerCost,
      last7DaysEarnings,
      last7DaysPoolFees,
      last7DaysPowerCost,
      last7DaysNetEarnings,
      tableLoading,
      last30DaysEarnings,
      last30DaysPoolFees,
      last30DaysPowerCost,
      last30DaysNetEarnings,
      accumulatedEarnings,
      pooFeesInBtc,
      netEarnings,
      netPoolFee,
      accumulatedPowerCost,
    } = this.state;
    const response = {
      grossEarnings: 0,
      poolFees: 0,
      powerCost: 0,
      netEarnings: 0,
      decimal: baseCurrency === "btc" ? 8 : 2,
    };
    const priceUsd = await getBtcUsd();
    let currentBtcPrice = parseFloat(priceUsd);
    const multiplicationRate = baseCurrency === "usd" ? currentBtcPrice : 1;
    if (timeHorizon === "24h") {
      response.grossEarnings =
        parseFloat(yesterdayEarnings) * multiplicationRate;
      response.poolFees = parseFloat(yesterdayPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(yesterdayPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(yesterdayNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "7d") {
      response.grossEarnings =
        parseFloat(last7DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last7DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last7DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last7DaysNetEarnings) * multiplicationRate;

    } else if (timeHorizon === "30d") {
      response.grossEarnings =
        parseFloat(last30DaysEarnings) * multiplicationRate;
      response.poolFees = parseFloat(last30DaysPoolFees) * multiplicationRate;
      response.powerCost = parseFloat(last30DaysPowerCost) * multiplicationRate;
      response.netEarnings =
        parseFloat(last30DaysNetEarnings) * multiplicationRate;
    } else if (timeHorizon === "all") {
      response.grossEarnings =
        parseFloat(accumulatedEarnings) * multiplicationRate;
      response.poolFees = parseFloat(netPoolFee) * multiplicationRate;
      response.powerCost =
        parseFloat(accumulatedPowerCost) * multiplicationRate;
      response.netEarnings = parseFloat(netEarnings) * multiplicationRate;
    }
    this.setState({
      earningDetails: response,
    });
  }

  render() {
    const {
      earningsData,
      tableData,
      tableLoading,
      earningsSizePerPage,
      earningsTotalSize,
      earningsRenderFlag,
      earningsPage,
      workerLoading,
      baseCurrency,
      earningDetails,
      timeHorizon,
      viewers,
      excelData,
      fileName,
    } = this.state;


    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "date",
        text: "Date",
        formatter: function (cell, row) {
          return row ? <span>{row.date}</span> : "";
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "binanceAccountNumber",
        text: "Account Number",
        formatter: function (cell, row) {
          return row ? <span>{row.binanceAccountNumber}</span> : "";
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "grossProfit",
        text: "Gross Earnings",
        formatter: function (cell, row) {
          return <span>{Number(cell).toFixed(8)}</span>;
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "pool_fees",
        text: "Fees",
        formatter: function (cell, row) {
          return (
            row ? <span>{Number(row.poolFee).toFixed(8)}</span> : ""
          );
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "electricityCost",
        text: "Power Cost",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            row ? <span>{Number(row.electricityCost).toFixed(8)}</span> : ""
          );
        },
      },
      {
        headerClasses: "custom-table-th text-bold",
        dataField: "",
        text: "Net Earnings",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            row ? <span>{Number(row.netProfit).toFixed(8)}</span> : ""
          );
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    <span className="pull-left">
                      Earnings
                      <span>
                        <select
                          className="earnings-select"
                          id="selectedCurrency"
                          name="selectedCurrency"
                          defaultValue="btc"
                          style={{
                            padding: "5px",
                          }}
                          onChange={(e) =>
                            this.selectBaseCurrency(e.target.value)
                          }
                        >
                          <option value="usd">USD</option>
                          <option value="btc">BTC</option>
                        </select>
                      </span>
                    </span>
                    <span className="pull-right" style={{ fontSize: "14px" }}>
                      <span
                        style={{
                          padding: "5px 15px",
                          borderRadius: "5px",
                        }}
                        className={
                          timeHorizon === "24h" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("24h")}
                      >
                        24H
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        className={
                          timeHorizon === "7d" ? "active-currency" : ""
                        }
                        onClick={() => this.selectTimeHorizon("7d")}
                      >
                        7D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("30d")}
                        className={
                          timeHorizon === "30d" ? "active-currency" : ""
                        }
                      >
                        30D
                      </span>
                      <span
                        style={{ padding: "5px 15px", borderRadius: "5px" }}
                        onClick={() => this.selectTimeHorizon("all")}
                        className={
                          timeHorizon === "all" ? "active-currency" : ""
                        }
                      >
                        All
                      </span>
                    </span>
                  </h4>
                  <div className="clearfix"></div>

                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mb-1">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Gross Earnings</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {`${earningDetails.grossEarnings?niceNumberDecimalDisplay(
                            earningDetails.grossEarnings,
                            earningDetails.decimal
                          ):"0"} ${_.toUpper(baseCurrency)}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin mb-1">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Fees</span>
                        <br />
                        <span className="color-black dashboard-text-bold">
                          {`${earningDetails.poolFees?niceNumberDecimalDisplay(
                            earningDetails.poolFees,
                            earningDetails.decimal
                          ):"0"} ${_.toUpper(baseCurrency)}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3 mb-1">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Power Cost</span>

                        <br />
                        <span className="color-black dashboard-text-bold">
                          {`${earningDetails.powerCost?niceNumberDecimalDisplay(
                            earningDetails.powerCost,
                            earningDetails.decimal
                          ):"0"} ${_.toUpper(baseCurrency)}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3 mb-1">
                      <img src={bitcoinImg} height="40" alt="bitcoin" />
                      <div className="dashboard_text_space Earnings-text ml-4">
                        <span className="color-black">Net Earnings</span>
                        <br />

                        <span className="color-black dashboard-text-bold">
                          {`${earningDetails.netEarnings?niceNumberDecimalDisplay(
                            earningDetails.netEarnings,
                            earningDetails.decimal
                          ):"0"} ${_.toUpper(baseCurrency)}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <button
                      className="btn btn-primary mt-1"
                      onClick={() => this.showModal()}
                    >
                      Export Data
                    </button>
                    <GlobalEarningModal
                      {...this.props}
                      {...this.state}
                      earningsDatas={excelData}
                      onCloseModal={this.onCloseModal}
                      onInputValueChange={this.onchange}
                      AddMinerRequest={this.AddMinerRequest}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
            <div>

            
              <h5 className="form-header">Filter Users</h5>
                <div className="row w-100">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control mb-2 mr-sm-2 mb-sm-2"
                        name="filterByDate"
                        id="filterByDate"
                        placeholder="Date"
                        type="date"
                        onChange={this.changeDate}
                        value={this.state.setDate}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control mb-2 mr-sm-2 mb-sm-2"
                        placeholder="Account Number"
                        type="text"
                        name="filterByAccount"
                        id="filterByAccount"
                        onChange={this.changeAccount}
                        value={this.state.setAccount}
                      />
                    </div>
                  </div>
                </div>
                </div>
                <div className="manageuser-btn-group btn-left">
                  <button
                    className="btn btn-primary "
                    type="button"
                    onClick={this.getFilterRecords}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    Clear
                  </button>
                </div>
              {tableLoading && (
                <div className="text-center">
                  <span
                    id="loading"
                    color="#047bf8"
                  />
                </div>
              )}
              <div className="earnings-table table-responsive">
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll
                    data={tableData}
                    page={earningsPage}
                    sizePerPage={earningsSizePerPage}
                    totalSize={earningsTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalEarning;
