import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
export const ExportCSV = ({
  csvData,
  fileName,
  exportType,
}) => {
  const exportToCSV = (
    csvData,
    fileName,
    exportType,
  ) => {
    
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    if (exportType == "xlsx") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      const fileExtension = ".csv";
      XLSX.writeFile(wb, fileName + fileExtension);
    }
    toast.success("Success! ");

  };
  return (
    <button
      className="btn btn-primary"
      onClick={(e) =>
        exportToCSV(
          csvData,
          fileName,
          exportType,
        )
      }
    >
      Export Data
    </button>
  );
};
