import React, { Component } from 'react';
import moment from 'moment';
class Footer extends Component {
  render() {
    var year = moment(new Date()).format("YYYY") ;
    return (
      <div>
        <div className="copyright-text text-center mt-3 footer">
          Copyright © {year} PermianChain Technologies, Inc. All right reserved
        </div>
      </div>
    );
  }
}
export default Footer;