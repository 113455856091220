import React from 'react';
class AdminLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};       
  }

  async componentDidMount(){
    //let authenticationToken = this.props.authToken;
    this.props.adminLogout();
    /*if(authenticationToken !== '' && authenticationToken !== null)
    {
      const api = new Api();
      const response =  await api.create("adminLogout", {
        adminAuthenticationToken: authenticationToken
      });
      if (response.status === "success") 
      {
        toast.success(response.message);
        this.props.adminLogout();
      }
      else
      {
        toast.error(response.message);
      }
    }*/
  }

  render() {
    return (
      <div></div>
      );
  }
}
export default AdminLogout;