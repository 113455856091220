import React, { Component } from "react";
import Modal from "react-responsive-modal";

class SubaccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      subaccountEditModal,
      onSubAccountCloseModal,
      onInputValueChange,
      ModifyPoolSettings,
      ModifyGeminiSettings,
      buttonLoading,
      geminiButtonLoading,
      viaBtcApiKey,
      geminiApiKey,
      binanceAccountNumber,
      viaBtcSecretKey,
      starter,
      geminiFlow,
      viabtcFlow,
      geminiCheck,
      viabtcCheck,
      geminiSecretKey,
      viaBtcPoolFees,
    } = this.props;
    let buttonDisabled = buttonLoading === true ? true : false;
    let geminiButtonDisabled = geminiButtonLoading === true ? true : false;
    return (
      <Modal
        open={subaccountEditModal}
        onClose={onSubAccountCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    {starter && (
                      <div>
                        <h4 className="onboarding-title">
                          Please Select Service for Wallet Generation
                        </h4>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary btn-md"
                            type="button"
                            onClick={geminiFlow}
                            // disabled={buttonDisabled}
                          >
                            Gemini
                            {/* {buttonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )} */}
                          </button>
                          <button
                            className="btn btn-primary btn-md ml-3"
                            type="button"
                            onClick={viabtcFlow}
                            // disabled={buttonDisabled}
                          >
                            ViaBtc
                            {/* {buttonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )} */}
                          </button>
                        </div>
                      </div>
                    )}
                    {viabtcCheck && (
                      <div>
                        <h4 className="onboarding-title">
                          Modify ViaBTC Settings
                        </h4>
                        <form>
                          <div className="form-group">
                            <label htmlFor="title">
                              Sub Account ID
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="binanceAccountNumber"
                              placeholder="Enter binance sub account id"
                              type="text"
                              name="binanceAccountNumber"
                              onChange={onInputValueChange}
                              value={binanceAccountNumber}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="title">
                              ViaBTC API Key
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="viaBtcApiKey"
                              placeholder="Enter ViaBTC API key"
                              type="text"
                              name="viaBtcApiKey"
                              onChange={onInputValueChange}
                              value={viaBtcApiKey}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="title">
                              ViaBTC Secret Key
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="viaBtcSecretKey"
                              placeholder="Enter ViaBTC API key"
                              type="text"
                              name="viaBtcSecretKey"
                              value={viaBtcSecretKey}
                              onChange={onInputValueChange}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="title">Pool Fees</label>
                            <input
                              className="form-control w-100"
                              id="viaBtcPoolFees"
                              placeholder="Enter ViaBTC Pool Fees"
                              type="text"
                              name="viaBtcPoolFees"
                              onChange={onInputValueChange}
                              value={viaBtcPoolFees}
                            />
                          </div>
                          <div className="pull-left mb-3">
                            <button
                              className="btn btn-primary btn-md"
                              type="button"
                              onClick={ModifyPoolSettings}
                              disabled={buttonDisabled}
                            >
                              Submit{" "}
                              {buttonLoading && (
                                <i className="fa-spin fa fa-spinner text-white m-1" />
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                    {geminiCheck && (
                      <div>
                        <h4 className="onboarding-title">
                          Modify Gemini Settings
                        </h4>
                        <form>
                          {/* <div className="form-group">
                            <label htmlFor="title">
                              Sub Account ID
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="binanceAccountNumber"
                              placeholder="Enter binance sub account id"
                              type="text"
                              name="binanceAccountNumber"
                              onChange={onInputValueChange}
                              value={binanceAccountNumber}
                            />
                          </div> */}

                          <div className="form-group">
                            <label htmlFor="title">
                              Gemini API Key
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="geminiApiKey"
                              placeholder="Enter Gemini API key"
                              type="text"
                              name="geminiApiKey"
                              onChange={onInputValueChange}
                              value={geminiApiKey}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="title">
                              Gemini Secret Key
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="geminiSecretKey"
                              placeholder="Enter Gemini Secret key"
                              type="text"
                              name="geminiSecretKey"
                              value={geminiSecretKey}
                              onChange={onInputValueChange}
                            />
                          </div>
                          <div className="pull-left mb-3">
                            <button
                              className="btn btn-primary btn-md"
                              type="button"
                              onClick={ModifyGeminiSettings}
                              disabled={geminiButtonDisabled}
                            >
                              Submit{" "}
                              {geminiButtonLoading && (
                                <i className="fa-spin fa fa-spinner text-white m-1" />
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default SubaccountModal;
