import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import { FilePond } from "react-filepond";
import Api from "../../services/api";
import validators from "../../validators";
import { API_ROOT } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";

class AdminUserDetailMachineAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      invoiceNumber: "",
      manufacturer: "",
      model: "",
      algorithm: "",
      hashrate: "",
      powerWth: "",
      isHavingBudget: "",
      powerConsumption: "",
      priceInUsd: "",
      quantity: "",
      status: "",
      trackLink: "",
    };
    this.validators = validators;
    this.updateValidators = this.updateValidators.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.saveMachine = this.saveMachine.bind(this);
    this.uploadImageArray = {};
    this.onchange = this.onchange.bind(this);
  }

  async componentDidMount() {
    if (this.props.location) {
      if (this.props.location.state) {
        this.setState({
          ishavingbudget: this.props.location.state.ishavingbudget,
        });
      }
    }
    // console.log(this.props.location.state.ishavingbudget);
    const api = new Api();
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;
    let machineId = this.props.match.params.machineId;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    try {
      if (!_.isUndefined(machineId)) {
        const response = await api
          .setToken(authenticationToken)
          .get("admin/machine/details", { machineId });
        if (response.code === 200) {
          this.setState(
            {
              invoiceNumber:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.invoiceNumber
                  : "",
              manufacturer:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.manufacturer
                  : "",
              model:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.model
                  : "",
              algorithm:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.algorithm
                  : "",
              hashrate:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.hashrate
                  : "",
              powerWth:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.powerWth
                  : "",
              powerConsumption:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.powerConsumption
                  : "",
              priceInUsd:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.priceInUsd
                  : "",
              quantity:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.quantity
                  : "",
              status:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.status
                  : "",
              trackLink:
                response.data && response.data.machineDetails
                  ? response.data.machineDetails.trackLink
                  : "",
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } else {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }

  async saveMachine(event) {
    let {
      manufacturer,
      model,
      algorithm,
      hashrate,
      powerConsumption,
      priceInUsd,
      quantity,
      status,
      trackLink,
      invoiceNumber,
      powerWth,
    } = this.state;
    let _this = this;
    let userId = this.props.match.params.id;
    let machineId = this.props.match.params.machineId;
    // console.log("manufacturer",manufacturer);
    if (userId && manufacturer !== "") {
      if (
        !_.isUndefined(machineId) &&
        !_.isNull(machineId) &&
        !_.isEmpty(machineId)
      ) {
        this.setState({
          buttonLoading: true,
        });
        const data = new FormData();
        data.append("billOfSale", this.uploadImageArray["file"]);

        if (invoiceNumber) {
          data.append("invoiceNumber", invoiceNumber);
        }

        if (manufacturer) {
          data.append("manufacturer", manufacturer);
        }
        if (model) {
          data.append("model", model);
        }
        if (algorithm) {
          data.append("algorithm", algorithm);
        }
        if (hashrate) {
          data.append("hashrate", hashrate);
        }
        if (powerConsumption) {
          data.append("powerConsumption", powerConsumption);
        }
        if (priceInUsd) {
          data.append("priceInUsd", priceInUsd);
        }
        if (quantity) {
          data.append("quantity", quantity);
        }
        if (status) {
          data.append("status", status);
        }
        if (trackLink) {
          data.append("trackLink", trackLink);
        }
        data.append("userId", userId);
        if (machineId) {
          data.append("machineId", machineId);
        }
        if (powerWth) {
          data.append("powerWth", powerWth);
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.props.authToken,
            // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Origin": "req.headers.origin",
          },
        };
        axios
          .post(API_ROOT + "/admin/machine/add", data, config)
          .then(function (response) {
            if (!_.isUndefined(response)) {
              _this.setState({
                buttonLoading: false,
              });
              if (response.data.code === 200) {
                toast.success(response.data.message);
                _this.props.history.push(`/user_detail/${userId}`);
              } else {
                toast.error(response.data.message);
              }
            }
          })
          .catch(function (error) {
            toast.error(messageConstants.SOMETHING_WENT_WRONG);
          });
      } else {
        this.setState({
          buttonLoading: true,
        });
        const data = new FormData();
        data.append("billOfSale", this.uploadImageArray["file"]);

        if (invoiceNumber) {
          data.append("invoiceNumber", invoiceNumber);
        }

        if (manufacturer) {
          data.append("manufacturer", manufacturer);
        }
        if (model) {
          data.append("model", model);
        }
        if (algorithm) {
          data.append("algorithm", algorithm);
        }
        if (hashrate) {
          data.append("hashrate", hashrate);
        }
        if (powerConsumption) {
          data.append("powerConsumption", powerConsumption);
        }
        if (priceInUsd) {
          data.append("priceInUsd", priceInUsd);
        }
        if (quantity) {
          data.append("quantity", quantity);
        }
        if (status) {
          data.append("status", status);
        }
        if (trackLink) {
          data.append("trackLink", trackLink);
        }
        data.append("userId", userId);
        if (machineId) {
          data.append("machineId", machineId);
        }
        if (powerWth) {
          data.append("powerWth", powerWth);
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.props.authToken,
            // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Origin": "req.headers.origin",
          },
        };
        axios
          .post(API_ROOT + "/admin/machine/add", data, config)
          .then(function (response) {
            if (!_.isUndefined(response)) {
              _this.setState({
                buttonLoading: false,
              });
              if (response.data.code === 200) {
                toast.success(response.data.message);
                _this.props.history.push(`/user_detail/${userId}`);
              } else {
                toast.error(response.data.message);
              }
            }
          })
          .catch(function (error) {
            toast.error(messageConstants.SOMETHING_WENT_WRONG);
          });
      }
    } else {
      let msg = "Please enter all required fields.";
      toast.error(msg);
    }
  }

  render() {
    let _this = this;
    let userId = this.props.match.params.id;
    let machineId = this.props.match.params.machineId;
    const {
      invoiceNumber,
      manufacturer,
      model,
      algorithm,
      ishavingbudget,
      hashrate,
      powerConsumption,
      priceInUsd,
      quantity,
      status,
      trackLink,
      powerWth,
    } = this.state;
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="pull-right">
                  <Link
                    to="/manage_users"
                    className="pull-right text-underline"
                  >
                    Back to users
                  </Link>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <form id="formValidate">
                      <h5 className="form-header">
                        {" "}
                        {machineId ? "Edit" : "Add"} Machine{" "}
                      </h5>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor=""> Invoice Number </label>
                            <input
                              className="form-control"
                              placeholder="Enter Invoice Number"
                              type="text"
                              name="invoiceNumber"
                              onChange={this.onchange}
                              value={invoiceNumber}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor=""> Make </label>
                            <input
                              className="form-control"
                              placeholder="Enter Make"
                              type="text"
                              name="manufacturer"
                              onChange={_this.onchange}
                              value={manufacturer}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Model</label>
                            <input
                              className="form-control"
                              placeholder="Enter model"
                              type="text"
                              name="model"
                              onChange={this.onchange}
                              value={model}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor=""> Algorithm </label>
                            <input
                              className="form-control"
                              placeholder="Enter Algorithm"
                              type="text"
                              name="algorithm"
                              onChange={this.onchange}
                              value={algorithm}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hashrate (TH)</label>
                            <input
                              className="form-control"
                              placeholder="Enter Hashrate"
                              type="text"
                              name="hashrate"
                              onChange={this.onchange}
                              value={hashrate}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Power (W/TH)</label>
                            <input
                              className="form-control"
                              placeholder="Enter W/TH"
                              type="text"
                              name="powerWth"
                              onChange={this.onchange}
                              value={powerWth}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Power Consumption (W)</label>
                            <input
                              className="form-control"
                              placeholder="Enter Power Consumption (W)"
                              type="text"
                              name="powerConsumption"
                              onChange={this.onchange}
                              value={powerConsumption}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor=""> Price (USD) </label>
                            <span
                              onClick={() => {
                                if (ishavingbudget) {
                                  toast.error(
                                    "This is user is having budget please don't change the price!"
                                  );
                                }
                              }}
                            >
                              <input
                                className="form-control"
                                placeholder="Price in USD"
                                type="text"
                                disabled={ishavingbudget}
                                name="priceInUsd"
                                onChange={this.onchange}
                                value={priceInUsd}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Quantity</label>
                            <span
                              onClick={() => {
                                if (ishavingbudget) {
                                  toast.error(
                                    "This is user is having budget please don't change the quantity!"
                                  );
                                }
                              }}
                            >
                              <input
                                className="form-control"
                                placeholder="Enter Quantity"
                                type="text"
                                name="quantity"
                                onChange={this.onchange}
                                value={quantity}
                                disabled={ishavingbudget}
                                onClick={() => {}}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor=""> Status </label>
                            <select
                              className="form-control"
                              name="status"
                              onChange={this.onchange}
                              value={status}
                            >
                              <option>Select Status</option>
                              <option value="accepted">Accepted</option>
                              <option value="shipped">Shipped</option>
                              <option value="delivered">Delivered</option>
                              <option value="configured">Configured</option>
                              <option value="installed">Installed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Track Link</label>
                            <input
                              className="form-control"
                              placeholder="Enter Track Link"
                              type="text"
                              name="trackLink"
                              onChange={this.onchange}
                              value={trackLink}
                            />
                          </div>
                        </div>
                      </div>

                      <label>Bill Of Sale</label>
                      <div className="resource-continaer dropzoneIdContainer">
                        <div className="row">
                          <div className="col-md-12">
                            <FilePond
                              allowMultiple={false}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={[
                                "image/*",
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              ]}
                              instantUpload={false}
                              onupdatefiles={(fileItems) => {
                                if (!_.isEmpty(fileItems)) {
                                  _this.fileUpdated(fileItems);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-buttons-w button-center">
                        <button
                          className="btn btn-primary "
                          type="button"
                          onClick={this.saveMachine}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminUserDetailMachineAdd;
