import React from 'react';
import { toast } from 'react-toastify';
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class UserKycVerifyEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let verificationCode = this.props.match.params.id;
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    const query = new URLSearchParams(this.props.location.search);
    const userId = query.get('userId')
    try {
      const api = new Api();
      const response = await api.setToken(verificationCode).get("admin/kyc/details/verified", { 'userId': userId });
      if (response.code === 200) {
        toast.success(response.message);
      }
    }
    catch (e) {
      toast.error(messageConstants.SOMETHING_WENT_WRONG);
    }
    this.props.history.push("/kyc_verification_success");
  }

  render() {
    return (
      <div></div>
    );
  }
}
export default UserKycVerifyEvent