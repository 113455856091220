import React from 'react';

class ExchangeVerifierSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1> Email Verified </h1>
          <p>Your email verified successfully. You will receive and email for sell mandate confirmation whenever new sell mandate is created.</p>
        </div>
      </div>
    );
  }
}
export default ExchangeVerifierSuccess