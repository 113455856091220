import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Api from "../../services/api";
import { niceNumberDecimalDisplay, hashrateConverter } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import GlobalHistoricWorkerModal from "./GlobalHistoricWorkerModal";
import "./Earnings.css";

class GlobalHistoricWorkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workerLoading: true,
      workerTableData: [],
      workerAllData: [],
      tableLoading: true,
      workerPage: 1,
      totalWorker: "",
      backUpAllSize: "",
      selectedOption: "",
      emailData: [],
      filterRecordCheck: false,
      excelData: [],
      workerSizePerPage: 20,
      workerRenderFlag: false,
      workerTotalSize: "",
      offlineWorkers: 0,
      hashRate: "",
      workerColumns: [],
    };
    this.props.pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.hashRateCall = this.hashRateCall.bind(this);
    this.setTableData = this.setTableData.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.giveDate = this.giveDate.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getFilterRecords = this.getFilterRecords.bind(this);
    this.handleEventsChange = this.handleEventsChange.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
  }
  getAllData(allData) {
    const workerArr = [];
    allData.map((data) => {
      let worker = {};
      worker.name = data.worker_name;
      worker.email = data.worker_name;
    });
  }
  clearFilterRecords() {
    this.setState(
      {
        earningsSizePerPage: 20,
        earningsPage: 1,
        filterRecordCheck: false,
        setAccount: "",
        selectedOption: "",
        workerTotalSize: this.state.backUpAllSize,
      },
      () => {
        this.setTableData(this.state.workerAllData);
      }
    );
  }
  handleEventsChange(selectedOption) {
    this.setState({
      selectedOption,
    });
  }
  async changeDate(e) {
    this.setState({
      setDate: e.target.value,
    });
  }
  async changeAccount(e) {
    this.setState({
      setAccount: e.target.value,
    });
  }
  async getTableData(tableData) {
    let workerData = tableData;
    let data = [];
    let emailData = [];

    for (let i = 0; i < workerData.length; i++) {
      emailData.push({
        value: workerData[i].email,
        label: workerData[i].email,
      });
      for (let j = 0; j < workerData[i].workerArray.length; j++) {
        let worker = {};
        worker.name = workerData[i].workerArray[j].worker_name;
        worker.email = workerData[i].email;
        worker['24H'] = workerData[i].workerArray[j].hashrate_24hour;
        worker.reject = `${workerData[i].workerArray[j].reject_rate?niceNumberDecimalDisplay(workerData[i].workerArray[j].reject_rate *100,2):"0.00"}%`;
        worker.lastActive = workerData[i].workerArray[j].last_active
        ? String(workerData[i].workerArray[j].last_active).includes("PM") ||
          String(workerData[i].workerArray[j].last_active).includes("AM")
          ? workerData[i].workerArray[j].last_active
          : moment(workerData[i].workerArray[j].last_active * 1000).format(
              "DD MMM YYYY hh:mm A"
            )
        : "";
        worker.status=workerData[i].workerArray[j].worker_status
        data.push(worker);
      }
    }
    this.setState({ emailData: emailData });

    return data;
  }
  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  giveDate(timeStamp) {
    const timestamp = timeStamp * 1000;
    return moment(timestamp).format("DD MMM YYYY HH:MM:ss");
  }
  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  setTableData(requiredData) {
    let data = [];
    if (this.state.workerPage !== "") {
      for (
        let i = 0;
        i < this.state.workerPage * this.state.workerSizePerPage;
        i++
      ) {
        data.push(requiredData[i]);
      }
      data = data.slice(-this.state.workerSizePerPage);
    }

    const filterData = data.filter((data) =>
      data ? (data.name || data.date ? data : "") : ""
    );
    this.setState({
      workerTableData: filterData,
      earningsTotalSize: filterData.length,
    });
  }

  async componentDidMount() {
    document.title =
      messageConstants.WORKERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getRecords();
  }
  async getFilterRecords(email, accountNumber) {
    try {
      const { workerAllData } = this.state;
      const freshData = workerAllData.filter((data) => {
        return data.email === email[0].label ? data : "";
      });

      this.setState(
        {
          tempData: freshData,
          workerTotalSize: freshData.length,
          filterRecordCheck: true,
        },
        () => {
          this.setTableData(this.state.tempData);
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  }
  async hashRateCall(value) {
    const data = await hashrateConverter(value, true, true);
    return data;
  }

  validateEmail(emailAdress) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  allData() {
    const { totalWorker } = this.state;
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("user/fetchWorkerDetail", { page: 1, sizePerPage: totalWorker })
      .then((data) => {

        if (data.data) {
          this.getTableData(data.data.workers).then((data) => {
            console.log("data",data);
            this.setState({ workerAllData: data,
              workerTotalSize: data.length,
              backUpAllSize: data.length,
             }, () => {
              this.setTableData(this.state.workerAllData);
            });
      });
    }
  })
}
  async getRecords() {
    try {
      const { workerPage, workerSizePerPage } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/fetchWorkerDetail", {
          page: workerPage,
        });
      if (response.code === 200) {
        let tableData = [];
        const responseData = await this.getTableData(response.data.workers);

        this.setState({ workerAllData: responseData,
          workerTotalSize: responseData.length,
          backUpAllSize: responseData.length,
          totalWorker: response.data.totalWorkers,
          workerLoading: false,
          tableLoading: false,
          workerRenderFlag: true,
        }, () => {
          this.setTableData(this.state.workerAllData);
          this.allData();

        });
        }
        this.setState(
          {
            workerLoading: false,
            tableLoading: false,
            workerRenderFlag: true,
          });
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    const { workerAllData, tempData, filterRecordCheck } = this.state;
    if (
      this.state.workerSizePerPage !== sizePerPage ||
      this.state.workerPage !== page
    ) {
      this.setState(
        { workerSizePerPage: sizePerPage, workerPage: page },
        () => {
          if (filterRecordCheck === false) {
            this.setTableData(workerAllData);
          } else if (filterRecordCheck === true) {
            this.setTableData(tempData);
          }
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.setTableData(this.state.workerAllData);
        });
        return true;
      }
    }
  };

  render() {
    const {
      workerSizePerPage,
      earningsLoading,
      workerTotalSize,
      emailData,
      selectedOption,
      workerRenderFlag,
      workerPage,
      workerLoading,
      validNum,
      tableLoading,
      excelData,
      workerAllData,
      invalidNum,
      hashRateCall,
      Total,
    } = this.state;
    let hashRate;
    let _this = this;

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "name",
        text: "Name",
        formatter: function (cell, row) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "email",
        text: "Email",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "24H",
        text: "24H",
        formatter: function (cell) {
          return <span>{cell ? cell : 0}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "reject",
        text: "Reject",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "lastActive",
        text: "Last Active",
        formatter: function (cell, row) {
          return cell
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "Status",
        formatter: function (cell, row) {
          return <span>{_.toUpper(cell)}</span>;
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="earnings-table table-responsive tb-margin">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable "
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box card-inner-padding">
              <div className="row spaceBetween">
                <div className="">
                  <h4 className="card-title-font form-table-header">
                    Workers Historic Data
                  </h4>
                </div>
                <div className="">
                  <span className="mr-1">
                    <button
                      className="btn btn-primary exportbtn "
                      onClick={() => this.showModal()}
                    >
                      Export Data
                    </button>
                    <GlobalHistoricWorkerModal
                      {...this.props}
                      {...this.state}
                      excelData={this.state.workerAllData}
                      onCloseModal={this.onCloseModal}
                      onInputValueChange={this.onchange}
                      AddMinerRequest={this.AddMinerRequest}
                    />
                  </span>
                </div>
              </div>
              <div className="mt-3 left" style={{ display: "flex" }}></div>
              <div className="earnings-table table-responsive">
                {tableLoading && (
                  <div className="text-center">
                    <span id="loading" color="#047bf8" />
                  </div>
                )}
                {workerRenderFlag === true && (
                  <WorkerRemoteAll
                    data={this.state.workerTableData}
                    page={workerPage}
                    sizePerPage={workerSizePerPage}
                    totalSize={this.state.workerTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalHistoricWorkers;
