import React from 'react';

class ProgressReportVerifiedSuccess extends React.Component {
  
  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }
  
  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1>Progress Report Details Verified </h1>
          <p>You have successfully verified campaign progress report.</p>
        </div>
      </div>
    );
  }
}
export default ProgressReportVerifiedSuccess