import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class CampaignProgressReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByTitle: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  async getRecords() {
    let listingId = this.props.match.params.listingId;
    const api = new Api();
    const { sizePerPage, page } = this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/progress/report/list", {
          sizePerPage: sizePerPage,
          page: page,
          listingId: listingId,
        });
      if (response.code === 200) {
        this.setState(
          {
            loading: false,
            data: response.data.progtressReportList,
            totalSize: response.data.progressReportListCount,
            renderFlag: true,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async componentDidMount() {
    document.title =
      messageConstants.LISTING_PROGRESS_REPORTS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByTitle: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  progressReportChangeOperation(listingProgressId, type) {
    let label = type === "accept" ? "accept" : "reject";
    let _self = this;
    let buttons = "";
    if (type === "accept") {
      buttons = [
        {
          label: "Accept",
          onClick: () =>
            _self.progressReportOperation(listingProgressId, "accept"),
        },
        {
          label: "Cancel",
        },
      ];
    } else {
      buttons = [
        {
          label: "Reject",
          onClick: () =>
            _self.progressReportOperation(listingProgressId, "reject"),
        },
        {
          label: "Cancel",
        },
      ];
    }
    confirmAlert({
      title: "Sure to " + label + " progress report?",
      buttons: buttons,
    });
  }

  async progressReportOperation(listingProgressId, type) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("admin/progress/report/update", {
        listingProgressId: listingProgressId,
        type: type,
      });
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }

  render() {
    const { data, sizePerPage, page, renderFlag, loading } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "title",
        text: "Title",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "categoryId",
        text: "Category Name",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              {row.categoryId && row.categoryId.title
                ? row.categoryId.title
                : "N/A"}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "shortDescription",
        text: "Short Description",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              {cell && cell !== "" ? cell : "N/A"}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "document",
        text: "Document",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <a href={cell} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        formatter: function (cell) {
          let labelClassName =
            cell === "p"
              ? "badge-warning"
              : cell === "a"
              ? "badge-primary"
              : "badge-danger";
          let label =
            cell === "p" ? "Pending" : cell === "a" ? "Accepted" : "Rejected";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        sort: true,
        formatter: function (cell) {
          var formatedDate = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{formatedDate}</div>;
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  {row.adminVerifiedStatus === false && (
                    <div>
                      <button
                        className="mr-2 mb-2 btn btn-primary"
                        type="button"
                        onClick={() =>
                          _self.progressReportChangeOperation(row._id, "accept")
                        }
                      >
                        Accept
                      </button>
                      <br />
                      <button
                        className="mr-2 mb-2 btn btn-danger"
                        type="button"
                        onClick={() =>
                          _self.progressReportChangeOperation(row._id, "reject")
                        }
                      >
                        {" "}
                        Reject
                      </button>
                    </div>
                  )}
                  {row.adminVerifiedStatus === true && <div>N/A</div>}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No campaign results found!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Campaigns Progress Reports</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CampaignProgressReports;
