import React, { Component } from 'react';
import Modal from "react-responsive-modal";


class AddVerifierModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { verifierModalOpen, onCloseModal, onInputValueChange, AddVerifierRequest, buttonLoading, fullName, email, phoneNumber } = this.props;
    let buttonDisabled = (buttonLoading === true) ? true : false;
    return (
      <Modal open={verifierModalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content verifier-modal">
                    <h4 className="onboarding-title">Add Exchange Verifier</h4>
                    <form>

                      <div className="form-group">
                        <label htmlFor="fullName">Full Name<span className="required-field">*</span></label>
                        <input className="form-control" id="fullName" placeholder="Enter Full Name" type="text" value={fullName} name="fullName" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="fullName">Email<span className="required-field">*</span></label>
                        <input className="form-control" id="email" placeholder="Enter Email" type="text" value={email} name="email" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="fullName">Phone Number</label>
                        <input className="form-control" id="phoneNumber" placeholder="Enter Phone Number" type="text" value={phoneNumber} name="phoneNumber" onChange={onInputValueChange} />
                      </div>

                      <div>
                        <button className="btn btn-primary btn-sm" type="button" onClick={AddVerifierRequest} disabled={buttonDisabled}> Submit {buttonLoading && (
                          <i className="fa-spin fa fa-spinner text-white m-1" />
                        )}</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddVerifierModal;