import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
// import moment from "moment";
export const ExportCSV = ({
  csvData,
  fileName,
  startDate,
  endDate,
  timeHorizon,
  earningsDatas,
  clearTime,
  onCloseModal
}) => {
  const state = { csvData: "" };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const getData = (firstDate,lastDate) => {
    const beginDate = new Date(firstDate);
    const finishDate = new Date(lastDate);
    const startDateYear = beginDate.getFullYear();
    const startDateMonth = beginDate.getMonth();
    const startDataDate = beginDate.getDate();

    const endDateYear = finishDate.getFullYear();
    const endDateMonth = finishDate.getMonth();
    const endDataDate = finishDate.getDate();
    // console.log(endDateYear, endDateMonth, endDataDate);
    // console.log(startDateYear, startDateMonth, startDataDate);
    if(csvData=="")
    {
      const arr = [];
      return arr;  
    }
    const arr = csvData.filter((data) => {
      let givenDataDate = new Date(data.date);

      const givenDateYear = givenDataDate.getFullYear();
      const givenDateMonth = givenDataDate.getMonth();
      const givenDate = givenDataDate.getDate();

      if (startDateYear < givenDateYear && endDateYear > givenDateYear) {
        // console.log(data);
        return data;
      }

      if (startDateYear == givenDateYear && endDateYear == givenDateYear) {
        if (startDateMonth < givenDateMonth && endDateMonth > givenDateMonth) {
          // console.log(data);

          return data;
        }
        if (
          startDateMonth == givenDateMonth &&
          endDateMonth == givenDateMonth
        ) {
          if (startDataDate <= givenDate && endDataDate >= givenDate) {
            // console.log(data);

            return data;
          }
        } else if (startDateMonth == givenDateMonth) {
          if (startDataDate <= givenDate) {
            // console.log(data);

            return data;
          }
        } else if (endDateMonth == givenDateMonth) {
          if (endDataDate >= givenDate) {
            // console.log(data);

            return data;
          }
        }
      } else if (startDateYear == givenDateYear) {
        if (startDateMonth < givenDateMonth) {
          // console.log(data);

          return data;
        }

        if (startDateMonth == givenDateMonth) {
          // debugger;
          if (startDataDate <= givenDate) {
            // console.log(data);

            return data;
          }
        }
        // if (endDateMonth == givenDateMonth) {
        //   // debugger;
        //   if (endDataDate >= givenDate) {
        //     console.log(data);

        //     return data;
        //   }
        // }
      } else if (endDateYear == givenDateYear) {
        if (endDateMonth > givenDateMonth) {
          // console.log(data);

          return data;
        }

        // if (startDateMonth == givenDateMonth) {
        //   // debugger;
        //   if (startDataDate <= givenDate) {
        //     console.log(data);

        //     return data;
        //   }
        // }
        if (endDateMonth == givenDateMonth) {
          // debugger;
          if (endDataDate >= givenDate) {
            // console.log(data);

            return data;
          }
        }
      }
      // // givenDataDate = givenDataDate.getTime();
      // if (givenDataDate >= sentStartDateStartTime && givenDataDate <= sentEndDateEndTime) {
      //   return data;
      // }
    });
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    // console.log("Hello");
    // toast.success("Success!");
    clearTime();
    return;
  };
  const exportToCSV = (
    csvData,
    fileName,
    startDate,
    endDate,
    timeHorizon,
    clearTime
  ) => {
    let startDateArr = startDate.split("-");
    // console.log(startDate)
    let endDateArr = endDate.split("-");
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // console.log(startDateArr[2]);
    const date = new Date();
    const todayDate = date.getTime();
    // console.log("csvData", csvData);
    let sentStartDate = new Date(startDate);
    // console.log("sentStartDate",sentStartDate)
    // var sentStartDateIST = new Date(sentStartDate).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    // let sentStartDateIST = moment(sentStartDate).tz("Asia/Kolkata");
    let sentStartDateIST = new Date(startDate);
    sentStartDateIST.setHours(sentStartDateIST.getHours() - 5);
    sentStartDateIST.setMinutes(sentStartDateIST.getMinutes() - 30);
    let sentStartDateEndTime = new Date(sentStartDateIST);
    let sentStartDateStartTime = new Date(sentStartDateIST);
    sentStartDate = sentStartDate.getTime();

    sentStartDateStartTime.setUTCHours(0, 0, 0, 0);
    sentStartDateEndTime.setUTCHours(23, 59, 59, 999);
    sentStartDateEndTime = sentStartDateEndTime.getTime();
    sentStartDateStartTime = sentStartDateStartTime.getTime();
    // console.log("sentStartDateStartTime",sentStartDateStartTime)
    // console.log("sentStartDate",sentStartDate)
    // console.log("sentStartDateEndTime",sentStartDateEndTime)

    let sentEndDate = new Date(endDate);

    let sentEndDateIST = new Date(sentEndDate);
    sentEndDateIST.setHours(sentEndDateIST.getHours() - 5);
    sentEndDateIST.setMinutes(sentEndDateIST.getMinutes() - 30);
    let sentEndDateEndTime = new Date(sentEndDateIST);
    let sentEndDateStartTime = new Date(sentEndDateIST);
    // sentStartDate = sentStartDate.getTime();
    sentEndDate = sentEndDate.getTime();

    sentEndDateStartTime.setUTCHours(0, 0, 0, 0);
    sentEndDateEndTime.setUTCHours(23, 59, 59, 999);
    sentEndDateEndTime = sentEndDateEndTime.getTime();
    sentEndDateStartTime = sentEndDateStartTime.getTime();
    // console.log(sentDate.getTime())
    // console.log("startDate",startDate)
    // console.log(startDate)
    // console.log(endDate)
    // console.log(timeHorizon)

    // console.log(csvData)
    if (
      (!startDate && !endDate && !timeHorizon) ||
      (startDate === undefined &&
        endDate === undefined &&
        timeHorizon === undefined) ||
      (startDate === "" && endDate === "" && timeHorizon === "")
    ) {
      toast.error("Please mention the dates properly.");
      clearTime();
      return;
    } else if (startDate && endDate && timeHorizon) {
      toast.error("Please select either date or time horizon.");
      clearTime();
      return;
    }
    else if (startDate  && timeHorizon) {
      toast.error("Please select either date or time horizon.");
      clearTime();
      return;
    }
    else if (endDate  && timeHorizon) {
      toast.error("Please select either date or time horizon.");
      clearTime();
      return;
    }
    else if (sentStartDate > sentEndDate) {
      toast.error("Please mention the dates properly.");
      clearTime();
      return;
    } 
    // else if (sentStartDate > todayDate || sentEndDate > todayDate) {
    //   toast.error("Please mention the dates properly.");
    //   clearTime();
    //   return;
    // }
     else if (startDate !== "" && endDate === "" && timeHorizon === "") {

      getData(startDate,startDate)
    } else if (startDate === "" && endDate !== "" && timeHorizon === "") {
      getData(endDate,endDate)
    } else if (startDate === "" && endDate === "" && timeHorizon !== "") {
      if (timeHorizon === "24H") {
        let todayDate = new Date();
        // console.log("todayDate", todayDate);
        todayDate = todayDate.getTime();

        let dueDate = new Date();
        dueDate.setDate(dueDate.getDate() - 1);

        getData(dueDate,dueDate);
      } else if (timeHorizon === "7D") {
        // const arr = csvData.slice(-7);

        let endDate = new Date();
        // console.log("todayDate", todayDate);
        // todayDate = todayDate.getTime();
        // startDate.setDate(startDate.getDate() - 7);

        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        getData(startDate,endDate)
      } else if (timeHorizon === "30D") {
        // const arr = csvData.slice(-30);

        let endDate = new Date();

        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        getData(startDate,endDate)

      } else if (timeHorizon === "All") {

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        // toast.success("Success!");
        clearTime();
        return;
      }
    } else if (startDate !== "" && endDate !== "" && timeHorizon === "") {
      getData(startDate,endDate)
    }
  };
  return (
    <button
      className="btn btn-primary"
      onClick={(e) =>
        {exportToCSV(
          csvData,
          fileName,
          startDate,
          endDate,
          timeHorizon,
          clearTime
        );
        onCloseModal();
        }
      }
    >
      Export Data
    </button>
  );
};
