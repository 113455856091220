import React from "react";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import _ from "lodash";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import TransactionOperationModal from "./TransactionOperationModal";
import { niceNumberDecimalDisplay, tooltipNumberDisplay } from '../../utils/Util';
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class ManageUsdTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTransactionButtonLoading: false,
      modalOpen: false,
      usdDepositeId: '',
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: '',
      filterByName: '',
      filterByEmail: '',
      loading: false,
      transactionHash: '',
      transactionNote: '',
      transactionAmount: '',
      userId: '',

    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.generateTransaction = this.generateTransaction.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  refreshCurrenttable(){
    this.getRecords();
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });
    const response = await api.setToken(authenticationToken).get("admin/exchange/usd/deposit/list", {
      'sizePerPage': sizePerPage,
      'page': page,
      'filterByName': filterByName,
      'filterByEmail': filterByEmail
    });
    if (response.code === 200) {
      this.setState({
        renderFlag: true,
        loading: false,
        data: response.data.transactions,
        totalSize: response.data.totalTransactions
      }, (async () => {
        if (typeof (this.props.pageProgress) === 'function') {
          this.props.pageProgress('remove');
        }
      }));
    }

    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('force_remove');
    }
  }

  openModal(usdDepositeId = '', userId = '', paymentRequestAmount = 0) {
    if (!_.isUndefined(usdDepositeId)) {
      this.setState({
        modalOpen: true,
        usdDepositeId,
        userId,
        transactionAmount: paymentRequestAmount
      });
    }
  }

  componentWillMount() {
    document.title = messageConstants.ADMIN_MANAGE_PAYMENT_REQUESTS_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState({
      filterByEmail: '',
      filterByName: ''
    }, (async () => {
      await this.getRecords();
    }));
  }

  onCloseModal() {
    this.setState({
      modalOpen: false
    });
  }

  async generateTransaction() {
    let { transactionAmount, transactionHash, userId, usdDepositeId, adminNote, type } = this.state;
    let authenticationToken = this.props.authToken;

    if (!_.isUndefined(transactionAmount) && !_.isUndefined(transactionHash)) {
      this.setState({ addTransactionButtonLoading: true });
      const api = new Api();
      const response = await api.setToken(authenticationToken).create("admin/deposit/status/update", {
        userId,
        type,
        transactionHash,
        usdDepositeId,
        transactionAmount,
        adminNote
      });
      if (response) {
        this.setState({ addTransactionButtonLoading: false });
      }
      if (response.code === 200) {
        this.setState({
          type: '',
          userId: '',
          transactionHash: '',
          transactionAmount: '',
          usdDepositeId: '',
          transactionNote: ''
        });
        await this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = 'Please enter valid details for required fields.';
      toast.error(msg);
    }
  }

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ 'sizePerPage': sizePerPage, 'page': page }, () => {
        this.getRecords();
      });
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value
    });
  }

  render() {
    const { data, sizePerPage, page, renderFlag, loading, filterByName, filterByEmail } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{(row.userId && row.userId.fullName) ? row.userId.fullName : 'N/A'}</div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "Email",
        text: "Email",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{(row.userId && row.userId.email) ? row.userId.email : 'N/A'}</div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "transactionAmount",
        text: "Amount (in $)",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>
                <span className="custom-tooltip" tooltip-title={tooltipNumberDisplay(cell, '$', 'prepend')}>{niceNumberDecimalDisplay(cell, 2)}</span>
              </div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "paymentFileKey",
        text: "Payment Proof",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>
                {!_.isUndefined(row.paymentFileKey) && row.paymentFileKey && row.paymentFileKey !== '' && row.paymentFileKey !== null &&
                  <a href={cell} target="_blank" rel="noopener noreferrer">View File</a>
                }
                {(!row.paymentFileKey || _.isUndefined(row.paymentFileKey)) &&
                  <span>N/A</span>
                }
              </div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "userNote",
        text: "Note",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.status === 'a') {
            label = "Completed";
            className = "badge-success";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={"badge " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date Time",
        formatter: function (cell) {
          var dobDate = moment(cell).format("DD/MM/YYYY HH:mm");
          return (
            <div className="text-left">
              <div>{dobDate}</div>
            </div>
          );
        }
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  {row.status === 'p' && !row.minimumVerifiersRequired &&
                    <div>
                      <button className="mr-2 mb-2 btn btn-primary" type="button" onClick={() => _self.openModal(row._id, row.userId._id, row.transactionAmount)}> Update</button>
                    </div>
                  }
                  {row.status === 'p' && row.minimumVerifierRequired !== row.verifiedCounter &&
                    <span className="badge badge-warning">Verification Process</span>
                  }
                  {(row.status !== 'p') &&
                    <div>N/A</div>
                  }
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={loading}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={page>1? paginationFactory({ page, sizePerPage, totalSize }):""}
          onTableChange={onTableChange}
          overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">

            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Requests</h5>
                <form className="form-inline">
                  <label className="sr-only"> Name</label>
                  <input className="form-control mb-2 mr-sm-2 mb-sm-0" name="filterByName" id="filterByName" placeholder="Full Name" type="text" onChange={this.onchange} value={filterByName} />
                  <label className="sr-only"> Email</label>
                  <input className="form-control mb-2 mr-sm-2 mb-sm-0" placeholder="Email Address" type="text" name="filterByEmail" id="filterByEmail" onChange={this.onchange} value={filterByEmail} />
                  <button className="btn btn-primary" type="button" onClick={this.filterRecords}> Filter</button>
                  <button className="btn btn-danger ml-2" type="button" onClick={this.clearFilterRecords}> Clear</button>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Transactions Requests</span>
                  <span className="fa fa-refresh ml-2 cursor-pointer" onClick={() => this.refreshCurrenttable()}></span>
                </h5>
                <div>
                  {renderFlag === true &&
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <TransactionOperationModal {...this.props} {...this.state} onCloseModal={this.onCloseModal} onInputValueChange={this.onchange} generateTransaction={this.generateTransaction} handleRadioChange={this.handleRadioChange} />
      </div>
    );
  }
}
export default ManageUsdTransactions;