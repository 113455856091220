import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';
import * as messageConstants from "../../utils/Messages";

class CorporateFatca extends React.Component {
  constructor(props) {
    super(props);
    let fatcaData = this.props.fatcaData;
    this.state = {
      fatcaData: fatcaData
    };
  }

  render() {
    const { fatcaData } = this.state;
    var date = (!_.isUndefined(fatcaData.coyDate)) ? moment(fatcaData.coyDate).format("DD MMMM YYYY") : '';
    return (
      <div className="identification-data-container">
        <div className="element-box">
          <div>
            <h6 className="element-header base-padding">
              <div>
                <span>Subscriber identification details</span>
                <Link to="/manage_users" className="pull-right content-left">Back to users list</Link>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Subscriber / Investor Name : {!_.isUndefined(fatcaData.coyInvestorName) && fatcaData.coyInvestorName ? fatcaData.coyInvestorName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Country of Incorporation or Organization : {!_.isUndefined(fatcaData.coyIncorporationCountry) && fatcaData.coyIncorporationCountry ? fatcaData.coyIncorporationCountry.name : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Contact Details </span>
              </div>
            </h6>

            <h3>Current Residential Address</h3>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Building name : {!_.isUndefined(fatcaData.coyRegisteredBuildingName) && fatcaData.coyRegisteredBuildingName ? fatcaData.coyRegisteredBuildingName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Street : {!_.isUndefined(fatcaData.coyRegisteredStreet) && fatcaData.coyRegisteredStreet ? fatcaData.coyRegisteredStreet : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  City/Town : {!_.isUndefined(fatcaData.coyRegisteredCity) && fatcaData.coyRegisteredCity ? fatcaData.coyRegisteredCity : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  State : {!_.isUndefined(fatcaData.coyRegisteredState) && fatcaData.coyRegisteredState ? fatcaData.coyRegisteredState : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Country : {!_.isUndefined(fatcaData.coyRegisteredCountry) && fatcaData.coyRegisteredCountry.name ? fatcaData.coyRegisteredCountry.name : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Postal Code : {!_.isUndefined(fatcaData.coyRegisteredPostalCode) && fatcaData.coyRegisteredPostalCode ? fatcaData.coyRegisteredPostalCode : 'N/A'}
                </div>
              </div>
            </div>

            <h3>Mailing address</h3>
            {fatcaData.coyMailingAddressSame === 'y' &&
              <h6>Mailing address is same as residential address.</h6>
            }

            {fatcaData.coyMailingAddressSame !== 'y' &&
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      Building name : {!_.isUndefined(fatcaData.coyMailingAddressBuildingName) && fatcaData.coyMailingAddressBuildingName ? fatcaData.coyMailingAddressBuildingName : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      Street : {!_.isUndefined(fatcaData.coyMailingAddressStreet) && fatcaData.coyMailingAddressStreet ? fatcaData.coyMailingAddressStreet : 'N/A'}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div>
                      City/Town : {!_.isUndefined(fatcaData.coyMailingAddressCity) && fatcaData.coyMailingAddressCity ? fatcaData.coyMailingAddressCity : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      State : {!_.isUndefined(fatcaData.coyMailingAddressState) && fatcaData.coyMailingAddressState ? fatcaData.coyMailingAddressState : 'N/A'}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div>
                      Country : {!_.isUndefined(fatcaData.coyMailingAddressCountry) && fatcaData.coyMailingAddressCountry.name ? fatcaData.coyMailingAddressCountry.name : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      Postal Code : {!_.isUndefined(fatcaData.coyMailingAddesssPostalCode) && fatcaData.coyMailingAddesssPostalCode ? fatcaData.coyMailingAddesssPostalCode : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>


        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>FATCA Declaration of U.S. Citizenship or U.S. Residence for Tax purposes</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div>
                  {messageConstants.LABEL_CORPORATE_FATCA_CRS_DECLARATION[_.toUpper(fatcaData.coySpecifiedUsPerson)]}
                </div>
              </div>
              {fatcaData.coySpecifiedUsPerson === 'specifiedusperson' && fatcaData.coyUsTinNo !== '' &&
                <div className="col-md-12">
                  U.S. TIN : {!_.isUndefined(fatcaData.coyUsTinNo) ? fatcaData.coyUsTinNo : 'N/A'}
                </div>
              }
            </div>
          </div>
        </div>



        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Entity’s FATCA Classification</span>
              </div>
            </h6>

            {!_.isUndefined(fatcaData.coyFinancialInsitution) &&
              <div className="row">

                <div className="col-md-12">
                  Subscriber is a Financial Institution.
                </div>

                <div className="col-md-12">
                  <div>
                    {messageConstants.LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION[_.toUpper(fatcaData.coyFinancialInsitution)]}
                  </div>
                </div>

                {!_.isUndefined(fatcaData.coySubscriberGiinNo) && fatcaData.coySubscriberGiinNo !== '' &&
                  <div className="col-md-12">
                    Subscriber’s GIIN : {!_.isUndefined(fatcaData.coySubscriberGiinNo) && fatcaData.coySubscriberGiinNo ? fatcaData.coySubscriberGiinNo : 'N/A'}
                  </div>
                }
              </div>
            }

            {!_.isUndefined(fatcaData.coyFinancialInsitutionWithoutGiin) &&
              <div className="row">

                <div className="col-md-12">
                  <div>
                    {messageConstants.LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[_.toUpper(fatcaData.coyFinancialInsitutionWithoutGiin)]}
                  </div>
                </div>

                {!_.isUndefined(fatcaData.coyFinancialInsitutionWithoutGiin) && fatcaData.coyFinancialInsitutionWithoutGiin === 'sponsoredby' &&
                  <div>
                    <div className="col-md-12">
                      Sponsor’s Name: : {!_.isUndefined(fatcaData.coySponsorName) && fatcaData.coySponsorName ? fatcaData.coySponsorName : 'N/A'}
                    </div>
                    <div className="col-md-12">
                      Sponsor’s GIIN: : {!_.isUndefined(fatcaData.coySponsorGiinNo) && fatcaData.coySponsorGiinNo ? fatcaData.coySponsorGiinNo : 'N/A'}
                    </div>
                  </div>
                }
              </div>
            }

            {!_.isUndefined(fatcaData.coyNonFinancialInsitution) &&
              <div className="row">
                <div className="col-md-12">
                  Subscriber is not a Financial Institution.
                </div>
                <div className="col-md-12">
                  <div>
                    {messageConstants.LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION[_.toUpper(fatcaData.coyNonFinancialInsitution)]}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>CRS Declaration of Tax Residency</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Country of Tax Residency : {!_.isUndefined(fatcaData.coyTaxResidencyCountry) && fatcaData.coyTaxResidencyCountry.name ? fatcaData.coyTaxResidencyCountry.name : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                Taxpayer Identification Number : {!_.isUndefined(fatcaData.coyTaxPayerIdentificationNo) && fatcaData.coyTaxPayerIdentificationNo ? fatcaData.coyTaxPayerIdentificationNo : 'N/A'}
              </div>
            </div>

            {(!_.isUndefined(fatcaData.coyTaxResidencyCountry2) || !_.isUndefined(fatcaData.coyTaxResidencyCountry2)) &&
              <div className="row">
                <div className="col-md-6">
                  <div>
                    Country of Tax Residency : {!_.isUndefined(fatcaData.coyTaxResidencyCountry2) && fatcaData.coyTaxResidencyCountry2.name ? fatcaData.coyTaxResidencyCountry2.name : 'N/A'}
                  </div>
                </div>
                <div className="col-md-6">
                  Taxpayer Identification Number : {!_.isUndefined(fatcaData.coyTaxPayerIdentificationNo2) && fatcaData.coyTaxPayerIdentificationNo2 ? fatcaData.coyTaxPayerIdentificationNo2 : 'N/A'}
                </div>
              </div>
            }

            {(!_.isUndefined(fatcaData.coyTaxResidencyCountry3) || !_.isUndefined(fatcaData.coyTaxResidencyCountry3)) &&
              <div className="row">
                <div className="col-md-6">
                  <div>
                    Country of Tax Residency : {!_.isUndefined(fatcaData.coyTaxResidencyCountry3) && fatcaData.coyTaxResidencyCountry3.name ? fatcaData.coyTaxResidencyCountry3.name : 'N/A'}
                  </div>
                </div>
                <div className="col-md-6">
                  Taxpayer Identification Number : {!_.isUndefined(fatcaData.coyTaxPayerIdentificationNo3) && fatcaData.coyTaxPayerIdentificationNo3 ? fatcaData.coyTaxPayerIdentificationNo3 : 'N/A'}
                </div>
              </div>
            }
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Entity’s CRS Classification</span>
              </div>
            </h6>
          </div>

          {!_.isUndefined(fatcaData.coyCrsEntityFinancialInsitution) &&
            <div className="row">
              <div className="col-md-12">
                Subscriber is a Financial Institution : {messageConstants.LABEL_CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_FINANCIAL_INSTITUTION[_.toUpper(fatcaData.coyCrsEntityFinancialInsitution)]}
              </div>
            </div>
          }

          {!_.isUndefined(fatcaData.coyControllingPerson) &&
            <div className="row">
              <div className="col-md-12">
                Controlling Person : {fatcaData.coyControllingPerson}
              </div>
            </div>
          }

          {!_.isUndefined(fatcaData.coyEntityNonFinancialInstitution) &&
            <div className="row">
              <div className="col-md-12">
                Subscriber is a Non-Financial Institution : {messageConstants.LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[_.toUpper(fatcaData.coyEntityNonFinancialInstitution)]}
              </div>
            </div>
          }
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Declaration and Undertakings</span>
              </div>
            </h6>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div>
                Print Name : {!_.isUndefined(fatcaData.coyPrintName) ? fatcaData.coyPrintName : 'N/A'}
              </div>
            </div>
            <div className="col-md-6">
              Capacity : {!_.isUndefined(fatcaData.coyCapacity) ? fatcaData.coyCapacity : 'N/A'}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div>
                Date : {!_.isUndefined(date) ? date : 'N/A'}
              </div>
            </div>
          </div>
        </div>

        {
          !_.isUndefined(fatcaData.coySignature) && fatcaData.coySignature !== '' &&
          <div className="element-box">
            <div>
              <h6 className="element-header">
                <div>
                  <span>Signature</span>
                </div>
              </h6>

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <img src={fatcaData.coySignature} height={200} className="signature-container-img" alt="Signature" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        }

      </div >

    );
  }
}
export default CorporateFatca;