import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';
import * as messageConstants from "../../utils/Messages";

class CorporateIdentification extends React.Component {

  constructor(props) {
    super(props);
    let identificationData = this.props.identificationData;
    this.state = {
      identificationData: identificationData
    };
  }

  render() {
    const { identificationData } = this.state;
    let displayBusinessActivities = '';
    let displayFinancialProductsInvested = '';

    var inCorporationDate = (!_.isUndefined(identificationData.coyIncorportionDate)) ? moment(identificationData.coyIncorportionDate).format("DD MMMM YYYY") : '';

    if (!_.isEmpty(identificationData.coyBusinessActivities) && !_.isUndefined(identificationData.coyBusinessActivities)) {
      let businessActivities = identificationData.coyBusinessActivities;
      displayBusinessActivities = businessActivities.map((activity, mainIndex) =>
        <span key={mainIndex}>
          {messageConstants.LABEL_CORPORATE_BUSINESS_ACTIVITIES[_.toUpper(activity)] + ', '}
        </span>
      )
    }

    if (!_.isEmpty(identificationData.coyFinancialProductsInvested) && !_.isUndefined(identificationData.coyFinancialProductsInvested)) {
      let coyFinancialProductsInvested = identificationData.coyFinancialProductsInvested;
      displayFinancialProductsInvested = coyFinancialProductsInvested.map((product, mainIndex) =>
        <span key={mainIndex}>
          {messageConstants.LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[_.toUpper(product)] + ', '}
        </span>
      )
    }

    return (
      <div className="identification-data-container">

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Corporate Identification Details </span>
                <Link to="/manage_users" className="pull-right">
                  Back to users list
                </Link>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Registered name : {!_.isUndefined(identificationData.coyRegisteredName) && identificationData.coyRegisteredName ? identificationData.coyRegisteredName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Legal form : {!_.isUndefined(identificationData.coyLegalForm) && identificationData.coyLegalForm ? identificationData.coyLegalForm : 'N/A'}
                </div>
              </div>
            </div>

            {((!_.isUndefined(identificationData.coyOtherUsedTradingName) && identificationData.coyOtherUsedTradingName !== '') || (!_.isUndefined(identificationData.coyOtherUsedTradingName2) && identificationData.coyOtherUsedTradingName2 !== '')) &&
              <div>
                <h6>Other used trading names</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      Trading Name 1 : {!_.isUndefined(identificationData.coyOtherUsedTradingName) && identificationData.coyOtherUsedTradingName ? identificationData.coyOtherUsedTradingName : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      Trading Name 2 : {!_.isUndefined(identificationData.coyOtherUsedTradingName2) && identificationData.coyOtherUsedTradingName2 ? identificationData.coyOtherUsedTradingName2 : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-6">
                <div>
                  Date of incorporation : {inCorporationDate}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Place of incorporation : {!_.isUndefined(identificationData.coyPlaceOfIncorporation) && identificationData.coyPlaceOfIncorporation ? identificationData.coyPlaceOfIncorporation : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Registration number : {!_.isUndefined(identificationData.coyRegistrationNumber) && identificationData.coyRegistrationNumber ? identificationData.coyRegistrationNumber : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Fiscal residence country : {!_.isUndefined(identificationData.coyFiscalResidenceCountry) && identificationData.coyFiscalResidenceCountry ? identificationData.coyFiscalResidenceCountry.name : 'N/A'}
                </div>
              </div>
            </div>

            {displayBusinessActivities !== '' &&
              <div>
                <label><b>Business Activities</b></label>
                <div className="row">
                  <div className="col-md-12">
                    {displayBusinessActivities}
                  </div>
                </div>

                {((!_.isUndefined(identificationData.coyOtherActivities1) && identificationData.coyOtherActivities1 !== '') || (!_.isUndefined(identificationData.coyOtherActivities2) && identificationData.coyOtherActivities2 !== '')) &&
                  <div className="row">

                    <div className="col-md-6">
                      {!_.isUndefined(identificationData.coyOtherActivities1) && identificationData.coyOtherActivities1 !== '' &&
                        <div>Other Activity : {identificationData.coyOtherActivities1 ? identificationData.coyOtherActivities1 : 'N/A'}</div>
                      }
                    </div>

                    <div className="col-md-6">
                      {!_.isUndefined(identificationData.coyOtherActivities2) && identificationData.coyOtherActivities2 !== '' &&
                        <div>Other Activity : {identificationData.coyOtherActivities2 ? identificationData.coyOtherActivities2 : 'N/A'}</div>
                      }
                    </div>

                  </div>
                }
              </div>
            }


            <div className="row">
              <div className="col-md-6">
                <div>
                  <b>Is the Company part of a group? : {!_.isUndefined(identificationData.coyPartOfGroup) && identificationData.coyPartOfGroup === 'y' ? 'Yes' : 'No'}</b>
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyPartOfGroup) && identificationData.coyPartOfGroup === 'y' &&
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      Group name : {!_.isUndefined(identificationData.coyGroupName) && identificationData.coyGroupName ? identificationData.coyGroupName : 'N/A'}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      Group Country : {!_.isUndefined(identificationData.coyGroupCountry) && identificationData.coyGroupCountry ? identificationData.coyGroupCountry.name : 'N/A'}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div>
                      Website : {!_.isUndefined(identificationData.coyGroupWebsite) && identificationData.coyGroupWebsite ? identificationData.coyGroupWebsite : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-6">
                <div>
                  Is the company supervised by a Financial Services Regulator? :  {!_.isUndefined(identificationData.coySupervisedBy) && identificationData.coySupervisedBy === 'y' ? 'yes' : 'No'}
                </div>
              </div>

              {!_.isUndefined(identificationData.coySupervisedBy) && !_.isUndefined(identificationData.coySupervisedByName) && identificationData.coySupervisedBy === 'y' && identificationData.coySupervisedByName !== '' &&
                <div className="col-md-6">
                  <div>
                    Supervisor Name : {identificationData.coySupervisedByName}
                  </div>
                </div>
              }
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Name of external Auditor :  {!_.isUndefined(identificationData.coyExternalAuditor) && identificationData.coyExternalAuditor ? identificationData.coyExternalAuditor : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div>
                  Politically Exposed Person :  {!_.isUndefined(identificationData.coyPoliticallyExposedPerson) && identificationData.coyPoliticallyExposedPerson === 'y' ? 'Yes' : 'No'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyPoliticallyExposedPerson) && identificationData.coyPoliticallyExposedPerson === 'y' &&
              <div>
                {(identificationData.coyPoliticalName !== '' || identificationData.coyPoliticalFunction !== '') &&

                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        Name :  {!_.isUndefined(identificationData.coyPoliticalName) && identificationData.coyPoliticalName ? identificationData.coyPoliticalName : 'N/A'}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Function :  {!_.isUndefined(identificationData.coyPoliticalFunction) && identificationData.coyPoliticalFunction ? identificationData.coyPoliticalFunction : 'N/A'}
                      </div>
                    </div>
                  </div>
                }

                {((!_.isUndefined(identificationData.coyPoliticalName2) && identificationData.coyPoliticalName2 !== '') || (!_.isUndefined(identificationData.coyPoliticalFunction2) && identificationData.coyPoliticalFunction2 !== '')) &&
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        Name : {!_.isUndefined(identificationData.coyPoliticalName2) && identificationData.coyPoliticalName2 ? identificationData.coyPoliticalName2 : 'N/A'}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Function : {!_.isUndefined(identificationData.coyPoliticalFunction2) && identificationData.coyPoliticalFunction2 ? identificationData.coyPoliticalFunction2 : 'N/A'}
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div className="element-box">
          <div>

            <h6 className="element-header">
              <div>
                <span>Contact details </span>
              </div>
            </h6>


            <h3>Registered address details</h3>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Building name : {!_.isUndefined(identificationData.coyPermanentAddressBuildingName) && identificationData.coyPermanentAddressBuildingName ? identificationData.coyPermanentAddressBuildingName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Street No : {!_.isUndefined(identificationData.coyPermanentAddressStreetNo) && identificationData.coyPermanentAddressStreetNo ? identificationData.coyPermanentAddressStreetNo : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Street : {!_.isUndefined(identificationData.coyPermanentAddressStreet) && identificationData.coyPermanentAddressStreet ? identificationData.coyPermanentAddressStreet : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Place :  {!_.isUndefined(identificationData.coyPermanentAddressPlace) && identificationData.coyPermanentAddressPlace ? identificationData.coyPermanentAddressPlace : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Country : {!_.isUndefined(identificationData.coyPermanentAddressCountry) && identificationData.coyPermanentAddressCountry.name ? identificationData.coyPermanentAddressCountry.name : 'N/A'}
                </div>
              </div>
            </div>


            <h3>Trading address details</h3>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Building name : {!_.isUndefined(identificationData.coyTradingAddressBuildingName) && identificationData.coyTradingAddressBuildingName ? identificationData.coyTradingAddressBuildingName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Street No : {!_.isUndefined(identificationData.coyTradingAddressStreetNo) && identificationData.coyTradingAddressStreetNo ? identificationData.coyTradingAddressStreetNo : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Street : {!_.isUndefined(identificationData.coyTradingAddressStreet) && identificationData.coyTradingAddressStreet ? identificationData.coyTradingAddressStreet : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Place :  {!_.isUndefined(identificationData.coyTradingAddressPlace) && identificationData.coyTradingAddressPlace ? identificationData.coyTradingAddressPlace : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Country : {!_.isUndefined(identificationData.coyTradingAddressCountry) && identificationData.coyTradingAddressCountry.name ? identificationData.coyTradingAddressCountry.name : 'N/A'}
                </div>
              </div>
            </div>


            <h3>Mailing address</h3>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Building name : {!_.isUndefined(identificationData.coyMailingAddressBuildingName) && identificationData.coyMailingAddressBuildingName ? identificationData.coyMailingAddressBuildingName : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  PO Box : {!_.isUndefined(identificationData.coyMailingAddressPoBox) && identificationData.coyMailingAddressPoBox ? identificationData.coyMailingAddressPoBox : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Street : {!_.isUndefined(identificationData.coyMailingAddressStreet) && identificationData.coyMailingAddressStreet ? identificationData.coyMailingAddressStreet : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Street No : {!_.isUndefined(identificationData.coyMailingAddressStreetNo) && identificationData.coyMailingAddressStreetNo ? identificationData.coyMailingAddressStreetNo : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Place : {!_.isUndefined(identificationData.coyMailingAddressPlace) && identificationData.coyMailingAddressPlace ? identificationData.coyMailingAddressPlace : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Country : {!_.isUndefined(identificationData.coyMailingAddressCountry) && identificationData.coyMailingAddressCountry.name ? identificationData.coyMailingAddressCountry.name : 'N/A'}
                </div>
              </div>
            </div>

            <h3>Contact Details</h3>
            <div className="row">
              <div className="col-md-6">
                <div>
                  Telephone : {!_.isUndefined(identificationData.coyContactDetailsTelephone1) && identificationData.coyContactDetailsTelephone1 ? identificationData.coyContactDetailsTelephone1 : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Telephone : {!_.isUndefined(identificationData.coyContactDetailsTelephone2) && identificationData.coyContactDetailsTelephone2 ? identificationData.coyContactDetailsTelephone2 : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Fax : {!_.isUndefined(identificationData.coyContactDetailsFax1) && identificationData.coyContactDetailsFax1 ? identificationData.coyContactDetailsFax1 : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Fax : {!_.isUndefined(identificationData.coyContactDetailsFax2) && identificationData.coyContactDetailsFax2 ? identificationData.coyContactDetailsFax2 : 'N/A'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  Email : {!_.isUndefined(identificationData.coyContactDetailsEmail1) && identificationData.coyContactDetailsEmail1 ? identificationData.coyContactDetailsEmail1 : 'N/A'}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  Email : {!_.isUndefined(identificationData.coyContactDetailsEmail2) && identificationData.coyContactDetailsEmail2 ? identificationData.coyContactDetailsEmail2 : 'N/A'}
                </div>
              </div>
            </div>



          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Business Intentions</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div>
                  How did you learn about us and our services?  : {!_.isUndefined(identificationData.coyLearnedAboutOurService) && identificationData.coyLearnedAboutOurService ? messageConstants.LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE[_.toUpper(identificationData.coyLearnedAboutOurService)] : 'N/A'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyReferralByName) && identificationData.coyReferralByName !== '' &&
              <div className="row">
                <div className="col-md-12">
                  <div>
                    Referral Name : {identificationData.coyReferralByName ? identificationData.coyReferralByName : 'N/A'}
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div>
                  Which amount of assets do you expect to be serviced by us? : {!_.isUndefined(identificationData.coyAssetAmountExpectedToServiced) && identificationData.coyAssetAmountExpectedToServiced ? identificationData.coyAssetAmountExpectedToServiced : 'N/A'} USD
                </div>

                <div>
                  {!_.isUndefined(identificationData.coyAssetPercentageExpectedToServiced) && identificationData.coyAssetPercentageExpectedToServiced ? identificationData.coyAssetPercentageExpectedToServiced : 'N/A'}  % of total net wealth
                </div>

              </div>
            </div>


            <div className="row">
              <div className="col-md-12">
                <div>
                  What is the expected annual in/out flow turnover of assets? : {!_.isUndefined(identificationData.coyExpectedInOutTurnoverFlow) && identificationData.coyExpectedInOutTurnoverFlow ? messageConstants.LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW[_.toUpper(identificationData.coyExpectedInOutTurnoverFlow)] : 'N/A'}
                </div>
              </div>
            </div>

          </div>
        </div>


        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Wealth</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div>
                  What is the estimated global net wealth of your company? : {!_.isUndefined(identificationData.coyEstimatedGlobalNetWealth) && identificationData.coyEstimatedGlobalNetWealth ? messageConstants.LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH[_.toUpper(identificationData.coyEstimatedGlobalNetWealth)] : 'N/A'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyEstimatedGlobalNetWealth) && !_.isUndefined(identificationData.coyEstimatedGlobalNetWealthOther) && identificationData.coyEstimatedGlobalNetWealth === 'other' && identificationData.coyEstimatedGlobalNetWealthOther !== '' &&
              <div className="row">
                <div className="col-md-12">
                  <div>
                    {identificationData.coyEstimatedGlobalNetWealthOther ? identificationData.coyEstimatedGlobalNetWealthOther : 'N/A'}
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div>
                  How has this been built up? : {!_.isUndefined(identificationData.coyBuiltGlobalWealth) && identificationData.coyBuiltGlobalWealth ? messageConstants.LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[_.toUpper(identificationData.coyBuiltGlobalWealth)] : 'N/A'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyBuiltGlobalWealth) && !_.isUndefined(identificationData.coyBuiltGlobalWealthOther) && identificationData.coyBuiltGlobalWealth === 'other' && identificationData.coyBuiltGlobalWealthOther !== '' &&
              <div className="row">
                <div className="col-md-12">
                  <div>
                    {identificationData.coyBuiltGlobalWealthOther ? identificationData.coyBuiltGlobalWealthOther : 'N/A'}
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div>
                  Is your Company a Personal Investment Vehicle? : {!_.isUndefined(identificationData.coyInvestmentVehicle) && identificationData.coyInvestmentVehicle === 'y' ? 'Yes' : 'No'}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div>
                  What is the source of the Vehicle’s capital? : {!_.isUndefined(identificationData.coyVehiclesCapitalSource) && identificationData.coyVehiclesCapitalSource ? messageConstants.LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[_.toUpper(identificationData.coyVehiclesCapitalSource)] : 'N/A'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyVehiclesCapitalSourceOther) && identificationData.coyVehiclesCapitalSource === 'other' && identificationData.coyVehiclesCapitalSourceOther !== '' &&
              <div className="row">
                <div className="col-md-12">
                  <div>
                    {identificationData.coyVehiclesCapitalSourceOther ? identificationData.coyVehiclesCapitalSourceOther : 'N/A'}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span>Company’s experience and understanding of financial markets and instruments</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div>
                  How is the company’s knowledge and understanding of financial markets and products? : {!_.isUndefined(identificationData.coyUnderstandingFinancialMarket) && identificationData.coyUnderstandingFinancialMarket ? messageConstants.LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[_.toUpper(identificationData.coyUnderstandingFinancialMarket)] : 'N/A'}
                </div>
              </div>
            </div>


            {!_.isUndefined(identificationData.coyUnderstandingFinancialMarket) && !_.isUndefined(identificationData.coyUnderstandingFinancialMarketOther) && identificationData.coyUnderstandingFinancialMarket === 'other' && identificationData.coyUnderstandingFinancialMarketOther !== '' &&
              <div className="row">
                <div className="col-md-6">
                  <div>
                    Other : {identificationData.coyUnderstandingFinancialMarketOther ? identificationData.coyUnderstandingFinancialMarketOther : 'N/A'}
                  </div>
                </div>
              </div>
            }


            <div className="row">
              <div className="col-md-12">
                <div>
                  Company active on financial market : {!_.isUndefined(identificationData.coyActiveOnFinancialMarket) && identificationData.coyActiveOnFinancialMarket ? messageConstants.LABEL_CORPORATE_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[_.toUpper(identificationData.coyActiveOnFinancialMarket)] : 'N/A'}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.coyActiveOnFinancialMarket) && !_.isUndefined(identificationData.coyActiveOnFinancialMarketOther) && identificationData.coyActiveOnFinancialMarket === 'other' && identificationData.coyActiveOnFinancialMarketOther !== '' &&
              <div className="row">
                <div className="col-md-12">
                  <div>
                    Active On Financial Market Other : {identificationData.coyActiveOnFinancialMarketOther ? identificationData.coyActiveOnFinancialMarketOther : 'N/A'}
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-6">
                <div>
                  Assets Managed Before : {!_.isUndefined(identificationData.coyAssetsManagedBefore) && identificationData.coyAssetsManagedBefore ? messageConstants.LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE[_.toUpper(identificationData.coyAssetsManagedBefore)] : 'N/A'}
                </div>
              </div>

            </div>

            {!_.isUndefined(identificationData.coyAssetsManagedBefore) && !_.isUndefined(identificationData.coyAssetsManagedBeforeOther) && identificationData.coyAssetsManagedBefore === 'other' && identificationData.coyAssetsManagedBeforeOther !== '' &&
              <div className="row">
                <div className="col-md-6">
                  <div>
                    Assets Managed Before Other : {identificationData.coyAssetsManagedBeforeOther ? identificationData.coyAssetsManagedBeforeOther : 'N/A'}
                  </div>
                </div>
              </div>
            }

            {!_.isUndefined(identificationData.displayFinancialProductsInvested) && displayFinancialProductsInvested !== '' &&
              <div>
                <label><b>In which financial products have you already invested on a regular basis?</b></label>
                <div className="row">
                  <div className="col-md-12">
                    {displayFinancialProductsInvested}
                  </div>
                </div>

                {identificationData.coyFinancialProductsInvestedOther !== '' &&
                  <div>Other {identificationData.coyFinancialProductsInvestedOther ? identificationData.coyFinancialProductsInvestedOther : 'N/A'}</div>
                }


              </div>
            }

            <div className="row">
              <div className="col-md-12">
                How often does the company take investments decisions (buy/sell)? : {!_.isUndefined(identificationData.coyInvestmentDecisions) && identificationData.coyInvestmentDecisions ? messageConstants.LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[_.toUpper(identificationData.coyInvestmentDecisions)] : 'N/A'}

              </div>
            </div>


          </div>
        </div>


        {!_.isUndefined(identificationData.coySignature) && identificationData.coySignature !== '' &&
          <div className="element-box">
            <div>
              <h6 className="element-header">
                <div>
                  <span>Signature</span>
                </div>
              </h6>

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <img src={identificationData.coySignature} height={200} className="signature-container-img" alt="Signature" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        }
      </div>
    );
  }
}
export default CorporateIdentification;