import React from 'react';

class EventSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1> Event Verified </h1>
          <p> You have successfully approved blockchain event. </p>
        </div>
      </div>
    );
  }
}
export default EventSuccess