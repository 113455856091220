import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  hashrateConverter,
  getLiveStats,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import Select from "react-select";
import GlobalWorkerModal from "./GlobalWorkerModal";
import "./Earnings.css";
class GlobalWorkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workerLoading: true,
      workerTableData: [],
      workerAllData: [],
      workerPage: 1,
      tableLoading: true,
      excelData: "",
      emailData: [],
      accountData: [],
      tempData: [],
      selectedOption: "",
      selectedAccount: "",
      setDate: "",
      filterRecordCheck: false,
      setEmail: "",
      setAccount: "",
      backUpAllSize: "",
      workerSizePerPage: 20,
      workerRenderFlag: false,
      workerTotalSize: "",
      offlineWorkers: 0,
      hashRate: "",
      totalWorker: "",
      workerColumns: [],
    };
    this.props.pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.hashRateCall = this.hashRateCall.bind(this);
    this.setTableData = this.setTableData.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.giveDate = this.giveDate.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.allData = this.allData.bind(this);
    this.handleEventsChange = this.handleEventsChange.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
  }
  clearFilterRecords() {
    const { workerAllData } = this.state;
    this.setState(
      {
        earningsSizePerPage: 20,
        earningsPage: 1,
        selectedAccount: "",
        selectedOption: "",
        setDate: "",
        workerTotalSize: this.state.backUpAllSize,
        filterRecordCheck: false,
      },
      () => {
        this.setTableData(workerAllData);
      }
    );
  }

  async getFilterRecords(email, accountNumber, date) {
    try {
      const { workerAllData } = this.state;
      const freshData = workerAllData.filter((data) => {
        if (date && accountNumber) {
          const beginDate = new Date(date);
          const startDateYear = beginDate.getFullYear();
          const startDateMonth = beginDate.getMonth();
          const startDataDate = beginDate.getDate();

          const finishDate = new Date(data.last_active);

          const endDateYear = finishDate.getFullYear();
          const endDateMonth = finishDate.getMonth();
          const endDataDate = finishDate.getDate();

          if (startDateYear > endDateYear || startDateYear < endDateYear) {
            return;
          }

          if (startDateYear == endDateYear) {
            if (
              startDateMonth < endDateMonth ||
              startDateMonth > endDateMonth
            ) {
              return;
            }
            if (startDateMonth == endDateMonth) {
              if (startDataDate > endDataDate || startDataDate < endDataDate) {
                return;
              } else if (startDataDate == endDataDate) {
                return data.account === accountNumber.label ? data : "";
              }
            }
          }
        } else if (date && !accountNumber) {
          const beginDate = new Date(date);
          const startDateYear = beginDate.getFullYear();
          const startDateMonth = beginDate.getMonth();
          const startDataDate = beginDate.getDate();

          const finishDate = new Date(data.last_active);

          const endDateYear = finishDate.getFullYear();
          const endDateMonth = finishDate.getMonth();
          const endDataDate = finishDate.getDate();

          if (startDateYear > endDateYear || startDateYear < endDateYear) {
            return;
          }

          if (startDateYear == endDateYear) {
            if (
              startDateMonth < endDateMonth ||
              startDateMonth > endDateMonth
            ) {
              return;
            }
            if (startDateMonth == endDateMonth) {
              if (startDataDate > endDataDate || startDataDate < endDataDate) {
                return;
              } else if (startDataDate == endDataDate) {
                return data;
              }
            }
          }
        } else if (!date && accountNumber) {
          return data.account === accountNumber.label ? data : "";
        }
      });

      this.setState(
        {
          tempData: freshData,
          workerTotalSize: freshData.length,
          filterRecordCheck: true,
        },
        () => {
          this.setTableData(this.state.tempData);
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  }

  async changeDate(e) {
    this.setState({
      setDate: e.target.value,
    });
  }
  async changeAccount(e) {
    this.setState({
      setAccount: e.target.value,
    });
  }
  allData() {
    const api = new Api();
    const { totalWorker } = this.state;
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("user/fetchGlobalMinerWorkersCron", {
        page: 1,
        sizePerPage: totalWorker,
      })
      .then((data) => {
        if (data.data) {
          this.getTableData(data.data.workers).then((data) => {
            this.setState(
              {
                workerAllData: data,
                workerTotalSize: data.length,
                backUpAllSize: data.length,
                excelData: data,
              },
              () => {
                this.setTableData(this.state.workerAllData);
              }
            );
          });
        }
      });
  }
  handleEventsChange(selectedOption) {
    this.setState({
      selectedOption,
    });
  }
  handleAccountChange(selectedAccount) {
    this.setState({
      selectedAccount,
    });
  }
  async getTableData(tableData) {
    let workerData = tableData;
    let data = [];
    let emailData = [];
    let accountData = [];
    for (let i = 0; i < workerData.length; i++) {
      if (workerData[i].workers) {
        emailData.push({
          value: workerData[i].email,
          label: workerData[i].email,
        });
        accountData.push({
          value: workerData[i].account,
          label: workerData[i].account,
        });
        for (let j = 0; j < workerData[i].workers.length; j++) {
          let worker = {};
          worker.name = workerData[i].workers[j].worker_name;
          worker.account = workerData[i].account;
          worker.email = workerData[i].email;
          worker.realHashrate = workerData[i].workers[j].hashrate_10min;
          worker["24H"] = workerData[i].workers[j].hashrate_24hour;
          worker.reject = `${
            workerData[i].workers[j].reject_rate
              ? niceNumberDecimalDisplay(
                  workerData[i].workers[j].reject_rate * 100,
                  2
                )
              : "0.00"
          }%`;
          worker.lastActive =
            workerData[i].workers[j].last_active &&
            (String(workerData[i].workers[j].last_active).includes("PM") ||
              String(workerData[i].workers[j].last_active).includes("AM"))
              ? workerData[i].workers[j].last_active
              : moment(workerData[i].workers[j].last_active * 1000).format(
                  "DD MMM YYYY hh:mm A"
                );
          worker.status = workerData[i].workers[j].worker_status;
          data.push(worker);
        }
      }
    }

    let newUniqueAccount = [];

    const uniqueAccount = [...new Set(accountData.map((item) => item.label))]; // [ 'A', 'B']
    newUniqueAccount = uniqueAccount.map((data) => {
      return { value: data, label: data };
    });

    let newEmailData = [];
    const unique = [...new Set(emailData.map((item) => item.label))]; // [ 'A', 'B']
    newEmailData = unique.map((data) => {
      return { value: data, label: data };
    });

    this.setState({ accountData: newUniqueAccount });
    this.setState({ emailData: newEmailData });
    return data;
  }

  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  giveDate(timeStamp) {
    const timestamp = timeStamp * 1000;
    return moment(timestamp).format("DD MMM YYYY HH:MM:ss");
  }
  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  setTableData(requiredData) {
    let data = [];

    if (this.state.workerPage !== "") {
      for (
        let i = 0;
        i < this.state.workerPage * this.state.workerSizePerPage;
        i++
      ) {
        data.push(requiredData[i]);
      }
      data = data.slice(-this.state.workerSizePerPage);
    }

    const filterData = data.filter((data) =>
      data && (data.name || data.date) ? data : ""
    );
    this.setState({
      workerTableData: filterData,
      earningsTotalSize: filterData.length,
    });
  }

  async componentDidMount() {
    document.title =
      messageConstants.WORKERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    let authenticationToken = this.props.authToken;
    const stats = await getLiveStats(authenticationToken, Api);
    if (stats) {
      this.setState({
        validNum: stats.active_workers,
        invalidNum: stats.unactive_workers,
        Total: stats.totalWorkers,
      });
    }
    this.getRecords();
  }

  async hashRateCall(value) {
    const data = await hashrateConverter(value, true, true);
    return data;
  }

  validateEmail(emailAdress) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }


  async getRecords() {
    try {
      const { workerPage, workerSizePerPage } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/fetchGlobalMinerWorkersCron");

      if (response.code === 200) {
        let workerData =
          response.data && response.data.workers ? response.data.workers : "";

        const tableData = await this.getTableData(response.data.workers);

        this.setState(
          {
            workerAllData: tableData,
            workerTotalSize: tableData.length,
            totalWorker: response.data.totalWorker,
            backUpAllSize: tableData.length,
          },
          () => {
            this.setTableData(this.state.workerAllData);
            this.allData();
          }
        );
        this.setState({
          workerLoading: false,
          tableLoading: false,
          workerRenderFlag: true,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.workerSizePerPage !== sizePerPage ||
      this.state.workerPage !== page
    ) {
      this.setState(
        { workerSizePerPage: sizePerPage, workerPage: page },
        () => {
          if (this.state.filterRecordCheck === false) {
            this.setTableData(this.state.workerAllData);
          } else if (this.state.filterRecordCheck === true) {
            this.setTableData(this.state.tempData);
          }
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.setTableData(this.state.workerAllData);
        });
        return true;
      }
    }
  };

  render() {
    const {
      workerSizePerPage,
      accountData,
      workerTotalSize,
      setDate,
      workerRenderFlag,
      workerPage,
      emailData,
      workerLoading,
      validNum,
      tableLoading,
      selectedOption,
      selectedAccount,
      invalidNum,
      workerTableData,
      hashRateCall,
      excelData,
      workerAllData,
      Total,
    } = this.state;
    let hashRate;
    let _this = this;

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "name",
        text: "Name",
        formatter: function (cell, row) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "account",
        text: "Account Number",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "email",
        text: "Email",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "realHashrate",
        text: "Real Hashrate",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },

      {
        headerClasses: "custom-table-th",
        dataField: "24H",
        text: "24H",
        formatter: function (cell) {
          return <span>{cell ? cell : 0}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "reject",
        text: "Reject",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "lastActive",
        text: "Last Active",
        formatter: function (cell, row) {
          return cell;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "Status",
        formatter: function (cell, row) {
          return <span>{_.toUpper(cell)}</span>;
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="earnings-table table-responsive tb-margin">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable "
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box card-inner-padding">
              <div className="row spaceBetween">
                <div className="">
                  <h4 className="card-title-font form-table-header">
                    My Workers
                  </h4>
                </div>
                <div className="">
                  <span className="mr-1">Total</span>
                  <span
                    className="mr-3"
                    style={{
                      borderRadius: "10%",
                      backgroundColor: "#047bf8",
                      color: "white",
                      width: "25px",
                      minWidth: "25px",
                      width: "fit-content !important",
                      textAlign: "center",
                      display: "inline-block",
                      padding: "0px 10px",
                    }}
                  >
                    {Total ? Total : "0"}
                  </span>
                  <span className="mr-1">Active</span>
                  <span
                    className="mr-3"
                    style={{
                      borderRadius: "10%",
                      backgroundColor: "#2bad2b",
                      color: "white",
                      // height: "25px",
                      width: "25px",
                      minWidth: "25px",
                      width: "fit-content !important",
                      textAlign: "center",
                      display: "inline-block",
                      padding: "0px 10px",
                    }}
                  >
                    {validNum ? validNum : "0"}
                  </span>

                  <span className="mr-1">Inactive</span>
                  <span
                    className="mr-3"
                    style={{
                      borderRadius: "10%",
                      backgroundColor: "red",
                      color: "white",
                      width: "25px",
                      minWidth: "25px",
                      width: "fit-content !important",
                      textAlign: "center",
                      display: "inline-block",
                      padding: "0px 10px",
                    }}
                  >
                    {invalidNum ? invalidNum : "0"}
                  </span>
                </div>
              </div>
              <div className="mt-3 row rightBtn">
                <button
                  className="btn btn-primary"
                  onClick={() => this.showModal()}
                >
                  Export Data
                </button>
                <GlobalWorkerModal
                  {...this.props}
                  {...this.state}
                  earningsDatas={excelData}
                  onCloseModal={this.onCloseModal}
                  onInputValueChange={this.onchange}
                  AddMinerRequest={this.AddMinerRequest}
                />
              </div>
              <div className="earnings-table table-responsive">
                <div>
                  <h5 className="form-header">Filter Users</h5>
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByDate"
                          id="filterByDate"
                          placeholder="Date"
                          type="date"
                          onChange={this.changeDate}
                          value={setDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <Select
                        value={selectedAccount}
                        options={accountData}
                        placeholder="Select Account"
                        isMulti={false}
                        onChange={this.handleAccountChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="manageuser-btn-group btn-left">
                  <button
                    className="btn btn-primary "
                    type="button"
                    onClick={() => {
                      this.getFilterRecords(
                        selectedOption,
                        selectedAccount,
                        setDate
                      );
                    }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    Clear
                  </button>
                </div>
                {tableLoading && (
                  <div className="text-center">
                    <span id="loading" color="#047bf8" />
                  </div>
                )}
                {workerRenderFlag === true && (
                  <WorkerRemoteAll
                    data={workerTableData}
                    page={workerPage}
                    sizePerPage={workerSizePerPage}
                    totalSize={this.state.workerTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalWorkers;
