import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { API_CONTRACTADDRESS } from "../../services/api-config";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  niceNumberDecimalDisplay,
  ethAddressDisplay,
  sendTokens,
} from "../../utils/Util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import moment from "moment";

class ManageDecTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      txHash: "",
      sizePerPage: 10,
      loading: false,
      statusValuesList: ["p", "a", "w", "u", "r", "c"],
      statusList: [
        { value: "p", label: "Pending" },
        { value: "a", label: "Complete" },
        { value: "w", label: "Waiting Payment"},
        { value: "u", label: "Under Review"},
        { value: "r", label: "Rejected"},
        { value: "c", label: "Cancelled"},
      ],
      status: "",
      statusLabel: "",
      filterByName: "",
      email: "",
      source: "",
      sourceLabel: "",
      userName: "",
      purposeId: "",
      sourceList: [
        { value: "DEC", label: "Stripe" },
        { value: "Subscription", label: "Subscription" },
      ],
    };
  }

  filterRecords = () => {
    this.getRecords();
  }
  clearFilterRecords = () => {
    this.setState(
      {
        page: 1,
        sizePerPage: 10,
        email: "",
        status: "",
        source: "",
        purposeId: "",
        statusLabel:"",
        sourceLabel:"",
        purposeIdLabel: "",
      },
      () => {
        this.getRecords();
      }
    );
  }
  changeSelection = (e, name) => {
    console.log("e.name", name);
    this.setState({
      [name]: e.value,
      [`${name}Label`]: e.label,
    });
  };
  refreshCurrenttable = () => {
    this.getRecords();
  };

  copyWhiteListAddress = () => {
    let msg = "Wallet Address copied successfully!";
    toast.success(msg);
  };

  copyTokenPurposeId = () => {
    let msg = "Purpose ID for tokens copied successfully!";
    toast.success(msg);
  };

  sendTokens = async (
    listedTokenContractAddress,
    receivingEthAddress,
    tokens,
    row
  ) => {
    if (window.ethereum === undefined) {
      console.log(window);
      return;
    }
    await window.ethereum.enable();
    const request = row.invoiceId ? "invoiceId" : "requestId";
    if (listedTokenContractAddress && receivingEthAddress && tokens) {
      const response = await sendTokens(
        listedTokenContractAddress,
        receivingEthAddress,
        tokens
      );
      const hash = response ? response.hash : "";
      if (response && hash) {
        const api = new Api();
        const authenticationToken = this.props.authToken;
        const response = await api
          .setToken(authenticationToken)
          .create("miner/issueDecToken", {
            txHash: hash,
            [request]: row.invoiceId ? row.invoiceId : row._id,
          });
        if (response.code == 200) {
          toast.success(response.message);
          this.getRecords();
        } else {
          toast.error(response.message);
          this.getRecords();
        }
      }
    } else {
      const erroMsg = !receivingEthAddress
        ? "User's wallet address is not whitelisted"
        : !tokens || tokens <= 0
        ? "Please enter a valid token."
        : "";
      toast.error(erroMsg);
      this.setState({ decAmount: "", recieverAddress: "" });
    }
  };

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getRecords = async () => {
    const api = new Api();
    const { sizePerPage, page, email, status, source, userName, purposeId } =
      this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true, authToken: authenticationToken });
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("miner/viewDecToken", {
          sizePerPage: sizePerPage,
          page: page,
          filterByEmail:email,
          filterByStatus:status,
          filterByName:userName,
          filterBySource:source,
          filterByPurposeId:purposeId,
        });

      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.decRequests,
            totalSize: response.data.totalDecRequestCount,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  };

  componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_KYC_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      loading,
      status,
      statusLabel,
      statusValuesList,
      statusList,
      source,
      sourceLabel,
      sourceList,
      email,
      userName,
      purposeId,
    } = this.state;
    let _self = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "name",
        text: "NAME",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.userId.fullName}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "EMAIL",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.userId.email}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "outstandingTokens",
        text: "OUTSTANDING TOKENS",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{niceNumberDecimalDisplay(cell, 0)}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "issuedTokens",
        text: "ISSUED TOKENS",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{niceNumberDecimalDisplay(cell, 0)}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "walletAddress",
        text: "WHITELISTED ADDRESS",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row && row.userId.receivingEthAddress
                  ? ethAddressDisplay(row.userId.receivingEthAddress)
                  : ""}
                {row && row.userId.receivingEthAddress && (
                  <CopyToClipboard
                    text={row.userId.receivingEthAddress}
                    onCopy={() => _self.copyWhiteListAddress()}
                  >
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2"
                      aria-hidden="true"
                    ></i>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "source",
        text: "Source",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.invoiceId ? "Subscription" : "Stripe"}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "tokenPurposeId",
        text: "Purpose ID",
        formatter: function (cell, row) {
          const purposeId = (row && row.tokenPurposeId ?
            row.tokenPurposeId : "");
          return (
            <div className="text-left">
              <div>
                {purposeId}
                {purposeId && (
                  <CopyToClipboard
                    text={purposeId}
                    onCopy={() => _self.copyTokenPurposeId()}
                  >
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2"
                      aria-hidden="true"
                    ></i>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "paymentStatus",
        text: "STATUS",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (!statusValuesList.includes(row.status)) {
             label = "Unknown";
             className = "badge-danger";
          } else if (row.status === "a") {
            label = "Complete";
            className = "badge-success";
          } else if (row.status === "w") {
            label = "Wait Pay";
          }
          return (
            <div>
              <div className="text-center">
                <div className="d-inline-block">
                  <span className={"badge " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="row justify-content-center">
              <div className="d-inline-block">
                <div className="btn-group mr-1 mb-1">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn default-border-btn dropdown-toggle"
                    data-toggle="dropdown"
                    disabled={row.status !== "p" ? true : false}
                    id="dropdownMenuButton6"
                    type="button"
                  >
                    Action
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton6"
                    className="dropdown-menu"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        _self.sendTokens(
                          API_CONTRACTADDRESS,
                          row.userId.receivingEthAddress,
                          row.outstandingTokens,
                          row
                        )
                      }
                    >
                      Issue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];
    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          key={Math.random()}
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
          })}
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Invoices Requests</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="email"
                          placeholder="Enter Email"
                          type="text"
                          onChange={this.onchange}
                          value={email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="userName"
                          placeholder="Enter Name"
                          type="text"
                          onChange={this.onchange}
                          value={userName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-sm-2">
                      <div className="form-group pr-1">
                        <Select
                          className="w-100"
                          value={{
                            value: status,
                            label: statusLabel || "Select Status",
                          }}
                          options={statusList}
                          onChange={(e) => this.changeSelection(e, "status")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group pr-1">
                        <Select
                          className="w-100"
                          value={{
                            value: source,
                            label: sourceLabel || "Select Source",
                          }}
                          options={sourceList}
                          onChange={(e) => this.changeSelection(e, "source")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="purposeId"
                          placeholder="Paste Token Purpose ID"
                          type="text"
                          onChange={this.onchange}
                          value={purposeId}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box horizontal-scroll">
                <h5 className="form-header">
                  <span>Manage DEC Transfer Requests</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div key={Math.random()}>
                  {renderFlag === true && (
                    <RemoteAll
                      key={Math.random()}
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageDecTransfer;
