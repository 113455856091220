import React from 'react';
import AdminCustomHeader from './AdminCustomHeader';
import AdminMenu from './AdminMenu';
import AdminTopBar from './AdminTopBar';
import { ToastContainer } from 'react-toastify';
class Admincustombasepages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        document.body.classList.add('menu-position-side');
        document.body.classList.add('menu-side-left');
        document.body.classList.add('full-screen');
        document.body.classList.add('with-content-panel');
        document.body.classList.add('admin-panel');
    }
    render() {
        return (
            <div className="all-wrapper with-side-panel solid-bg-all admin-container">
                <ToastContainer hideProgressBar={true} />
                <div className="">
                    <AdminCustomHeader authUserInfo={this.props.authUserInfo} />
                    <div className="layout-w">
                        <AdminMenu authUserInfo={this.props.authUserInfo} />
                        <div className="content-w">
                            <AdminTopBar authUserInfo={this.props.authUserInfo} />
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Admincustombasepages;