import React from "react";
import Api from "../../services/api";

import IndividualIdentification from "./IndividualIdentificationDetail";
import CorporateIdentification from "./CorporateIdentificationDetail";

class AdminUserIdentificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identificationType: '',
      identificationData: ''
    };
  }

  async componentWillMount() {
    const api = new Api();

    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }

    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;

    try {
      const identificationResponse = await api.setToken(authenticationToken).get("admin/identification/details", { 'userId': userId });
      if (identificationResponse.code === 200) {
        this.setState({
          identificationType: identificationResponse.data.identificationType,
          identificationData: identificationResponse.data
        });
      }
      if (typeof (this.props.pageProgress) === 'function') {
        this.props.pageProgress('remove');
      }
    }
    catch (error) {
      if (typeof (this.props.pageProgress) === 'function') {
        this.props.pageProgress('remove');
      }
    }
  }

  render() {
    const { identificationType, identificationData } = this.state;

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">

              {identificationType === 'i' &&
                <IndividualIdentification identificationData={identificationData} />
              }

              {identificationType === 'ci' &&
                <CorporateIdentification identificationData={identificationData} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminUserIdentificationDetail;