import React from 'react';

class EventError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div className="section-heading centered event-success-message">
          <h1> Event Verification Failed </h1>
          <p> This blockchain event is either expired or details are removed by admin. </p>
        </div>
      </div>
    );
  }
}
export default EventError