import React from "react";
import Api from "../../services/api";
import { toast } from "react-toastify";
import _ from "lodash";
import Select from "react-select";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as messageConstants from "../../utils/Messages";

class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: EditorState.createEmpty(),
      panelList: [
        { value: "m", label: "Miner" },
        { value: "i", label: "Investor" },
        { value: "s", label: "Supplier" },
        { value: "d", label: "Dealer" },
        { value: "all", label: "All" },
      ],
      kycStatusList: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
        { value: "both", label: "Both" },
      ],
      link: "",
      role: "",
      roleLabel: "",
      kycStatus: "",
      selectedKYCLabel: "",
    };
  }
  onChange = (e) => {
    this.setState({ [`${e.target.name}`]: e.target.value });
  };
  async componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onEditorStateChange = (e) => {
    this.setState({ message: e });
  };
  changePanel = (e) => {
    this.setState({
      role: e.value,
      roleLabel: e.label,
    });
  };
  changeKYC = (e) => {
    this.setState({
      kycStatus: e.value,
      selectedKYCLabel: e.label,
    });
  };
  componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }
  sendEmail = async () => {
    const { subject, message, role, link, kycStatus } = this.state;
    if (!subject || !message || !role) {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      return;
    }
    const requestArr = [{ subject, message, role, link, kycStatus }];
    const api = new Api();
    const authenticationToken = this.props.authToken;
    let requestObject = {};
    requestArr.forEach((item, index) => {
      requestObject.subject = item.subject;
      requestObject.message = draftToHtml(
        convertToRaw(item.message.getCurrentContent())
      );

      if (item.role !== "all") {
        requestObject.role = item.role;
      }
      if (!!item.link) {
        requestObject.link = item.link;
      }
      if (item.kycStatus !== "both" && !!item.kycStatus) {
        requestObject.kycStatus = item.kycStatus;
      }
    });
    const response = await api
      .setToken(authenticationToken)
      .create("admin/sendMail", requestObject);
    if (response.code === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  render() {
    const {
      role,
      roleLabel,
      panelList,
      kycStatusList,
      kycStatus,
      selectedKYCLabel,
      message,
      subject,
      link,
    } = this.state;

    return (
      <div className="adminDashboardContainer">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                  <h6 className="element-header">Compose Email</h6>
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Subject<span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Subject"
                          type="text"
                          onChange={this.onChange}
                          name="subject"
                          value={subject}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Panel <span className="required-sign">*</span>
                        </label>
                        <Select
                          value={{
                            value: role,
                            label: roleLabel || "Select Panel",
                          }}
                          options={panelList}
                          onChange={(e) => {
                            this.changePanel(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="">KYC Verified</label>
                        <Select
                          value={{
                            value: kycStatus,
                            label: selectedKYCLabel || "Select Status",
                          }}
                          options={kycStatusList}
                          onChange={(e) => {
                            this.changeKYC(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label htmlFor=""> Link </label>
                        <input
                          className="form-control"
                          placeholder="Link"
                          type="text"
                          onChange={this.onChange}
                          name="link"
                          value={link}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Editor
                        editorState={message}
                        toolbar={{
                          image: {
                            defaultSize: {
                              height: "100%",
                              width: "100%",
                            },
                          },
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName=" editor-wrapper"
                        editorClassName="content-wrapper"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.sendEmail}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SendEmail;
