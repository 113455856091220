import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import TransactionOperationModal from "./TransactionOperationModal";
import DetailModal from "./DetailModal";

import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";

class ManageWithdrawals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByTxnNo: "",
      filterByName: "",
      filterByEmail: "",
      loading: false,
      modalOpen: false,
      detailModalOpen: false,
      requestDetails: [],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.tokenTransactionOperation = this.tokenTransactionOperation.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.updateWithdrawalRequestTransaction =
      this.updateWithdrawalRequestTransaction.bind(this);
    this.onDetailsCloseModal = this.onDetailsCloseModal.bind(this);
    this.openDetailsModal = this.openDetailsModal.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByTxnNo, filterByName, filterByEmail } =
      this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/withdrawal/request/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
          filterByTxnNo: filterByTxnNo,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.withdrawalRequests,
            totalSize: response.data.totalWithdrawalRequestCount,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onCloseModal() {
    this.setState({
      modalOpen: false,
    });
  }

  onDetailsCloseModal() {
    this.setState({
      detailModalOpen: false,
      requestDetails: [],
    });
  }

  openDetailsModal(details) {
    this.setState({
      detailModalOpen: true,
      requestDetails: details,
    });
  }

  async transactionOperation(mandateId, type) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("admin/mandate/update", {
        mandateId: mandateId,
        type: type,
      });
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }

  async tokenTransactionOperation(mandateId) {
    const api = new Api();
    if (mandateId) {
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/mandate/token/transfer/update", {
          mandateId: mandateId,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.getRecords();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  }

  async componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_WITHDRAWAL_REQUESTS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByTxnNo: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  openModal(withdrawalRequestId = "") {
    if (!_.isUndefined(withdrawalRequestId)) {
      this.setState({
        modalOpen: true,
        withdrawalRequestId,
      });
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  async updateWithdrawalRequestTransaction() {
    let { txHash, withdrawalRequestId, adminNote, type } = this.state;
    let authenticationToken = this.props.authToken;

    if (!_.isUndefined(type)) {
      this.setState({ updateButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/withdrawal/request/status/update", {
          txHash,
          withdrawalRequestId,
          adminNote,
          type,
        });
      if (response) {
        this.setState({
          updateButtonLoading: false,
          modalOpen: false,
        });
        this.getRecords();
      }
      if (response.code === 200) {
        this.setState({
          type: "",
          userId: "",
          transactionHash: "",
          transactionAmount: "",
          usdDepositeId: "",
          transactionNote: "",
        });
        await this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please select valid status.";
      toast.error(msg);
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByTxnNo,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn #",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Name",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId && row.userId.fullName
                  ? row.userId.fullName
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId && row.userId.originalEmail
                  ? row.userId.originalEmail
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "phone",
        text: "Phone No",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId &&
                row.userId.phoneNumber &&
                row.userId.phoneNumber !== ""
                  ? row.userId.phoneNumber
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amount",
        text: "Amount",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amount,
                  row.currencyType
                )}
              >
                {niceNumberDecimalDisplay(row.amount, 2)} {row.currencyType}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        formatter: function (cell) {
          let labelClassName =
            cell === "p"
              ? "badge-warning"
              : cell === "a"
              ? "badge-primary"
              : "badge-danger";
          let label =
            cell === "p" ? "Pending" : cell === "a" ? "Executed" : "Rejected";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{moment(row.createdOnUTC).format("DD MMM YYYY HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <button
                    className="btn btn-xs btn-sm btn-primary"
                    onClick={() => _self.openDetailsModal(row)}
                  >
                    Details
                  </button>
                  {row.status === "p" && (
                    <button
                      className="ml-0 mt-1 btn btn-success btn-xs btn-sm"
                      type="button"
                      onClick={() => _self.openModal(row._id)}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper filter-element-withdrawal-request-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Withdrawal Requests</h5>

                <form className="form-inline">
                  <label className="sr-only"> Txn #</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByTxnNo"
                    id="filterByTxnNo"
                    placeholder="Txn No"
                    type="text"
                    onChange={this.onchange}
                    value={filterByTxnNo}
                  />
                  <label className="sr-only"> Name</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByName"
                    id="filterByName"
                    placeholder="Full Name"
                    type="text"
                    onChange={this.onchange}
                    value={filterByName}
                  />
                  <label className="sr-only"> Email</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Email Address"
                    type="text"
                    name="filterByEmail"
                    id="filterByEmail"
                    onChange={this.onchange}
                    value={filterByEmail}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Withdrawal Requests</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TransactionOperationModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          updateWithdrawalRequestTransaction={
            this.updateWithdrawalRequestTransaction
          }
          handleRadioChange={this.handleRadioChange}
        />

        <DetailModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onDetailsCloseModal}
        />
      </div>
    );
  }
}
export default ManageWithdrawals;
