import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
class AdminChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    };
    this.onChange = this.onChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  async componentDidMount() {
    document.title = messageConstants.ADMIN_CHANGE_PASSWORD_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async updatePassword(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      currentPassword,
      newPassword,
      confirmNewPassword
    } = this.state;
    if (!_.isUndefined(currentPassword) && currentPassword !== '' && !_.isUndefined(newPassword) && newPassword !== '' && !_.isUndefined(confirmNewPassword) && confirmNewPassword !== '') {
      this.setState({
        buttonLoading: true
      });
      const api = new Api();
      const response = await api.setToken(authenticationToken).create("admin/change_password", {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword
      });
      if (response) {
        this.setState({
          buttonLoading: false
        });
      }
      if (response.code === 200) {
        toast.success(response.message);
        this.props.history.push("/dashboard");
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    const { buttonLoading } = this.state;
    let buttonDisabled = (buttonLoading === true) ? true : false;
    return (
      <div className="adminSiteSettingsContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header">Change Password</h6>
                    <div className="row">

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Current Password*</label>
                          <input className="form-control" placeholder="Current Password" type="password" name="currentPassword" onChange={this.onChange} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>New Password*</label>
                          <input className="form-control" placeholder="New Password" type="password" name="newPassword" onChange={this.onChange} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Current new password*</label>
                          <input className="form-control" placeholder="Enter Current new password" type="password" name="confirmNewPassword" onChange={this.onChange} />
                        </div>
                      </div>
                    </div>

                    <div className="form-buttons-w d-flex justify-content-center">
                      <button className="btn btn-primary" type="submit" onClick={this.updatePassword} disabled={buttonDisabled}> Submit {buttonLoading && (
                        <i className="fa-spin fa fa-spinner text-white m-1" />
                      )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminChangePassword;