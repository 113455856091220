import Web3 from "web3";
import { toFixedCustom } from "./Util";
import { contractReadApiUrl } from "../services/api-config";
import { toast } from "react-toastify";

class ETH {
  constructor() {
    // https://github.com/MetaMask/faq/blob/master/detecting_metamask.md#web3-deprecation
    // Checking if Web3 has been injected by the browser (Mist/MetaMask)
    this.web3provider = null;
    if (
      typeof window.ethereum !== "undefined" ||
      typeof window.web3 !== "undefined"
    ) {
      const provider = window.ethereum || window.web3.currentProvider;
      this.web3 = new Web3(provider);
    } else {
      this.web3 = null;
    }
    this.isLoggedin = true;
    this.tokenContract = null;
    this.tokenSymbol = null;
    this.tokenDecimals = null;
    this.tokenName = null;
    this.errorMessage = "";
    this.accounts = null;
    this.contractAddress = null;
    this.currentAccount = null;
    this.currentAccountBalance = null;
    this.timer = null;
    this.reload = false;
    this.networkId = 1;
    this.setUp();

    setInterval(async () => {
      if (this.web3 !== null) {
        const accounts = await this.web3.eth.getAccounts();
        const currentAccount = accounts.length > 0 ? accounts[0] : null;
        if (this.currentAccount !== currentAccount) {
          this.reloadInformation();
        }
      }
    }, 1000);
  }

  async setUp() {
    if (!this.web3) {
      return;
    }
    await this.getNetwork();
    await this.getUserAccounts();
  }

  async reloadInformation() {
    this.reload = true;
    await this.setUp();
    // if (this.contractAddress) {
    //   await this.connect()
    // }
  }

  async setContractAddress(contractAddress) {
    if (contractAddress !== this.contractAddress) {
      this.contractAddress = contractAddress;
      await this.connect();
    }
  }

  reloadDone() {
    this.reload = false;
  }

  async login() {
    return new Promise(async (resolve, reject) => {
      if (this.web3 && !this.isLoggedin) {
        try {
          await window.ethereum.enable();
          await this.reloadInformation();
          resolve(true);
        } catch (e) {
          await this.reloadInformation();
          reject(e);
        }
      } else {
        reject(new Error("error"));
      }
    });
  }

  async connect() {
    if (!this.contractAddress || !this.contractReadAbiUrl || !this.web3) {
      if (!this.web3) {
        toast("Unable to connect to Wallet: Web3 not initialized");
      }
      return;
    }
    const response = await fetch(
      `${contractReadApiUrl}?module=contract&action=getabi&address=${this.contractAddress}`
    );
    const json = await response.json();
    if (json.status === "0") {
      this.tokenContract = null;
      this.errorMessage = json.result;
    } else {
      const contractABI = JSON.parse(json.result);
      this.tokenContract = new this.web3.eth.Contract(
        contractABI,
        this.contractAddress
      );
      await this.getTokenDecimal();
      await this.getTokenSybmol();
      await this.getTokenName();
    }
  }

  isAvailable() {
    return this.web3 !== null;
  }

  isTokenContractAvailable() {
    return this.tokenContract !== null;
  }

  async getNetwork() {
    this.networkId = await this.web3.eth.net.getId();
  }

  async getUserAccounts() {
    this.accounts = await this.web3.eth.getAccounts();
    this.currentAccount = this.accounts.length ? this.accounts[0] : null;
    this.isLoggedin = Boolean(this.accounts.length);
    await this.getUserAccountTokenBalance();
  }

  async getTokenSybmol() {
    this.tokenSymbol = await this.tokenContract.methods.symbol().call();
  }

  async getTokenDecimal() {
    this.tokenDecimals = await this.tokenContract.methods.decimals().call();
  }

  async getTokenName() {
    this.tokenName = await this.tokenContract.methods.name().call();
  }

  async getUserAccountTokenBalance() {
    if (!this.currentAccount) {
      return;
    }
    if (!this.tokenContract) {
      return;
    }
    const result = await this.tokenContract.methods
      .balanceOf(this.currentAccount)
      .call();
    this.currentAccountBalance =
      this.currentAccount && this.tokenDecimals
        ? result / 10 ** this.tokenDecimals
        : null;
  }

  transferTokens(addressToTransfer, numberOfTokens) {
    return new Promise((resolve, reject) => {
      if (this.currentAccount && this.tokenContract && this.tokenDecimals) {
        const tokenAmount = toFixedCustom(
          numberOfTokens * 10 ** this.tokenDecimals
        );
        this.tokenContract.methods
          .transfer(addressToTransfer, tokenAmount)
          .send(
            {
              from: this.currentAccount,
            },
            (e, txHash) => {
              if (!e && txHash) {
                resolve(txHash);
              } else {
                reject(e);
              }
            }
          );
      } else {
        reject(new Error("error"));
      }
    });
  }
}
export default new ETH();
