import React from "react";
import { toast } from "react-toastify";
import Api from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import * as messageConstants from "../../utils/Messages";
class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierCounter: 0,
      traderCounter: 0,
      investorCounter: 0,
      dealerCounter: 0,
      pendingKycRequestCounter: 0,
      ethReceived: 0,
      tokensSold: 0,
      totalTokens: 0,
      ETHTotalBalance: 0,
      BTCTotalBalance: 0,
      BCHTotalBalance: 0,
      costPerTeraHash: "",
      CARDTotalBalance: 0,
      LTCTotalBalance: 0,
      poolFees: "",
      rateOfElectricity: "",
      transactionFeeRate: 0,
    };
  }

  getRecords = async () => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/getAdminDashboardInfo");
      if (response.code === 200 && response.data) {
        this.setState({
          investorCounter: response.data.investorCounter,
          supplierCounter: response.data.supplierCounter,
          traderCounter: response.data.minerCounter,
          dealerCounter: response.data.dealerCounter,
          pendingKycRequestCounter: response.data.pendingKycCounter,
          poolFees: response.data.feeAndROE && response.data.feeAndROE.poolFee,
          costPerTeraHash:
            response.data.feeAndROE && response.data.feeAndROE.costPerTeraHash,
          rateOfElectricity:
            response.data.feeAndROE &&
            response.data.feeAndROE.rateOfElectricity,
          transactionFeeRate:
            response.data.feeAndROE &&
            response.data.feeAndROE.transactionFeeRate,
        });
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    }
  };

  componentWillMount() {
    if (localStorage && !localStorage.getItem("hardReloadStatus")) {
      this.hardReloadAlert();
    }

    document.title =
      messageConstants.ADMIN_DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    this.getRecords();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
  }
  hardReloadAlert = () => {
    let question =
      'A new version of PermianChain Miner is now available. Click  the "Okay" button below and then press "CTRL + SHIFT + R" on your keyboard to update to the latest version';

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h3 className="text-bold text-center">{question}</h3>
              <div className="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    const hardReloadStatus = { hardReloadStatus: "true" };
                    localStorage.setItem(
                      "hardReloadStatus",
                      JSON.stringify(hardReloadStatus)
                    );
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };
  updateFeeSettings = async (event) => {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const { poolFees, rateOfElectricity, costPerTeraHash, transactionFeeRate } =
      this.state;
    if (poolFees) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/feeAndRateofElectricity", {
          poolFee: poolFees,
          rateOfElectricity,
          costPerTeraHash,
          transactionFeeRate,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      supplierCounter,
      traderCounter,
      investorCounter,
      dealerCounter,
      rateOfElectricity,
      poolFees,
      costPerTeraHash,
      transactionFeeRate,
    } = this.state;
    return (
      <div className="adminDashboardContainer">
        <div className="content-panel-toggler">
          <i className="os-icon os-icon-grid-squares-22"></i>
          <span>Sidebar</span>
        </div>
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="element-wrapper">
                  <div className="element-actions d-none">
                    <form className="form-inline justify-content-sm-end">
                      <select className="form-control form-control-sm rounded">
                        <option value="Pending">Today</option>
                        <option value="Active">Last Week</option>
                        <option value="Cancelled">Last 30 Days</option>
                      </select>
                    </form>
                  </div>
                  <h6 className="element-header">Statistics</h6>
                  <div className="element-content">
                    <div className="row">
                      <div className="col-sm-6 col-xxxl-3">
                        <span className="element-box el-tablo">
                          <div className="label">Investors</div>
                          <div className="value">{investorCounter}</div>
                        </span>
                      </div>
                      <div className="col-sm-6 col-xxxl-3">
                        <span className="element-box el-tablo">
                          <div className="label">Suppliers</div>
                          <div className="value">{supplierCounter}</div>
                        </span>
                      </div>
                      <div className="col-sm-6 col-xxxl-3">
                        <span className="element-box el-tablo">
                          <div className="label">Miners</div>
                          <div className="value">{traderCounter}</div>
                        </span>
                      </div>
                      <div className="col-sm-6 col-xxxl-3">
                        <span className="element-box el-tablo">
                          <div className="label">Dealers</div>
                          <div className="value">{dealerCounter}</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header">Fees Settings</h6>
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Fee*</label>
                          <input
                            className="form-control"
                            placeholder="Pool Fees"
                            type="number"
                            name="poolFees"
                            onChange={this.onChange}
                            value={poolFees}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Rate of Electricity</label>
                          <input
                            className="form-control"
                            placeholder="Rate of Electricity"
                            type="number"
                            name="rateOfElectricity"
                            onChange={this.onChange}
                            value={rateOfElectricity}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Cost per Terahash</label>
                          <input
                            className="form-control"
                            placeholder="Cost per Terahash"
                            type="number"
                            name="costPerTeraHash"
                            onChange={this.onChange}
                            value={costPerTeraHash}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Transaction Fee %</label>
                          <input
                            className="form-control"
                            placeholder="Transaction Fee"
                            type="number"
                            name="transactionFeeRate"
                            onChange={this.onChange}
                            value={transactionFeeRate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-1">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.updateFeeSettings}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminDashboard;
