import React, { Component } from 'react';
import adminLogoImg from "../../assets/img/admin_logo.png";
import { Link } from "react-router-dom";
class AdminTopBar extends Component {

  constructor(props) {
    super(props);
    this.interval = '';
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="top-bar color-scheme-transparent admin-top-bar">
          <div className="top-menu-controls">
            <div className="logged-user-w">
              <div className="logged-user-i">
                <div className="avatar-w">
                  <img alt="" src={adminLogoImg} />
                </div>
                <div className="logged-user-menu color-style-bright">
                  <div className="logged-user-avatar-info">
                    <div className="avatar-w">
                      <img alt="" src={adminLogoImg} />
                    </div>
                    <div className="logged-user-info-w">
                      <div className="logged-user-name">
                        Admin
                        </div>
                      <div className="logged-user-role">
                        Administrator
                        </div>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="os-icon os-icon-wallet-loaded"></i>
                  </div>
                  <ul>
                    <li>
                      <Link to="/manage_kyc/">
                        <i className="os-icon os-icon-others-43"></i><span>Manage KYC</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/change_password/">
                        <i className="fa fa-key"></i><span>Change Password</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logout/">
                        <i className="os-icon os-icon-others-43"></i><span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminTopBar;