import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import Api from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import browseFileImg from "../../assets/img/browse_file.png?v1";
import { niceNumberDecimalDisplay } from "../../utils/Util";

class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listingDetails: {},
      kycDocumentTypeListTypes: "",
      listingFiles: "",
      objectIdRef: "",
      listingEventId: null,
      listingEventName: "Select Event",
    };
    this.listingOperation = this.listingOperation.bind(this);
    this.listingStatusChange = this.listingStatusChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.imageExtension = ["png", "jpg", "jpeg", "gif", "bmp"];
  }

  async componentWillMount() {
    const api = new Api();

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    //const { sizePerPage, page } = this.state;
    let authenticationToken = this.props.authToken;
    let listingId = this.props.match.params.id;
    if (!_.isUndefined(listingId)) {
      this.state.decPrice = await this.getPriceDEC();
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("admin/listing/details", { listingId: listingId });
        if (response.code === 200) {
          this.setState(
            {
              listingDetails: response.data.listingDetail,
              documentTypeListTypes: response.data.documentTypeListTypes,
              listingFiles: response.data.listingFiles,
              objectIdRef: response.data.objectIdRef,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
  }

  async getPriceDEC() {
    let decPrice = 0.05;
    const api = new Api();
    const priceResponse = await api
      .setToken(this.props.authToken)
      .get("admin/StripePrice", {
        type: "DEC",
      });
    if (
      priceResponse.code === 200 &&
      priceResponse.data
    ) {
      if (priceResponse.data.stripePrice) {
        decPrice = Number(priceResponse.data.stripePrice.unitPrice);
      } else {
        decPrice = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get DEC price, using default $0.05");
    }
    return decPrice;
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  listingOperation(listingId, status, type) {
    let label = type === "approve" ? "accept" : "reject";
    let trailer = "";
    if (type === "approve" && status === "PROCESSING") {
      label = "transition";
      trailer = " to pending state"
    } else if (type === "delist") {
      label = "transition";
      trailer = " to the de-listed state";
    } else if (type == "relist") {
      label = "transition";
      trailer = " back to the pending state";
      type = "approve";
    }
    let _self = this;
    let buttons = "";
    if (type === "approve") {
      buttons = [
        {
          label: "Accept",
          onClick: () => _self.listingStatusChange(listingId, "approve"),
        },
        {
          label: "Cancel",
        },
      ];
    } else if (type === "reject") {
      buttons = [
        {
          label: "Reject",
          onClick: () => _self.listingStatusChange(listingId, "reject"),
        },
        {
          label: "Cancel",
        },
      ];
    } else if (type === "delist") {
      buttons = [
        {
          label: "De-List",
          onClick: () => _self.listingStatusChange(listingId, "delist"),
        },
        {
          label: "Cancel",
        },
      ];
    }
    confirmAlert({
      title: "Are you sure want to " + label + " campaign" + trailer + "?",
      buttons: buttons,
    });
  }

  async listingStatusChange(listingId, type) {
    let { statusNote } = this.state;
    if (listingId !== "" && type && type !== "") {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/listing/update/status", {
          listingId: listingId,
          type: type,
          statusNote: statusNote,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          toast.success(response.message);
          this.props.history.push("/manage_campaigns");
          window.location.reload();  // crude, should use state
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Invalid request.");
    }
  }

  render() {
    const {
      listingDetails,
      documentTypeListTypes,
      listingFiles,
      objectIdRef,
      statusNote,
    } = this.state;
    let displayDocumentTypes = "";
    let displayDocumentTypesModified = "";
    var date = moment(listingDetails.createdOnUTC).format("DD/MM/YYYY");

    var usdPrice = 0.0;
    var decPrice = 0.0;
    // Handle legacy USD pricing and unset entries
    if (listingDetails.powerPrice) {
      if (listingDetails.powerPriceUnits &&
          listingDetails.powerPriceUnits == "dec") {
            usdPrice = Math.ceil(listingDetails.powerPrice * this.state.decPrice);
            decPrice = listingDetails.powerPrice;
      } else {
        // Legacy, fixed DEC price
        usdPrice = listingDetails.powerPrice;
        decPrice = Math.ceil(listingDetails.powerPrice / 0.05);
      }
    } else {
      usdPrice = "unset";
      decPrice = "unset";
    }

    if (
      !_.isUndefined(documentTypeListTypes) &&
      !_.isEmpty(documentTypeListTypes)
    ) {
      displayDocumentTypes = documentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div>
            {objectIdRef[parentDocumentType._id] && (
              <div className="element-box">
                <h5
                  className={
                    parentDocumentType.children.length > 1
                      ? "text-center"
                      : "d-none hide"
                  }
                >
                  <span className="document-type-header">
                    {parentDocumentType.label}
                  </span>
                </h5>
                <div className="row">
                  <div className="col-md-12">
                    {parentDocumentType.children.map((documentType, index) => (
                      <div
                        className={
                          parentDocumentType.children.length > 1 ? "mt-4" : ""
                        }
                      >
                        <h6 className="element-header">
                          <span>{documentType.label}</span>
                        </h6>
                        <div className="row">
                          {objectIdRef[documentType._id] &&
                            !_.isEmpty(objectIdRef[documentType._id]) &&
                            objectIdRef[documentType._id].map(
                              (image, imageIndex) => (
                                <div className="col-md-6 mt-4">
                                  {(_.includes(listingFiles[image], "png") ||
                                    _.includes(listingFiles[image], "jpg") ||
                                    _.includes(listingFiles[image], "jpeg") ||
                                    _.includes(listingFiles[image], "bmp")) && (
                                    <a
                                      rel="noopener noreferrer"
                                      href={listingFiles[image]}
                                      target="_blank"
                                    >
                                      <img
                                        src={listingFiles[image]}
                                        height={350}
                                        alt={documentType.label}
                                      />
                                    </a>
                                  )}

                                  {!_.includes(listingFiles[image], "png") &&
                                    !_.includes(listingFiles[image], "jpg") &&
                                    !_.includes(listingFiles[image], "jpeg") &&
                                    !_.includes(listingFiles[image], "bmp") && (
                                      <a
                                        rel="noopener noreferrer"
                                        href={listingFiles[image]}
                                        target="_blank"
                                      >
                                        <img
                                          src={browseFileImg}
                                          height={200}
                                          alt={documentType.label}
                                        />
                                      </a>
                                    )}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      );
    }

    if (
      !_.isUndefined(documentTypeListTypes) &&
      !_.isEmpty(documentTypeListTypes)
    ) {
      displayDocumentTypesModified = documentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="col-md-6">
            {objectIdRef[parentDocumentType._id] && (
              <div className="mt-2">
                {parentDocumentType.label} :{" "}
                <a
                  href={
                    listingFiles[
                      objectIdRef[parentDocumentType.children[0]._id][0]
                    ]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </div>
            )}
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span>Details</span>
                      <Link to="/manage_campaigns" className="pull-right">
                        Back to Campaigns
                      </Link>
                    </div>
                  </h6>

                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold"> User Name :</span>{" "}
                        {listingDetails && listingDetails.userId
                          ? listingDetails.userId.fullName
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold"> User Email :</span>{" "}
                        {listingDetails &&
                        listingDetails.userId &&
                        listingDetails.userId.email
                          ? listingDetails.userId.email
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Daily Production (MCF) :
                        </span>{" "}
                        {listingDetails.dailyProductionMcf}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Total Estimated Power Capacity (kWh) :
                        </span>{" "}
                        {niceNumberDecimalDisplay(listingDetails.initialEstimatedPowerCapacity, 4)}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Oil total Proved Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Oil total Probable Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalProbableReserves}{" "}
                        {listingDetails.oilTotalProbableReservesUnit}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Oil total Proved Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Gas total Proved Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalProvedReserves}{" "}
                        {listingDetails.gasTotalProvedReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          Gas total Probable Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalProbableReserves}{" "}
                        {listingDetails.gasTotalProbableReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Gas total Possible Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalPossibleReserves}{" "}
                        {listingDetails.gasTotalPossibleReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Location :</span>{" "}
                        {listingDetails &&
                        listingDetails.location &&
                        listingDetails.location !== ""
                          ? listingDetails.location
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Latitude / Longitude :
                        </span>{" "}
                        {listingDetails.latitude ? listingDetails.latitude : ""}{" "}
                        {listingDetails.longitude
                          ? listingDetails.longitude
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Remaining (unsold) Power Capacity (KWH) :{" "}
                        {niceNumberDecimalDisplay(listingDetails.estimatedPoweCapacity, 4)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>Campaign Pricing (DEC/kWh) : {decPrice} DEC ({usdPrice} USD)</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Created Date :{" "}
                        </span>
                        {date}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <span className="font-weight-bold">
                        <div>Status : {listingDetails.status}</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <span>Campaign Documents</span>
                  </h6>
                  <div className="row">{displayDocumentTypesModified}</div>
                </div>
              </div>

              {listingDetails.status !== "PENDING" &&
                listingDetails.statusNote !== "" &&
                listingDetails.statusNote !== null && (
                  <div className="element-box">
                    <h6>Note</h6>
                    {listingDetails.statusNote}
                  </div>
              )}

              {listingDetails && (listingDetails.status === "PENDING" ||
                                  listingDetails.status === "PROCESSING") && (
                <div>
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="element-header">Note : </h6>
                        <textarea
                          rows={3}
                          className="form-control"
                          name="statusNote"
                          id="statusNote"
                          onChange={this.onchange}
                          value={statusNote}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="element-box">
                    <div className="button-center">
                      <button
                        className="mr-2 mb-2 btn btn-primary"
                        type="button"
                        onClick={() =>
                          this.listingOperation(listingDetails._id,
                            listingDetails.status, "approve")
                        }
                      >
                        {" "}
                        Accept
                      </button>
                      <button
                        className="mr-2 mb-2 btn btn-danger"
                        type="button"
                        onClick={() =>
                          this.listingOperation(listingDetails._id,
                            listingDetails.status, "reject")
                        }
                      >
                        {" "}
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {listingDetails && listingDetails.status != "DELISTED" && (
                <div>
                  <div className="element-box">
                    <div className="button-center">
                      <button
                        className="mr-2 mb-2 btn btn-danger"
                        type="button"
                        onClick={() =>
                          this.listingOperation(listingDetails._id,
                            listingDetails.status, "delist")
                        }
                      >
                        {" "}
                        De-List
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {listingDetails && listingDetails.status === "DELISTED" && (
                <div>
                  <div className="element-box">
                    <div className="button-center">
                      <button
                        className="mr-2 mb-2 btn btn-primary"
                        type="button"
                        onClick={() =>
                          this.listingOperation(listingDetails._id,
                            listingDetails.status, "relist")
                        }
                      >
                        {" "}
                        Re-List
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CampaignDetails;
