import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class AdminManageResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      categoryList: [],
      selectedCategory: "",
      selectedCategoryLabel: "",
      panelList: [
        { value: "m", label: "Miner" },
        { value: "i", label: "Investor" },
        { value: "s", label: "Supplier" },
        { value: "d", label: "Dealer" },
        { value: "all", label: "All" },
      ],
      selectedPanel: "",
      selectedPanelLabel: "",
      sizePerPage: 10,
      filterByTitle: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.deleteResource = this.deleteResource.bind(this);
    this.resourceOperation = this.resourceOperation.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.changePanel = this.changePanel.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
  }
  changeCategory(e) {
    this.setState({
      selectedCategory: e.value,
      selectedCategoryLabel: e.label,
    });
  }
  changePanel(e) {
    this.setState({
      selectedPanel: e.value,
      selectedPanelLabel: e.label,
    });
  }
  async getCategories() {
    try {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      this.setState({ loading: true });
      const response = await api
        .setToken(authenticationToken)
        .get("user/viewCategories");
      if (response.code === 200) {
        if (response.data) {
          const categoryArr = [];
          const resourceCategories = response.data.resourceCategories;

          resourceCategories.map((data) => {
            const category = {};
            category.value = data.title;
            category.label = data.title;
            categoryArr.push(category);
          });
          categoryArr.push({
            value: "undefined",
            label: "undefined",
          });

          this.setState({ categoryList: categoryArr });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async deleteResource(deleteId) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .delete("admin/resource/delete", {
        deleteId: deleteId,
      });
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        toast.success(response.message);
        this.getRecords();
      } else {
        toast.error(response.message);
      }
    }
  }

  resourceOperation(resourceId, type) {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Yes",
        onClick: () => _self.deleteResource(resourceId),
      },
      {
        label: "Cancel",
      },
    ];
    confirmAlert({
      title: "Are you sure want to delete?",
      buttons: buttons,
    });
  }
  showDescription(description) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h3 className="text-bold text-center">Description</h3>
              <p className="alert__body text-center mt-2">{description}</p>
              <div className="react-confirm-alert-button-group mt-3">
                <button
                  onClick={() => {
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  async getRecords() {
    const api = new Api();
    const {
      sizePerPage,
      page,
      filterByTitle,
      selectedCategory,
      selectedPanel,
    } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("admin/resources", {
        sizePerPage: sizePerPage,
        page: page,
        filterByTitle: filterByTitle,
        panel: selectedPanel,
        category: selectedCategory,
      });
    if (response.code === 200) {
      this.setState(
        {
          renderFlag: true,
          loading: false,
          data: response.data.resources,
          totalSize: response.data.totalResources,
        },
        async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        }
      );
    } else {
      this.setState({
        loading: false,
      });
      toast.message(response.message);
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByTitle: "",
        selectedPanel: "",
        selectedPanelLabel: "",
        selectedCategory: "",
        selectedCategoryLabel: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentDidMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_RESOURCES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
    this.getCategories();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByTitle,
      loading,
      selectedCategory,
      selectedCategoryLabel,
      categoryList,
      selectedPanel,
      panelList,
      selectedPanelLabel,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "resourceTitle",
        text: "Title",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "resourceDescription",
        text: "Description",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let newDes = cell ? cell.slice(0, 20) : "";
          return (
            <div className="text-left">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  _self.showDescription(cell);
                }}
              >
                {cell.length > 20 ? `${newDes}...` : cell}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "category",
        text: "CATEGORY",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "panel",
        text: "PANEL",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "";
          if (cell === "m") {
            label = "Miner";
          } else if (cell === "i") {
            label = "Investor";
          } else if (cell === "s") {
            label = "Supplier";
          } else if (cell === "d") {
            label = "Dealer";
          } else if (cell === "all") {
            label = "All";
          }
          return (
            <div className="text-left">
              <div>{label}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "documentTypeId",
        text: "File",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <a
                href={row.resourceFile}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Resource
              </a>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "delete",
        text: "Delete",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <button
                className="mr-2 mb-2 btn btn-danger"
                type="button"
                onClick={() => _self.resourceOperation(row._id)}
              >
                {" "}
                Delete
              </button>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Resources</h5>

                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <input
                        className="form-control mb-2 mr-sm-2 mb-sm-0"
                        name="filterByTitle"
                        id="filterByTitle"
                        placeholder="Keyword"
                        type="text"
                        onChange={this.onchange}
                        value={filterByTitle}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <Select
                        value={{
                          value: selectedCategory,
                          label: selectedCategoryLabel || "Select Category",
                        }}
                        options={categoryList}
                        onChange={(e) => {
                          this.changeCategory(e);
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <Select
                        value={{
                          value: selectedPanel,
                          label: selectedPanelLabel || "Select Panel",
                        }}
                        options={panelList}
                        onChange={(e) => {
                          this.changePanel(e);
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 ">
                      <div className="form-group">
                        <div className="d-flex">
                          {" "}
                          <button
                            className="btn btn-primary  "
                            type="button"
                            onClick={this.filterRecords}
                          >
                            {" "}
                            Filter
                          </button>
                          <button
                            className="btn btn-danger ml-2"
                            type="button"
                            onClick={this.clearFilterRecords}
                          >
                            {" "}
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Resources</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                  <span className="pull-right content-left">
                    <Link
                      to="/resource/add"
                      className="color-white text-underline"
                    >
                      <button className="btn btn-sm btn-primary">
                        Add Resource
                      </button>
                    </Link>
                  </span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminManageResources;
