import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class ManageDealers extends React.Component {
  constructor(props) {
    super(props);
    this.tokenContract = "";
    this.web3 = "";
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.deleteDealer = this.deleteDealer.bind(this);
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/dealers/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.dealers,
            totalSize: response.data.totalDealers,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_DEALERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  deleteDealer(dealerId = "") {
    if (dealerId !== "") {
      let _self = this;
      let buttons = "";
      buttons = [
        {
          label: "Deactivate",
          onClick: () => _self.deleteDealerRequest(dealerId),
        },
        {
          label: "Cancel",
        },
      ];
      confirmAlert({
        title: "Sure to deactivate dealer?",
        buttons: buttons,
      });
    }
  }

  async deleteDealerRequest(dealerId) {
    if (dealerId !== "") {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("admin/dealer/remove", {
          dealerId,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.getRecords();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "originalEmail",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date Time",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var dateTime = moment(cell).format("DD/MM/YYYY HH:mm");
          return (
            <div className="text-left">
              <div>{dateTime}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          let tokens =
            parseFloat(row.totalTokenSent) > 0
              ? parseFloat(row.tokens) - parseFloat(row.totalTokenSent)
              : row.tokens;
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <button
                    className="mr-2 mb-2 btn btn-danger"
                    type="button"
                    onClick={() => _self.deleteDealer(row._id)}
                  >
                    {" "}
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper users-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Dealers</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only"> Name</label>
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2 "
                          name="filterByName"
                          id="filterByName"
                          placeholder="Full Name"
                          type="text"
                          onChange={this.onchange}
                          value={filterByName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="sr-only"> Email</label>
                        <input
                          className="form-control mb-2 mr-sm-2  mb-sm-2"
                          placeholder="Email Address"
                          type="text"
                          name="filterByEmail"
                          id="filterByEmail"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.filterRecords}
                    >
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Dealers</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageDealers;
