import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import { tokenSymbol } from "../../services/api-config";

class ManageMandates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/mandate/book/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.mandateBookRequests,
            totalSize: response.data.totalMandateRequestCount,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async transactionOperation(mandateId, type) {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("admin/mandate/update", {
        mandateId: mandateId,
        type: type,
      });
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }

  async componentWillMount() {
    document.title =
      messageConstants.ADMIN_MANAGE_MANDATE_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  mandateOperation(mandateId, type) {
    let label = type === "approve" ? "accept" : "reject";
    let _self = this;
    let buttons = "";
    if (type === "approve") {
      buttons = [
        {
          label: "Accept",
          onClick: () => _self.transactionOperation(mandateId, "approve"),
        },
        {
          label: "Cancel",
        },
      ];
    } else {
      buttons = [
        {
          label: "Reject",
          onClick: () => _self.transactionOperation(mandateId, "reject"),
        },
        {
          label: "Cancel",
        },
      ];
    }
    confirmAlert({
      title: "Sure to " + label + " mandate?",
      buttons: buttons,
    });
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Name",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId && row.userId.fullName
                  ? row.userId.fullName
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId && row.userId.originalEmail
                  ? row.userId.originalEmail
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "phone",
        text: "Phone No",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {row.userId &&
                row.userId.phoneNumber &&
                row.userId.phoneNumber !== ""
                  ? row.userId.phoneNumber
                  : "N/A"}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "quantity",
        text: `${tokenSymbol} Qty.`,
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(row.quantity, tokenSymbol)}
              >
                {niceNumberDecimalDisplay(row.quantity, 2)}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "totalOrderAmount",
        text: "Total",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              <span>
                {row.totalOrderAmount} {row.currencyType}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "type",
        text: "Type",
        formatter: function (cell) {
          let labelClassName =
            cell === "BUY" ? "badge-primary" : "badge-danger";
          let label = cell === "BUY" ? "Buy" : "Sell";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        formatter: function (cell) {
          let labelClassName =
            cell === "PENDING"
              ? "badge-warning"
              : cell === "ACCEPTED"
              ? "badge-primary"
              : "badge-danger";
          let label =
            cell === "PENDING"
              ? "Pending"
              : cell === "ACCEPTED"
              ? "Accepted"
              : "Rejected";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "executedStatus",
        text: "Executed",
        formatter: function (cell) {
          let labelClassName =
            cell === "EXECUTED" ? "badge-success" : "badge-warning";
          let label = cell === "EXECUTED" ? "Yes" : "No";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{moment(row.createdOnUTC).format("DD MMM YYYY HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  {row.status === "PENDING" &&
                    ((row.adminVerifiedStatus === "PENDING" &&
                      row.type === "SELL") ||
                      (row.type === "BUY" &&
                        row.adminVerifiedStatus === "PENDING" &&
                        row.requiredVerifiersCounter > 0)) && (
                      <div>
                        <button
                          className="mr-2 mb-2 btn btn-primary"
                          type="button"
                          onClick={() =>
                            _self.mandateOperation(row._id, "approve")
                          }
                        >
                          {" "}
                          Accept
                        </button>
                        <br />
                        <button
                          className="mr-2 mb-2 btn btn-danger"
                          type="button"
                          onClick={() =>
                            _self.mandateOperation(row._id, "reject")
                          }
                        >
                          {" "}
                          Reject
                        </button>
                      </div>
                    )}
                  {(row.adminVerifiedStatus !== "PENDING" ||
                    row.status === "CANCELLED") && <div>N/A</div>}
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Mandates</h5>

                <form className="form-inline">
                  <label className="sr-only"> Name</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByName"
                    id="filterByName"
                    placeholder="Full Name"
                    type="text"
                    onChange={this.onchange}
                    value={filterByName}
                  />
                  <label className="sr-only"> Email</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Email Address"
                    type="text"
                    name="filterByEmail"
                    id="filterByEmail"
                    onChange={this.onchange}
                    value={filterByEmail}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Manage Mandates</span>
                  <span
                    className="fa fa-refresh ml-2 cursor-pointer"
                    onClick={() => this.refreshCurrenttable()}
                  ></span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageMandates;
