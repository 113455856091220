import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  ethAddressDisplay,
  niceNumberDecimalDisplay,
  pageProgress
} from "../../utils/Util";
import BootstrapTable from "react-bootstrap-table-next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import dollarImg from "../../assets/img/dollar-icon.png";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import ExportInvoicesModal from "./ExportInvoicesModal";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "./Earnings.css";

class ManageInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      renderOriginal: true,
      toolTipText: "Covert to USD",
      filterByAccount: "",
      filterByStartDate: "",
      filterByEndDate: "",
      currencyList: [
        { value: "CAD", label: "CAD" },
        { value: "USD", label: "USD" },
        { value: "BTC", label: "BTC" },
        { value: "DEC", label: "DEC" },
      ],
      statusList: [
        { value: "p", label: "Pending" },
        { value: "r", label: "Rejected" },
        { value: "a", label: "Paid" },
        { value: "u", label: "Under Review" },
      ],
      subscribedList: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],
      paymentMethodList: [
        { value: "stripe", label: "Stripe" },
        { value: "wireTransfer", label: "Wire" },
        { value: "BTC", label: "BTC" },
        { value: "DEC", label: "DEC" },
      ],
      dateFilterTypeList: [
        {value: "created", label: "Filter by Invoice Date"},
        {value: "updated", label: "Filter by Payment Date"},
      ],
      dateFilterTypeLabel: "Select Date Filter Column",
      paymentMethodLable: "Select Payment Method",
      currencyLabel: "Select Currency",
      paymentStatusLabel: "Select Payment Status",
      subscribedLabel: "Select Subscribed",
      filterByDateFilterType: "",
      filterByPaymentMethod: "",
      filterByPaymentStatus: "",
      filterByCurrency: "",
      filterBySubscribed: "",
      page: 1,
      totalSize: "",
      data:[],
      usdData: [],
      cadData: [],
      originalDatat: [],
      sizePerPage: 10,
      loading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.copyWhiteListAddress = this.copyWhiteListAddress.bind(this);
    this.convertOriginal = this.convertOriginal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.allData = this.allData.bind(this);
    this.onchange = this.onchange.bind(this);
    this.changeDateFilterType = this.changeDateFilterType.bind(this);
    this.changePaymentStatus = this.changePaymentStatus.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.changeSubscribed = this.changeSubscribed.bind(this);
    this.changeCurrency = this.changeCurrency.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterBySubscribed: "",
        filterByAccount: "",
        filterByStartDate: "",
        filterByEndDate: "",
        page:1,
        filterByDateFilterType: "",
        dateFilterTypeLabel: "Select Date Filter Column",
        filterByPaymentMethod: "",
        filterByPaymentStatus: "",
        filterByCurrency: "",
        subscribedLabel: "Select Subscribed",
        paymentStatusLabel: "Select Payment Status",
        currencyLabel: "Select Currency",
        paymentMethodLable: "Select Payment Method",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  changeCurrency(e) {
    this.setState({
      filterByCurrency: e.value,
      currencyLabel: e.label,
    });
  }
  changeSubscribed(e) {
    this.setState({
      filterBySubscribed: e.value,
      subscribedLabel: e.label,
    });
  }
  changeDateFilterType(e) {
    this.setState({
      filterByDateFilterType: e.value,
      dateFilterTypeLabel: e.label,
    });
  }
  changePaymentStatus(e) {
    this.setState({
      filterByPaymentStatus: e.value,
      paymentStatusLabel: e.label,
    });
  }
  changePaymentMethod(e) {
    this.setState({
      filterByPaymentMethod: e.value,
      paymentMethodLable: e.label,
    });
  }
  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  fetchPaymentDetails(data) {
    let currency = "USD";  // Currently all data is USD
    let raisedAmt = data.invoiceId.raisedAmountinUSD;
    let subscribedAmt = data.invoiceId.subscribedAmountinUSD;
      return {raisedAmt, subscribedAmt, currency};
  }

  getCurrency(row) {
    const {currency} = this.fetchPaymentDetails(row);
    return currency;
  }
 
  determineConversion(invoiceAmount, usdAmount) {
    let ratio = 0.0;
    if (invoiceAmount && (invoiceAmount > 0.0) && usdAmount) {
      ratio = Math.round(((usdAmount / invoiceAmount) + 
                          Number.EPSILON) * 1000.0) / 1000.0;
    }
    const conversion = (ratio != 0.0 ? 
                        niceNumberDecimalDisplay(ratio, 3) : "?");
    const conversionRate = (conversion == 1.00 ? "1 : 1" :
                            `1 : ${conversion}`);
    return conversionRate;
  }

  allData() {
    const { totalSize } = this.state;
    const api = new Api();
    let authenticationToken = this.props.authToken;
    api
      .setToken(authenticationToken)
      .get("admin/allInvoices", {
        sizePerPage: totalSize,
        page: 1,
      })
      .then((data) => {
        let usdDataArr = [];
        let cadDataArr = [];
        let originalDataArr = [];
        // Note: addresses only feteched for export data, backend
        //       needs to be modified to fetch for render.
        const userAddresses = data.data.minerUserAddresses;
        data.data.minerpaymentrequestlists.map((data) => {
          let usdData = {};
          let cadData = {};
          let originalData = {};
          usdData.InvoiceNo = data.invoiceId && data.invoiceId._id;
          cadData.InvoiceNo = data.invoiceId && data.invoiceId._id;
          originalData.InvoiceNo = data.invoiceId && data.invoiceId._id;
          usdData.AccountNo = data.invoiceId && data.invoiceId.account;
          cadData.AccountNo = data.invoiceId && data.invoiceId.account;
          originalData.AccountNo = data.invoiceId && data.invoiceId.account;
          if (data.invoiceId) {
            originalData.InvoiceDate = data.createdOnUTC
              ? moment(data.createdOnUTC).format("YY-MM-DD")
              : "";
            usdData.InvoiceDate = originalData.InvoiceDate;
            cadData.InvoiceDate = originalData.InvoiceDate;
          }
          usdData.Customer = data.invoiceId && data.userId.fullName;
          cadData.Customer = data.invoiceId && data.userId.fullName;
          originalData.Customer = data.invoiceId && data.userId.fullName;
          // Customer address not yet populated
          const customerAddress = (userAddresses[data.userId._id] ?
                                   userAddresses[data.userId._id] :"");
          usdData.CustomerAddress = customerAddress;
          cadData.CustomerAddress = customerAddress;
          originalData.CustomerAddress = customerAddress;
          // TODO: Start populating a service type field
          usdData.DescriptionOfService = "Electricity Fees";
          cadData.DescriptionOfService = "Electricity Fees";
          originalData.DescriptionOfService = "Electricity Fees";
          // Compute CAD and USD amounts up front for use later.
          const usdAmount = data.invoiceId && niceNumberDecimalDisplay(
            data.invoiceId.raisedAmountinUSD +
              (data.invoiceId.subscribedAmountinUSD
                ? data.invoiceId.subscribedAmountinUSD
                : 0),
            2
          );
          const cadAmount = data.invoiceId && niceNumberDecimalDisplay(
            data.invoiceId.raisedAmountinCAD +
              (data.invoiceId.subscribedAmountinCAD
                ? data.invoiceId.subscribedAmountinCAD
                : 0),
            2
          );
          
          if (data.invoiceId) {
            const {raisedAmt, subscribedAmt, currency} = this.fetchPaymentDetails(data);
            // Want Currency, Original Currency, ConversionRate, InvoiceAmount
            const invoiceAmount = niceNumberDecimalDisplay(
                raisedAmt + (subscribedAmt ? subscribedAmt : 0), 2);
            const pendingAmount = niceNumberDecimalDisplay(
                data.status === "a" ? 0 : raisedAmt, 2);
            const subscribedAmount = subscribedAmt ? niceNumberDecimalDisplay(
                     subscribedAmt, 2) : 0;
            // If we have an amount in the non-USD currency,
            // record the conversion rate used.
            const conversionRate = this.determineConversion(
              raisedAmt + (subscribedAmt ? subscribedAmt : 0), 
                           data.invoiceId.raisedAmountinUSD +
                              (data.invoiceId.subscribedAmountinUSD
                               ? data.invoiceId.subscribedAmountinUSD
                               : 0));
            const conversionRateCAD = this.determineConversion(
              raisedAmt + (subscribedAmt ? subscribedAmt : 0), 
                           data.invoiceId.raisedAmountinCAD +
                              (data.invoiceId.subscribedAmountinCAD
                               ? data.invoiceId.subscribedAmountinCAD
                               : 0));
            
            // Original currency
            originalData.Currency = currency;
            usdData.originalCurrency = currency;
            cadData.originalCurrency = currency;
            // Invoice in original currency
            usdData.OriginalInvoiceAmount = invoiceAmount;
            cadData.OriginalInvoiceAmount = invoiceAmount;
            originalData.InvoiceAmount = invoiceAmount;
            // Sales tax (NOT YET CAPTURED)
            usdData.OriginalSalesTax = niceNumberDecimalDisplay(0.0, 2);
            cadData.OriginalSalesTax = niceNumberDecimalDisplay(0.0, 2);
            originalData.SalesTax = niceNumberDecimalDisplay(0.0, 2);
            // Total charges
            usdData.OriginalTotalAmount = invoiceAmount;
            cadData.OriginalTotalAmount = invoiceAmount;
            originalData.InvoiceTotalAmount = invoiceAmount;
            // Invoice anmout in currency
            usdData.InvoiceAmountUSD = usdAmount;
            cadData.InvoiceAmountCAD = cadAmount;
            // Sales tax in currency (NOT YET CAPTURED)
            usdData.SalesTaxUSD = niceNumberDecimalDisplay(0.0, 2);
            cadData.SalesTaxCAD = niceNumberDecimalDisplay(0.0, 2);
            // Total Charges
            usdData.TotalAmountUSD = usdAmount;
            cadData.TotalAmountCAD = cadAmount;
            // Conversion rate
            usdData.FxRateAtBilling = conversionRate;
            cadData.FxRateAtBilling = conversionRateCAD;
            originalData.conversionRate = conversionRate;
            originalData.PendingAmount = pendingAmount;
            originalData.SubscribedAmount = subscribedAmount;

            // Subscribed amounts
            //usdData.SubscribedAmountUSD = data.invoiceId && data.invoiceId.subscribedAmountinUSD
            //  ? niceNumberDecimalDisplay(data.invoiceId.subscribedAmountinUSD, 2)
            //  : 0;
            //cadData.SubscribedAmountCAD = data.invoiceId && data.invoiceId.subscribedAmountinCAD
            //  ? niceNumberDecimalDisplay(data.invoiceId.subscribedAmountinCAD, 2)
            //  : 0;
            // Pending amounts
            //usdData.PendingAmountUSD =
            //  data.status === "a"
            //  ? 0
            //    : data.invoiceId && niceNumberDecimalDisplay(data.invoiceId.raisedAmountinUSD, 2);
            //cadData.PendingAmountCAD =
            //  data.status === "a"
            //    ? 0
            //    : data.invoiceId && niceNumberDecimalDisplay(data.invoiceId.raisedAmountinCAD, 2);

            // Payment status
          if (data.status === "a") {
            originalData.PaymentStatus = "Paid";
          } else if (data.status === "u") {
            originalData.PaymentStatus = "Under Review";
          } else if (data.status === "r") {
            originalData.PaymentStatus = "Rejected";
          } else if (data.status === "p") {
            originalData.PaymentStatus = "Pending";
          }
          usdData.PaymentStatus = originalData.PaymentStatus;
          cadData.PaymentStatus = originalData.PaymentStatus;
            // Payment date
            originalData.PaymentDate = data.updatedOnUTC
              ? moment(data.updatedOnUTC).format("YY-MM-DD")
              : "";
            usdData.PaymentDate = originalData.PaymentDate;
            cadData.PaymentDate = originalData.PaymentDate;
            // Payment method
            usdData.PaymentMethod = data.paymentMode ? data.paymentMode : "";
            cadData.PaymentMethod = data.paymentMode ? data.paymentMode : "";
            originalData.PaymentMethod = data.paymentMode ? data.paymentMode : "";
             // Payment Amount: for paid invoices this is transactionAmount
             const amountPaid = (data.status === "a" ?
                                 data.transactionAmount : 0.0);
             usdData.PaymentAmount = (amountPaid > 0.0 ? amountPaid : "");
             cadData.PaymentAmount = (amountPaid > 0.0 ? amountPaid : "");
             originalData.PaymentAmount = (amountPaid> 0.0 ? amountPaid : "");
             // Fx rate at payment
             usdData.FxRateUSD = (amountPaid > 0.0 ? usdAmount / amountPaid : "");
             cadData.FxRateCAD = (amountPaid > 0.0 ? cadAmount / amountPaid : "");
             originalData.FxRateUSD = (amountPaid > 0.0 ? usdAmount / amountPaid : "");
          }
          // Subscription status
          originalData.Subscribed = data.userId
            ? data.userId.isSubscribed
              ? "True"
              : "False"
            : "";
          //usdData.Subscribed = originalData.Subscribed;
          //cadData.Subscribed = originalData.Subscribed;

          // Memo
          if (data.status === "r" && data.adminNote) {
            usdData.Memo = data.adminNote;
            cadData.Memo = data.adminNote;
            originalData.Memo = data.adminNote;
          } else if (data.adminNote && data.userId.isSubscribed) {
            let stringNote = data.adminNote.toLowerCase();
            if (stringNote.includes("subscribed")) {
              usdData.Memo = data.adminNote;
              cadData.Memo = data.adminNote;
              originalData.Memo = data.adminNote;
            }
          } else {
            usdData.Memo = "";
            cadData.Memo = "";
            originalData.Memo = "";
          }
          
          if (originalData.InvoiceAmount) {
            usdDataArr.push(usdData);
            cadDataArr.push(cadData);
            originalDataArr.push(originalData);
          }
          return 0;
        });
        this.setState({
          usdData: usdDataArr,
          cadData: cadDataArr,
          originalData: originalDataArr,
        });
      });
  }

  copyWhiteListAddress() {
    let msg = "Invoice ID is copied successfully!";
    toast.success(msg);
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }
  showModal() {
    this.setState({ modalOpen: true });
  }

  convertOriginal() {
    if (this.state.renderOriginal === false) {
      this.setState({
        renderOriginal: true,
        toolTipText: "Convert to USD",
      });
    } else if (this.state.renderOriginal === true) {
      this.setState({
        renderOriginal: false,
        toolTipText: "Convert to Original",
      });
    }
  }

  async getRecords() {
    try {
    const api = new Api();

    let stringEndDate = "";
    let stringStartDate = "";
    const {
      sizePerPage,
      page,
      filterBySubscribed,
      filterByAccount,
      filterByDateFilterType,
      filterByStartDate,
      filterByEndDate,
      filterByPaymentMethod,
      filterByPaymentStatus,
      filterByCurrency,
    } = this.state;

    if (filterByStartDate) {
      stringStartDate = filterByStartDate.concat("T00:00:00.000Z");
    }
    if (filterByEndDate) {
      stringEndDate = filterByEndDate.concat("T23:59:59.000Z");
    }

    if ((filterByStartDate || filterByEndDate) && !filterByDateFilterType) {
      toast.error("Please select a date filter column");
    }

    if (filterByDateFilterType && !filterByStartDate && !filterByEndDate) {
      toast.error("Please set a start and/or end date");
    }

    if (!filterByStartDate && filterByEndDate) {
      toast.error("Please mention the start date also!");
    } else if (filterByStartDate && filterByEndDate) {
      if (moment(filterByStartDate) >= moment(filterByEndDate)) {
        toast.error(
          "Please make sure the end date must be greater then start date!"
        );
      } else {
        const authenticationToken = this.props.authToken;
        this.setState({ loading: true, authToken: authenticationToken });
          const response = await api
            .setToken(authenticationToken)
            .get("admin/allInvoices", {
              sizePerPage: sizePerPage,
              page: page,
              filterBySubscribed,
              filterByAccount,
              filterByDateFilterType:filterByDateFilterType,
              filterByStartDate:stringStartDate,
              filterByEndDate: stringEndDate,
              filterByMode:filterByPaymentMethod,
              filterByStatus:filterByPaymentStatus,
              filterByCurrency,
            });

          if (response.code === 200) {
            this.setState(
              {
                renderFlag: true,
                loading: false,
                data: response.data.minerpaymentrequestlists,
                totalSize: response.data.minerpaymentrequestCount,
              },
              () => {
                pageProgress("remove");
                this.allData();
              }
            );
          } else {
            this.setState({
              loading: false,
            });
            toast.message(response.message);
          }
      }
    } else {
      const authenticationToken = this.props.authToken;
        this.setState({ loading: true, authToken: authenticationToken });
          const response = await api
            .setToken(authenticationToken)
            .get("admin/allInvoices", {
              sizePerPage: sizePerPage,
              page: page,
              filterBySubscribed,
              filterByAccount,
              filterByDateFilterType:filterByDateFilterType,
              filterByStartDate:stringStartDate,
              filterByEndDate: stringEndDate,
              filterByMode:filterByPaymentMethod,
              filterByStatus:filterByPaymentStatus,
              filterByCurrency,
            });

          if (response.code === 200) {
            this.setState(
              {
                renderFlag: true,
                loading: false,
                data: response.data.minerpaymentrequestlists,
                totalSize: response.data.minerpaymentrequestCount,
              },
              () => {
                pageProgress("remove");
                this.allData();
              }
            );
          } else {
            this.setState({
              loading: false,
            });
            toast.message(response.message);
          }
      }
    } catch (error) {
      pageProgress("remove");
    }
  }

  componentDidMount() {
    document.title =
      "Manage Invoices" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  kycOperation(note) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h2 className="">Note</h2>
              <p>{note}</p>
              <div className="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    const {
      data,
      sizePerPage,
      totalSize,
      renderOriginal,
      toolTipText,
      page,
      renderFlag,
      usdData,
      cadData,
      paymentMethodList,
      subscribedList,
      filterByDateFilterType,
      dateFilterTypeLabel,
      dateFilterTypeList,
      filterByPaymentMethod,
      paymentMethodLable,
      filterByAccount,
      filterByPaymentStatus,
      paymentStatusLabel,
      filterBySubscribed,
      subscribedLabel,
      statusList,
      filterByEndDate,
      filterByStartDate,
      currencyLabel,
      filterByCurrency,
      currencyList,
      originalData,
      loading,
    } = this.state;
    let _self = this;
    let columns = [];
    if (renderOriginal === true) {
      columns = [
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "INOVICE ID",
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>
                  <span>
                    {row.invoiceId ? ethAddressDisplay(row.invoiceId._id) : ""}
                  </span>
                  {row.invoiceId && (
                    <CopyToClipboard
                      text={row.invoiceId._id}
                      onCopy={() => _self.copyWhiteListAddress()}
                    >
                      <i
                        className="fa fa-clipboard cursor-pointer ml-2"
                        aria-hidden="true"
                      ></i>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "CUSTOMER",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{row.userId.fullName}</div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "createdOnUTC",
          // text: "Date",
          text: "INVOICE DATE",
  
          // text: <span>DATE <span style={{fontWight}}>(DD-MM-YYYY)</span></span>,
          formatter: function (cell, row) {
            return (
              <div className="text-left">
                {row.createdOnUTC ? (
                  <div>{moment(cell).format("YYYY-MM-DD")}</div>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "ACCOUNT",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{row.invoiceId.account}</div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "currency",
          text: "CURRENCY",
          isDummyField: true,
          formatter: function (cell, row) {
            return <div className="text-center">{_self.getCurrency(row)}</div>;
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "INVOICE AMOUNT",
          formatter: function (cell, row, rowIndex, formatExtraData) {
            let decimal = 2;
            let subscribedAmount = row.invoiceId
              ? row.invoiceId.subscribedAmountinUSD
                ? row.invoiceId.subscribedAmountinUSD
                : 0
              : 0;

            let invoiceAmount = row.invoiceId
              ? row.invoiceId.raisedAmountinUSD
              : 0;

            let discountAmount = row.invoiceId?
                row.invoiceId.raisedAmountInUsdAfterDecDiscount: 0;

            const discount = invoiceAmount > 0 && discountAmount > 0?
              discountAmount / invoiceAmount: 0;

            if (row.currency) {
              if (row.currency === "BTC") {
                decimal = 8;
                subscribedAmount = row.invoiceId.subscribedAmountinBTC
                  ? row.invoiceId.subscribedAmountinBTC
                  : 0;
                invoiceAmount = row.invoiceId.raisedAmountinBTC;
              } else if (row.currency === "CAD") {
                decimal = 2;
                subscribedAmount = row.invoiceId.subscribedAmountinCAD
                  ? row.invoiceId.subscribedAmountinCAD
                  : 0;
                invoiceAmount = row.invoiceId.raisedAmountinCAD;
              } else if (row.currency === "USD") {
                decimal = 2;
                subscribedAmount = row.invoiceId.subscribedAmountinUSD
                  ? row.invoiceId.subscribedAmountinUSD
                  : 0;
                invoiceAmount = row.invoiceId.raisedAmountinUSD;
              } else if (row.currency === "DEC") {
                decimal = 2;
                subscribedAmount = row.invoiceId.subscribedAmountinDEC
                  ? row.invoiceId.subscribedAmountinDEC
                  : 0;
                invoiceAmount = row.invoiceId.raisedAmountinDEC;
              }
            }
            const total = subscribedAmount + invoiceAmount;
            return (
              <>
                {
                  (row.currency !== "DEC" || discount === 0) && (
                    <div className="text-left">
                      <div>{niceNumberDecimalDisplay(total, decimal)}</div>
                    </div>
                  )
                }
                {
                  (row.currency === "DEC" && discount > 0) && (
                    <>
                      <div>
                        <strike style={{ color: "red" }}>
                          {niceNumberDecimalDisplay(total, 2)}
                        </strike>
                      </div>
                      <div>
                          <span>
                            {niceNumberDecimalDisplay(
                              invoiceAmount * discount + subscribedAmount,
                              2
                            )}
                          </span>
                      </div>
                    </>
                  )
                }
              </>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "transactionAmount",
          text: "SUBSCRIBED AMOUNT",
          formatter: function (cell, row) {
            let currencyBalance = row.invoiceId
              ? row.invoiceId.subscribedAmountinUSD
              : "";
            let currency = _self.getCurrency(row).substring(0,3);
            let decimal = 2;
            if (currency === "BTC") {
              currencyBalance = row.invoiceId.subscribedAmountinBTC;
              decimal = 8;
            } else if (currency === "CAD") {
              currencyBalance = row.invoiceId.subscribedAmountinCAD;
              decimal = 2;
            } else if (currency === "USD") {
              currencyBalance = row.invoiceId.subscribedAmountinUSD;
              decimal = 2;
            } else if (currency === "DEC") {
              currencyBalance = row.invoiceId.subscribedAmountinDEC;
              decimal = 2;
            }
            return (
              <div className="text-left">
                <div>
                  {row.invoiceId
                    ? niceNumberDecimalDisplay(currencyBalance, decimal)
                    : 0}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "transactionAmount",
          text: "PENDING AMOUNT",
          formatter: function (cell, row) {
            let currencyBalance = row.invoiceId
              ? row.invoiceId.raisedAmountinUSD
              : "";
            let currency = _self.getCurrency(row).substring(0,3);
            let decimal = 2;
            if (currency === "BTC") {
              currencyBalance = row.invoiceId.raisedAmountinBTC;
              decimal = 8;
            } else if (currency === "CAD") {
              currencyBalance = row.invoiceId.raisedAmountinCAD;
              decimal = 2;
            } else if (currency === "USD") {
              currencyBalance = row.invoiceId.raisedAmountinUSD;
              decimal = 2;
            } else if (currency === "DEC") {
              currencyBalance = row.invoiceId.raisedAmountinDEC;
              decimal = 2;
            }
            return (
              <div className="text-left">
                <div>
                  {row.status === "a"
                    ? 0
                    : row.invoiceId
                    ? niceNumberDecimalDisplay(currencyBalance, decimal)
                    : ""}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "adminNote",
          text: "ADMIN NOTE",
          formatter: function (cell, row) {
            if (row.adminNote && row.userId.isSubscribed) {
              let stringNote = row.adminNote.toLowerCase();
              if (stringNote.includes("subscribed")) {
                return (
                  <div className="text-left">
                    {
                      <button
                        className="btn btn-primary"
                        onClick={() => _self.kycOperation(row.adminNote)}
                      >
                        Admin Note
                      </button>
                    }
                  </div>
                );
              } else {
                return;
              }
            } else if (row.adminNote && row.status === "r") {
              return (
                <div className="text-left">
                  {
                    <button
                      className="btn btn-primary"
                      onClick={() => _self.kycOperation(row.adminNote)}
                    >
                      Admin Note
                    </button>
                  }
                </div>
              );
            }
          },
        },

        {
          headerClasses: "text-bold",
          dataField: "",
          text: "PAYMENT DATE",
          formatter: function (cell, row) {
            return (
              <div className="text-left">
                <div>
                  {row.updatedOnUTC
                    ? moment(row.updatedOnUTC).format("YYYY-MM-DD")
                    : ""}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "paymentMode",
          text: "PAYMENT METHOD",
          isDummyField: true,
          formatter: function (cell, row) {
            return (
              <div className="text-center">
                {row.paymentMode
                  ? row.paymentMode === "wireTransfer"
                    ? "Wire"
                    : row.paymentMode
                  : ""}
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "transactionAmount",
          text: "PAYMENT AMOUNT",
          formatter: function (cell, row) {
            let amountPaid = row.invoiceId
              ? row.transactionAmount
              : "";
            let decimal = (row.currency == "BTC" ? 8 : 2);
            return (
              <div>
              {row.invoiceId
                ? niceNumberDecimalDisplay(amountPaid, decimal)
                : 0}
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "",
          text: "SUBSCRIBED",
          isDummyField: true,
          formatter: function (cell, row) {
            let classLabel = "";
            let label = "";

            if (row.userId && row.userId.isSubscribed) {
              classLabel = "badge-success";
              label = "True";
            } else if (row.userId && !row.userId.isSubscribed) {
              classLabel = "badge-danger";
              label = "False";
            }
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={"badge1 " + classLabel}
                  style={{
                    marginRight: "5px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {label}
                </span>
              </span>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "PAYMENT STATUS",
          isDummyField: true,
          formatter: function (cell, row) {
            let classLabel = "";
            let statusLabel = "";
            if (
              _.isNull(row.status) ||
              _.isUndefined(row.status) ||
              _.isEmpty(row.status)
            ) {
              classLabel = "badge-warning";
              statusLabel = "Pending";
            } else if (row.status === "u") {
              classLabel = "badge-primary";
              statusLabel = "Under Review";
            } else if (row.status === "p") {
              classLabel = "badge-warning";
              statusLabel = "Pending";
            } else if (row.status === "a") {
              classLabel = "badge-success";
              statusLabel = "Paid";
            } else if (row.status === "r") {
              classLabel = "badge-danger";
              statusLabel = "Rejected";
            }
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={"badge1 " + classLabel}
                  style={{
                    marginRight: "5px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {statusLabel}
                </span>
              </span>
            );
          },
        },
      ];
    } else if (renderOriginal === false) {
      columns = [
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "ACCOUNT",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{row.invoiceId.account}</div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "INOVICE ID",
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>
                  <span>
                    {row.invoiceId ? ethAddressDisplay(row.invoiceId._id) : ""}
                  </span>
                  {row.invoiceId && (
                    <CopyToClipboard
                      text={row.invoiceId._id}
                      onCopy={() => _self.copyWhiteListAddress()}
                    >
                      <i
                        className="fa fa-clipboard cursor-pointer ml-2"
                        aria-hidden="true"
                      ></i>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "currency",
          text: "CURRENCY",
          isDummyField: true,
          formatter: function (cell, row) {
            return <div className="text-center">USD</div>;
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "INVOICE AMOUNT",
          formatter: function (cell, row, rowIndex, formatExtraData) {
            let decimal = 2;
            let subscribedAmount = row.invoiceId
              ? row.invoiceId.subscribedAmountinUSD
                ? row.invoiceId.subscribedAmountinUSD
                : 0
              : 0;

            let invoiceAmount = row.invoiceId
              ? row.invoiceId.raisedAmountinUSD
              : 0;

            let discountAmount = row.invoiceId?
              row.invoiceId.raisedAmountInUsdAfterDecDiscount: 0;

            const total = subscribedAmount + invoiceAmount;
            return (
              <>
                {
                  row.currency !== "DEC" && (
                    <div className="text-left">
                      <div>{niceNumberDecimalDisplay(total, decimal)}</div>
                    </div>
                  )
                }
                {
                  row.currency === "DEC" && (
                    <>
                      <div>
                        <strike style={{ color: "red" }}>
                          {niceNumberDecimalDisplay(total, 2)}
                        </strike>
                      </div>
                      <div>
                          <span>
                            {niceNumberDecimalDisplay(
                              discountAmount + subscribedAmount,
                              2
                            )}
                          </span>
                      </div>
                    </>
                  )
                }
              </>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "transactionAmount",
          text: "SUBSCRIBED AMOUNT",
          formatter: function (cell, row) {
            let currencyBalance = row.invoiceId
              ? row.invoiceId.subscribedAmountinUSD
              : "";
            let decimal = 2;
            return (
              <div className="text-left">
                <div>
                  {row.invoiceId
                    ? niceNumberDecimalDisplay(currencyBalance, decimal)
                    : 0}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "transactionAmount",
          text: "PENDING AMOUNT",
          formatter: function (cell, row) {
            let currencyBalance = row.invoiceId
              ? row.invoiceId.raisedAmountinUSD
              : "";
            let decimal = 2;
            return (
              <div className="text-left">
                <div>
                  {row.status === "a"
                    ? 0
                    : row.invoiceId
                    ? niceNumberDecimalDisplay(currencyBalance, decimal)
                    : ""}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "adminNote",
          text: "ADMIN NOTE",
          formatter: function (cell, row) {
            if (row.adminNote && row.userId.isSubscribed) {
              let stringNote = row.adminNote.toLowerCase();
              if (stringNote.includes("subscribed")) {
                return (
                  <div className="text-left">
                    {
                      <button
                        className="btn btn-primary"
                        onClick={() => _self.kycOperation(row.adminNote)}
                      >
                        Admin Note
                      </button>
                    }
                  </div>
                );
              } else {
                return;
              }
            } else if (row.adminNote && row.status === "r") {
              return (
                <div className="text-left">
                  {
                    <button
                      className="btn btn-primary"
                      onClick={() => _self.kycOperation(row.adminNote)}
                    >
                      Admin Note
                    </button>
                  }
                </div>
              );
            }
          },
        },

        {
          headerClasses: "text-bold",
          dataField: "",
          text: "PAYMENT DATE",
          formatter: function (cell, row) {
            return (
              <div className="text-left">
                <div>
                  {row.updatedOnUTC
                    ? moment(row.updatedOnUTC).format("YYYY-MM-DD")
                    : ""}
                </div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "paymentMode",
          text: "PAYMENT METHOD",
          isDummyField: true,
          formatter: function (cell, row) {
            return (
              <div className="text-center">
                {row.paymentMode
                  ? row.paymentMode === "wireTransfer"
                    ? "wire"
                    : row.paymentMode
                  : ""}
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "transactionAmount",
          text: "PAYMENT AMOUNT",
          formatter: function (cell, row) {
            let amountPaid = row.invoiceId
              ? row.transactionAmount
              : "";
            let decimal = (row.currency == "BTC" ? 8 : 2);
            return (
              <div>
              {row.invoiceId
                ? niceNumberDecimalDisplay(amountPaid, decimal)
                : 0}
              </div>
            );
          },
        },
        {
          headerClasses: "text-center text-bold",
          dataField: "",
          text: "SUBSCRIBED",
          isDummyField: true,
          formatter: function (cell, row) {
            let classLabel = "";
            let label = "";

            if (row.userId && row.userId.isSubscribed) {
              classLabel = "badge-success";
              label = "True";
            } else if (row.userId && !row.userId.isSubscribed) {
              classLabel = "badge-danger";
              label = "False";
            }
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={"badge1 " + classLabel}
                  style={{
                    marginRight: "5px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {label}
                </span>
              </span>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "",
          text: "PAYMENT STATUS",
          isDummyField: true,
          formatter: function (cell, row) {
            let classLabel = "";
            let statusLabel = "";
            if (
              _.isNull(row.status) ||
              _.isUndefined(row.status) ||
              _.isEmpty(row.status)
            ) {
              classLabel = "badge-warning";
              statusLabel = "Pending";
            } else if (row.status === "u") {
              classLabel = "badge-primary";
              statusLabel = "Under Review";
            } else if (row.status === "p") {
              classLabel = "badge-warning";
              statusLabel = "Pending";
            } else if (row.status === "a") {
              classLabel = "badge-success";
              statusLabel = "Paid";
            } else if (row.status === "r") {
              classLabel = "badge-danger";
              statusLabel = "Rejected";
            }
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={"badge1 " + classLabel}
                  style={{
                    marginRight: "5px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {statusLabel}
                </span>
              </span>
            );
          },
        },
      ];
    }
    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box pr-0">
                <h5 className="form-header">Filter Invoices Requests</h5>
                <form className="form-inline">
                  <div className="row w-100">
                  <div className="col-sm-12 col-md-4 col-lg-4 mb-sm-2">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByDateFilterType,
                            label: dateFilterTypeLabel,
                          }}
                          options={dateFilterTypeList}
                          placeholder="PaymentDate"
                          onChange={this.changeDateFilterType}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className={
                            !filterByStartDate
                              ? "filterStartDate form-control mb-2 mr-sm-2 mb-sm-2"
                              : "form-control mb-2 mr-sm-2 mb-sm-2"
                          }
                          name="filterByStartDate"
                          id="filterByStartDate"
                          placeholder="Start Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByStartDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className={
                            !filterByEndDate
                              ? "filterEndDate form-control mb-2 mr-sm-2 mb-sm-2"
                              : "form-control mb-2 mr-sm-2 mb-sm-2"
                          }
                          name="filterByEndDate"
                          id="filterByEndDate"
                          // placeholder="Start Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByEndDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <input
                          className="form-control mb-2 mr-sm-2 mb-sm-2"
                          name="filterByAccount"
                          id="filterByAccount"
                          placeholder="Enter Account"
                          type="text"
                          onChange={this.onchange}
                          value={filterByAccount}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-sm-2">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByPaymentMethod,
                            label: paymentMethodLable,
                          }}
                          options={paymentMethodList}
                          placeholder="Select Payment Method"
                          onChange={this.changePaymentMethod}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-sm-2">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByCurrency,
                            label: currencyLabel,
                          }}
                          options={currencyList}
                          placeholder="Select Currency"
                          onChange={this.changeCurrency}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-sm-2">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterByPaymentStatus,
                            label: paymentStatusLabel,
                          }}
                          options={statusList}
                          placeholder="Select Payment Status"
                          onChange={this.changePaymentStatus}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-sm-2">
                      <div className="form-group pr-2">
                        <Select
                          className="w-100"
                          value={{
                            value: filterBySubscribed,
                            label: subscribedLabel,
                          }}
                          options={subscribedList}
                          placeholder="Select Subscribed"
                          onChange={this.changeSubscribed}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manageuser-btn-group button-center">
                    <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box horizontal-scroll">
                <div className="invoiceHeader row align-items-center justify-content-between">
                  <h5 className="ml-3 form-header row align-items-center">
                    <span>Manage Invoices Requests</span>

                    <span
                      className="custom-tooltip1"
                      tooltip-title={toolTipText}
                    >
                      {renderOriginal === true && (
                        <span
                          className="fa fa-refresh ml-2 cursor-pointer"
                          onClick={() => this.convertOriginal()}
                        ></span>
                      )}
                      {renderOriginal === false && (
                        <img alt="usd"
                          src={dollarImg}
                          style={{
                            marginLeft: "5px",
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => this.convertOriginal()}
                        />
                      )}
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.showModal()}
                    >
                      Export Data
                    </button>
                    <ExportInvoicesModal
                      {...this.props}
                      {...this.state}
                      usdData={usdData}
                      cadData={cadData}
                      originalData={originalData}
                      // earningsDatas={usdData}
                      onCloseModal={this.onCloseModal}
                      onInputValueChange={this.onchange}
                      AddMinerRequest={this.AddMinerRequest}
                    />
                  </h5>
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageInvoices;
