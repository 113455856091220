import React from 'react';

class KycVerifiedSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  async componentWillMount() {
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('remove');
    }
  }

  render() {
    return (
      <div className="register-container">
        <div class="section-heading centered event-success-message">
          <h1>KYC Details Verified </h1>
          <p>You have successfully verified user's KYC details. </p>
        </div>
      </div>
    );
  }
}
export default KycVerifiedSuccess