import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";

import { FilePond, registerPlugin } from "react-filepond";
// import Cookies from "universal-cookie";
import Api from "../../services/api";
import { niceNumberDisplay, niceNumberDecimalDisplay } from "../../utils/Util";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { API_ROOT } from "../../services/api-config";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const currencies = [
  { label: "USD", value: "USD" },
  { label: "CAD", value: "CAD" },
];
class WireTransferModal extends Component {
  constructor(props, state) {
    super(props);
    this.state = {
      userNote: "",
      invoice: this.props.invoiceId._id,
    };
    // console.log("invoice",this.state.invoice)
    this.changeAmount = this.changeAmount.bind(this);
    this.changeUserNote = this.changeUserNote.bind(this);
    this.saveNrtBuyPaymentRequest = this.saveNrtBuyPaymentRequest.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadImageArray = {};
  }

  async componentDidMount() {
    // this.getDocument()
  }

  onChangeCurrencyType(value) {
    this.setState({
      currency: value,
    });
  }
  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  async saveNrtBuyPaymentRequest() {
    const { file } = this.state;
    const data = new FormData();
    data.append("image", this.uploadImageArray["file"]);
    data.append("transactionAmount", this.state.transactionAmount);
    data.append("userNote", this.state.userNote);
    data.append("issuerId", this.state.issuerId);
    data.append("invoiceId", this.state.invoiceNumber);
    data.append("email", "sagarkumarapril@gmail.com");

    let authenticationToken = this.props.authToken;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authenticationToken,
      },
    };

    axios
      .post(API_ROOT + "/miner/WireTransferPayment", data, config)
      .then(function (response) {
        if (!_.isUndefined(response)) {
          if (response.data.code === 200) {
            this.props.onCloseModal();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }
      })
      .catch(function (error) {
        // toast.error(messageConstants.SOMETHING_WENT_WRONG);
      });
  }
  async changeAmount(event) {
    this.setState({
      transactionAmount: event.target.value,
    });
  }
  async changeUserNote(event) {
    this.setState({
      userNote: event.target.value,
    });
  }
  render() {
    const {
      wireTransferModalOpen,
      onCloseModal,
      onInputValueChange,
      reference,
      invoiceId,
      transactionAmount,
      kycOperation,
      documnetLink,
      adminNote,
      saveNrtBuyPaymentRequest,
      paymentRequestButtonLoading,
      authToken,
    } = this.props;
    // console.log("documnetLink", documnetLink);

    const {
      uploadImageArray,
      bankName,
      bankAccountNumber,
      bankSwiftCode,
      bankBicCode,
    } = this.state;
    let buttonDisabled = paymentRequestButtonLoading === true ? true : false;
    // console.log("bankName", bankName);
    let wireTransferDetails = true;
    // let amount = Number(invoiceId.raisedAmountinUSD).toFixed(2);
    // amount = `$ ${amount}`;
    // console.log("invoiceId",invoiceId)
    return (
      <Modal
        open={wireTransferModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="text-large">Wire Transfer Details</h4>
                    <form className="mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="transactionAmount"
                          className="text-default-color"
                        >
                          Transaction Amount
                          <span className="required-field">*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            className="form-control mr-3 "
                            id="transactionAmount"
                            placeholder="Enter transaction amount"
                            type="text"
                            value={transactionAmount}
                            name="transactionAmount"
                            // onChange={this.changeAmount}
                            disabled={true}
                          />
                        </div>
                        <div className="text-center mt-4 receive-text text-default-color"></div>
                      </div>

                      <label className="text-default-color">
                        {" "}
                        Proof of Payment
                      </label>
                      <div
                        className="payment-proof-container dropzoneIdContainer"
                        // style={{ backgroundColor: "#dddddd" }}
                      >
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <img
                              style={{
                                width: "300px",
                                height: "200px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                boxShadow: "2px 2px 5px gray"
                              }}
                              src={documnetLink}
                              alt=""
                              onClick={() => {
                                window.open(documnetLink);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          className="btn mb-2 mr-2 cancel-button"
                          type="button"
                          onClick={onCloseModal}
                          // style={{padding:"0px"}}
                        >
                          Cancel
                        </button>
                        <button
                          className="mr-2 mb-2 btn btn-primary"
                          type="button"
                          onClick={() => {
                            kycOperation(
                              invoiceId._id,
                              "a",
                              reference,
                              invoiceId
                            );
                            onCloseModal();
                          }}
                        >
                          {" "}
                          Accept
                        </button>
                        <button
                          className="mr-2 mb-2 btn btn-danger"
                          type="button"
                          onClick={() => {
                            kycOperation(
                              invoiceId._id,
                              "r",
                              reference,
                              invoiceId
                            );
                            onCloseModal();
                          }}
                        >
                          {" "}
                          Reject
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default WireTransferModal;
