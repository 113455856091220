import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import Api from "../../services/api";
import browseFileImg from "../../assets/img/browse_file.png?v1";
class AdminManageKycDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      contactNo: "",
      gender: "",
      dob: "",
      countryName: "",
      state: "",
      city: "",
      postalCode: "",
      kycStatus: "",
      note: "",
      kycDocumentTypeListTypes: "",
      kycFiles: "",
      objectIdRef: "",
    };
    this.onchange = this.onchange.bind(this);
    this.imageExtension = ["png", "jpg", "jpeg", "gif", "bmp"];
  }

  async componentWillMount() {
    const api = new Api();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const authenticationToken = this.props.authToken;
    const detailId = this.props.match.params.id;
    if (!_.isUndefined(detailId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("admin/getKYCByDetailId", { detailId: detailId });
        if (response.code === 200) {
          this.setState(
            {
              fullName: response.data.kycDetail.fullName,
              email: response.data.kycDetail.email,
              contactNo: response.data.kycDetail.contactNo,
              gender: response.data.kycDetail.gender,
              dob: response.data.kycDetail.dob,
              countryName: response.data.kycDetail.countryId.name,
              state: response.data.kycDetail.state,
              city: response.data.kycDetail.city,
              postalCode: response.data.kycDetail.postalCode,
              kycStatus: response.data.kycDetail.status,
              note: response.data.kycDetail.note,
              kycDocumentTypeListTypes: response.data.kycDocumentTypeListTypes,
              kycFiles: response.data.kycFiles,
              objectIdRef: response.data.objectIdRef,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const {
      fullName,
      email,
      contactNo,
      gender,
      dob,
      countryName,
      state,
      city,
      postalCode,
      kycStatus,
      note,
      kycDocumentTypeListTypes,
      kycFiles,
      objectIdRef,
    } = this.state;
    var dobDate = moment(dob).format("DD/MM/YYYY");
    let labelClassName = "";
    let label = "";
    if (gender === "m") {
      label = "Male";
      labelClassName = "badge-primary";
    } else if (gender === "f") {
      label = "Female";
      labelClassName = "badge-warning";
    }

    let kycStatuslabelClassName =
      kycStatus === "a"
        ? "badge-success"
        : kycStatus === "u"
          ? "Under badge-primary"
          : kycStatus === "r"
            ? "badge-danger"
            : "badge-warning";
    let kycLabel =
      kycStatus === "a"
        ? "Approved"
        : kycStatus === "u"
          ? "Under Review"
          : kycStatus === "r"
            ? "Rejected"
            : "Pending";

    let displayDocumentTypes = "";
    if (
      !_.isUndefined(kycDocumentTypeListTypes) &&
      !_.isEmpty(kycDocumentTypeListTypes)
    ) {
      displayDocumentTypes = kycDocumentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div key={mainIndex} className="element-box">
            <h5
              className={
                parentDocumentType.children.length > 1
                  ? "text-center"
                  : "d-none hide"
              }
            >
              <span className="document-type-header">
                {parentDocumentType.label}
              </span>
            </h5>
            <div className="row">
              <div className="col-md-12">
                {parentDocumentType.children.map((documentType, index) => (
                  <div key={index}
                    className={
                      parentDocumentType.children.length > 1 ? "mt-4" : ""
                    }
                  >
                    <h6 className="element-header">
                      <span>{documentType.label}</span>
                    </h6>
                    <div className="row">
                      {_.get(objectIdRef, [documentType._id], []).map(
                        (image, imageIndex) => (
                          <div key={imageIndex} className="col-md-6 mt-4 mobile-center-img">
                            {(_.includes(kycFiles[image], "png") ||
                              _.includes(kycFiles[image], "jpg") ||
                              _.includes(kycFiles[image], "jpeg") ||
                              _.includes(kycFiles[image], "bmp")) && (
                                <a
                                  rel="noopener noreferrer"
                                  href={kycFiles[image]}
                                  target="_blank"
                                >
                                  <img
                                    src={kycFiles[image]}
                                    height={350}
                                    className="kyc-document-img"
                                    alt={documentType.label}
                                  />
                                </a>
                              )}
                            {!_.includes(kycFiles[image], "png") &&
                              !_.includes(kycFiles[image], "jpg") &&
                              !_.includes(kycFiles[image], "jpeg") &&
                              !_.includes(kycFiles[image], "bmp") && (
                                <a
                                  rel="noopener noreferrer"
                                  href={kycFiles[image]}
                                  target="_blank"
                                >
                                  <img
                                    src={browseFileImg}
                                    height={200}
                                    alt={documentType.label}
                                  />
                                </a>
                              )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span>Details</span>
                      <Link to="/manage_kyc" className="pull-right">
                        Back to KYC list
                      </Link>
                    </div>
                  </h6>

                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Full Name : </span>{" "}
                        {fullName}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">Email : </span>{" "}
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Contact No : </span>{" "}
                        {contactNo}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">
                          Date of birth :{" "}
                        </span>
                        {dobDate}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold"> Gender :</span>{" "}
                        <span className={"badge " + labelClassName}>
                          {label}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">City : </span> {city}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">
                          State/Province :{" "}
                        </span>
                        {state}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">Country : </span>
                        {countryName}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        <span className="font-weight-bold">Postal Code : </span>
                        {postalCode}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div>
                        <span className="font-weight-bold">Status:</span>{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {displayDocumentTypes}
              {kycStatus !== "u" && !!note && (
                <div className="element-box">
                  <h6>Note</h6>
                  {note}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminManageKycDetail;
