import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import auth from "../../utils/auth";
import Basepages from "./Basepages";
import AdminBasepages from "./Admincustombasepages";
import AdminSignin from "../Signin/Signin";
import GlobalMining from "../Global Mining/GolbalMining";
import GlobalEarning from "../Global Earning/GlobalEarning";
import GlobalWorkers from "../Global Workers/GlobalWorkers";
import GlobalHistoricWorkerModal from "../Global Historic Worker/GlobalHistoricWorkers";
import GlobalDailyEarning from "../Global Daily Earning/GlobalDailyEarning";
import GlobalDailyWorkers from "../Global daily Worker/GlobalDailyWorker";
import GetPayment from "../GetPayment/GetPayment";
import SendEmail from "../SendEmail/SendEmail";
import ManageWireTransfer from '../Manage Wire Transfer/ManageWireTransfer';
import ManageBudgetWireTransfer from '../Manage Budget Wire Transfer/ManageBudgetWireTransfer';
import ManageInvoices from '../ManageInvoices/ManageInvoices';
import AdminDashboard from "../Dashboard/Dashboard";
import AdminChangePassword from "../AdminChangePassword/AdminChangePassword";
import AdminManageKyc from "../ManageKyc/ManageKyc";
import AdminManageMinerKyc from "../ManageMinerKyc/ManageMinerKyc";
import AdminManageKycDetail from "../ManageKyc/ManageKycDetail";
import AdminUserDetail from "../UserDetail/UserDetail";
import AdminUserIdentificationDetail from "../UserIdentificationDetail/UserIdentificationDetail";
import AdminUserFatcaDetail from "../UserFatcaDetail/UserFatcaDetail";
import AdminIdentificationDetailPdfGenerate from "../IdentificationPdfGenerate/IdentificationPdfGenerate";
import AdminManagePaymentRequests from "../ManagePaymentRequests/ManagePaymentRequests";
import AdminManageResources from "../ManageResources/ManageResources";
import AdminResourceAdd from "../ManageResources/AddResource";
import AdminManageProgressCategories from "../ManageProgressCategories/ManageProgressCategories";
import ManageCategories from "../ManageCategories/ManageCategories";
import AdminManageUsers from "../ManageUsers/ManageUsers";
import ManageDealers from "../ManageDealers/ManageDealers";
import WorkerInventory from "../WorkerInventory";

import ManageCampaigns from "../ManageCampaigns/ManageCampaigns";
import CampaignDetails from "../ManageCampaigns/CampaignDetails";
import ManageDecTransfer from "../ManageDecTransfer/ManageDecTransfer";
import CampaignProgressReports from "../ManageCampaigns/CampaignProgressReports";

import AdminManageTeamMembers from "../ManageTeamMembers/ManageTeamMembers";
import AdminLogout from "../Logout/Logout";
import ManageMandates from "../ManageMandates/ManageMandates";
import EmailVerify from "../EmailVerify/EmailVerify";
import EventVerify from "../EventVerify/EventVerify";
import VerifySuccess from "../VerifySuccess/VerifySuccess";
import EventSuccess from "../EventSuccess/EventSuccess";
import EventError from "../EventError/EventError";
import ManageUsdTransactions from "../ManageUsdTransactions/ManageUsdTransactions";
import ManageExchangeVerifiers from "../ManageExchangeVerifiers/ManageExchangeVerifiers";
import ExchangeVerifierEmailVerify from "../ExchangeVerifierEmailVerify/ExchangeVerifierEmailVerify";
import ExchangeVerifierSuccess from "../ExchangeVerifierSuccess/ExchangeVerifierSuccess";
import SellMandateVerify from "../SellMandateVerify/SellMandateVerify";
import MandateSuccess from "../MandateSuccess/MandateSuccess";
import UserKycVerifyEvent from "../UserKycVerifyEvent/UserKycVerifyEvent";
import KycVerifiedSuccess from "../KycVerifiedSuccess/KycVerifiedSuccess";
import WhitelistAddresses from "../WhitelistAddresses/WhitelistAddresses";
import ManageWithdrawals from "../ManageWithdrawals/ManageWithdrawals";

import WiredTransferVerifyEvent from "../WiredTransferVerifyEvent/WiredTransferVerifyEvent";
import WiredTransferVerifiedSuccess from "../WiredTransferVerifiedSuccess/WiredTransferVerifiedSuccess";

import ProgressReportVerifyEvent from "../ProgressReportVerifyEvent/ProgressReportVerifyEvent";
import ProgressReportVerifiedSuccess from "../ProgressReportVerifyEvent/ProgressReportVerifiedSuccess";
import AdminUserDetailMachineAdd from "../UserDetail/MachineAdd";
import AdminManageMinerKycDetail from "../ManageMinerKyc/ManageMinerKycDetail";

let currentLocation = "";
let adminLoginCheckInterval;

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  let messageLoginPage = "",
    messageClass = "";
  const authToken = auth.getToken("", true);
  if (authToken === null) {
    messageLoginPage = "LOGIN_REQUIRED";
    messageClass = "warning";
  }
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !_.isNull(authToken) ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/admin",
                state: {
                  from: props.location,
                  message: messageLoginPage,
                  messageClass,
                },
              }}
            />
          )
        }
      />
    </div>
  );
};

const AdminPublicRoute = ({ component: Component, ...rest }) => {
  const authToken = auth.getToken("", true);
  return (
    <Route
      {...rest}
      render={(props) =>
        _.isNull(authToken) ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.adminLogout = this.adminLogout.bind(this);
    this.checkAdminLogin = this.checkAdminLogin.bind(this);
    this.pageProgress = this.pageProgress.bind(this);
  }

  adminLogout(msg, message_type) {
    auth.clearToken("");
    auth.clearUserInfo("");
    clearInterval(adminLoginCheckInterval);
    adminLoginCheckInterval = false;
    this.props.history.push({
      pathname: "/",
    });
  }

  checkAdminLogin() {
    const adminAuthToken = auth.getToken("");
    if (!_.isNull(adminAuthToken)) {
      adminLoginCheckInterval = setInterval(() => {
        const updatedToken = auth.getToken("");
        if (_.isNull(updatedToken)) {
          this.adminLogout("TOKEN_EXPIRED", "warning");
        }
      }, 1500);
    }
  }

  pageProgress(action) {
    var body = document.body;
    if (action === "remove") {
      body.classList.add("page-loaded");
    } else if (action === "force_remove") {
      setTimeout(() => {
        body.classList.add("page-loaded");
      }, 5000);
    } else {
      body.classList.remove("page-loaded");
    }
  }

  componentDidMount() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    this.checkAdminLogin();
  }

  componentWillUnmount() {
    clearInterval(adminLoginCheckInterval);
    adminLoginCheckInterval = false;
  }

  updateWindowDimensions = () => {
    const windowHeight = window.innerHeigh;
    const windowWidth = window.innerWidth;
    const isMobileSized = window.innerWidth < 700 ? true : false;
    this.setState({ windowWidth, windowHeight, isMobileSized });
  };

  renderEmailVerifyRoute() {
    return (
      <div>
        <AdminPublicRoute
          {...this.state}
          path="/verifier_verification/:id"
          component={EmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/event_verification/:id"
          component={EventVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/verifier_success"
          component={VerifySuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/event_success"
          component={EventSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/event_error"
          component={EventError}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/sellmandate/verifier/verification/:id"
          component={ExchangeVerifierEmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/exchange_verifier_success"
          component={ExchangeVerifierSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/mandate_verification/:id"
          component={SellMandateVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/mandate_success"
          component={MandateSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/kyc_verification/:id"
          component={UserKycVerifyEvent}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/kyc_verification_success"
          component={KycVerifiedSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/wired_transfer_verification/:id"
          component={WiredTransferVerifyEvent}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/wired_transfer_verification_success"
          component={WiredTransferVerifiedSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/progress_report_verification/:id"
          component={ProgressReportVerifyEvent}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <AdminPublicRoute
          {...this.state}
          path="/progress_report_verification_success"
          component={ProgressReportVerifiedSuccess}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
      </div>
    );
  }

  renderAdminAuthenticationRoute() {
    return (
      <Basepages
        {...this.state}
        adminLogout={this.adminLogout}
        currentLocation={currentLocation}
      >
        <AdminPublicRoute
          {...this.state}
          path="/"
          component={AdminSignin}
          urlpath={currentLocation}
          checkAdminLogin={this.checkAdminLogin}
          pageProgress={this.pageProgress}
        />
      </Basepages>
    );
  }

  renderAdminRoute() {
    const authToken = auth.getToken("", true);
    const authUserInfo = auth.getUserInfo("", true);
    return (
      <AdminBasepages
        {...this.state}
        adminLogout={this.adminLogout}
        currentLocation={currentLocation}
      >
        <AdminPrivateRoute
          {...this.state}
          path="/dashboard"
          component={AdminDashboard}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/global_mining"
          component={GlobalMining}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/global_earning"
          component={GlobalEarning}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/global_daily_earning"
          component={GlobalDailyEarning}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/global_workers"
          component={GlobalWorkers}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          exact path="/global_historic_workers"
          component={GlobalHistoricWorkerModal}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/global_daily_workers"
          component={GlobalDailyWorkers}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/getpayment"
          component={GetPayment}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/sendEmail"
          component={SendEmail}
          urlpath={currentLocation}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/change_password"
          component={AdminChangePassword}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/kyc_detail/:id"
          component={AdminManageKycDetail}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/miner_kyc_detail/:id"
          component={AdminManageMinerKycDetail}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/user/:id/machine/add"
          component={AdminUserDetailMachineAdd}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/machine/edit/:id/:machineId"
          component={AdminUserDetailMachineAdd}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/user_detail/:id"
          component={AdminUserDetail}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/user_identificaiton_detail/:id"
          component={AdminUserIdentificationDetail}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/user_fatca_crs_detail/:id"
          component={AdminUserFatcaDetail}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/identificaiton_details_pdf/:id"
          component={AdminIdentificationDetailPdfGenerate}
          authUserInfo={authUserInfo}
          authToken={authToken}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_kyc"
          component={AdminManageKyc}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_miner_kyc"
          component={AdminManageMinerKyc}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_wire_transfer"
          component={ManageWireTransfer}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_budget_wire_transfer"
          component={ManageBudgetWireTransfer}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_invoices"
          component={ManageInvoices}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/whitelist_addresses"
          component={WhitelistAddresses}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_users"
          component={AdminManageUsers}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_dealers"
          component={ManageDealers}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_dec_transfer"
          component={ManageDecTransfer}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/campaign/progress_reports/:listingId"
          component={CampaignProgressReports}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_campaigns"
          component={ManageCampaigns}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_team_members"
          component={AdminManageTeamMembers}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_payment_requests"
          component={AdminManagePaymentRequests}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_resources"
          component={AdminManageResources}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_progress_categories"
          component={AdminManageProgressCategories}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_categories"
          component={ManageCategories}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/resource/add"
          component={AdminResourceAdd}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/logout"
          component={AdminLogout}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          adminLogout={this.adminLogout}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/campaign_detail/:id"
          component={CampaignDetails}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_mandates"
          component={ManageMandates}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_withdrawals"
          component={ManageWithdrawals}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_usd_transactions"
          component={ManageUsdTransactions}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/manage_exchange_verifiers"
          component={ManageExchangeVerifiers}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
        <AdminPrivateRoute
          {...this.state}
          path="/worker_inventory"
          component={WorkerInventory}
          urlpath={currentLocation}
          authToken={authToken}
          authUserInfo={authUserInfo}
          pageProgress={this.pageProgress}
        />
      </AdminBasepages>
    );
  }

  renderMainPage() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    if (_.isNull(authToken) || _.isUndefined(authToken)) {
      return (
        <Basepages
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <AdminSignin
                {...this.props}
                {...this.state}
                urlpath={currentLocation}
                checkAdminLogin={this.checkAdminLogin}
                pageProgress={this.pageProgress}
              />
            )}
          />
        </Basepages>
      );
    } else {
      return (
        <AdminBasepages
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <AdminDashboard
                {...this.props}
                {...this.state}
                pageProgress={this.pageProgress}
                authToken={authToken}
                authUserInfo={authUserInfo}
              />
            )}
          />
        </AdminBasepages>
      );
    }
  }

  render() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];

    let adminRoutes = [
      "dashboard",
      "site_settings",
      "token_settings",
      "manage_kyc",
      "sendEmail",
      "manage_miner_kyc",
      "whitelist_addresses",
      "manage_users",
      "global_mining",
      "global_daily_earning",
      "global_earning",
      "manage_invoices",
      "getpayment",
      "global_workers",
      "global_historic_workers",
      "global_daily_workers",
      "manage_payment_requests",
      "logout",
      "kyc_detail",
      "miner_kyc_detail",
      "transactions",
      "unresolved_transactions",
      "user_detail",
      "user_identificaiton_detail",
      "user_fatca_crs_detail",
      "identificaiton_details_pdf",
      "manage_resources",
      "resource",
      "change_password",
      "manage_team_members",
      "manage_campaigns",
      "manage_wire_transfer",
      "manage_budget_wire_transfer",
      "campaign_detail",
      "manage_progress_categories",
      "manage_categories",
      "campaign",
      "manage_mandates",
      "manage_verifiers",
      "manage_verification_events",
      "exchange",
      "manage_usd_transactions",
      "manage_exchange_verifiers",
      "manage_withdrawals",
      "manage_dealers",
      "manage_dec_transfer",
      "user",
      "machine",
      "worker_inventory"
    ];

    let emailVerify = [
      "verifier_verification",
      "event_verification",
      "verifier_success",
      "event_success",
      "event_error",
      "sellmandate",
      "exchange_verifier_success",
      "mandate_verification",
      "mandate_success",
      "kyc_verification",
      "kyc_verification_success",
      "wired_transfer_verification",
      "wired_transfer_verification_success",
      "progress_report_verification",
      "progress_report_verification_success",
    ];

    if (currentLocation === "") {
      return <div>{this.renderMainPage()}</div>;
    } else if (
      currentLocation !== "" &&
      emailVerify.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderEmailVerifyRoute()}</div>;
    } else if (
      currentLocation !== "" &&
      adminRoutes.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderAdminRoute()}</div>;
    } else {
      return <div>{this.renderAdminAuthenticationRoute()}</div>;
    }
  }
}
export default withRouter(Layout);
