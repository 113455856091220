import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import moment from 'moment';
import { txnLink, btcExplorerLink, tokenSymbol } from '../../services/api-config';

class DetailModal extends Component {

  render() {
    const { detailModalOpen, onCloseModal, requestDetails } = this.props;
    let dispCurrencyType = (requestDetails && requestDetails.currencyType && requestDetails.currencyType !== 'USD') ? requestDetails.currencyType : '';
    dispCurrencyType = (dispCurrencyType === 'XPR') ? tokenSymbol : dispCurrencyType;

    return (
      <Modal open={detailModalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center" style={{ 'min-width': '520px' }}>
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">Withdrawal request details</h4>

                    <div className="row">
                      <div className="col-md-3 text-left">
                        <span className="text-left">Txn #</span>
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.txnId) ? requestDetails.txnId : 'N/A'}
                      </div>
                    </div>

                    {requestDetails && requestDetails.currencyType === 'USD' && typeof requestDetails.txnHash !== 'undefined' && requestDetails.txnHash !== '' &&
                      <div className="row mt-3">
                        <div className="col-md-3 text-left">
                          <span className="text-left">Transaction #</span>
                        </div>
                        <div className="col-md-9 text-left">
                          {(requestDetails && requestDetails.txnHash) ? requestDetails.txnHash : 'N/A'}
                        </div>
                      </div>
                    }

                    <div className="row mt-3">
                      <div className="col-md-3 text-left">
                        <span className="text-left">Amount</span>
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.currencyType && requestDetails.currencyType === 'USD') ? '$' : ''}{(requestDetails && requestDetails.amount) ? requestDetails.amount : 'N/A'} &nbsp;
                        {dispCurrencyType}
                      </div>
                    </div>
                    
                    {requestDetails && requestDetails.currencyType === 'XPR' &&
                      <div className="row mt-3">
                        <div className="col-md-3 text-left">
                          <span className="text-left">ETH Address</span>
                        </div>
                        <div className="col-md-9 text-left">
                          {(requestDetails && requestDetails.userId) ? requestDetails.userId.receivingEthAddress : 'N/A'}
                        </div>
                      </div>
                    }

                    <div className="row mt-3">
                      <div className="col-md-3 text-left">
                        <span className="text-left"> Note</span>
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.userNote) ? requestDetails.userNote : 'N/A'}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-3 text-left">
                        <span className="text-left"> Admin Note</span>
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.adminNote) ? requestDetails.adminNote : 'N/A'}
                      </div>
                    </div>

                    {requestDetails && requestDetails.currencyType === 'ETH' && typeof requestDetails.txnHash !== 'undefined' && requestDetails.txnHash !== '' &&
                      <div className="row mt-3">
                        <div className="col-md-3 text-left">
                          <span className="text-left"> View on etherscan</span>
                        </div>
                        <div className="col-md-9 text-left">
                          <a href={`${txnLink}/${requestDetails.txnHash}`} target="_blank" rel="noopener noreferrer">View Details</a>
                        </div>
                      </div>
                    }

                    {requestDetails && requestDetails.currencyType === 'BTC' && typeof requestDetails.txnHash !== 'undefined' && requestDetails.txnHash !== '' &&
                      <div className="row mt-3">
                        <div className="col-md-3 text-left">
                          <span className="text-left"> View on bitcoin explorer</span>
                        </div>
                        <div className="col-md-9 text-left">
                          <a href={`${btcExplorerLink}/${requestDetails.txnHash}`} target="_blank" rel="noopener noreferrer">View Details</a>
                        </div>
                      </div>
                    }  
                    
                    <div className="row mt-3">
                      <div className="col-md-3 text-left">
                        <span className="text-left">Created date</span>
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.createdOnUTC) ? moment(requestDetails.createdOnUTC).format("DD/MM/YYYY HH:mm") : 'N/A'}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-3 text-left">
                        Txn date
                      </div>
                      <div className="col-md-9 text-left">
                        {(requestDetails && requestDetails.updatedOnUTC) ? moment(requestDetails.updatedOnUTC).format("DD/MM/YYYY HH:mm") : 'N/A'}
                      </div>
                    </div>
                    {requestDetails && requestDetails.ipAddress !== '' &&
                      <div className="row mt-3">
                        <div className="col-md-3 text-left">IP Address</div>
                        <div className="col-md-9 text-left">
                          {(requestDetails && requestDetails.ipAddress) ? requestDetails.ipAddress : 'N/A'}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DetailModal;