import React, { Component } from 'react';
import Modal from "react-responsive-modal";

class AddCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { modalOpen, onCloseModal, onInputValueChange, AddCategoryRequest, buttonLoading, title } = this.props;
    let buttonDisabled = (buttonLoading === true) ? true : false;
    return (
      <Modal open={modalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">Add Progress Report Category</h4>
                    <form>

                      <div className="form-group">
                        <label htmlFor="title">Title<span className="required-field">*</span></label>
                        <input className="form-control w-100" id="title" placeholder="Enter title" type="text" value={title} name="title" onChange={onInputValueChange} />
                      </div>

                      <div className="d-flex justify-content-center">
                        <button className="btn btn-primary btn-sm" type="button" onClick={AddCategoryRequest} disabled={buttonDisabled}> Submit {buttonLoading && (
                          <i className="fa-spin fa fa-spinner text-white m-1" />
                        )}</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddCategoryModal;